import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import Footer from "../../../../common_components/Footer";
import Footerwithdr from "../../../../common_components/Footerwithdr";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import Modal from "react-modal";
import {
  primaryChapterListActionId,
  toastError,
  toastSuccess,
  primaryChapterMasterListActionId
} from "../../../../utils/constants";
import Pagination from "../../../../utils/pagination";
import moment from "moment";
import { addChapterMaster_action, deleteChapterMaster_action, updateChapterMaster_action } from "../../../../actions/admin_module_action/master_action/chapter_master_action";
import { fill_action, fill_master_action } from "../../../../actions/fillCombo_action";

const breadcrumbCSS = `
/* Breadcrumb styles */
.breadcrumb {
  padding: 0;
  margin: 0;
  list-style: none;
  color:black;
}
.breadcrumb-item::before {
    content:"" !important;
}
.breadcrumb-item {
  display: inline-block;
  margin-right: -10px;
  font-size: 14px;
}

/* Style for the right angle icon */
.breadcrumb-item .fa-angle-double-right {
  margin: 0 10px;
}

`;

class masterChapterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      UserID: "",
      ExhibitionID: "",
      ReceiptID: "",
      noOfLevels: 1,
      parentID: 0,
      AccountID: "",
      Level: "",
      currentData: [],
      primaryChapterListData: [],
      level0: [],
      level2: [],
      level3: [],
      level4: [],
      currentLevel0: [],
      currentLevel2: [],
      currentLevel3: [],
      currentLevel4: [],
      deleteData: '',
      isOpen: false,
      isModalOpen: false,
      deleteChapterdata: '',
      //row work
      showNewRowChapter: false,
      chapterList: [],
      ParentChapterID: 0,
      ChapterDetails: {
        ChapterName: "",
      },
      editedRowIndexChapter: null,
      editedRowDataChapters: {},
      editedChapterName: '',
      Srno: '',
    };
    this.getFillList = this.getFillList.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    sessionStorage.removeItem('parentsID')
    sessionStorage.removeItem('level')

    this.getFillList(
      primaryChapterMasterListActionId,
      "",
      "",
      "",
      "",
      ""
    );

  }
  getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
    const {
      level3,
      level4,
      level2,
      level0,
      is_update,
      PrimaryChapterId,
      primaryChapterRadio,
      stallAreaId,
    } = this.state;
    if (!is_update) {
      this.setState({ isLoading: true });
    }
    this.setState({ isLoading: true });
    this.props
      .fill_master_action(
        actionID,
        exhibitionID,
        moduleID,
        keyValue,
        value,
        from
      )
      .then((data) => {
        if (data.error != 1) {
          // country list
          if (actionID === primaryChapterMasterListActionId) {
            console.log("primaryChapterListActionId 121212", data.data);
            console.log(data.data["level4"])
            this.setState({
              // currentData: data.data["primaryChapter"],
              primaryChapterListData: data.data["primaryChapter"],
              level0: data.data["level0"],
              level2: data.data["level2"],
              level3: data.data["level3"],
              level4: data.data["level4"],
              // isLoading: false,
            });
            console.log('fillist', sessionStorage.getItem('parentsID'), sessionStorage.getItem('level'))
            var curArr = []
            if (sessionStorage.getItem('level') == 2) {
              for (var i = 0; i < data.data["level2"].length; i++) {
                if (data.data["level2"][i].ParentChapterID == JSON.parse(sessionStorage.getItem('parentsID'))) {
                  curArr.push(data.data["level2"][i])
                }
              }
              this.setState({ noOfLevels: 2 })
            }
            else if (sessionStorage.getItem('level') == 3) {
              for (var i = 0; i < data.data["level3"].length; i++) {
                if (data.data["level3"][i].ParentChapterID == sessionStorage.getItem('parentsID')) {
                  curArr.push(data.data["level3"][i])
                }
              }
              console.log('fillist level3', curArr)
              this.setState({ noOfLevels: 3 })
            }
            else if (sessionStorage.getItem('level') == 4) {
              for (var i = 0; i < data.data["level4"].length; i++) {
                if (data.data["level4"][i].ParentChapterID == sessionStorage.getItem('parentsID')) {
                  curArr.push(data.data["level4"][i])
                }
              }
              this.setState({ noOfLevels: 4 })
              console.log('fillist level4', curArr)
            }
            else {
              curArr = data.data["primaryChapter"]
            }
            this.setState({ currentData: curArr })
            this.setState({ isLoading: false });

          } else {
            this.setState({ isLoading: false });
            toastError(data.msg);
          }
        }
      })
  }
  handleAddRowChapter() {
    const { chapterList, ChapterDetails, currentData, level3, noOfLevels, parentID, Level, level2, ParentChapterID } = this.state;
    const newRow = { ...ChapterDetails };
    this.setState({
      chapterList: [...chapterList, newRow],
      ChapterDetails: {
        ChapterName: "",
      },
    });
    var Srno
    if (noOfLevels == 1) {
      Srno = null
    } else if (noOfLevels == 2) {
      Srno = level2.length + 1
    } else if (noOfLevels == 3) {
      for (var i = 0; i < level2.length; i++) {
        if (level2[i].ChapterID == parentID) {
          var lvl2Sr = level2[i].ChapterName.split(' ')
          Srno = lvl2Sr[0] + '.' + (parseInt(currentData.length) + 1)
        }
      }
    } else if (noOfLevels == 4) {
      for (var i = 0; i < level3.length; i++) {
        if (level3[i].ChapterID == parentID) {
          var lvl3Sr = level3[i].ChapterName.split(' ')
          Srno = lvl3Sr[0] + '.' + (parseInt(currentData.length) + 1)
        }
      }
    }

    console.log(currentData, "updaaaa", noOfLevels, Srno, parentID, newRow.ChapterName, 1)
    if (noOfLevels != null && parentID != null && newRow.ChapterName != '' && newRow.ChapterName != null) {
      this.setState({ isLoading: true })
      this.props.addChapterMaster_action(noOfLevels, Srno, parentID, newRow.ChapterName, 1)
        .then((data) => {
          if (data.error != 1) {
            this.getFillList(primaryChapterMasterListActionId, '', '', '', '', '');
            this.setState({ isLoading: false, isOpen: false, })
            toastSuccess("added successfully")
          }
          else {
            this.setState({ isLoading: false })
            toastError(data.msg)
          }
          this.setState({
            showNewRowChapter: false, // Hide the "Add" row after addingc
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    else {
      toastError("chapter Name cannot be null")
    }
    setTimeout(() => {

      console.log('Level, SrNo, ParentChapterID, ChapterName, IsCheckbox', this.state.noOfLevels, null, this.state.parentID, newRow.ChapterName)
    }, 200);

  }

  toggleNewRowChapter() {
    this.setState((prevState) => ({
      showNewRowChapter: !prevState.showNewRowChapter,
      isOpen: !this.state.isOpen
    }));
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.handleAddRowChapter();
    }
  }
  handleEditRowChapter(index, data) {
    var chapterName = ''
    if (data.Level != 1) {
      for (var i = 0; i < data.ChapterName.length; i++) {
        if (data.ChapterName[i] == ' ') {
          for (var j = i + 1; j < data.ChapterName.length; j++) {
            console.log(data.ChapterName[j])
            chapterName = chapterName + data.ChapterName[j]
          }
          break
        }
      }
      console.log(chapterName)
    } else {
      chapterName = data.ChapterName
    }
    this.setState({
      editedRowIndexChapter: index,
      editedRowDataChapters: { ...data },
      editedChapterName: chapterName,
      isOpen: true
    });
    console.log(data)
  }
  handleRowEditFieldChangeChapter(index, field, value) {
    const editedDataCopy = { ...this.state.editedRowDataChapters };
    editedDataCopy[field] = value;
    const chapterName = value
    this.setState({ editedRowDataChapters: editedDataCopy, editedChapterName: chapterName });
  }

  saveEditedRowChapter(index, data) {
    const { chapterList, editedRowDataChapters } = this.state;
    const updatedList = [...chapterList];
    updatedList[index] = editedRowDataChapters;
    this.setState({
      chapterList: updatedList,
      editedRowIndexChapter: null,
      editedRowDataChapters: {},
    });
    if (updatedList.length > 0) {

      this.setState({ isLoading: true })
      this.props.updateChapterMaster_action(updatedList[index].ChapterID, updatedList[index].ChapterName)
        .then((data) => {
          if (data.error != 1) {
            this.getFillList(primaryChapterMasterListActionId, '', '', '', '', '');
            this.setState({ isLoading: false })
            toastSuccess("updated successfully")
          }
          else {
            this.setState({ isLoading: false })
            toastError(data.msg)
          }
          this.setState({
            showNewRowChapter: false, // Hide the "Add" row after addingc
            isOpen: false,
            // noOfLevels: 1
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    console.log(updatedList[index], chapterList, data)
  }
  cancelEditRowChapter() {
    this.setState({
      editedRowIndexChapter: null,
      editedRowDataChapters: {},
      editedChapterName: '',
      isOpen: false
    });
  }
  handleDeleteRowChapter(data) {
    const { chapterList } = this.state;
    // const updatedList = [...chapterList];
    // updatedList.splice(index, 1); // Remove the row at the specified index
    // this.setState({
    //   chapterList: updatedList,
    // });
    if (data.ChapterID != null && data.ChapterID != '') {

      this.setState({ isLoading: true })
      this.props.deleteChapterMaster_action(data.ChapterID)
        .then((data) => {
          console.log(data)
          if (data.error != 1) {
            this.getFillList(primaryChapterMasterListActionId, '', '', '', '', '');
            this.setState({ isLoading: false, })
            toastSuccess("deleted successfully")
          }
          else {
            this.setState({ isLoading: false })
            toastError(data.msg)
          }
          this.setState({
            showNewRowChapter: false, // Hide the "Add" row after addingc
            isOpen: false,
            // noOfLevels: 1,
            isModalOpen: false
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  onDelete(i, data) {
    const { } = this.state
    this.setState({ deleteChapterdata: data })
    this.setState({ isModalOpen: true, })
    console.log("ChapterID:", "ParentChapterID:", this.state.ParentChapterID, this.state.noOfLevels);

  }
  iterateToNextLevel = (ChapterID, Level, ParentChapterID) => {
    if (Level >= 4) {
      return; // Do nothing, stay with the current data
    }

    const { currentData, level2, parentID, level3, level4 } = this.state;
    let nextLevelData = [];

    // Use level-specific data and ChapterID to get the next level's data
    if (Level === 1) {
      nextLevelData = level2.filter(
        (item) => item.ParentChapterID === ChapterID
      );
      this.setState({ currentLevel2: nextLevelData })
    } else if (Level === 2) {
      nextLevelData = level3.filter(
        (item) => item.ParentChapterID === ChapterID
      );
      this.setState({ currentLevel3: nextLevelData })

    } else if (Level === 3) {
      nextLevelData = level4.filter(
        (item) => item.ParentChapterID === ChapterID
      );
      this.setState({ currentLevel4: nextLevelData })
    }

    // Log ChapterID and ParentChapterID
    sessionStorage.setItem('parentsID', ChapterID)
    sessionStorage.setItem('level', Level + 1)
    this.setState({ currentData: nextLevelData, ParentChapterID: ParentChapterID, noOfLevels: (Level + 1), parentID: ChapterID });
    setTimeout(() => {
      console.log('parentsID', sessionStorage.getItem('parentsID'), sessionStorage.getItem('level'))
    }, 500);
    console.log(currentData);
  };

  iterateBack = (Level) => {
    const { primaryChapterListData, level2, ChapterID, currentData, parentID, ParentChapterID, level3, level4 } = this.state;
    let backData = [];
    console.log('currlvl', this.state.currentLevel2, this.state.currentLevel3)
    if (Level === 1) {
      this.setState({ currentData: primaryChapterListData });
      primaryChapterListData.length > 0 && this.setState({ parentID: primaryChapterListData[0].ParentChapterID })
      sessionStorage.setItem('parentsID', primaryChapterListData[0].ParentChapterID)

    } else if (Level === 2) {
      // backData = level2.filter(
      //   (item) => item.ParentChapterID === ParentID
      // );
      this.setState({ currentData: this.state.currentLevel2 });
      console.log(this.state.currentLevel2[0])

      this.state.currentLevel2.length > 0 && this.setState({ parentID: this.state.currentLevel2[0].ParentChapterID })
      sessionStorage.setItem('parentsID', this.state.currentLevel2[0].ParentChapterID)


    } else if (Level === 3) {
      // backData = level3.filter((item) => item.ParentChapterID === ParentID);
      this.setState({ currentData: this.state.currentLevel3 });

      this.state.currentLevel3.length > 0 && this.setState({ parentID: this.state.currentLevel3[0].ParentChapterID })
      sessionStorage.setItem('parentsID', this.state.currentLevel3[0].ParentChapterID)
      console.log(this.state.currentLevel3, 'this.state.currentLevel3[0].ParentChapterID', this.state.currentLevel3[0].ParentChapterID)

    } else if (Level === 4) {
      return;
    }
    sessionStorage.setItem('level', Level)
    this.setState({ noOfLevels: Level, });
    setTimeout(() => {
      console.log(sessionStorage.getItem('parentsID'), sessionStorage.getItem('level'))

      console.log("ChapterID:", parentID, "ParentChapterID:", ParentChapterID, currentData, this.state.noOfLevels);

    }, 100);
  };

  handleKeyDown(e) {
    console.log(e.code)
    if (e.code === "Enter") {
      this.handleAddRowChapter(e);
    }
  }

  render() {
    const {
      isLoading,
      currentData,
      primaryChapterListData,
      level0,
      level2,
      level3,
      level4,
      Level,
      isModalOpen,
      editedRowDataChapters,
      editedRowIndexChapter,
      showNewRowChapter,
      chapterList,
      ChapterDetails,
    } = this.state;
    return (
      <>
        {isLoading ? <Loader></Loader> : null}
        <ToastContainer />
        <HeaderWithLogo />
        <style type="text/css">{breadcrumbCSS}</style>

        <>
          <div className="app-content with-dr-container com-height">
            <AdminDrawer />
            <div className="row">
              <div className="step-name">
                <p>Chapters</p>
              </div>
            </div>
            {/* <div
              className={
                JSON.parse(sessionStorage.getItem("loginDataSS")).RoleID == 1
                  ? "with-dr-container"
                  : "with-dr-container com-height"
              }
            > */}
            <div className="main-content app-content mt-0">
              <div className="container-fluid">
                <div style={{ marginTop: 10 }}>
                  <div className="row mb-4">
                    <div className="chap-name-list mt-3 d-flex justify-content-left">
                      <ul className="breadcrumb" style={{ cursor: 'pointer' }}>{console.log(currentData)}
                        {this.state.primaryChapterListData.length > 0 && (
                          <>
                            {this.state.noOfLevels >= 1 && (
                              <li
                                className="breadcrumb-item"
                                onClick={this.state.isOpen == false ? (() =>
                                  this.iterateBack(
                                    // currentData[0].ChapterID,
                                    // currentData[0].ParentChapterID,
                                    1
                                  )) : null
                                }
                              >
                                List of Primary Chapter
                              </li>
                            )}
                            {this.state.noOfLevels >= 2 && (
                              <li
                                className="breadcrumb-item chap-name-list-link text-primary"
                                onClick={this.state.isOpen == false ? (() =>
                                  this.iterateBack(
                                    // currentData[0].ChapterID,
                                    // currentData[0].ParentChapterID,
                                    2
                                  )) : null
                                }
                              >
                                <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                                />
                                Chapter
                              </li>
                            )}
                            {this.state.noOfLevels >= 3 && (
                              <li
                                className="breadcrumb-item chap-name-list-link text-primary"
                                onClick={this.state.isOpen == false ? (() =>
                                  this.iterateBack(
                                    // currentData[0].ChapterID,
                                    // currentData[0].ParentChapterID,
                                    3
                                  )) : null
                                }
                              >
                                <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                                />
                                Sub Chapter
                              </li>
                            )}
                            {this.state.noOfLevels >= 4 && (
                              <li
                                className="breadcrumb-item chap-name-list-link text-primary"
                                onClick={this.state.isOpen == false ? (() =>
                                  this.iterateBack(
                                    // currentData[0].ChapterID,
                                    // currentData[0].ParentChapterID,
                                    4
                                  )) : null
                                }
                              >
                                <i
                                  className="fa fa-angle-double-right"
                                  aria-hidden="true"
                                />
                                Product
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </div>

                    <div
                      className="table-responsive mt-2"
                      style={{ width: 1000, marginBottom: 25 }}
                    >
                      <table
                        class="table table-bordered"
                        id="tbl"
                      >
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>Sr. no</th>
                            <th style={{ width: "80%" }}>Name</th>
                            <th class="text-right" style={{ width: "10%" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody id="myTable">
                          {currentData.length > 0 ? (
                            currentData.map((data, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td
                                  className={data.Level == 4 ? '' : "input-hover"}
                                  onClick={this.state.isOpen == false ? (() =>
                                    this.iterateToNextLevel(
                                      data.ChapterID,
                                      data.Level,
                                      data.ParentChapterID
                                    )) : null
                                  }
                                >
                                  {editedRowIndexChapter === index ? (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={editedRowDataChapters.ChapterName}
                                      onChange={(e) =>
                                        this.handleRowEditFieldChangeChapter(
                                          index,
                                          "ChapterName",
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    data.ChapterName
                                  )}
                                </td>
                                <td className="text-right">
                                  {editedRowIndexChapter === index ? (
                                    <>
                                      <a
                                        title="Save"
                                        className="btn btn-sm padding_0"
                                        onClick={() =>
                                          this.saveEditedRowChapter(index, data)
                                        }
                                      >
                                        <i className="fa fa-save action_btn_table" />
                                      </a>
                                      <a
                                        title="Cancel"
                                        className="btn btn-sm padding_0"
                                        onClick={() =>
                                          this.cancelEditRowChapter()
                                        }
                                      >
                                        <i className="fa fa-times action_btn_table" />
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        title="Edit"
                                        className="btn btn-sm padding_0"
                                        onClick={() =>
                                          this.handleEditRowChapter(index, data)
                                        }
                                      >
                                        <i className="fa fa-edit action_btn_table" />
                                      </a>
                                      <a
                                        title="Delete"
                                        className="btn btn-sm delete_record padding_0"
                                        onClick={() =>
                                          this.onDelete(index, data)
                                        }
                                      >
                                        <i className="fa fa-trash action_btn_table" />
                                      </a>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (showNewRowChapter == false &&
                            <tr>
                              <td colSpan="3">No data available.</td>
                            </tr>
                          )}
                          {showNewRowChapter && (
                            <tr>
                              <td></td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationCustom03"
                                  value={ChapterDetails.ChapterName}
                                  onChange={(e) =>
                                    this.setState({
                                      ChapterDetails: {
                                        ...ChapterDetails,
                                        ChapterName: e.target.value,
                                      },
                                    })
                                  }
                                  onKeyDown={(e) => this.handleKeyDown(e)}
                                  placeholder="Chapter Name"
                                />
                              </td>
                              <td>
                                <button
                                  className="btn btn-primary btn-md"
                                  onClick={() => this.handleAddRowChapter()}

                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-primary btn-sm create_btn add_additional_days_btn"
                          style={{ width: "auto" }}
                          onClick={() => this.toggleNewRowChapter()}
                        >
                          <i class="fa fa-plus"></i>{" "}
                          <span class="hidden-xs">
                            {showNewRowChapter ? "Cancel" : "Add"}
                          </span>
                        </a>
                        {/* <button type="button" class="delete-row">Delete Row</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            {JSON.parse(sessionStorage.getItem("loginDataSS")).RoleID != 1 && (
              <Footer />

            )}
          </div>
        </>
        <Modal isOpen={isModalOpen} onRequestClose={() => this.setState({ isModalOpen: false })}>
          <div className="modal-dialog" style={{ marginTop: '20%' }}>
            <div className="modal-content confirm-logout">
              <div class="modal-header text-right p-2">
                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>
              </div>
              <div class="modal-footer p-1">
                <div class="form-group text-right mod-fot">
                  <button type="button" class="btn btn-del-pop"
                    onClick={(e) => this.handleDeleteRowChapter(this.state.deleteChapterdata)}
                  >Yes</button>
                  <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                    onClick={() => this.setState({ isModalOpen: false })}
                  >No</button>
                </div>
              </div>

            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default withRouter(connect(null, { fill_action, fill_master_action, addChapterMaster_action, deleteChapterMaster_action, updateChapterMaster_action })(masterChapterList));
