import { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { getAgentList_action, sendAgentCredentials_action, sendAgentPIMail_action, view_agent_PI_action } from "../../../actions/admin_module_action/agent_entry_action/agent_action"
import { data } from "jquery";
import { toastError, toastSuccess, downloadReport } from "../../../utils/constants";
import ExhibitionDrawer from "../../../common_components/admin_module_drawer";




class Agentlist extends Component {

    // Initialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentAgentList: [],
            allAgentList: [],
            loginAccesslist: []
        }
        this.onMixClick = this.onMixClick.bind(this)
        this.getAgentList = this.getAgentList.bind(this)
        this.onNationalClick = this.onNationalClick.bind(this)
        this.onInternationalClick = this.onInternationalClick.bind(this)
        this.onEdit = this.onEdit.bind(this)
        this.onView = this.onView.bind(this)
        this.onLoginClick = this.onLoginClick.bind(this)
        this.viewAgentPI = this.viewAgentPI.bind(this)
        this.onSendLoginCredClick = this.onSendLoginCredClick.bind(this)
        this.sendAgentPImail = this.sendAgentPImail.bind(this)
        this.getWirteAccess = this.getWirteAccess.bind(this)
        this.getUpdateAccess = this.getUpdateAccess.bind(this)
        this.getSpecialAccess = this.getSpecialAccess.bind(this)
        this.onEnroll = this.onEnroll.bind(this)
    }

    // On Component Load
    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getAgentList()
        sessionStorage.removeItem('IsAgentDirectLogin')
        sessionStorage.removeItem('indirectAgentloginDataSS')
    }

    // On Mix Click
    onMixClick(e) {
        e.preventDefault()
        this.props.navigate('/agententry', { state: { is_add: true, nationalityId: "mix" } })
    }

    // On national Click
    onNationalClick(e) {
        e.preventDefault()
        this.props.navigate('/agententry', { state: { is_add: true, nationalityId: "national" } })
    }

    // On International Click
    onInternationalClick(e) {
        e.preventDefault()
        this.props.navigate('/agententry', { state: { is_add: true, nationalityId: "international" } })
    }

    //on update
    onEdit(e, id, type) {
        console.log(type)
        e.preventDefault()
        this.props.navigate('/agententry', {
            state: {
                is_update: true,
                nationalityId: type,
                Account_Id: id
            }
        })
    }

    //enroll
    onEnroll(e, id, type, ExhibitionID) {
        e.preventDefault()
        this.props.navigate('/agententry', {
            state: {
                is_update: true,
                nationalityId: type,
                Account_Id: id,
                is_enroll: true,
                exhibitionID: ExhibitionID
            }
        })
    }

    //on view
    onView(e, id, type, is1stAgentPICreated, is2ndAgentPICreated, isManualPICreated, isTaxInvoiceCreated) {
        e.preventDefault()
        this.props.navigate('/agententry', {
            state: {
                is_view: true,
                nationalityId: type,
                Account_Id: id,
                is1stAgentPICreated: is1stAgentPICreated,
                is2ndAgentPICreated: is2ndAgentPICreated,
                isManualPICreated: isManualPICreated,
                isTaxInvoiceCreated: isTaxInvoiceCreated
            }
        })
    }

    // Get Agent List
    getAgentList() {
        this.setState({ isLoading: true })
        this.props.getAgentList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Agent Listing ----------------->", data)
                    this.setState({
                        isLoading: false,
                        currentAgentList: data.data.currentAgentList.length > 0 ? data.data.currentAgentList : [],
                        allAgentList: data.data.allAgentList.length > 0 ? data.data.allAgentList : []
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On send Login credentials Click
    onSendLoginCredClick(id, data) {
        this.setState({ isLoading: true })
        this.props.sendAgentCredentials_action(id)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess('The credentials shared successfully.')
                    this.setState({
                        isLoading: false,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    // On Login Click
    onLoginClick(id, data) {
        console.log("1111111111111111111111", id, data)


        if (id == data['AccountID']) {
            console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx")
            var mapData = {
                "AccountID": data['AccountID'],
                "AccountName": data['AccountName'],
                "CompanyName": data['Company Name'],
                "CountryID": data['CountryID'],
                "CountryName": data['CountryName'],
                "FirstName": data['FirstName'],
                "LastName": data['LastName'],
                "RoleID": data['RoleID'],
                "RoleName": data['RoleName'],
                "UserID": data['UserID'],
                "AgentCode": data['agentcode'],
                "AgentRegistrationType": data['AgentRegistrationType']
            }
            // Prepare Session storage after Agent login Form admin
            sessionStorage.setItem('IsAgentDirectLogin', false)
            sessionStorage.setItem("indirectAgentuserEmailSS", data['PrimaryEmail'])
            sessionStorage.setItem("indirectAgenttokenSS", "token")
            sessionStorage.setItem("IndirectAgentAccountIDSS", data['AccountID'])
            sessionStorage.setItem("indirectAgentloginDataSS", JSON.stringify(mapData))
            console.log("login data", mapData['FirstName'].charAt(0))
            console.log("login data", mapData['LastName'].charAt(0))
            sessionStorage.setItem("indirectAgentfirstNameSS", mapData['FirstName'])
            sessionStorage.setItem("indirectAgentlastNameSS", mapData['LastName'])
            sessionStorage.setItem("indirectAgentprofileNameSS", `${mapData['FirstName'].charAt(0)}${mapData['LastName'].charAt(0)}`)

            // preparing Session storage for exhibition on login agnt from admin
            sessionStorage.setItem("indirectAgentsessionExhibitionID", data['ExhibitionID'])
            sessionStorage.setItem("indirectAgentExhibitionFromDateSS", data['FromDate'])
            sessionStorage.setItem("indirectAgentExhibitionToDateSS", data['ToDate'])
            sessionStorage.setItem("indirectAgentExhibitionAddressSS", data['Address'])
            setTimeout(() => {
                this.props.navigate("/agentdashboard");
            }, 200);
        }



    }

    getWirteAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '8');
        if (moduleAccess) {
            return moduleAccess.WriteAccess;
        } else {
            return false
        }
        return false;
    }

    getUpdateAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '8');
        if (moduleAccess) {
            return moduleAccess.UpdateAccess;
        } else {
            return false
        }
        return false;
    }

    getSpecialAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '8');
        if (moduleAccess) {
            return moduleAccess.Is_SpecialAccess;
        } else {
            return false
        }
        return false;
    }

    sendAgentPImail(AccountID, currencyID, status) {
        this.setState({ isLoading: true })
        this.props.sendAgentPIMail_action(AccountID, currencyID, status)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess('Mail sent successfully')
                    this.setState({
                        isLoading: false,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    viewAgentPI(AccountID, currencyID, status) {
        this.setState({ isLoading: true })
        this.props.view_agent_PI_action(AccountID, currencyID, status)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    console.log(data)
                    var url = `${downloadReport}?SessionIdentifier=${data}&Export=true`;
                    const win = window.location.replace(url, '_blank');
                    if (win != null) {
                        win.focus();
                    }
                }
                else {

                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    render() {
        const { isLoading, allAgentList, currentAgentList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer></ToastContainer>
                <HeaderWithLogo />
                <div className="page">
                    <ExhibitionDrawer />
                    <div className="page-main">
                        <div className="main-content app-content with-dr-container">
                            <div className="row">
                                <div className="step-name-space" style={{ zIndex: 9 }}>
                                    <p>Agent Registration</p>
                                </div>
                            </div>
                            <div className="main-container  top-space">
                                <div className="row">
                                    <div className="card mb-0 p-0 ">
                                        <div className="card-body p-2 mt-4" style={{ paddingTop: "0 !important" }}>
                                            {" "}
                                            {/* ACCORDION BEGIN */}
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary p-1"
                                                        disabled={!this.getWirteAccess()}
                                                        style={{ width: 140, fontSize: 15 }}
                                                        onClick={(e) => this.onNationalClick(e)}
                                                    >
                                                        National
                                                    </button>
                                                </div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary p-1"
                                                        disabled={!this.getWirteAccess()}
                                                        style={{ width: 140, fontSize: 15 }}
                                                        onClick={(e) => this.onInternationalClick(e)}
                                                    >
                                                        International
                                                    </button>
                                                </div>
                                                <div className="col-md-2">
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-primary p-1"
                                                        disabled={!this.getWirteAccess()}
                                                        style={{ width: 140, fontSize: 15 }}
                                                        onClick={(e) => this.onMixClick(e)}
                                                    >
                                                        Mix
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="table-responsive mb-3">
                                        <h5 className="">
                                            <strong>Agent List for {sessionStorage.getItem("ExhibitionName")}</strong>
                                        </h5>
                                        <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Type</th>
                                                    <th>Agent Code</th>
                                                    <th>Company Name</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th className="text-center">Mobile</th>
                                                    <th className="text-center">Total Area</th>
                                                    <th className="text-center">Booked Area</th>
                                                    <th className="text-center">Balance Area</th>
                                                    <th className="text-center">Total Exhibitor</th>
                                                    <th colSpan={2} className="text-center">1st Agent PI</th>
                                                    <th colSpan={2} className="text-center">2nd Agent PI</th>
                                                    <th colSpan={2} className="text-center">Tax Invoice</th>
                                                    <th colSpan={2} className="text-center">Manual PI</th>


                                                    {this.getUpdateAccess() &&
                                                        <th />
                                                    }
                                                    <th />
                                                    {this.getSpecialAccess() &&
                                                        <th />
                                                    }
                                                    {this.getSpecialAccess() &&
                                                        <th />
                                                    }
                                                </tr>
                                            </thead>
                                            {console.log('currentAgentList', currentAgentList)}
                                            <tbody>
                                                {
                                                    currentAgentList.length > 0 ?
                                                        currentAgentList.map((data) => (
                                                            <tr key={Math.random()}>
                                                                <td className="text-center">{data.Type}</td>
                                                                <td>{data.agentcode}</td>
                                                                <td>{data['Company Name']}</td>
                                                                <td>{data.Name}</td>
                                                                <td>{data.PrimaryEmail}</td>
                                                                <td className="text-center">{data.MobileNo}</td>
                                                                <td className="text-center">{data['Total Area']}</td>
                                                                <td className="text-center">{data['Booked Area']}</td>
                                                                <td className="text-center">{data['Balance Area']}</td>
                                                                <td className="text-center">{data['Total Exhibitor']}</td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-primary btn-sm"
                                                                            disabled={data.is1stAgentPICreated == 0}
                                                                            onClick={(e) => this.sendAgentPImail(data.AccountID, data.currencyID, 1)}
                                                                        >
                                                                            Send
                                                                        </button>}
                                                                </td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-success btn-sm"
                                                                            disabled={data.is1stAgentPICreated == 0}
                                                                            onClick={(e) => this.viewAgentPI(data.AccountID, data.currencyID, 1)}
                                                                        >
                                                                            View
                                                                        </button>}
                                                                </td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-primary btn-sm"
                                                                            disabled={data.is2ndAgentPICreated == 0}
                                                                            onClick={(e) => this.sendAgentPImail(data.AccountID, data.currencyID, 2)}

                                                                        >
                                                                            Send
                                                                        </button>}
                                                                </td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-success btn-sm"
                                                                            disabled={data.is2ndAgentPICreated == 0}
                                                                            onClick={(e) => this.viewAgentPI(data.AccountID, data.currencyID, 2)}
                                                                        >
                                                                            View
                                                                        </button>}
                                                                </td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-primary btn-sm"
                                                                            disabled={data.isTaxInvoiceCreated == 0}
                                                                            onClick={(e) => this.sendAgentPImail(data.AccountID, data.currencyID, 3)}
                                                                        >
                                                                            Send
                                                                        </button>}
                                                                </td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-success btn-sm"
                                                                            disabled={data.isTaxInvoiceCreated == 0}
                                                                            onClick={(e) => this.viewAgentPI(data.AccountID, data.currencyID, 3)}
                                                                        >
                                                                            View
                                                                        </button>}
                                                                </td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-primary btn-sm"
                                                                            disabled={data.isManualPICreated == 0 || data.isManualPICreated == null}
                                                                            onClick={(e) => this.sendAgentPImail(data.AccountID, data.currencyID, 4)}
                                                                        >
                                                                            Send
                                                                        </button>}
                                                                </td>
                                                                <td>
                                                                    {data.IsSelfPaid &&
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-success btn-sm"
                                                                            disabled={data.isManualPICreated == 0 || data.isManualPICreated == null}
                                                                            onClick={(e) => this.viewAgentPI(data.AccountID, data.currencyID, 4)}
                                                                        >
                                                                            View
                                                                        </button>}
                                                                </td>
                                                                {this.getUpdateAccess() &&
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-primary btn-sm"
                                                                            onClick={(e) => this.onEdit(e, data.AccountID, data.AgentRegistrationType)}
                                                                        >
                                                                            Edit
                                                                        </button>
                                                                    </td>
                                                                }
                                                                <td>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-success btn-sm"
                                                                        onClick={(e) => this.onView(e, data.AccountID, data.AgentRegistrationType, data.is1stAgentPICreated, data.is2ndAgentPICreated, data.isManualPICreated, data.isTaxInvoiceCreated)}
                                                                    >
                                                                        View
                                                                    </button>
                                                                </td>
                                                                {this.getSpecialAccess() &&
                                                                    <td>
                                                                        <button type="button" className="btn btn-outline-info btn-sm"
                                                                            onClick={(e) => this.onSendLoginCredClick(data.AccountID, data)}
                                                                        >
                                                                            Send Login Credentials
                                                                        </button>
                                                                    </td>
                                                                }
                                                                {this.getSpecialAccess() &&
                                                                    <td>
                                                                        <button type="button" className="btn btn-outline-info btn-sm"
                                                                            onClick={(e) => this.onLoginClick(data.AccountID, data)}
                                                                        >
                                                                            Login
                                                                        </button>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )) :
                                                        <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="table-responsive mb-3">
                                        <h5 className="">
                                            <strong>Un-Enrolled Agent List</strong>
                                        </h5>
                                        <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Type</th>
                                                    <th>Agent Code</th>
                                                    <th>Company Name</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th className="text-center">Mobile</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    allAgentList.length > 0 ?
                                                        allAgentList.map((data) => (
                                                            <tr key={Math.random()}>
                                                                <td className="text-center">{data.Type}</td>
                                                                <td>{data.agentcode}</td>
                                                                <td>{data['Company Name']}</td>
                                                                <td>{data.Name}</td>
                                                                <td>{data.PrimaryEmail}</td>
                                                                <td className="text-center">{data.MobileNo}</td>
                                                                <td className="text-center">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-primary btn-sm"
                                                                        onClick={(e) => this.onEnroll(e, data.AccountID, data.AgentRegistrationType, data.ExhibitionID)}
                                                                    >
                                                                        Enroll
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-first">
                                <div className="container-fluid">
                                    <div className="footer-first">
                                        {" "}
                                        ITME All Rights Reserved{" "}
                                        <i className="fa fa-copyright" aria-hidden="true" /> 2023{" "}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(connect(null, { getAgentList_action, sendAgentCredentials_action, sendAgentPIMail_action, view_agent_PI_action })(Agentlist))