import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { ASSIGN_CIRCULAR_ACTION_TYPE, DEL_VISITOR_CIRCULAR_ACTION_TYPE, GET_VISITOR_CIRCULAR_COMPANY_ACTION_TYPE, GET_VISITOR_CIRCULAR_LIST_ACTION_TYPE, INSERT_VISITOR_CIRCULAR_ACTION_TYPE, assignCircularUrl, delVisitorCircularUrl, getVisitorCircularCompanyUrl, getVisitorCircularListUrl, headers, insertVisitorCircularUrl } from "../../../utils/constants";


export const getVisitorCircularCompany_action = (companyName) => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            CompanyName: companyName
        }
        console.log("getVisitorCircularCompany_action Action------->", params)

        const res = await axiosGet(getVisitorCircularCompanyUrl, params, headers)

        dispatch({
            type: GET_VISITOR_CIRCULAR_COMPANY_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const insertVisitorCircular_action = (circularDate, circularTitle, circularFile, list, circularFileType, isSendMail) => async (dispatch) => {
    try {
        const mapData = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            CircularVisitorID: null,
            CircularVisitorDate: circularDate,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            title: circularTitle,
            Attachment: circularFile,
            circularListData: list,
            AttachmentType: circularFileType,
            isSendMail: isSendMail
        }
        console.log("insertVisitorCircular_action Action------->", mapData)

        const res = await axiosPost(insertVisitorCircularUrl, mapData, headers)

        dispatch({
            type: INSERT_VISITOR_CIRCULAR_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisitorCircularList_action = () => async (dispatch) => {
    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID
        }
        console.log("getCircularList_action Action------->", params)

        const res = await axiosGet(getVisitorCircularListUrl, params, headers)

        dispatch({
            type: GET_VISITOR_CIRCULAR_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const delVisitorCircular_action = (circularId) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            CircularVisitorID: circularId
        }
        console.log("delCircular_action Action------->", params)

        const res = await axiosGet(delVisitorCircularUrl, params, headers)

        dispatch({
            type: DEL_VISITOR_CIRCULAR_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const assignCircular_action = (list, list2, flag) => async (dispatch) => {
    try {
        const mapData = {
            UserId: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            circularListData: list,
            circularList: list2,
            isFlag: flag
        }
        console.log("assignCircular_action Action------->", mapData)

        const res = await axiosPost(assignCircularUrl, mapData, headers)

        dispatch({
            type: ASSIGN_CIRCULAR_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}