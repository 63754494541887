import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import Loader from "../../../utils/Loader";
import { getExhibitorCircularList_action, getVisitorCircularList_action } from "../../../actions/exhibitor_module_actions/exhibitor_circular_actions/exhibitor_circular_action";
import { toastError } from "../../../utils/constants";
import { data } from "jquery";
import moment from "moment";
import VisitorHeader2 from "../../../common_components/visitor_module_header2";
import VisitorDrawer from "../../../common_components/visitor_module_drawer";
import VisitorTopMenu from "../../../common_components/visitor_module_topmenu";

const { Component } = require("react");

class ExhibitorCircularList extends Component {

    // Intialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            circularList: []
        }
        this.getCircularList = this.getCircularList.bind(this)
        this.getVisitorCircularList = this.getVisitorCircularList.bind(this)
    }

    // On componet Load
    componentDidMount() {
        this.props.location.state != null && this.props.location.state.userFlag && this.props.location.state.userFlag == 'Exhibitor' ?
            this.getCircularList() : this.getVisitorCircularList()
        // this.getCircularList()
    }

    // Get Exhibitor Circular List
    getCircularList() {
        this.setState({ isLoading: true })
        this.props.getExhibitorCircularList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("getCircularList Data", data.data)
                    this.setState({
                        circularList: data.data,
                        isLoading: false,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get Exhibitor Circular List
    getVisitorCircularList() {
        this.setState({ isLoading: true })
        this.props.getVisitorCircularList_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("getCircularList Data", data.data)
                    this.setState({
                        circularList: data.data,
                        isLoading: false,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    render() {
        const { isLoading, circularList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <VisitorHeader2 />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <VisitorDrawer />
                }
                <div className="main-content with-dr-container">
                    <div className="">
                        <div className="">
                            {/* <div class="step-name">
                  <p>Dashboard</p>
              </div> */}
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : <VisitorTopMenu />
                            }
                        </div>
                        <div className="row">
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div className="card-header p-3">
                                        <h3 className="card-title">Circulars</h3>
                                    </div>
                                    <div className="card-body p-4">
                                        <div className="table-responsive">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ width: 60 }}>
                                                            Sr No
                                                        </th>
                                                        <th>Circulars Date</th>
                                                        <th>Subject</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        circularList.length > 0 ? circularList.map((data, index) => (
                                                            <tr key={Math.random()}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td>{moment(data.CircularDate).utc().format('DD-MM-YYYY')}</td>
                                                                <td>
                                                                    <a
                                                                        className="text-primary"
                                                                        href={data.Attachment}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {data.subject}
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(connect(null, { getExhibitorCircularList_action, getVisitorCircularList_action })(ExhibitorCircularList))