import { axiosGet } from "../../../react_axios/Axios";
import { GET_EXH_CIRCULAR_LIST_ACTION_TYPE, GET_EXH_VISITOR_CIRCULAR_LIST_ACTION_TYPE, getExhCircularListUrl, getExhVisitorCircularListUrl, headers } from "../../../utils/constants";



export const getExhibitorCircularList_action = () => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getExhibitorCircularList_action Action------->", params)

        const res = await axiosGet(getExhCircularListUrl, params, headers)

        dispatch({
            type: GET_EXH_CIRCULAR_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisitorCircularList_action = () => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getVisitorCircularList_action Action------->", params)

        const res = await axiosGet(getExhVisitorCircularListUrl, params, headers)

        dispatch({
            type: GET_EXH_VISITOR_CIRCULAR_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}





