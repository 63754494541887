import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { HashRouter as Switch, Route, Routes, BrowserRouter } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";

import CountrySelect from "./screens/registration/select_country";

import Registration from "./screens/registration/registration";
import ApplicationSummary from "./screens/admin_module/dashboard/application_summary/application_summary";
import AreaSummary from "./screens/admin_module/dashboard/area_summary/area_summary";
import PaymentSummary from "./screens/admin_module/dashboard/payment_summary/payment_summary";
import ParticipantSummary from "./screens/admin_module/dashboard/participant_summary/participant_summary";
import AdditionalSummary from "./screens/admin_module/dashboard/additional_summary/additional_summary";
import StepHeader from "./screens/registration/stepHeader";
import PrimaryChapter from "./screens/registration/primary_chapter";
import Description from "./screens/registration/description";
import MainRegistration from "./screens/registration/mainRegister";
import LastSubmit from "./screens/registration/last_submit";
import ThankYou from "./screens/registration/thanks";
import ExcelListData from "./screens/registration/excelListData";
import Login from "./screens/user_login_auth/login";
import ReceiptEntry from "./screens/admin_module/registration_receipt/ReceiptEntry";
import ReceiptList from "./screens/admin_module/registration_receipt/ReceiptList";
import Exhibitor_dashboard from "./screens/exhibitor_module/exhibitor_dashboard/Exhibitor_dashboard";
import Undertaking from "./screens/exhibitor_module/exhibitor_dashboard/undertaking";
import Dashboard from "./screens/admin_module/dashboard/dashboard";
import Select_exhibition from "./screens/admin_module/exhibition/select_exhibition";
import ExhibitionDrawer from "./common_components/admin_module_drawer";
import SelectApplication from "./screens/exhibitor_module/Select_Application/select_application";
import ManualForms from "./screens/exhibitor_module/manual_forms/manual_forms";
import RulesRegulations from "./screens/exhibitor_module/rules_and_regulations/rules_and_regulations";
import ImportantDates from "./screens/exhibitor_module/important_dates/important_dates";
import Spacebooking from "./screens/exhibitor_module/spacebooking/spacebooking";
import TaxInvoices from "./screens/exhibitor_module/text_invoices/textInvoices.js";
import UpdatePayment from "./screens/exhibitor_module/accounts/update_payment";
import UpdatePaymentAdd from "./screens/exhibitor_module/accounts/update_payment_add";
import FasciaForm from "./screens/exhibitor_module/manual_forms/forms/fascia_Form/fascia_form";
import ExhibitorBadge from "./screens/exhibitor_module/manual_forms/forms/exhibitor_badge_form/exhibitor_badge";
import AddExhibitorBadge from "./screens/exhibitor_module/manual_forms/forms/exhibitor_badge_form/exhibitor_badge_add";
import InvitationVisaApplicationList from "./screens/exhibitor_module/manual_forms/forms/invitationVisaApplication/invitationVisaApplicationList";
import InvitationVisaApplicationAU from "./screens/exhibitor_module/manual_forms/forms/invitationVisaApplication/invitationVisaApplicationAU";
import ExhibitorDetails from "./screens/exhibitor_module/manual_forms/forms/exhibitor_details_form/exhibitor_details";
import PowerConnection from "./screens/exhibitor_module/manual_forms/forms/power_connection_form/power_connection";
import KeywordsInformation from "./screens/exhibitor_module/manual_forms/forms/keywords_information_form/keywords_information";
import VisitorRegistration from "./screens/visitor_module/visitor_registartion/visitor_registration";
import VisitorIndustrySegment from "./screens/visitor_module/visitor_registartion/visitor_industry_segment";
import VisitorThankYou from "./screens/visitor_module/visitor_registartion/visitor_thanks";
import PaymentList from "./screens/admin_module/Account_payment/paymentList";
import Visitor_dashboard from "./screens/visitor_module/visitor_dashboard/visitor_dashboard";
import Agentlist from "./screens/admin_module/Agent_Entry/agentList";
import Agententry from "./screens/admin_module/Agent_Entry/agentEntry";
import AgentDashboard from "./screens/agent_module/agent_dashboard/agentDashboard";
import VisitorForm from "./screens/visitor_module/visitor_Form/visitorForm";
import VisitorBadge from "./screens/visitor_module/visitor_badge/visitor_badge";
import ChangePassword from "./screens/user_login_auth/changePassword";
import ChangePassword2 from "./screens/user_login_auth/changePassword2";
import ProformaInvoiceList from "./screens/exhibitor_module/profomaInvoiceList/proformaInvoiceList";
import DuePaymentsList from "./screens/exhibitor_module/duePayments/due_payments_list.js";
import ExhibitorList from "./screens/admin_module/exhibitors_list/exhibitors_list";
import Reminder_emails from "./screens/admin_module/Reminder_Emails/reminder_emails";
import EDirectory_list from "./screens/exhibitor_module/manual_forms/forms/e-direcory_form/e-directory_list";
import EDirectory_add_form from "./screens/exhibitor_module/manual_forms/forms/e-direcory_form/e-directory_add_form";
import Visa_invitation from "./screens/admin_module/visa_approval/visa_invitation";
import Visa_approval from "./screens/admin_module/visa_approval/visa_approval";
import Visitor_visa_invitation from "./screens/admin_module/Visitor_visa_approval/visitor_visa_invitation";
import Visitor_visa_approval from "./screens/admin_module/Visitor_visa_approval/visitor_visa_approval";
import CircularAdd from "./screens/admin_module/Circulars/circularAdd";
import ExhibitorCircularList from "./screens/exhibitor_module/Exibitor_circular/exhibitorCircularList";
import ExhibitorsSpaceBooking from "./screens/admin_module/exhibitor_spacebooking/exhibitorsSpaceBooking";
import Visitor_visa_form from "./screens/admin_module/Visitor_visa_approval/visitor_visa_form";
import StallDesign from "./screens/exhibitor_module/manual_forms/forms/stallDesign/stallDesign.js";
import Fascia_admin from "./screens/admin_module/manual_forms_admin/fascia_form_admin/fascia_admin";
import Fascia_approval from "./screens/admin_module/manual_forms_admin/fascia_form_admin/fascia_approval";
import Exhibit_details_admin from "./screens/admin_module/manual_forms_admin/exhibit_details_admin/exhibit_details_admin";
import Exhibit_details_approval from "./screens/admin_module/manual_forms_admin/exhibit_details_admin/exhibit_details_approval";
import ExhibitorBadges_admin from "./screens/admin_module/manual_forms_admin/exhibitorBadge_admin/exhibitorBadges_admin";
import ExhibitorBadgesApproval from "./screens/admin_module/manual_forms_admin/exhibitorBadge_admin/exhibitorBadgesApproval";
import EdirectoryApproval from "./screens/admin_module/manual_forms_admin/edirectory_admin/edirectoryApproval";
import EdirectoryAdmin from "./screens/admin_module/manual_forms_admin/edirectory_admin/edirectoryAdmin";
import ExhibitorContractorBadgeAdmin from "./screens/admin_module/manual_forms_admin/exhibitiorContractorBadge_admin/exhibitiorContractorBadge_admin.js";
import ExhibitorContractorBadgeApproval from "./screens/admin_module/manual_forms_admin/exhibitiorContractorBadge_admin/exhibitiorContractorBadge_approval.js";
import CarPassesApplicationAdmin from "./screens/admin_module/manual_forms_admin/carPassesApplication_admin/carPassesApplication_admin.js";
import CarPassesApplicationApproval from "./screens/admin_module/manual_forms_admin/carPassesApplication_admin/carPassesApplication_approval.js";
import LiquorLicenseAdmin from "./screens/admin_module/manual_forms_admin/liquoreLicence_admin/liquoreLicense_admin.js";
import LiquorLicenseApproval from "./screens/admin_module/manual_forms_admin/liquoreLicence_admin/liquoreLicence_approval.js";
import DelegateMeetingAdmin from "./screens/admin_module/manual_forms_admin/delegateMeeting_admin/delegateMeetingAdmin.js";
import DelegateMeetingApproval from "./screens/admin_module/manual_forms_admin/delegateMeeting_admin/delegateMeetingApproval.js";
import LeadRetrievalConnectionApplicationAdmin from "./screens/admin_module/manual_forms_admin/leadRetrievalConnectionApplication_admin/leadRetrievalConnectionApplication_admin.js";
import LeadRetrievalConnectionApplicationApproval from "./screens/admin_module/manual_forms_admin/leadRetrievalConnectionApplication_admin/leadRetrievalConnectionApplicationApproval.js";
import MeetingRoomRequestFormAdmin from "./screens/admin_module/manual_forms_admin/MeetingRoomRequestForm_admin/MeetingRoomRequestFormAdmin.js";
import MeetingRoomRequestFormApproval from "./screens/admin_module/manual_forms_admin/MeetingRoomRequestForm_admin/MeetingRoomRequestFormApproval.js";
import VisitorMeetingApplicationAdmin from "./screens/admin_module/manual_forms_admin/visitorMeetingApplication/visitorMeetingApplicationAdmin.js";
import VisitorMeetingApplicationApproval from "./screens/admin_module/manual_forms_admin/visitorMeetingApplication/visitorMeetingApplicationApproval.js";
import InternetConnectionAdmin from "./screens/admin_module/manual_forms_admin/internetConnection_admin/internetConnectionAdmin.js";
import InternetConnectionApproval from "./screens/admin_module/manual_forms_admin/internetConnection_admin/internetConnectionApproval.js";
import HousekeepingConnectionApplicationAdmin from "./screens/admin_module/manual_forms_admin/housekeepingConnectionApplication_admin/housekeepingConnectionApplicationAdmin.js";
import HousekeepingConnectionApplicationApproval from "./screens/admin_module/manual_forms_admin/housekeepingConnectionApplication_admin/housekeepingConnectionApproval.js";
import SecurityApplicationAdmin from "./screens/admin_module/manual_forms_admin/securityApplication_admin/securityApplication_admin.js";
import SecurityApplicationApproval from "./screens/admin_module/manual_forms_admin/securityApplication_admin/securityApplication_approval.js";
import CateringServicesAdmin from "./screens/admin_module/manual_forms_admin/cateringServices_admin/cateringServices_admin.js";
import CateringServicesApproval from "./screens/admin_module/manual_forms_admin/cateringServices_admin/cateringServices_approval.js";
import HostAndHostessAdmin from "./screens/admin_module/manual_forms_admin/hostAndHostess_admin/hostAndHostess_admin.js";
import HostAndHostessApproval from "./screens/admin_module/manual_forms_admin/hostAndHostess_admin/hostAndHostess_approval.js";
import BrandingOpportunityAdmin from "./screens/admin_module/manual_forms_admin/brandingOpportunity_admin/brandingOpportunity_admin.js";
import BrandingOpportunityApproval from "./screens/admin_module/manual_forms_admin/brandingOpportunity_admin/brandingOpportunity_approval.js";
import RentalAudioAndVedioFormAdmin from "./screens/admin_module/manual_forms_admin/rentalAudioAndVedioForm_admin/rentalAudioAndVedioForm_admin.js";
import RentalAudioAndVedioFormApproval from "./screens/admin_module/manual_forms_admin/rentalAudioAndVedioForm_admin/rentalAudioAndVedioForm_approval.js";
import AirConnectionApplicationAdmin from "./screens/admin_module/manual_forms_admin/airConnectionApplication_admin/airConnectionApplication_admin.js";
import AirConnectionApplicationApproval from "./screens/admin_module/manual_forms_admin/airConnectionApplication_admin/airConnectionApplication_approval.js";
import PowerConnectionAdmin from "./screens/admin_module/manual_forms_admin/powerConnection_admin/powerConnection_admin.js";
import PowerConnectionApproval from "./screens/admin_module/manual_forms_admin/powerConnection_admin/powerConnection_approval.js";
import WaterConnectionAdmin from "./screens/admin_module/manual_forms_admin/waterConnection_admin/waterConnection_admin.js";
import WaterConnectionApproval from "./screens/admin_module/manual_forms_admin/waterConnection_admin/waterConnection_approval.js";
import Additional_furniture from "./screens/exhibitor_module/manual_forms/forms/additional furniture/additional_furniture";
import Stall_possesion from "./screens/exhibitor_module/manual_forms/forms/stall_possesion/stall_possesion";
import Exhibitor_manualForm_summary from "./screens/admin_module/exhibitor_manualForm_summary/exhibitor_manualForm_summary";
import Chapter_master_list from "./screens/admin_module/master/chapter_master/chapter_master_list.js";
import Features from "./screens/admin_module/features/features";
import VisitorList from './screens/admin_module/visitors_list/visitorList';
import Freight_forward from './screens/exhibitor_module/manual_forms/forms/freight_forward/freight_forward';
// import VisitorList from "./screens/admin_module/visitors_list/visitorList";
// import Tax_invoice_au from './screens/admin_module/Tax_invoices/tax_invoice_au';
import ReportList from './screens/admin_module/reports/report_list';
import UserList from './screens/admin_module/user/user_list.js';
import UserAU from './screens/admin_module/user/userAU.js';
import AccountSubReports from './screens/admin_module/reports/account_sub_reports/account_sub_reports';

// import ReportList from './screens/admin_module/reports/report_list';
// import SubReport1 from './screens/admin_module/reports/report1/subReport1';
// import ExhibitionMasterList from './screens/admin_module/master/exihibition_master/exhibition_master_list';
import ExhibitionMasterList from './screens/admin_module/master/exhibitionMaster/exhibition_master_list.js';
import ExhibitionMasterAU from './screens/admin_module/master/exhibitionMaster/exihibition_master/exhibition_master_AU';
// import ExhibitionMaster from './screens/admin_module/master/exhibitionMaster/exhibitionMaster'
import HallMasterList from './screens/admin_module/master/hall_master/hall_master_list.js'
import BankDetailsList from './screens/admin_module/master/bank_details_master/bank_details_list.js'
import GSTList from './screens/admin_module/master/GST_master/gst_master_list.js'
import GSTAU from './screens/admin_module/master/GST_master/gst_master_AU.js'
import BankDetailsAU from './screens/admin_module/master/bank_details_master/bank_details_AU.js'
import HallMasterAU from './screens/admin_module/master/hall_master/hall_master_AU.js'
import StallMasterList from './screens/admin_module/master/stall_master/stall_master_list.js'
import StallMasterAU from './screens/admin_module/master/stall_master/stall_master_AU.js'
import RoleMasterList from './screens/admin_module/master/role_master/role_master_list';
import RoleMasterAU from './screens/admin_module/master/role_master/role_master_AU';
import ExhibitionPaymentConfig from './screens/admin_module/master/exhibitionMaster/exh_payment_config/exh_payment_config.js';
import LedgerList from './screens/admin_module/ledger/ledger_List'
import ManageArea from './screens/admin_module/manage_area/manage_area'
import ApplicationStatusManagement from './screens/admin_module/manage_area/application_status_management/application_status_management.js'
import AreaManagement from './screens/admin_module/manage_area/area_management/area_management'
import ApplicationHistory from './screens/admin_module/manage_area/aaplication_history/application_history'
import Tax_invoice_list from "./screens/admin_module/Tax_invoices/tax_invoice/tax_invoice_list";
import Tax_invoice_au from "./screens/admin_module/Tax_invoices/tax_invoice/tax_invoice_au";
import Tax_invoice_exhibitors from "./screens/admin_module/Tax_invoices/tax_invoice_exhibitors/tax_invoice_exhibitors";
import Visitor_circularAdd from "./screens/admin_module/visitor_Circulars/visitor_circularAdd.js";
import Water from './screens/exhibitor_module/manual_forms/forms/water_form/water.js';
import Visitor_preview from "./screens/visitor_module/visitor_registartion/visitor_preview.js";
import VendorRegistrationList from "./screens/admin_module/vendor/vendorRegistration/vendorRegistration_list.js";
import VendorRegistrationAU from "./screens/admin_module/vendor/vendorRegistration/vendorRegistration_au.js";
import VendorAllocationList from "./screens/admin_module/vendor/vendorAllocation/vendorAllocationList.js";
import VendorModule from "./screens/vendor_module/vendor_module.js";
import VendorExhibitionList from "./screens/vendor_module/vendor_exhibition_list/vendor_exhibition_list.js";
import VendorDashboard from "./screens/vendor_module/vendor_dashboard/vendor_dashboard.js";
import Exh_promoCode from "./screens/admin_module/master/exhibitionMaster/exh_promoCode/exh_promoCode.js";
import CarPassesApplication from "./screens/exhibitor_module/manual_forms/forms/car_passes_application/car_passes_application.js";
import ExhibitorContractorBadgeApplication from "./screens/exhibitor_module/manual_forms/forms/exhibitor_contractor_badgeApplication/exhibitor_contractor_badgeApplication.js";
import LiquareLicense from "./screens/exhibitor_module/manual_forms/forms/liquare_license/liquare_license.js";
import LeadRetrievalConnectionApplication from "./screens/exhibitor_module/manual_forms/forms/leadRetrievalConnectionApplication/leadRetrievalConnectionApplication.js";
import Partner from './screens/partner_module/partnert_registration/partner.js';
import PartnerApplication from './screens/partner_module/partner_application/partner_application.js';
import DelegateMeeting from "./screens/exhibitor_module/manual_forms/forms/delegate_meeting/delegate_meeting.js";
import DelegateMeetingAU from "./screens/exhibitor_module/manual_forms/forms/delegate_meeting/delegate_meeting_AU.js";
import VisitorMeetingApplication from "./screens/exhibitor_module/manual_forms/forms/visitor_meeting_application/visitor_meeting_application.js";
import VisitorMeetingAU from "./screens/exhibitor_module/manual_forms/forms/visitor_meeting_application/visitor_meeting_AU";
import MeetingRoomRequestForm from "./screens/exhibitor_module/manual_forms/forms/Meeting room request form/MeetingRoomRequestForm.js";
import InternetConnection from './screens/exhibitor_module/manual_forms/forms/internet_connection/internet_connection.js'
import HousekeepingConnection from './screens/exhibitor_module/manual_forms/forms/housekeeping_connection/housekeeping_connection.js'
import SecurityApplication from './screens/exhibitor_module/manual_forms/forms/security_application/security_application.js'
import CateringServices from './screens/exhibitor_module/manual_forms/forms/catering_services/catering_services.js'
import HostHostessRequirements from './screens/exhibitor_module/manual_forms/forms/host_hostess_requirement/host_hostess_requirement.js'
import OnlinePresenceList from './screens/admin_module/master/online_presence_master/online_presence_master_list.js';
import OnlinePresenceAU from './screens/admin_module/master/online_presence_master/online_presence_master_AU.js';
import Exh_configration from './screens/admin_module/master/exhibitionMaster/exh_configration/exh_configration.js';
import IndustrySegmentMaster from './screens/admin_module/master/industry_segment_master/industry_segment_master.js';
import PurposeofVisitMaster from './screens/admin_module/master/purpose_of_visit_master/purpose_of_visit_master.js';
import BrandingApportunityAndAdvertisement from './screens/exhibitor_module/manual_forms/forms/brandingApportunityAndAdvertisement/brandingApportunityAndAdvertisement.js'
import RentalAudioAndVedioEquipment from './screens/exhibitor_module/manual_forms/forms/rentalAudioAndVedioEquipment/rentalAudioAndVedioEquipment.js'
import AirConnectionApplication from './screens/exhibitor_module/manual_forms/forms/airConnectionApplication/airConnectionApplication.js'
import AccountTally from './screens/admin_module/Account_tally/account_tally.js'
import StallDesign_admin from "./screens/admin_module/manual_forms_admin/stallDesign_admin/stallDesign_admin.js";
import StallDesign_approval from "./screens/admin_module/manual_forms_admin/stallDesign_admin/stallDesign_approval.js";
import ManualFormReport from "./screens/admin_module/reports/manualForm_sub_reports/manualForm_sub_reports.js";
import LiquorlicenseMaster from './screens/admin_module/master/manual_form_master/liquor_license_master/liquor_license_master.js'
import LeadRetrivalConnectionMaster from './screens/admin_module/master/manual_form_master/lead_retrival_connection_master/lead_retrival_connection_master.js'
import HostAndHostessMaster from './screens/admin_module/master/manual_form_master/host_and_hostess_master/host_and_hostess_master.js'
import VisitorMeetingMaster from './screens/admin_module/master/manual_form_master/visitor_meeting_master/visitor_meeting_master.js'
import RentalAudioVideoMaster from './screens/admin_module/master/manual_form_master/rental_audio_video_master/rental_audio_video_master.js'
import HousekeepingApplicationMasterList from './screens/admin_module/master/manual_form_master/housekeeping_application_master/housekeeping_application_master_list.js'
import HousekeepingApplicationMasterAU from './screens/admin_module/master/manual_form_master/housekeeping_application_master/housekeeping_application_master_AU'
import InternetConnectionMasterList from './screens/admin_module/master/manual_form_master/internet_connection_master/internet_connection_master_list.js'
import InternetConnectionMasterAU from './screens/admin_module/master/manual_form_master/internet_connection_master/internet_connection_master_AU.js'
import CateringServicesMasterList from './screens/admin_module/master/manual_form_master/catering_services_master/catering_services_master_list.js'
import CateringServicesMasterAU from './screens/admin_module/master/manual_form_master/catering_services_master/catering_services_master_AU.js'
import SecurityApplicationMasterList from './screens/admin_module/master/manual_form_master/security_application_master/security_application_master_list.js'
import SecurityApplicationMasterAU from './screens/admin_module/master/manual_form_master/security_application_master/security_application_master_AU.js'
import DelegateMeetingMasterList from './screens/admin_module/master/manual_form_master/delegate_meeting_master/delegate_meeting_master_list.js'
import DelegateMeetingMasterAU from './screens/admin_module/master/manual_form_master/delegate_meeting_master/delegate_meeting_master_AU.js'
import MeetingRoomMasterList from './screens/admin_module/master/manual_form_master/meeting_room_master/meeting_room_master_list.js'
import MeetingRoomMasterAU from './screens/admin_module/master/manual_form_master/meeting_room_master/meeting_room_master_AU.js'
import BrandingOpportunityMaster from './screens/admin_module/master/manual_form_master/branding_opportunity_master/branding_opportunity_master.js'
import AgentReceiptList from './screens/admin_module/agent_receipt/agent_receipt_list.js'
import AgentReceiptAU from './screens/admin_module/agent_receipt/agent_receipt_AU.js'

// import Login from "./screens/registration/login";

function App() {
  // useEffect(() => {
  //   sessionStorage.setItem("renderPage", 'countrySelect')

  // }, [])

  return (
    <div className="App">
      <div className="auth-inner">
        <Switch>
          <Routes>
            {/* <Route exact path='/login' element={<Login />} /> */}
            {/* // register form */}
            <Route exact path="/:id" element={<CountrySelect />} />
            <Route exact path="/registration" element={<Registration />} />
            <Route exact path="/stepHeader" element={<StepHeader />} />
            <Route exact path="/primary_chapter" element={<PrimaryChapter />} />
            <Route exact path="/description" element={<Description />} />
            <Route
              exact
              path="/mainRegistration"
              element={<MainRegistration />}
            />
            <Route exact path="/lastSubmit" element={<LastSubmit />} />
            <Route exact path="/thankYou" element={<ThankYou />} />
            <Route exact path="/excelListData" element={<ExcelListData />} />
            <Route exact path="/registration/:id" element={<Registration />} />
            <Route
              exact
              path="/primary_chapter/:id"
              element={<PrimaryChapter />}
            />
            <Route exact path="/description/:id" element={<Description />} />
            <Route
              exact
              path="/excelListData/:id"
              element={<ExcelListData />}
            />

            {/* // */}

            <Route exact path="/login/:id" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/changePassword" element={<ChangePassword />} />
            <Route
              exact
              path="/Change_Password"
              element={<ChangePassword2 />}
            />

            <Route exact path="/" element={<Login />} />
            {/* // admin */}
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route
              exact
              path="/applicationSummary"
              element={<ApplicationSummary />}
            />
            <Route
              exact
              path="/participantSummary"
              element={<ParticipantSummary />}
            />
            <Route exact path="/areaSummary" element={<AreaSummary />} />
            <Route exact path="/paymentSummary" element={<PaymentSummary />} />
            <Route exact path="/additionalSummary" element={<AdditionalSummary />} />
            <Route exact path="/selectExhibition" element={<Select_exhibition />} />
            <Route exact path='/receiptentry' element={<ReceiptEntry />} />
            <Route exact path='/receiptlist' element={<ReceiptList />} />
            <Route exact path='/paymentlist' element={<PaymentList />} />
            <Route exact path='/reminderEmails' element={<Reminder_emails />} />
            <Route exact path='/manualformsummary' element={<Exhibitor_manualForm_summary />} />
            <Route exact path='/features' element={<Features />} />
            <Route exact path='/visitorslist' element={<VisitorList />} />

            {/* <Route exact path='/reportList' element={<ReportList />} />
            <Route exact path='/subReport1' element={<SubReport1 />} /> */}
            <Route exact path='/reportList' element={<ReportList />} />
            <Route exact path='/accountSubReports' element={<AccountSubReports />} />


            {/* Master */}
            <Route exact path='/exhibitionMasterList' element={<ExhibitionMasterList />} />
            <Route exact path='/exhibitionMasterAU' element={<ExhibitionMasterAU />} />
            {/* <Route exact path='/exhibitionMaster' element={<ExhibitionMaster />} /> */}
            <Route exact path='/hallMasterList' element={<HallMasterList />} />
            <Route exact path='/hallMasterAU' element={<HallMasterAU />} />
            <Route exact path='/stallMasterList' element={<StallMasterList />} />
            <Route exact path='/stallMasterAU' element={<StallMasterAU />} />
            <Route exact path='/roleMasterList' element={<RoleMasterList />} />
            <Route exact path='/roleMasterAU' element={<RoleMasterAU />} />
            <Route exact path='/exhibitionPaymentConfig' element={<ExhibitionPaymentConfig />} />
            <Route exact path='/userList' element={<UserList />} />
            <Route exact path='/userAU' element={<UserAU />} />
            <Route exact path="/promocode" element={<Exh_promoCode />} />
            <Route exact path="/exhConfigration" element={<Exh_configration />} />
            <Route exact path="/industrySegmentMaster" element={<IndustrySegmentMaster />} />
            <Route exact path="/purposeofVisitMaster" element={<PurposeofVisitMaster />} />

            <Route exact path='/ledgerList' element={<LedgerList />} />

            {/* admin manage area */}
            <Route exact path="/manageArea" element={<ManageArea />} />
            {/* admin manage area child component */}
            <Route
              exact
              path="/applicationStatusManagement"
              element={<ApplicationStatusManagement />}
            />
            <Route exact path="/areaManagement" element={<AreaManagement />} />
            <Route
              exact
              path="/applicationHistory"
              element={<ApplicationHistory />}
            />
            <Route
              exact
              path="/VendorRegistrationList"
              element={<VendorRegistrationList />}
            />
            <Route
              exact
              path="/VendorRegistrationAU"
              element={<VendorRegistrationAU />}
            />
            <Route
              exact
              path="/VendorAllocationList"
              element={<VendorAllocationList />}
            />
            <Route
              exact
              path="/VendorModule"
              element={<VendorModule />}
            />
            <Route
              exact
              path="/VendorDashboard"
              element={<VendorDashboard />}
            />
            <Route
              exact
              path="/VendorExhibitionList"
              element={<VendorExhibitionList />}
            />

            {/* Visa Approval */}
            <Route exact path="/visainvitation" element={<Visa_invitation />} />
            <Route exact path="/visaapproval" element={<Visa_approval />} />
            {/* Visitor Visa Approval */}
            <Route
              exact
              path="/visitorVisaInvitation"
              element={<Visitor_visa_invitation />}
            />
            <Route
              exact
              path="/visitorVisaapproval"
              element={<Visitor_visa_approval />}
            />
            <Route
              exact
              path="/visitorvisaform"
              element={<Visitor_visa_form />}
            />
            {/* Circulars */}
            <Route exact path="/addcircular" element={<CircularAdd />} />
            {/* Visitor Cicular */}
            <Route exact path="/addVisitorCircular" element={<Visitor_circularAdd />} />
            {/* Exhibitor Spacebooking */}
            <Route
              exact
              path="/exhibitorspacebooking"
              element={<ExhibitorsSpaceBooking />}
            />
            {/* Manual Forms Approval */}
            {/* Fascia Approval */}
            <Route exact path="/fasciaadmin" element={<Fascia_admin />} />
            <Route exact path="/fasciaapproval" element={<Fascia_approval />} />
            {/* Exhibit Details */}
            <Route
              exact
              path="/exhibitDetailsAdmin"
              element={<Exhibit_details_admin />}
            />
            <Route
              exact
              path="/exhibitDetailsApproval"
              element={<Exhibit_details_approval />}
            />
            {/* Exhibito Badges Approval */}
            <Route
              exact
              path="/exhibitorBadgesAdmin"
              element={<ExhibitorBadges_admin />}
            />
            <Route
              exact
              path="/exhibitoBadgesApproval"
              element={<ExhibitorBadgesApproval />}
            />
            {/* Edirectory Approval */}
            <Route
              exact
              path="/edirectoryapproval"
              element={<EdirectoryApproval />}
            />
            <Route
              exact
              path="/edirectoryAdmin"
              element={<EdirectoryAdmin />}
            />
            {/*Exhibitor Contractor Badge Admin*/}
            <Route exact path="/exhibitorContractorBadgeAdmin" element={<ExhibitorContractorBadgeAdmin />} />
            <Route exact path="/exhibitorContractorBadgeApproval" element={<ExhibitorContractorBadgeApproval />} />
            {/*Car Passes Application Admin*/}
            <Route exact path="/carPassesApplicationAdmin" element={<CarPassesApplicationAdmin />} />
            <Route exact path="/carPassesApplicationApproval" element={<CarPassesApplicationApproval />} />
            {/*Liquor License Admin*/}
            <Route exact path="/liquorLicenseAdmin" element={<LiquorLicenseAdmin />} />
            <Route exact path="/liquorLicenseApproval" element={<LiquorLicenseApproval />} />
            {/*Liquor License Admin*/}
            <Route exact path="/leadRetrievalConnectionApplicationAdmin" element={<LeadRetrievalConnectionApplicationAdmin />} />
            <Route exact path="/leadRetrievalConnectionApplicationApproval" element={<LeadRetrievalConnectionApplicationApproval />} />
            {/*meeting Room Request Form Admin*/}
            <Route exact path="/meetingRoomRequestFormAdmin" element={<MeetingRoomRequestFormAdmin />} />
            <Route exact path="/meetingRoomRequestFormApproval" element={<MeetingRoomRequestFormApproval />} />
            {/*Delegate Meeting Admin*/}
            <Route exact path="/delegateMeetingAdmin" element={<DelegateMeetingAdmin />} />
            <Route exact path="/delegateMeetingApproval" element={<DelegateMeetingApproval />} />
            {/*Visitor Meeting Application Admin*/}
            <Route exact path="/visitorMeetingApplicationAdmin" element={<VisitorMeetingApplicationAdmin />} />
            <Route exact path="/visitorMeetingApplicationApproval" element={<VisitorMeetingApplicationApproval />} />
            {/*Internet Connection Admin*/}
            <Route exact path="/internetConnectionAdmin" element={<InternetConnectionAdmin />} />
            <Route exact path="/internetConnectionApproval" element={<InternetConnectionApproval />} />
            {/*Internet Connection Admin*/}
            <Route exact path="/housekeepingConnectionApplicationAdmin" element={<HousekeepingConnectionApplicationAdmin />} />
            <Route exact path="/housekeepingConnectionApplicationApproval" element={<HousekeepingConnectionApplicationApproval />} />
            {/*Internet Connection Admin*/}
            <Route exact path="/securityApplicationAdmin" element={<SecurityApplicationAdmin />} />
            <Route exact path="/securityApplicationApproval" element={<SecurityApplicationApproval />} />
            {/*Host And Hostess Admin*/}
            <Route exact path="/hostAndHostessAdmin" element={<HostAndHostessAdmin />} />
            <Route exact path="/hostAndHostessApproval" element={<HostAndHostessApproval />} />
            {/*branding Opportunity Admin*/}
            <Route exact path="/brandingOpportunityAdmin" element={<BrandingOpportunityAdmin />} />
            <Route exact path="/brandingOpportunityApproval" element={<BrandingOpportunityApproval />} />
            {/*Rental Audio And Vedio Form Admin*/}
            <Route exact path="/rentalAudioAndVedioAdmin" element={<RentalAudioAndVedioFormAdmin />} />
            <Route exact path="/rentalAudioAndVedioFormApproval" element={<RentalAudioAndVedioFormApproval />} />
            {/*Air Connection Application Admin*/}
            <Route exact path="/airConnectionApplicationAdmin" element={<AirConnectionApplicationAdmin />} />
            <Route exact path="/airConnectionApplicationApproval" element={<AirConnectionApplicationApproval />} />
            {/*Power Connection Admin*/}
            <Route exact path="/powerConnectionAdmin" element={<PowerConnectionAdmin />} />
            <Route exact path="/powerConnectionApproval" element={<PowerConnectionApproval />} />
            {/*Water Connection Admin*/}
            <Route exact path="/waterConnectionAdmin" element={<WaterConnectionAdmin />} />
            <Route exact path="/waterConnectionApproval" element={<WaterConnectionApproval />} />
            {/*Catering Services Admin*/}
            <Route exact path="/cateringServicesAdmin" element={<CateringServicesAdmin />} />
            <Route exact path="/cateringServicesApproval" element={<CateringServicesApproval />} />
            {/* Tax Invoices */}
            <Route
              exact
              path="/taxinvoicelist"
              element={<Tax_invoice_list />}
            />
            <Route exact path="/taxinvoiceedit" element={<Tax_invoice_au />} />
            <Route exact path="/taxinvoiceexhibitors" element={<Tax_invoice_exhibitors />} />
            <Route exact path="/chapterList" element={<Chapter_master_list />} />

            {/* Agent */}
            <Route exact path="/agentlist" element={<Agentlist />} />
            <Route exact path="/agententry" element={<Agententry />} />
            <Route exact path="/agentdashboard" element={<AgentDashboard />} />
            <Route exact path="/exhibitorlist" element={<ExhibitorList />} />

            {/* // exhibitor */}
            <Route
              exact
              path="/exhibitor_dashboard"
              element={<Exhibitor_dashboard />}
            />
            <Route exact path="/undertaking" element={<Undertaking />} />
            <Route
              exact
              path="/selectApplication"
              element={<SelectApplication />}
            />
            <Route exact path="/manualForms" element={<ManualForms />} />
            <Route
              exact
              path="/rulesRegulations"
              element={<RulesRegulations />}
            />
            <Route exact path="/importantDates" element={<ImportantDates />} />
            <Route exact path="/spacebooking" element={<Spacebooking />} />
            <Route exact path="/updatePayment" element={<UpdatePayment />} />
            <Route
              exact
              path="/updatePaymentAdd"
              element={<UpdatePaymentAdd />}
            />
            <Route
              exact
              path="/proformalist"
              element={<ProformaInvoiceList />}
            />
            <Route
              exact
              path="/duePaymentsList"
              element={<DuePaymentsList />}
            />
            <Route
              exact
              path="/duePaymentsList/:status"
              element={<DuePaymentsList />}
            />
            <Route
              exact
              path="/taxInvoices"
              element={<TaxInvoices />}
            />
            {/* Circular list */}
            <Route
              exact
              path="/exhCircularList"
              element={<ExhibitorCircularList />}
            />

            {/* Manual Forms */}
            {/* Fascia Form */}
            <Route exact path="/fascia-form" element={<FasciaForm />} />
            {/* Exibitor Badge */}
            <Route exact path="/exhibitorbadge" element={<ExhibitorBadge />} />
            <Route
              exact
              path="/exhibitorbadgeAU"
              element={<AddExhibitorBadge />}
            />
            {/* Visa application */}
            <Route
              exact
              path="/visaApplication"
              element={<InvitationVisaApplicationList />}
            />
            <Route
              exact
              path="/visaApplicationAU"
              element={<InvitationVisaApplicationAU />}
            />
            {/* Exhibitor Details */}
            <Route
              exact
              path="/exhibitorDetails"
              element={<ExhibitorDetails />}
            />
            {/* power connection */}
            <Route
              exact
              path="/powerConnection"
              element={<PowerConnection />}
            />
            {/* keywords information */}
            <Route
              exact
              path="/keywordsInformation"
              element={<KeywordsInformation />}
            />
            {/*Water */}
            <Route
              exact
              path="/water"
              element={<Water />}
            />
            {/* Car Passes Application */}
            <Route
              exact
              path="/carPassesApplication"
              element={<CarPassesApplication />}
            />
            {/* Exhibitor Contractor Badge Application */}
            <Route
              exact
              path="/exhibitorContractorBadgeApplication"
              element={<ExhibitorContractorBadgeApplication />}
            />
            {/* Liquare License */}
            <Route
              exact
              path="/liquareLicense"
              element={<LiquareLicense />}
            />
            {/* Lead Retrieval Connection Application */}
            <Route
              exact
              path="/leadRetrievalConnectionApplication"
              element={<LeadRetrievalConnectionApplication />}
            />
            {/* Delegate Meeting */}
            <Route
              exact
              path="/delegateMeeting"
              element={<DelegateMeeting />}
            />
            <Route
              exact
              path="/delegateMeetingAU"
              element={<DelegateMeetingAU />}
            />
            {/* Delegate Meeting */}
            <Route
              exact
              path="/visitorMeetingApplication"
              element={<VisitorMeetingApplication />}
            />
            <Route
              exact
              path="/visitorMeetingAU"
              element={<VisitorMeetingAU />}
            />
            {/* Meeting Room Request Form */}
            <Route
              exact
              path="/meetingRoomRequestForm"
              element={<MeetingRoomRequestForm />}
            />
            {/* Internet Connection */}
            <Route
              exact
              path="/internetConnection"
              element={<InternetConnection />}
            />
            {/* Internet Connection */}
            <Route
              exact
              path="/housekeepingConnection"
              element={<HousekeepingConnection />}
            />
            {/* Security  Connection */}
            <Route
              exact
              path="/securityApplication"
              element={<SecurityApplication />}
            />
            {/* Catering Services */}
            <Route
              exact
              path="/cateringServices"
              element={<CateringServices />}
            />
            {/* Host Hostess Requirements */}
            <Route
              exact
              path="/hostHostessRequirements"
              element={<HostHostessRequirements />}
            />
            {/* Branding Apportunity And Advertisement */}
            <Route
              exact
              path="/brandingApportunityAndAdvertisement"
              element={<BrandingApportunityAndAdvertisement />}
            />
            {/* Rental Audio And Vedio Equipment */}
            <Route
              exact
              path="/rentalAudioAndVedioEquipment"
              element={<RentalAudioAndVedioEquipment />}
            />
            {/* Air Connection Application */}
            <Route
              exact
              path="/airConnectionApplication"
              element={<AirConnectionApplication />}
            />
            {/* E-Directory */}
            <Route exact path="/edirectoryList" element={<EDirectory_list />} />
            <Route
              exact
              path="/edirectoryAdd"
              element={<EDirectory_add_form />}
            />
            {/* Disclaimer */}
            <Route exact path="/stallDesign" element={<StallDesign />} />
            {/* Additional Furniture */}
            <Route
              exact
              path="/aditionalfurniture"
              element={<Additional_furniture />}
            />
            {/* Stall Possession */}
            <Route exact path='/stallpossesion' element={<Stall_possesion />} />
            <Route exact path='/freightForward' element={<Freight_forward />} />

            {/* stall Design admin */}
            <Route exact path='/stallDesign_admin' element={<StallDesign_admin />} />
            <Route exact path='/stallDesign_approval' element={<StallDesign_approval />} />

            {/* Visitor */}
            <Route
              exact
              path="/visitorregistration/:id"
              element={<VisitorRegistration />}
            />
            <Route
              exact
              path="/visitorindustrysegment"
              element={<VisitorIndustrySegment />}
            />
            <Route
              exact
              path="/visitorindustrysegment/:status"
              element={<VisitorIndustrySegment />}
            />
            <Route
              exact
              path="/visitorpreview"
              element={<Visitor_preview />}
            />
            <Route
              exact
              path="/visitorpreview/:status"
              element={<Visitor_preview />}
            />
            <Route
              exact
              path="/visitorthankyou"
              element={<VisitorThankYou />}
            />
            <Route
              exact
              path="/visitordashboard"
              element={<Visitor_dashboard />}
            />
            <Route exact path="/visitorForm" element={<VisitorForm />} />
            <Route exact path="/visitorForm/:status" element={<VisitorForm />} />
            <Route exact path="/visitorBadge" element={<VisitorBadge />} />
            <Route exact path="/partner" element={<Partner />} />
            <Route exact path="/partnerApplication" element={<PartnerApplication />} />
            <Route exact path="/bankDetailsList" element={<BankDetailsList />} />
            <Route exact path="/bankDetailsAU" element={<BankDetailsAU />} />
            <Route exact path="/gstAU" element={<GSTAU />} />
            <Route exact path="/gstList" element={<GSTList />} />
            <Route exact path="/onlinePresenceList" element={<OnlinePresenceList />} />
            <Route exact path="/onlinePresenceAU" element={<OnlinePresenceAU />} />
            <Route exact path="/accountTally" element={<AccountTally />} />
            <Route exact path="/manualFormReport" element={<ManualFormReport />} />
            <Route exact path="/liquorlicenseMaster" element={<LiquorlicenseMaster />} />
            <Route exact path="/leadRetrivalConnectionMaster" element={<LeadRetrivalConnectionMaster />} />
            <Route exact path="/hostAndHostessMaster" element={<HostAndHostessMaster />} />
            <Route exact path="/visitorMeetingMaster" element={<VisitorMeetingMaster />} />
            <Route exact path="/rentalAudioVideoMaster" element={<RentalAudioVideoMaster />} />
            <Route exact path="/housekeepingApplicationMasterList" element={<HousekeepingApplicationMasterList />} />
            <Route exact path="/housekeepingApplicationMasterAU" element={<HousekeepingApplicationMasterAU />} />
            <Route exact path="/internetConnectionMasterList" element={<InternetConnectionMasterList />} />
            <Route exact path="/internetConnectionMasterAU" element={<InternetConnectionMasterAU />} />
            <Route exact path="/cateringServicesMasterList" element={<CateringServicesMasterList />} />
            <Route exact path="/cateringServicesMasterAU" element={<CateringServicesMasterAU />} />
            <Route exact path="/securityApplicationMasterList" element={<SecurityApplicationMasterList />} />
            <Route exact path="/securityApplicationMasterAU" element={<SecurityApplicationMasterAU />} />
            <Route exact path="/delegateMeetingMasterList" element={<DelegateMeetingMasterList />} />
            <Route exact path="/delegateMeetingMasterAU" element={<DelegateMeetingMasterAU />} />
            <Route exact path="/meetingRoomMasterList" element={<MeetingRoomMasterList />} />
            <Route exact path="/meetingRoomMasterAU" element={<MeetingRoomMasterAU />} />
            <Route exact path="/brandingOpportunityMaster" element={<BrandingOpportunityMaster />} />
            <Route exact path="/agentReceiptList" element={<AgentReceiptList />} />
            <Route exact path="/agentReceiptAU" element={<AgentReceiptAU />} />

          </Routes>
        </Switch>
      </div>
    </div>
  );
}

export default App;
