import {
    headers,
    agentEntrySaveUrl,
    AGENT_ENTRY_SAVE_ACTION_TYPE,
    agentEntryUpdateUrl,
    AGENT_ENTRY_UPDATE_ACTION_TYPE,
    getAgentListUrl,
    AGENT_LIST_ACTION_TYPE,
    getAgentDetailsUrl,
    sendAgentDetailsUrl,
    GET_AGENT_DETAILS_ACTION_TYPE,
    verifyAgentEmailUrl,
    VERIFY_AGENT_EMAIL_ACTION_TYPE,
    insertAgentPI1Url, INSERT_AGENT_PI_1, insertAgentPI2Url,fetchAuthReport,DOWNLOAD_PERFORMA_INVOICE, INSERT_AGENT_PI_2, getAgentDetailsforMailUrl, GET_AGENT_DETAILS_FOR_MAIL, ForSendMailUrl, SEND_AGENT_MAIL,
} from "../../../utils/constants"
import { axiosPost, axiosGet } from "../../../react_axios/Axios"



export const agentEntrySave_action = (isSelfPaid, companyName, add1, add2, city, state, pin, countryId, prefixPersonInchFirst, personInchFirst,
    personInchLast, designation, mobNo, altMobNo, telephone, altTelephone, primaryEmail, secondaryEmail, website,
    isSameAsCorrespondence, billCompanyName, billAdd1, billAdd2, billCity, billState, billPin, billCountryId, billPrefixPersonInchFirst,
    billPersonInchFirst, billPersonInchLast, billDesignation, billMobNo, billAltMobNo, billTelephone, billAltTelephone,
    billPrimaryEmail, billSecondaryEmail, billWebsite, accName, accNo, accType, bankName, branchName, IFSC, finalAgentCode, nationalityId,
    totalNationalArea, totalInternationalArea, areaAllocationCountryData, areaAllocationChapterData, stallTypeList, Installment_1_Percent, Installment_2_Percent) => async (dispatch) => {
        try {
            const mapdata = {
                Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                AccountID: null,
                ExhibitionID: parseInt(sessionStorage.getItem('sessionExhibitionID')),
                IsSelfPaid: isSelfPaid,
                CompanyName: companyName,
                Address1: add1,
                Address2: add2,
                City: city,
                State: state,
                Pin: pin,
                CountryID: parseInt(countryId),
                Title_PersonIncharge: prefixPersonInchFirst,
                PersonIncharge: personInchFirst,
                LastNamePersonIncharge: personInchLast,
                Designation: designation,
                MobileNo: `${sessionStorage.getItem('sessionPreMobNo')}-${mobNo}`,
                AlternateMobileNo: `${sessionStorage.getItem('sessionPreAltMobNo')}-${altMobNo}`,
                Telephone: `${sessionStorage.getItem('sessionPreTelNo')}-${telephone}`,
                Telephone1: `${sessionStorage.getItem('sessionPreAltTelNo')}-${altTelephone}`,
                PrimaryEmail: primaryEmail,
                SecondaryEmail: secondaryEmail,
                Website: website,
                SameAsCorrespondence: isSameAsCorrespondence,
                InvCompanyName: billCompanyName,
                InvAddress1: billAdd1,
                InvAddress2: billAdd2,
                InvCity: billCity,
                InvState: billState,
                InvPin: billPin,
                InvCountryID: billCountryId,
                Title_InvPersonIncharge: billPrefixPersonInchFirst,
                InvPersonIncharge: billPersonInchFirst,
                InvLastNamePersonIncharge: billPersonInchLast,
                InvDesignation: billDesignation,
                InvMobileNo: `${sessionStorage.getItem('sessionInvPreMobNo')}-${billMobNo}`,
                InvAlternateMobileNo: `${sessionStorage.getItem('sessionInvPreAltMobNo')}-${billAltMobNo}`,
                InvTelephone: `${sessionStorage.getItem('sessionInvPreTelNo')}-${billTelephone}`,
                InvTelephone1: `${sessionStorage.getItem('sessionInvPreAltTelNo')}-${billAltTelephone}`,
                InvPrimaryEmail: billPrimaryEmail,
                InvSecondaryEmail: billSecondaryEmail,
                InvWebsite: billWebsite,
                AccountName: accName,
                AccountNo: accNo,
                AccountType: accType,
                BankName: bankName,
                BranchName: branchName,
                SwiftCode_IFSCCode: IFSC,
                AgentCode: finalAgentCode,
                AgentRegistrationType: nationalityId,
                DomesticArea: parseFloat(totalNationalArea),
                InternationalArea: parseFloat(totalInternationalArea),
                countryList: areaAllocationCountryData,
                chapterList: areaAllocationChapterData,
                areaPricingList: stallTypeList,
                Installment_1_Percent: Installment_1_Percent,
                Installment_2_Percent: Installment_2_Percent
            }
            console.log("agentEntrySave_action-Action--------------------------->", mapdata)
            const res = await axiosPost(agentEntrySaveUrl, mapdata, headers);

            dispatch({
                type: AGENT_ENTRY_SAVE_ACTION_TYPE,
                payload: res,
            })
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    }

export const agentEntryUpate_action = (AccountId, isSelfPaid, companyName, add1, add2, city, state, pin, countryId, prefixPersonInchFirst, personInchFirst,
    personInchLast, designation, mobNo, altMobNo, telephone, altTelephone, primaryEmail, secondaryEmail, website,
    isSameAsCorrespondence, billCompanyName, billAdd1, billAdd2, billCity, billState, billPin, billCountryId, billPrefixPersonInchFirst,
    billPersonInchFirst, billPersonInchLast, billDesignation, billMobNo, billAltMobNo, billTelephone, billAltTelephone,
    billPrimaryEmail, billSecondaryEmail, billWebsite, accName, accNo, accType, bankName, branchName, IFSC, finalAgentCode, nationalityId,
    totalNationalArea, totalInternationalArea, areaAllocationId, areaAllocationCountryData, areaAllocationChapterData, stallTypeList, Installment_1_Percent, Installment_2_Percent) => async (dispatch) => {
        try {
            const mapData = {
                Userid: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                AccountID: AccountId,
                ExhibitionID: parseInt(sessionStorage.getItem('sessionExhibitionID')),
                IsSelfPaid: isSelfPaid,
                CompanyName: companyName,
                Address1: add1,
                Address2: add2,
                City: city,
                State: state,
                Pin: pin,
                CountryID: countryId,
                Title_PersonIncharge: prefixPersonInchFirst,
                PersonIncharge: personInchFirst,
                LastNamePersonIncharge: personInchLast,
                Designation: designation,
                MobileNo: `${sessionStorage.getItem('sessionPreMobNo')}-${mobNo}`,
                AlternateMobileNo: `${sessionStorage.getItem('sessionPreAltMobNo')}-${altMobNo}`,
                Telephone: `${sessionStorage.getItem('sessionPreTelNo')}-${telephone}`,
                Telephone1: `${sessionStorage.getItem('sessionPreAltTelNo')}-${altTelephone}`,
                PrimaryEmail: primaryEmail,
                SecondaryEmail: secondaryEmail,
                Website: website,
                SameAsCorrespondence: isSameAsCorrespondence,
                InvCompanyName: billCompanyName,
                InvAddress1: billAdd1,
                InvAddress2: billAdd2,
                InvCity: billCity,
                InvState: billState,
                InvPin: billPin,
                InvCountryID: billCountryId,
                Title_InvPersonIncharge: billPrefixPersonInchFirst,
                InvPersonIncharge: billPersonInchFirst,
                InvLastNamePersonIncharge: billPersonInchLast,
                InvDesignation: billDesignation,
                InvMobileNo: `${sessionStorage.getItem('sessionInvPreMobNo')}-${billMobNo}`,
                InvAlternateMobileNo: `${sessionStorage.getItem('sessionInvPreAltMobNo')}-${billAltMobNo}`,
                InvTelephone: `${sessionStorage.getItem('sessionInvPreTelNo')}-${billTelephone}`,
                InvTelephone1: `${sessionStorage.getItem('sessionInvPreAltTelNo')}-${billAltTelephone}`,
                InvPrimaryEmail: billPrimaryEmail,
                InvSecondaryEmail: billSecondaryEmail,
                InvWebsite: billWebsite,
                AccountName: accName,
                AccountNo: accNo,
                AccountType: accType,
                BankName: bankName,
                BranchName: branchName,
                SwiftCode_IFSCCode: IFSC,
                AgentCode: finalAgentCode,
                AgentRegistrationType: nationalityId,
                DomesticArea: totalNationalArea,
                InternationalArea: totalInternationalArea,
                AgentAreaAllocationID: areaAllocationId,
                countryList: areaAllocationCountryData,
                chapterList: areaAllocationChapterData,
                areaPricingList: stallTypeList,
                Installment_1_Percent: Installment_1_Percent,
                Installment_2_Percent: Installment_2_Percent
            }
            console.log("receiptUpdate_action-Action Update--------------------------->", mapData);
            const res = await axiosPost(agentEntryUpdateUrl, mapData, headers);
            dispatch({
                type: AGENT_ENTRY_UPDATE_ACTION_TYPE,
                payload: res
            })
            return Promise.resolve(res)
        } catch (error) {
            return Promise.resolve(error)
        }
    }

export const getAgentList_action = () => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID
        }
        console.log("getAgentList_action -Action--------------------------->", params)

        const res = await axiosGet(getAgentListUrl, params, headers);
        dispatch({
            type: AGENT_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const sendAgentPIMail_action = (AccountID, currencyID, mailType) => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: AccountID,
            currencyID: currencyID,
            mailType: mailType
        }
        console.log("getAgentList_action -Action--------------------------->", params)

        const res = await axiosGet(ForSendMailUrl, params, headers);
        dispatch({
            type: SEND_AGENT_MAIL,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const view_agent_PI_action = (AccountID, currencyID, mailType) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName": "@ExhibitionID",
            "ParameterValue": sessionStorage.getItem('sessionExhibitionID'),
            "ParameterDataType": "int"
        }
        const mapdata2 = {
            "ParameterName": "@AccountID",
            "ParameterValue": AccountID,
            "ParameterDataType": "int"
        }
        const mapdata3 = {
            "ParameterName": "@CurrancyID",
            "ParameterValue": currencyID,
            "ParameterDataType": "int"
        }
        list.push(mapdata1);
        list.push(mapdata2);
        list.push(mapdata3);

        const mapData = {
            "report_name": mailType == 1 ? 'Agentpi1st_India.rpt' : mailType == 2 ? 'Agentpi2nd_India.rpt' : mailType == 3 ? 'AgentTaxInvoice.rpt' : 'AgentTaxInvoiceManualForm.rpt',
            "parameter_details": list
        }
        const res = await axiosPost(fetchAuthReport, mapData, headers);

        dispatch({
            type: DOWNLOAD_PERFORMA_INVOICE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const agentDetails_action = (accId, exhibitionID) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: accId,
            ExhibitionID: exhibitionID
        }
        console.log("agentDetails_action-Action--------------------------->", params)

        const res = await axiosGet(getAgentDetailsUrl, params, headers);
        dispatch({
            type: GET_AGENT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const verifyAgentEmail_action = (email, code) => async (dispatch) => {
    try {
        const param = {
            EmailID: email,
            AgentCode: code,
        }

        const res = await axiosGet(verifyAgentEmailUrl, param, headers);
        dispatch({
            type: VERIFY_AGENT_EMAIL_ACTION_TYPE,
            payload: res,
        });
        console.log("verifyAgentEmail_action--------->", param)
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const sendAgentCredentials_action = (accId) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: accId,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID')
        }
        console.log("agentDetails_action-Action--------------------------->", params)

        const res = await axiosGet(sendAgentDetailsUrl, params, headers);
        dispatch({
            type: GET_AGENT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

export const insertAgentPI1_action = (AccountID, ConRate, AgentType, currencyID) => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: AccountID,
            ConRate: ConRate,
            AgentType: AgentType,
            currencyID: currencyID
        }
        console.log("agentDetails_action-Action--------------------------->", params)

        const res = await axiosGet(insertAgentPI1Url, params, headers);
        dispatch({
            type: INSERT_AGENT_PI_1,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const insertAgentPI2_action = (AccountID, ConRate, AgentType, currencyID) => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: AccountID,
            ConRate: ConRate,
            AgentType: AgentType,
            currencyID: currencyID
        }
        console.log("agentDetails_action-Action--------------------------->", params)

        const res = await axiosGet(insertAgentPI2Url, params, headers);
        dispatch({
            type: INSERT_AGENT_PI_2,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}
export const getAgentMailDetails_action = (AccountID, ConRate, AgentType, currencyID, mailType) => async (dispatch) => {

    try {
        const params = {
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
            AccountID: AccountID,
            ConRate: ConRate,
            AgentType: AgentType,
            currencyID: currencyID,
            mailType: mailType
        }
        console.log("agentDetails_action-Action--------------------------->", params)

        const res = await axiosGet(getAgentDetailsforMailUrl, params, headers);
        dispatch({
            type: GET_AGENT_DETAILS_FOR_MAIL,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}