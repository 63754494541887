import { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import VisitorHeader from "../../../common_components/visitor_module_header";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../actions/fillCombo_action'
import { attendeeTypeActionId, cityActionId, countryActionId, countryIWithCodeActionId, exhibitorBadgeDaysActionId, pincodeActionId, stateActionId, toastError, exhibitionStatusActionId } from "../../../utils/constants";
import { data } from "jquery";
import { getVistiorRegistarionDetails_action, visitorRegistrationNext_action, verifyVisitorEmail_action } from "../../../actions/visitor_module_action/visitor_registration_action/visitor_registration_action"
import Resizer from "react-image-file-resizer";
import { emailValidator } from "../../../utils/Validator";


class VisitorRegistration extends Component {

    // Initialized Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOtherPreName: false,
            isDisplayPincodeList: false,
            isDisplayCityList: false,
            isDisplayStateList: false,
            isDisplayPersonalCountryList: false,
            isSameAsMobNo: false,
            isDataLoad: false,

            // Input Fields
            countryId: "",
            country: "",
            attendeeTypeId: "",
            otherName: "",
            preName: "Mr",
            firstName: "",
            middleName: "",
            lastName: "",
            designation: "",
            institute: "",
            preMobNo: "",
            mobNo: "",
            preWhatsAppMobNo: "",
            whatsAppMobNo: "",
            personalCountryId: "",
            personalCountry: "",
            pincode: "",
            city: "",
            regState: "",
            website: "",
            gstNo: "",
            panNo: "",
            email: "",
            preTelNo: "",
            telNo: "",
            preTel1No: "",
            tel1No: "",
            vaccineCertificateFile: "",
            vaccineCertificateFileType: "",
            photoIdFile: "",
            photoIdFileType: "",
            photoFile: "",
            photoFileType: "",

            //ERROR
            countryIdErr: '',
            attendeeTypeErr: '',
            daysErr: "",
            cityErr: '',
            mobNoErr: '',
            regStateErr: '',
            pincodeErr: "",
            designationErr: '',
            photoFileErr: '',
            lastNameErr: '',
            personalCountryErr: "",
            photoIdFileErr: '',
            instituteErr: '',
            firstNameErr: '',
            whatsAppMobNoErr: '',
            emailErr: "",
            isExhbitionClosed: false,
            // lists
            daysList: [],
            countryList: [],
            personalCountryList: [],
            tempPersonalCountryList: [],
            stateList: [],
            tempStateList: [],
            cityList: [],
            tempCityList: [],
            pincodeList: [],
            tempPincodeList: [],
            attendeeList: [],


        }
        this.getFillList = this.getFillList.bind(this)
        this.onSelectDates = this.onSelectDates.bind(this)
        this.onVisitorRegistrationNext = this.onVisitorRegistrationNext.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.onCountrySelect = this.onCountrySelect.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.onStateSearch = this.onStateSearch.bind(this)
        this.renderStateList = this.renderStateList.bind(this)
        this.onSameAsMobNoClick = this.onSameAsMobNoClick.bind(this)
        this.onSameAsMobNoClick = this.onSameAsMobNoClick.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.getRegistrationDetails = this.getRegistrationDetails.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.onPersonalCountrySearch = this.onPersonalCountrySearch.bind(this)
        this.renderPersonalCountryList = this.renderPersonalCountryList.bind(this)
        this.onAttendeeSelect = this.onAttendeeSelect.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.verifyEmail = this.verifyEmail.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
    }

    // On Component Load
    componentDidMount() {
        const { location } = this.props;
        if (location) {
           const pathname = location.pathname;
           const urlParts = pathname.split('/');
           const loginContent = urlParts[urlParts.length - 1];
           const [param1, param2] = loginContent.split('--');
           var newUrl
           if(param1 ==null){
             newUrl = `https://india-itme.com/#/`;
           }else if(param2==null){
             newUrl = `https://india-itme.com/#/${param1}`;
           }else{
             newUrl = `https://india-itme.com/#/${param1}--${param2}`;
           }
           window.location.href = newUrl;
         }
        // getting Exhibition Code from Url
        var exhCode = ""
        if (this.props.location != null && this.props.location.state == null) {
            var url = this.props.location.pathname != null && this.props.location.pathname.split("/")
            exhCode = url[url.length - 1]
            // sessionStorage.setItem("sessionExhibitionID", exhCode)
            // console.log("h7hyhyhh", this.props.location.state, 'this.props.location.state', exhCode)
            this.getFillList(countryIWithCodeActionId, '', '', '', exhCode, '').then(res => {
                //exhibition status..................
                this.getFillList(exhibitionStatusActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
                // Country List 
                this.getFillList(countryActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
                // Attendee List
                this.getFillList(attendeeTypeActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
                // Days List Action
                this.getFillList(exhibitorBadgeDaysActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")

                // Get Registration Details When Previous Click
            })
        }
        else if (this.props.location.state != null && this.props.location.state.is_previous) {
            //exhibition status..................
            this.getFillList(exhibitionStatusActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
            // Country List 
            this.getFillList(countryActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
            // Attendee List
            this.getFillList(attendeeTypeActionId, sessionStorage.getItem('sessionExhibitionID'), '', '', '', '')
            // Days List Action
            this.getFillList(exhibitorBadgeDaysActionId, sessionStorage.getItem('sessionExhibitionID'), "", "", "", "")
            this.getRegistrationDetails()
        }

        //exhibition status..................
        this.getFillList(exhibitionStatusActionId, '', '', '', '', '')
        // Country List 
        this.getFillList(countryActionId, '', '', '', '', '')
        // Attendee List
        this.getFillList(attendeeTypeActionId, '', '', '', '', '')
        // Days List Action
        this.getFillList(exhibitorBadgeDaysActionId, "", "", "", "", "")

    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        return new Promise((resolve, reject) => {
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value, from)
                .then((data) => {
                    if (data.error != 1) {
                        // country list 

                        if (actionID === exhibitionStatusActionId) {
                            console.log("exhibitionStatusActionId", data.data)
                            this.setState({
                                isExhbitionClosed: data != null && data.data[0]['IsExhibitionClosed'] == 1 ? true : false,
                                isLoading: false,
                            })
                            resolve(true)
                        }

                        if (actionID === countryActionId) {
                            console.log("country data", data.data)
                            this.setState({
                                countryList: data != null ? data.data : [],
                                personalCountryList: data.data,
                                tempPersonalCountryList: data.data,
                                isLoading: false,

                            })
                            resolve(true)
                        }
                        // ExhibitionID
                        if (actionID === countryIWithCodeActionId) {
                            console.log("country data", data.data[0]['ExhibitionID'])
                            var emailArr = data.data[0].ExhMailEmail != '' && data.data[0].ExhMailEmail != null ? data.data[0].ExhMailEmail.split(",") : []
                            var mobileArr = data.data[0].ExhMailMobile != '' && data.data[0].ExhMailMobile != null ? data.data[0].ExhMailMobile.split(",") : []
                            sessionStorage.setItem("sessionExhibitionID", data.data[0]['ExhibitionID'])
                            sessionStorage.setItem("exhibitionAddress", data.data[0]['Address'])
                            sessionStorage.setItem("sessionBannerPath", data.data[0]['Banner'])
                            sessionStorage.setItem("exhibitionDateFrom", data.data[0]['ExhibitionDateFrom'])
                            sessionStorage.setItem("exhibitionDateTo", data.data[0]['ExhibitionDateTo'])
                            sessionStorage.setItem("sessionLogoPath", data.data[0]['Logo'])

                            sessionStorage.setItem("sessionExhibitionName", data.data[0]['ExhibitionName'])
                            sessionStorage.setItem("sessionExhibitionEmail", JSON.stringify(emailArr))
                            sessionStorage.setItem("sessionExhibitionMobileNo", JSON.stringify(mobileArr))
                            sessionStorage.setItem("sessionExhMailWebsite", data.data[0]['ExhMailWebsite'])
                            sessionStorage.setItem("isVisitorB2B", data.data[0]['isVisitorB2B'])
                            sessionStorage.setItem("isVisitorSeminar", data.data[0]['isVisitorSeminar'])
                            sessionStorage.setItem("ExhibitionCountryID", data.data[0]['ExhibitionCountryID'])
                            this.setState({
                                isDataLoad: true,
                                bannerPath: data.data[0]['Banner'] != null && data.data[0]['Banner']
                            })

                            this.getFillList(countryActionId, data.data[0]['ExhibitionID'], '', '', '', '')
                            resolve(true)
                        }
                        // state list
                        if (actionID === stateActionId) {
                            console.log("stateList data", data.data)
                            this.setState({
                                stateList: data.data,
                                tempStateList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        // city list
                        if (actionID === cityActionId) {
                            console.log("city data", data.data)
                            this.setState({
                                cityList: data.data,
                                tempCityList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        // pincode
                        if (actionID === pincodeActionId) {
                            console.log("pincode data", data.data)
                            this.setState({
                                pincodeList: data.data,
                                tempPincodeList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                        // Days List
                        if (actionID === exhibitorBadgeDaysActionId) {
                            console.log("exhibitor data", data.data)
                            var DateList = []
                            data.data.map((item) => {
                                var mapData = {
                                    "Code": item['DateValue'],
                                    "description": item['DateValue'],
                                    "isSelect": false,
                                    "isReadOnly": false
                                }
                                DateList.push(mapData)
                            })
                            this.setState({
                                daysList: DateList,
                                isLoading: false
                            })
                            resolve(true)
                        }
                        // Attendee Type List
                        if (actionID === attendeeTypeActionId) {
                            console.log("Attendee Type data", data.data)
                            data.data.length > 0 && data.data.map((item) => {
                                item.isChecked = false
                            })
                            this.setState({
                                attendeeList: data.data,
                                isLoading: false,
                            })
                            resolve(true)
                        }
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })

    }

    // On Select Dates
    onSelectDates(e) {
        const { daysList } = this.state
        var code = e.target.value
        var checked = e.target.checked
        daysList.length > 0 && daysList.map((data) => {
            if (checked) {
                if (code == data.Code) {
                    data.isSelect = true
                }
            } else {
                if (code == data.Code) {
                    data.isSelect = false
                }

            }
        })
        console.log("hgdddhdjdjdjdkdj", daysList)
        this.setState({ daysList: daysList, daysErr: "" })
    }

    //input validate
    inputValidate() {
        const { city, preMobNo, mobNo, regState, designation, photoFile, lastName, photoIdFile, gender, institute, firstName,
            preWhatsAppMobNo, whatsAppMobNo, personalCountryId, pincode, email, countryId, attendeeTypeId, daysList } = this.state
        if (countryId == "" || countryId == "Select") {
            this.setState({ countryIdErr: "Please Select Country" });
        }
        if (attendeeTypeId == "") {
            this.setState({ attendeeTypeErr: "Please Select Attendee Type" });
        }
        if (!(daysList.some(obj => obj.isSelect == true))) {
            this.setState({ daysErr: "Please Select Days" })
        }
        if (firstName == "") {
            this.setState({ firstNameErr: "Please enter first name" });
        }
        if (lastName == "") {
            this.setState({ lastNameErr: "Please enter last name" });
        }
        if (designation == "") {
            this.setState({ designationErr: "Please enter designation" });
        }
        if (institute == "") {
            this.setState({ instituteErr: "Please enter institue/Organization" });
        }
        if (mobNo == "") {
            this.setState({ mobNoErr: "Please enter mobile no" });
        }
        if (whatsAppMobNo == "") {
            this.setState({ whatsAppMobNoErr: "Please enter whatsapp no" });
        }
        if (personalCountryId == "") {
            this.setState({ personalCountryErr: "Please enter Country" });
        }
        // if (photoFile == "") {
        //     this.setState({ photoFileErr: "Please upload photo" });
        // }
        // if (photoIdFile == "") {
        //     this.setState({ photoIdFileErr: "Please upload photo ID" });
        // }
        if (regState == "" && countryId == "91") {
            this.setState({ regStateErr: "Please enter province/state" });
        }
        if (city == "") {
            this.setState({ cityErr: "Please enter city" });
        }
        if (pincode == "") {
            this.setState({ pincodeErr: "Please enter Pincode" });
        }
        if (email == "") {
            this.setState({ emailErr: "Please enter Email" });
        }
        if (email != "") {
            var emailError = emailValidator(email)
            if (emailError != '') {
                this.setState({ emailErr: emailError })
                return
            }
        }
    }

    // On Visitor Registration Next
    onVisitorRegistrationNext(e) {
        const { daysList } = this.state
        e.preventDefault()
        // Is Days Selcted Check
        var daysListValidation = false
        for (var i = 0; i < this.state.daysList.length; i++) {
            if (this.state.daysList[i].isSelect == true) {
                daysListValidation = true
            }
        }
        // preparing Selected Days for udt
        const selectedDays = daysList.filter(data => data.isSelect == true)
        var list = []
        selectedDays.map((data) => {
            var data = {
                "visitDay": data.description
            }
            list.push(data)
        })
        this.inputValidate()
        setTimeout(() => {
            const { countryId, attendeeTypeId, city, preMobNo, mobNo, regState, designation, photoFile, lastName, photoIdFile, gender,
                institute, firstName, preWhatsAppMobNo, whatsAppMobNo, pincode, email, firstNameErr, lastNameErr, designationErr,
                instituteErr, mobNoErr, whatsAppMobNoErr, regStateErr, cityErr, pincodeErr, emailErr, preName, middleName,
                isSameAsMobNo, website, preTelNo, preTel1No, telNo, tel1No, countryIdErr, attendeeTypeErr, daysErr } = this.state

            if (firstName != "" && lastName != "" && designation != "" && institute != "" && mobNo != "" && whatsAppMobNo != "" &&
                city != "" && pincode != "" && email != "" && countryId != "" && attendeeTypeId != "") {
                if (firstNameErr == "" && lastNameErr == "" && designationErr == "" && instituteErr == "" && mobNoErr == "" &&
                    whatsAppMobNoErr == "" && regStateErr == "" && cityErr == "" && pincodeErr == "" && (emailErr == "" && emailErr != "Please enter proper email." && emailErr != "your emailId already register as a visitor account") && countryIdErr == ""
                    && attendeeTypeErr == "" && daysErr == ""
                ) {
                    this.setState({ isLoading: true })
                    /// Setting data in session storage For Insert Next
                    sessionStorage.setItem("sessionregisterTelephoneNoPre", preTelNo)
                    sessionStorage.setItem("sessionregisterTelephoneNo1Pre", preTel1No)
                    sessionStorage.setItem("sessionCountryNamePreRegisterMobileNo", preMobNo)
                    sessionStorage.setItem("sessionMobileNo", mobNo)
                    sessionStorage.setItem("sessionCountryNamePreRegisterWhatsAppMobNo", preWhatsAppMobNo)
                    sessionStorage.setItem("sessionVisitorEmail", email)

                    this.props.visitorRegistrationNext_action(countryId, attendeeTypeId, preName, firstName, middleName, lastName,
                        designation, institute, mobNo, whatsAppMobNo, isSameAsMobNo, regState, city,
                        pincode, website, email, telNo, tel1No, list)
                        .then((data) => {
                            if (data.error != 1) {
                                console.log("Visitor Registration Next", data.data[0])
                                this.setState({ isLoading: false })
                                sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0]))
                                sessionStorage.setItem('countryId', countryId)
                                this.props.navigate('/visitorindustrysegment', {
                                    state: {
                                        ExhVisitorID: data.data[0]['ExhVisitorID'],
                                        countryID: countryId
                                    }
                                })
                            } else {
                                this.setState({ isLoading: false })
                                toastError(data.msg);
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    toastError("Enter Mandatory Fields")
                }
            }
            else {
                toastError("Enter Mandatory Fields")
            }

        }, 200);
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    //Pincode Search
    onPincodeSearch(e) {
        const { pincodeList, tempPincodeList, pincode } = this.state
        if (e.target.value != '') {
            var searchPincodeList = tempPincodeList.filter(data => {
                return (
                    data
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                pincode: e.target.value,
                pincodeErr: "",
                tempPincodeList: searchPincodeList,
                isDisplayPincodeList: true
            })
        } else {
            this.setState({
                pincode: e.target.value,
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }

    // render pincode
    renderPincodeList() {
        const { tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pincode: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // On Country Select
    onCountrySelect(e) {
        const { countryList } = this.state
        var countryId = e.target.value
        this.setState({ countryId: countryId, countryIdErr: "" })

        var countryCode = countryList.filter(obj => obj.Code == countryId)[0].CountryCode
        this.setState({
            preMobNo: `+${countryCode}`,
            preWhatsAppMobNo: `+${countryCode}`
        })



        // Calling City , State , Pincode filllist on country Select
        this.getFillList(pincodeActionId, '', '', countryId, '', '')
        this.getFillList(stateActionId, '', '', countryId, '', '')
        this.getFillList(cityActionId, '', '', countryId, '', '')
    }

    // search city
    onCitySearch(e) {
        const { cityList, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                city: e.target.value,
                cityErr: "",
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
        } else {
            this.setState({
                city: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }

    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ city: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // search state
    onStateSearch(e) {
        const { tempStateList, stateList } = this.state
        if (e.target.value != '') {
            var searchStateList = tempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                regState: e.target.value,
                regStateErr: "",
                tempStateList: searchStateList,
                isDisplayStateList: true
            })
        } else {
            this.setState({
                regState: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }

    // render state
    renderStateList() {
        const { tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ regState: data.Description, isDisplayStateList: false })}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }

    // On Same As Mobile No Click
    onSameAsMobNoClick(e) {
        const { isSameAsMobNo, mobNo, preMobNo } = this.state
        this.setState({ isSameAsMobNo: !isSameAsMobNo })
        var checked = e.target.checked
        if (checked) {
            this.setState({
                whatsAppMobNo: mobNo,
                preWhatsAppMobNo: preMobNo,
                whatsAppMobNoErr: ""
            })
        } else {
            this.setState({
                whatsAppMobNo: "",

            })

        }
    }

    // Get Registration Details
    getRegistrationDetails() {

        setTimeout(() => {
            const { attendeeList, daysList } = this.state
            this.setState({ isLoading: true })

            this.props.getVistiorRegistarionDetails_action()
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get Registration Details", data.data['SelectedVisitDays'])
                        // Updating Attendee List
                        var attendeeId = data.data['registrationDetails'][0]['AttendeeTypeID'] != null ? data.data['registrationDetails'][0]['AttendeeTypeID'] : ''
                        attendeeList.length > 0 && attendeeList.map((item) => {
                            if (item.AttendeeTypeID == attendeeId) {
                                item.isChecked = true
                            }
                        })
                        // Split Phone Numbers and country Code 
                        //  Mobile No
                        var mob1 = data.data['registrationDetails'][0]['MobileNo']
                        const parts = mob1.split("-");
                        const Mob1Code = parts[0]
                        const Mob1No = parts[1]
                        // WhatsApp Mobile No
                        var mob2 = data.data['registrationDetails'][0]['WhatsappNo']
                        const parts2 = mob2.split("-")
                        const Mob2Code = parts2[0]
                        const Mob2No = parts2[1]
                        // TelePhone No
                        var tel = data.data['registrationDetails'][0]['Telephone']
                        const parts3 = tel.split("-")
                        const telCode = parts3[0]
                        const telNumber = parts3[1]
                        // Telephone 1 Mobile No
                        var tel1 = data.data['registrationDetails'][0]['Telephone1']
                        const parts4 = tel1.split("-")
                        const tel1Code = parts4[0]
                        const tel1Number = parts4[1]

                        // Days List Prepare on basis on get Days list
                        var selectedDaysList = data.data['SelectedVisitDays'] != null ? data.data['SelectedVisitDays'] : []
                        daysList.length > 0 && daysList.map((item) => {
                            selectedDaysList.length > 0 && selectedDaysList.map((element) => {
                                if (element.VisitDate == item.description) {
                                    item.isSelect = true
                                }
                            })
                        })

                        this.setState({
                            isLoading: false,
                            countryId: data.data['registrationDetails'][0]['CountryID'] != null ? data.data['registrationDetails'][0]['CountryID'] : '',
                            attendeeTypeId: data.data['registrationDetails'][0]['AttendeeTypeID'] != null ? data.data['registrationDetails'][0]['AttendeeTypeID'] : '',
                            preName: data.data['registrationDetails'][0]['Title'] != null ? data.data['registrationDetails'][0]['Title'] : '',
                            firstName: data.data['registrationDetails'][0]['FirstName'] != null ? data.data['registrationDetails'][0]['FirstName'] : '',
                            middleName: data.data['registrationDetails'][0]['MiddleName'] != null ? data.data['registrationDetails'][0]['MiddleName'] : '',
                            lastName: data.data['registrationDetails'][0]['LastName'] != null ? data.data['registrationDetails'][0]['LastName'] : '',
                            designation: data.data['registrationDetails'][0]['Designation'] != null ? data.data['registrationDetails'][0]['Designation'] : '',
                            institute: data.data['registrationDetails'][0]['Institute_Organisation'] != null ? data.data['registrationDetails'][0]['Institute_Organisation'] : '',
                            preMobNo: data.data['registrationDetails'][0]['MobileNo'] ? Mob1Code : "",
                            mobNo: data.data['registrationDetails'][0]['MobileNo'] ? Mob1No : "",
                            preWhatsAppMobNo: data.data['registrationDetails'][0]['WhatsappNo'] ? Mob2Code : "",
                            whatsAppMobNo: data.data['registrationDetails'][0]['WhatsappNo'] ? Mob2No : "",
                            isSameAsMobNo: data.data['registrationDetails'][0]['IsSameAsMobileNo'] != null ? data.data['registrationDetails'][0]['IsSameAsMobileNo'] : '',
                            regState: data.data['registrationDetails'][0]['State'] != null ? data.data['registrationDetails'][0]['State'] : '',
                            city: data.data['registrationDetails'][0]['City'] != null ? data.data['registrationDetails'][0]['City'] : '',
                            pincode: data.data['registrationDetails'][0]['Pincode'] != null ? data.data['registrationDetails'][0]['Pincode'] : '',
                            website: data.data['registrationDetails'][0]['Website'] != null ? data.data['registrationDetails'][0]['Website'] : '',
                            email: data.data['registrationDetails'][0]['Email'] != null ? data.data['registrationDetails'][0]['Email'] : '',
                            preTelNo: data.data['registrationDetails'][0]['Telephone'] != null ? telCode : '',
                            telNo: data.data['registrationDetails'][0]['Telephone'] != null ? telNumber : '',
                            preTel1No: data.data['registrationDetails'][0]['Telephone1'] != null ? tel1Code : '',
                            tel1No: data.data['registrationDetails'][0]['Telephone1'] != null ? tel1Number : '',
                            attendeeList: attendeeList,
                            daysList: daysList

                        })
                        this.setState({ isLoading: false })
                    } else {
                        toastError(data.msg)
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }, 400);
    }
    // On File Pick
    onFilePick = async (e, file, key) => {
        e.preventDefault()
        console.log("File01010101------", file, key)
        const { } = this.state
        if (file[0]['size'] >= 5 * 1024 * 1024) {
            toastError('File size more than 5mb are not allow.')
        } else {
            var image = '';
            if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                file[0]['name'].toString().toLowerCase().includes('.png') ||
                file[0]['name'].toString().toLowerCase().includes('.jpg')
            ) {

                if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                ) {
                    image = await this.resizeFile(file[0]);
                    if (key == 1) {
                        this.setState({ photoFile: image })
                    } else if (key == 2) {
                        this.setState({ photoIdFile: image })
                    } else if (key == 3) {
                        this.setState({ vaccineCertificateFile: image })
                    }
                }
                else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                    image = await this.onPdfSelect(file[0]);
                    if (key == 1) {
                        this.setState({ photoFile: image })
                    } else if (key == 2) {
                        this.setState({ photoIdFile: image })
                    } else if (key == 3) {
                        this.setState({ vaccineCertificateFile: image })
                    }
                }

                var fileType = file[0]['type']
                var fileTypeList;
                if (fileType.includes("/")) {
                    fileTypeList = fileType.split("/")
                }
                if (key == 1) {
                    this.setState({ photoFileType: image })
                } else if (key == 2) {
                    this.setState({ photoIdFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                } else if (key == 3) {
                    this.setState({ vaccineCertificateFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                }
            } else {
                toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
            }
        }
    }

    // If Pdf is selected in file
    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    // File resize
    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    // on Cross click
    onCrossClick(key) {

        if (key == 1) {
            this.setState({ photoFile: "" })
            document.getElementById("photoFile").value = ""
        } else if (key == 2) {
            this.setState({ photoIdFile: "" })
            document.getElementById("photoIdFile").value = ""
        } else if (key == 3) {
            this.setState({ vaccineCertificateFile: "" })
            document.getElementById("vaccFile").value = ""
        }
    }

    //On Country Search In Personal Information
    onPersonalCountrySearch(e) {
        const { personalCountryList, tempPersonalCountryList } = this.state
        this.setState({ personalCountry: '' })
        if (e.target.value != '') {
            console.log("tempPersonalCountryList ++++----------->", tempPersonalCountryList)

            var searchCoutryList = tempPersonalCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                personalCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                personalCountryErr: '',
                tempPersonalCountryList: searchCoutryList,
                isDisplayPersonalCountryList: true
            })
        }
        else {

            this.setState({
                personalCountry: e.target.value,
                tempPersonalCountryList: personalCountryList,
                isDisplayPersonalCountryList: false
            })
        }
    }

    // Render Country List in Personal Information
    renderPersonalCountryList() {
        const { tempPersonalCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPersonalCountryList.length > 0 && tempPersonalCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                personalCountry: data.Description, personalCountryId: data.Code,
                                isDisplayPersonalCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // On Select Attendee 
    onAttendeeSelect(e) {
        const { attendeeList } = this.state
        var checked = e.target.checked
        var id = e.target.value
        attendeeList.length > 0 && attendeeList.map((data) => {
            if (checked) {
                if (id == data.AttendeeTypeID) {
                    data.isChecked = true
                    this.setState({ attendeeTypeId: data.AttendeeTypeID, attendeeTypeErr: "" })
                } else {
                    data.isChecked = false
                }
            } else {
                data.isChecked = false
            }
        })
        console.log("attendeeList------->", attendeeList)
        this.setState({ attendeeList: attendeeList })
    }

    // Email Verify after Input
    verifyEmail(e) {
        var value = e.target.value
        if (value != "") {
            var emailError = emailValidator(value)
            if (emailError != '') {
                this.setState({ emailErr: emailError })
                return
            } else {
                this.setState({ isLoading: true })
                this.props.verifyVisitorEmail_action(value)
                    .then((data) => {
                        if (data.error == 1) {
                            this.setState({ isLoading: false })
                            console.log("verifyEmail--->", data.ErrMsg)
                            if (data.ErrMsg != "" && data.ErrMsg != null) {
                                this.setState({ emailErr: data.ErrMsg })
                            } else {
                                this.setState({ emailErr: "" })
                            }
                        } else {
                            this.setState({ isLoading: false })
                            this.setState({ emailErr: "" })
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }


        } else {
            this.setState({ emailErr: "Please enter Email" })
        }
    }

    onPreviousClick(e) {
        e.preventDefault();
        const win = window.location.replace('https://www.itme-africa.com/', '_blank');
        if (win != null) {
            win.focus();
        }
    }


    render() {
        const { isLoading, country, countryList, countryId, daysList, firstName, middleName, lastName, preName,
            isOtherPreName, otherName, gender, designation, institute, preMobNo, mobNo, preWhatsAppMobNo, whatsAppMobNo,
            add1, add2, add3, pincode, city, regState, website, gstNo, panNo, email, preTelNo, telNo, tel1No, preTel1No,
            isDisplayPincodeList, isDisplayCityList, isDisplayStateList, isSameAsMobNo, vaccineCertificateFile, photoFile,
            photoIdFile, countryIdErr, attendeeTypeErr, cityErr, preMobNoErr, mobNoErr, regStateErr, designationErr, photoFileErr,
            lastNameErr, photoIdFileErr, genderErr, instituteErr, firstNameErr, preWhatsAppMobNoErr, whatsAppMobNoErr, personalCountryId,
            personalCountry, isDisplayPersonalCountryList, personalCountryErr, attendeeList, attendeeTypeId, pincodeErr, emailErr, daysErr, isExhbitionClosed
        } = this.state

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {this.state.isDataLoad && < VisitorHeader />}
                <div
                    className="main-content app-content mt-0"
                    style={{ marginInlineStart: 0 }}
                >
                    <div className="side-app">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div
                                    className="step-name" style={{ backgroundColor: "#bc202b", color: "#fff;", marginTop: '85px' }}
                                >
                                    <p>Visitor Registration form</p>
                                </div>
                            </div>
                        </div>
                        {!isExhbitionClosed ? (
                            <div className="needs-validation after-step mt-1">
                                <div id="Menu1">
                                    <div id="section-1">
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Country</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 mb-3">
                                                <div className="d-flex">
                                                    <label className="mt-3 w-auto" >
                                                        Select Country
                                                        <span className="text-red">*</span>

                                                    </label>
                                                    {<span className='text-danger text-start text-left d-block mt-3 ml-2'>{countryIdErr}</span>}
                                                </div>
                                                <select className="form-control" value={countryId}
                                                    onChange={(e) => this.onCountrySelect(e)}
                                                >
                                                    <option>Select Your Country</option>
                                                    {
                                                        countryList.length > 0 && countryList.map((data) => (
                                                            <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Attendee Type <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{attendeeTypeErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 mb-4">
                                                <div className="row mt-4">
                                                    {
                                                        attendeeList.length > 0 && attendeeList.map((data) => (
                                                            <div className="col-sm-4" key={data.AttendeeTypeID}>
                                                                <label className="d-flex">
                                                                    <input type="radio" name="attendeeType" value={data.AttendeeTypeID}
                                                                        checked={data.isChecked}
                                                                        // onChange={e => this.setState({ attendeeType: "Business Visitor" })}
                                                                        onChange={(e) => this.onAttendeeSelect(e)}
                                                                    />
                                                                    {data.Name}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Date of Visit Single Day Pass / All Day Pass{" "}
                                                                <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{daysErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 mb-4">
                                                <div className="row mt-4" >
                                                    {
                                                        daysList.length > 0 && daysList.map((data) => (
                                                            <div className="col-sm-4" key={data.Code}>
                                                                <label className="d-flex">
                                                                    <input type="checkbox" select={data.isSelect}
                                                                        checked={data.isSelect} value={data.Code}
                                                                        onChange={(e) => this.onSelectDates(e)}
                                                                    />
                                                                    {data.description}
                                                                </label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Personal Information</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-xl-3 mt-3">
                                                        <label>
                                                            First Name<span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            isOtherPreName &&
                                                            <input
                                                                type="text"
                                                                className="form-control-1 other-fil"
                                                                maxLength={'40'}
                                                                value={otherName}
                                                                onChange={e => this.setState({ otherName: e.target.value })}
                                                                placeholder={'Other'}
                                                                style={{
                                                                    // position: "absolute",
                                                                    paddingLeft: '5px'
                                                                }}
                                                            />
                                                        }
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={'50'}
                                                            style={{ position: "relative", paddingLeft: 55 }}
                                                            value={firstName}
                                                            onChange={(e) => this.setState({ firstName: e.target.value, firstNameErr: "" })}
                                                        />

                                                        <div className="col-xl-1 mb-3">
                                                            <div
                                                                className="form-group"
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: "-57px",
                                                                    left: 0
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 form-control select2 select-hidden-accessible"
                                                                    id="validationCustom03"
                                                                    required=""
                                                                    style={{
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: 2,
                                                                        borderRadius: 0,
                                                                        borderLeft: 0,
                                                                        height: 33,
                                                                        background: "none"
                                                                    }}
                                                                    value={preName}
                                                                    onChange={(e) => this.setState({
                                                                        preName: e.target.value,
                                                                        isOtherPreName: e.target.value == "Other" && true
                                                                    })}
                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        {<span className='text-danger text-start text-left d-block'>{firstNameErr}</span>}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label className="mt-3">Middle Name</label>
                                                        <input type="text" className="form-control" value={middleName}
                                                            maxLength={'50'}
                                                            onChange={(e) => this.setState({ middleName: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label className="mt-3">Last Name
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={lastName} maxLength={'50'}
                                                            onChange={(e) => this.setState({ lastName: e.target.value, lastNameErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{lastNameErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-3">
                                                            Designation <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={designation} maxLength={'50'}
                                                            onChange={(e) => this.setState({ designation: e.target.value, designationErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-4 mb-4">
                                            <div className="row mt-2">
                                                <div className="col-sm-2">
                                                    <label className="d-flex">
                                                        Gender:
                                                        <span className="text-red">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-2">
                                                    <label className="d-flex">
                                                        <input type="radio" name="gender"
                                                            checked={gender == "Male"}
                                                            onChange={e => this.setState({ gender: "Male" })}
                                                        />
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label className="d-flex">
                                                        <input type="radio" name="gender"
                                                            checked={gender == "Female"}
                                                            onChange={e => this.setState({ gender: "Female" })}
                                                        />
                                                        Female
                                                    </label>
                                                </div>
                                                {<span className='text-danger text-start text-left d-block mt-1'>{genderErr}</span>}
                                            </div>
                                        </div> */}
                                            <div className="col-xl-12 mb-4">
                                                <div className="row">

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            Institute/Organisation <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={institute} maxLength={'500'}
                                                            onChange={(e) => this.setState({ institute: e.target.value, instituteErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{instituteErr}</span>}

                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Mobile No
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={mobNo}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: 50 }}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ mobNo: e.target.value, mobNoErr: "" })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            placeholder="ISD"
                                                            maxLength={'10'}
                                                            value={preMobNo}
                                                            onChange={(e) => this.setState({ preMobNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{mobNoErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> WhatsApp Mobile No
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={whatsAppMobNo}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: 50 }}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ whatsAppMobNo: e.target.value, whatsAppMobNoErr: "" })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preWhatsAppMobNo}
                                                            placeholder="ISD"
                                                            maxLength={'10'}
                                                            onChange={(e) => this.setState({ preWhatsAppMobNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{whatsAppMobNoErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-6">
                                                            <input type="checkbox" name="fav_language"
                                                                value={isSameAsMobNo} checked={isSameAsMobNo}
                                                                onClick={(e) => this.onSameAsMobNoClick(e)}
                                                            />
                                                            Same as Mobile No
                                                        </label>
                                                    </div>
                                                    {/* <div className="col-xl-4">
                                                    <label className="mt-4">
                                                        Upload Photo <span className="rad-star">*</span>
                                                    </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="photoFile" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                        />
                                                        {
                                                            photoFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(1)}></i>
                                                        }
                                                    </form>

                                                    <p>
                                                        <strong>Note:</strong> Upload Maximum 2mb Size Only /
                                                        Allowed File Type: .jpg,.png
                                                    </p>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{photoFileErr}</span>}
                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="mt-4">
                                                        Upload Photo ID <span className="rad-star">*</span>
                                                    </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="photoIdFile" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 2)}
                                                        />
                                                        {
                                                            photoIdFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(2)}></i>
                                                        }
                                                    </form>

                                                    <p>
                                                        <strong>Note:</strong> Upload Maximum 2mb Size Only /
                                                        Allowed File Type: .jpg,.png
                                                    </p>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{photoIdFileErr}</span>}

                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="mt-4">
                                                        Upload Fully Vaccination Certificate
                                                    </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="vaccFile" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 3)}
                                                        />
                                                        {
                                                            vaccineCertificateFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(3)}></i>
                                                        }
                                                    </form>

                                                    <p>
                                                        <strong>Note:</strong> Upload Maximum 2mb Size Only /
                                                        Allowed File Type: .jpg,.png
                                                    </p>
                                                </div> */}
                                                    {/* <div className="col-sm-6">
                                                    <label className="d-flex mt-2"> Address Line 1</label>
                                                    <input type="text" className="form-control" value={add1}
                                                        onChange={(e) => this.setState({ add1: e.target.value })}
                                                    />
                                                </div> */}
                                                    {/* <div className="col-sm-6">
                                                    <label className="d-flex mt-2"> Address Line 2</label>
                                                    <input type="text" className="form-control" value={add2}
                                                        onChange={(e) => this.setState({ add2: e.target.value })}
                                                    />
                                                </div> */}
                                                    {/* <div className="col-sm-6">
                                                    <label className="d-flex mt-2"> Address Line 3</label>
                                                    <input type="text" className="form-control" value={add3}
                                                        onChange={(e) => this.setState({ add3: e.target.value })}
                                                    />
                                                </div> */}
                                                    {/* <div className="col-sm-3">
                                                    <label className="d-flex mt-2">
                                                        Country<span className="red-star text-danger">*</span>
                                                    </label>
                                                    <input type="text" className="form-control" value={personalCountry}
                                                        onChange={(e) => this.onPersonalCountrySearch(e)}
                                                    />
                                                    {isDisplayPersonalCountryList && this.renderPersonalCountryList()}
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{personalCountryErr}</span>}
                                                </div> */}
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            Pincode/Zipcode
                                                            {countryId == "91" && <span className="red-star text-danger">*</span>}
                                                        </label>
                                                        {
                                                            countryId == "91" &&
                                                            <input type="text" className="form-control" value={pincode}
                                                                maxLength={6} onChange={(e) => this.onPincodeSearch(e)}
                                                            />
                                                        }
                                                        {
                                                            countryId != "91" &&
                                                            <input type="text" className="form-control" value={pincode}
                                                                maxLength={10} onChange={(e) => this.onPincodeSearch(e)}
                                                                onInput={toInputUppercase}
                                                            />
                                                        }
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{pincodeErr}</span>}
                                                        {isDisplayPincodeList && this.renderPincodeList()}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            State/Province
                                                            {countryId == "91" && <span className="red-star text-danger">*</span>}
                                                        </label>
                                                        <input type="text" className="form-control" value={regState} maxLength={'50'}
                                                            onChange={(e) => this.onStateSearch(e)}
                                                        />
                                                        {isDisplayStateList && this.renderStateList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regStateErr}</span>}
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">City
                                                            <span className="red-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={city} maxLength={'50'}
                                                            onChange={(e) => this.onCitySearch(e)} />
                                                        {isDisplayCityList && this.renderCityList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{cityErr}</span>}
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> Website</label>
                                                        <input type="text" className="form-control" value={website} maxLength={'50'}
                                                            onChange={(e) => this.setState({ website: e.target.value })}
                                                        />
                                                    </div>
                                                    {/* <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> GST No</label>
                                                    <input type="text" className="form-control" value={gstNo}
                                                        onChange={(e) => this.setState({ gstNo: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> PAN No</label>
                                                    <input type="text" className="form-control" value={panNo}
                                                        onChange={(e) => this.setState({ panNo: e.target.value })}
                                                    />
                                                </div> */}
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> Email
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={email} maxLength={'50'}
                                                            onChange={(e) => this.setState({ email: e.target.value, emailErr: "" })}
                                                            onBlur={(e) => this.verifyEmail(e)}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{emailErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Telephone No</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={telNo}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ telNo: e.target.value })}
                                                            style={{ paddingLeft: 50 }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preTelNo} placeholder="STD" maxLength={'10'}
                                                            onChange={(e) => this.setState({ preTelNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Telephone No 1</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={tel1No}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ tel1No: e.target.value })}
                                                            style={{ paddingLeft: 50 }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preTel1No} placeholder="STD" maxLength={'10'}
                                                            onChange={(e) => this.setState({ preTel1No: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="main-container m-2">
                                            <div className="mb-4">
                                                <a
                                                    className="btn btn-next-bot"
                                                    type="submit"
                                                    onClick={(e) => this.onVisitorRegistrationNext(e)}
                                                >
                                                    Next
                                                </a>
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="main-content app-content mt-0"
                                style={{ backgroundColor: "#ececec" }}
                            >
                                <div className="side-app">
                                    <div className="main-container container-fluid top-form-name">
                                        <div className="row ">
                                            <form className="needs-validation " noValidate="">
                                                <div className="form-row mt-4">
                                                    <div className="form-group col-md-7 p-4 thank-box">
                                                        <div className="form-group mb-2">
                                                            <h4
                                                                className="mb-0 pt-2 pb-4 thank-top-mes"
                                                                style={{ color: "#318958" }}
                                                            >
                                                                <strong>Exhibition Closed</strong>
                                                            </h4>
                                                            <p className="mb-1 mt-1 text-center com-font">
                                                                Thank you for your interest but the
                                                            </p>
                                                            <p className="mt-1 mb-0 text-center com-font">
                                                                ITME AFRICA &amp; M.E. 2023 from 30 November 2023 to 2
                                                                December 2023
                                                            </p>
                                                            <p className="mb-3 text-center com-font">
                                                                at Kenyatta International Convention Centre, Nairobi, Kenya is now closed.
                                                            </p>
                                                            <p className="mb-3 text-center com-font">
                                                                This Event already completed. Please Visit Again once the next edition is announced.
                                                            </p>
                                                        </div>
                                                        <div classname="">
                                                            <div classname="mt-1 mb-4">

                                                                <button
                                                                    classname="btn btn-next-bot m-1"
                                                                    style={{
                                                                        background: "#007bff",
                                                                        padding: "6px 20px",
                                                                        fontSize: 14,
                                                                        border: "1px solid #007bff",
                                                                        borderRadius: 2,
                                                                        color: "#fff",
                                                                        marginTop: 16
                                                                    }}
                                                                    onClick={e => this.onPreviousClick(e)}
                                                                >
                                                                    {" "}
                                                                    BACK TO EVENT WEBSITE
                                                                </button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(connect(null, {
    fill_action, getVistiorRegistarionDetails_action, visitorRegistrationNext_action,
    verifyVisitorEmail_action
})(VisitorRegistration));