import { Component } from "react";
import Loader from "../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../../actions/fillCombo_action';
import { cityActionId, stallTypeActionId, stallListActionId, countryActionId, exhibitionActionId, toastError, toastSuccess } from "../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Pagination from "../../../../utils/pagination";
import AdminDrawer from "../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import { getHallMasterList_action, deleteHallMaster_action } from "../../../../actions/admin_module_action/master_action/hall_master_action";
import Footer from "../../../../common_components/Footer";


class HallMasterList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOpen: false,
            currentPage: 1,
            exhibitionList: [],
            hallMasterList: [],
            exhibitionID: '',
            hallName: '',
            deleteID: '',
        }

        this.getFillList = this.getFillList.bind(this)
        this.record = this.record.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.deleteHallMaster = this.deleteHallMaster.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.gethallMasterList = this.gethallMasterList.bind(this)
    }

    componentDidMount() {
        this.getFillList(exhibitionActionId, '', '', '', '', '');

        this.gethallMasterList()
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === exhibitionActionId) {
                        console.log("country data", data.data)
                        // var internationalCountryData = data.data.length > 0 && data.data.filter((item) => item.Code != "91")
                        this.setState({
                            exhibitionList: data.data
                        })

                    }
                }
                this.setState({ isLoading: false })
            })
            .catch((e) => {
                console.log(e);
            });
    }

    record(list) {
        return (
            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                <thead>
                    <tr>
                        {/* <th className="text-center">Role ID</th> */}
                        {/* <th className="text-center">From Date</th>
                        <th className="text-center">To Date</th>
                    <th className="text-center">Country</th> */}
                        <th className="text-center" style={{ width: '50px' }}>Sr no.</th>
                        <th >Exhibition Name</th>
                        <th className="text-center"> Hall Name</th>
                        <th className="text-center">Segment</th>
                        <th style={{ width: '60px' }} className="text-center"></th>
                        <th style={{ width: '60px' }} className="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ? <>{
                            list.map((data, i) => (
                                <tr key={Math.random()}>
                                    {/* <td className="text-center">{data.RoleID}</td> */}
                                    {/* <td className="text-center">{moment(data.ExhibitionDateFrom).format('L')}</td>
                                    <td className="text-center">{moment(data.ExhibitionDateTo).format('L')}</td>
                                <td className="text-center">{this.state.countryList.map(id => (parseInt(id.Code) == data.CountryID ? id.Description : null))}</td> */}
                                    <td className="text-center" style={{ width: '50px' }}>{i + 1}</td>
                                    <td >{data.ExhibitionName}</td>
                                    <td className="text-center">{data.HallName}</td>
                                    <td className="text-center">{data.segmentArr}</td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={(e) => this.handleUpdate(data.HallId)}
                                        >
                                            Update
                                        </button>
                                    </td>
                                    <td className="text-center">
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={(e) => this.onDelete(data.HallId)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </> :
                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                    }
                </tbody>
            </table>)
    }

    gethallMasterList() {
        this.setState({ isLoading: true })
        const { hallName, exhibitionID } = this.state
        this.props.getHallMasterList_action(exhibitionID, hallName)
            .then((data) => {
                if (data.error != 1) {
                    var doneID = []
                    for (var i = 0; i < data.data[1].length; i++) {
                        var segmentArr = []
                        if (!doneID.includes(data.data[1][i].HallId)) {
                            for (var j = 0; j < data.data[1].length; j++) {
                                if (data.data[1][i].HallId == data.data[1][j].HallId) {
                                    segmentArr.push(data.data[1][j].ChapterName)
                                }
                            }
                            for (var j = 0; j < data.data[0].length; j++) {
                                if (data.data[1][i].HallId == data.data[0][j].HallId) {
                                    data.data[0][j].segmentArr = segmentArr.toString()
                                }
                            }
                            doneID.push(data.data[1][i].HallId)
                        }
                    }
                    console.log(data.data[0])

                    this.setState({ hallMasterList: data.data[0] })
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    clearSearch() {
        const { exhibitionID, hallName } = this.state
        this.setState({
            exhibitionID: '',
            hallName: ''
        })
        setTimeout(() => {
            this.gethallMasterList()
        }, 100);
    }

    handleAdd() {
        this.props.navigate('/hallMasterAU', { state: { is_add: true, } })
    }

    handleUpdate(id) {
        this.props.navigate('/hallMasterAU', { state: { is_update: true, hallID: id } })
    }

    deleteHallMaster(id) {
        this.setState({ isLoading: true })
        this.props.deleteHallMaster_action(id)
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    this.setState({ isLoading: false })
                    toastSuccess("deleted successfully")
                    setTimeout(() => {
                        this.gethallMasterList()
                    }, 200);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
                this.setState({ isOpen: false })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    onDelete(i) {
        const { } = this.state
        this.setState({ deleteID: i })
        this.setState({ isOpen: true, })
    }


    render() {
        const { isOpen, isLoading, exhibitionID, currentPage, hallMasterList, exhibitionList, hallName } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = hallMasterList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(hallMasterList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <div className="app-content with-dr-container com-height">

                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Hall List</p>
                            </div>
                        </div>
                        {/* <div className="app-content with-dr-container com-height"> */}
                            <div className="m-3">
                                <div className="row ">
                                    {
                                        JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                        <div className="row ">
                                            <div className="col-md-4">
                                                <label>Exhibition</label>
                                                <select className="form-control"
                                                    value={exhibitionID}
                                                    onChange={(e) => this.setState({ exhibitionID: e.target.value })}  >
                                                    <option value={''}>Select</option>
                                                    {
                                                        exhibitionList.length > 0 && exhibitionList.map(data => (
                                                            <option value={data.ExhibitionID} key={data.ExhibitionID}>{data.ExhibitionName}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-4">
                                                <label>Hall Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={hallName}
                                                    onChange={(e) => this.setState({ hallName: e.target.value, })}
                                                />
                                            </div>
                                            {/* <div class="">
                                    <label for="validationCustom03">Total Exhibition Area<span style={{ color: 'red' }}>*</span></label>
                                    <input value={totalExhibitionArea} onChange={(e) => this.setState({ totalExhibitionArea: parseInt(e.target.value) })} type="number" class="form-control" placeholder="" />
                                </div> */}
                                        </div>
                                    }
                                </div>
                                < div className="row" >
                                    <div className="col-md-12">
                                        {
                                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                            <div
                                                className="btn-group mb-1"
                                                role="group"
                                                aria-label="Basic example"
                                                style={{ float: "right" }}
                                            >
                                                <button type="button" className="btn ad-fil-btn"
                                                    onClick={e => this.gethallMasterList()}
                                                >
                                                    Search
                                                </button>
                                                <button type="button" className="btn ad-fil-btn"
                                                    onClick={e => this.handleAdd()}
                                                >
                                                    Add
                                                </button>
                                                <button type="button" className="btn ad-fil-btn"
                                                    onClick={e => this.clearSearch()}
                                                >
                                                    Clear
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {this.record(currentRecords)}
                                </div>
                                <div className="mt-2 float">
                                    {
                                        hallMasterList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </div>
                </>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-2">
                                <h5 class="modal-title modal_title_text text-danger">Do you really want to delete this record?</h5>

                                {/* <button type="button " class="close pop-close" data-dismiss="modal"
                                    onClick={() => this.setState({ isOpen: false })}
                                ><span aria-hidden="true" class="pop-cancel">&times;</span></button> */}
                            </div>
                            {/* <div class="modal-body">
                                <div class="row">
                                </div>
                            </div> */}
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop"
                                        onClick={(e) => this.deleteHallMaster(this.state.deleteID)}
                                    >Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }}
                                        onClick={() => this.setState({ isOpen: false })}
                                    >No</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Footer />
            </>)
    }

}
export default withRouter(connect(null, { fill_action, getHallMasterList_action, deleteHallMaster_action })(HallMasterList));