import moment from "moment";
import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_PROFORMA_INV_LIST_ACTION, GET_PROFORMA_PDF_ACTION_TYPE, getDuePaymentListUrl, GET_DUE_PAYMENT_LIST_ACTION_TYPE, fetchAuthReport, getProformaInvListUrl, SHARE_PROFORMA_INV_ACTION, proformaShareUrl, headers } from "../../../utils/constants";

export const proformaInvShare_action = (proformaListShare) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            proformaListShare: proformaListShare,
        }
        console.log("pendingReceiptDetails_action-Action--------------------------->", params)

        const res = await axiosPost(proformaShareUrl, params, headers);
        dispatch({
            type: SHARE_PROFORMA_INV_ACTION,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const getProformaPdf_action = (ExhRegistrationID, countryid, InstallmentType) => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName": "@ExhRegistrationID",
            "ParameterValue": ExhRegistrationID,
            "ParameterDataType": "int"
        }
        list.push(mapdata1);
        const mapData = {
            "report_name": InstallmentType == "INST 1" ? (countryid == "91" ? "1stInstallment_India.rpt" : "1stInstallment.rpt") : (countryid == "91" ? "2ndInstallment_India.rpt" : "2ndInstallment.rpt"),
            "parameter_details": list
        }
        const res = await axiosPost(fetchAuthReport, mapData);
        dispatch({
            type: GET_PROFORMA_PDF_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const proformaInvList_action = (exhibitor, proformaDate, proformaNo) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            Exhibitor: exhibitor,
            ProformaInvoiceDate: proformaDate != "" ? (moment(proformaDate).format('MM-DD-YYYY')) : null,
            ProformaInvoiceNo: proformaNo
        }
        console.log("proformaInvList_action -Action--------------------------->", params)

        const res = await axiosGet(getProformaInvListUrl, params, headers);
        dispatch({
            type: GET_PROFORMA_INV_LIST_ACTION,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const getDuePaymentList_action = (exhibitor, proformaDate, proformaNo) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            Exhibitor: exhibitor,
            ProformaInvoiceDate: proformaDate != "" ? (moment(proformaDate).format('MM-DD-YYYY')) : null,
            ProformaInvoiceNo: proformaNo
        }
        console.log("proformaInvList_action -Action--------------------------->", params)

        const res = await axiosGet(getDuePaymentListUrl, params, headers);
        dispatch({
            type: GET_DUE_PAYMENT_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}