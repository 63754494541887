import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Footer from '../../common_components/Footer';
import Drawer from '../../common_components/Drawer';
import {

} from '../../actions/registration_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { sendPaymentForm_action } from "../../actions/payment_action/payment_action";
import { clearSessionStorage, countryActionId, paymentPortalLink, paymentAccessCode, toastError, toastSuccess, downloadReport, paymentMasterListActionId } from '../../utils/constants'
import Pagination from '../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../actions/fillCombo_action';
import { lastSubmitAdd_action } from "../../actions/last_submit_action";
import {
    getdescriptionDetails_action, descriptionAdd_action, descriptionUpdate_action,
    getdescriptionTermsAndCondition_action, descriptionPreview_action,
} from '../../actions/description_action'
import {
    getPendingRegDetails_action
} from '../../actions/registration_action'
import StepHeader from './stepHeader';
import $ from 'jquery'
import {
    contactNoValidator,
    panNoValidator,
    tanNoValidator,
    gstNoValidator,
    emailValidator,
} from "../../utils/Validator";

/* 
 * mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
 * mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class Description extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            isDecscriptionUpdate: false,

            comment: '',
            commentErr: '',
            countryId: "",
            isChecBox1: false,
            isChecBox2: false,
            isChecBox3: false,
            isChecBox4: false,
            isChecBox5: false,
            estimateDescription: '',
            descriptionList: [],
            paymentList: [],

            exhRegistrationID: 0,
            exhibitionID: '',
            RandRpath: '',
            openPop1: false,
            openPop2: false,
            openPop5: false,
            openPop3: false,
            openPop4: false,
            openPaymentPop: false,
            remittancesInfavour: '',
            checkboxList: [],
            undertakingContent: [],
            AuthorizedPersonName: '',
            DesignationAuthorizedPerson: '',
            ExhibitorCompanyName: '',
            EmailAuthorizedPerson: '',
            MobileAuthorizedPerson: '',
            AuthorizedPersonNameErr: '',
            DesignationAuthorizedPersonErr: '',
            ExhibitorCompanyNameErr: '',
            EmailAuthorizedPersonErr: '',
            MobileAuthorizedPersonErr: '',
            PreMobileAuthorizedPerson: '',

            remark: '',
            toPay: '',
            toPaydummy: '',
            paymentType: 1,
            customPayment: '',
            isCustompayment: '',
            minPay: '',
            encPayload: '',
            isPaymentEnable: false,
        }
        this.submitfrm = React.createRef()
        this.getFillList = this.getFillList.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.onCheckBoxClick = this.onCheckBoxClick.bind(this)
        this.onCheckBox2Click = this.onCheckBox2Click.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.onLinkbtn1Click = this.onLinkbtn1Click.bind(this)
        this.onLinkbtn1Click = this.onLinkbtn1Click.bind(this)
        this.onLinkbtn2Click = this.onLinkbtn2Click.bind(this)
        this.onLinkbtn3Click = this.onLinkbtn3Click.bind(this)
        this.onLinkbtn4Click = this.onLinkbtn4Click.bind(this)
        this.loadscript = this.loadscript.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onSubmitClick = this.onSubmitClick.bind(this)
        this.onPreviewClick = this.onPreviewClick.bind(this)
        this.getPendingRegDetailsByCode = this.getPendingRegDetailsByCode.bind(this)
        this.onPayAndSubmit = this.onPayAndSubmit.bind(this)
        this.onPaymentTypeSelect = this.onPaymentTypeSelect.bind(this)
        this.navPayment = this.navPayment.bind(this)

    }

    componentDidMount() {
        console.log('prop is :', this.props);
        // sessionStorage.getItem("sessionContryName",)
        //if pending link call.........................................
        if (this.props.location.pathname.includes('/description/')) {
            var code = this.props.location.pathname.replace('/description/', '');
            sessionStorage.setItem("renderPage", "description")
            this.getPendingRegDetailsByCode(code)
            this.loadscript()
        } else {
            sessionStorage.setItem("renderPage", "description")
            this.setState({
                exhibitionID: sessionStorage.getItem("sessionExhibitionID"),
                exhRegistrationID: sessionStorage.getItem("sessionExhRegistrationID"),
                countryId: sessionStorage.getItem("countryId"),
                PreMobileAuthorizedPerson: sessionStorage.getItem("countryId") == 91 ? '+91' : ''
            });
            this.getDescriptionDetails();
            this.loadscript()
        }
        setTimeout(() => {
            var param = this.props.params != '' && Object.keys(this.props.params).length != 0 && this.props.params != undefined && this.props.params != null ? this.props.params : null
            var status = param != null ? param.status != undefined && param.status != '' && param.status != null ? param.status : null : null
            console.log('=====================>', status, Object.keys(this.props.params).length)
            if (status != null) {
                if (status == 'Success') {
                    toastSuccess("Payment Successful")
                } else if (status == 'Failure') {
                    toastError('Something went wrong! Please try again later.')
                } else if (status == 'Invalid') {
                    toastError('Payment for the order is already processed')
                }
            }
        }, 500);
        console.log(sessionStorage.getItem("countryId"), sessionStorage.getItem("ExhRegistrationIDSS")
        )

    }


    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    getPendingRegDetailsByCode(code) {
        console.log('code is : ', code);
        const { } = this.state

        this.setState({ isLoading: true });

        this.props
            .getPendingRegDetails_action(code)
            .then((data) => {
                if (data.error != 1) {
                    console.log('data is : ', data.data[0]);
                    console.log('ExhRegistrationID is : ', data.data[0].ExhRegistrationID);
                    sessionStorage.setItem("sessionExhibitionID", data.data.length > 0 ? data.data[0].ExhibitionID : '')
                    sessionStorage.setItem("sessionExhRegistrationID", data.data.length > 0 ? data.data[0].ExhRegistrationID : '')
                    sessionStorage.setItem("sessionContryNamePre", data.data.length > 0 ? data.data[0].CountryCode : '')
                    sessionStorage.setItem("isCountrySelected", true)
                    sessionStorage.setItem("isFirstStepCompleted", true)
                    sessionStorage.setItem("sessionContryName", data.data.length > 0 ? data.data[0].CountryName : '')
                    sessionStorage.setItem("countryId", data.data.length > 0 ? data.data[0].CountryID : '')




                    setTimeout(() => {
                        // this.setState({ isLoading: false })
                        this.setState({
                            exhibitionID: data.data.length > 0 ? data.data[0].ExhibitionID : '',
                            exhRegistrationID: data.data.length > 0 ? data.data[0].ExhRegistrationID : '',
                            countryId: data.data.length > 0 ? data.data[0].CountryID : ''
                        });
                        this.getDescriptionDetails();
                    }, 500);
                }
                else {
                    this.setState({ isLoading: false })
                    if (data.msg.includes('Registration completed with ')) {
                        sessionStorage.setItem("sessionExhRegistrationNo", data.msg.replace('Registration completed with ', ''))
                        this.props.navigate('/thankYou')
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onLinkbtn1Click() {
        const { isChecBox1 } = this.state
        this.setState({ isChecBox1: !isChecBox1 })
    }
    onLinkbtn2Click() { }
    onLinkbtn3Click() { }
    onLinkbtn4Click() { }



    onCheckBoxClick(data, i) {
        const { checkboxList, isChecBox1, isChecBox3, openPop4, openPop5, isChecBox4, isChecBox5 } = this.state
        console.log(data.checked)
        // setTimeout(() => {
        if (i == 18) {
            data.checked = !data.checked
            this.setState({ isChecBox1: data.checked })
        } else if (i == 19) {
            if (data.checked) {
                this.setState({ isChecBox4: false })
                data.checked = !data.checked
                // this.setState({ checkboxList: checkboxList })
            } else {
                this.setState({ openPop4: !openPop4 })
            }
        } else if (i == 20) {
            data.checked = !data.checked
            this.setState({ isChecBox3: data.checked })
        } else if (i == 21) {
            if (data.checked) {
                this.setState({ isChecBox5: false })
                data.checked = !data.checked
            } else {
                this.setState({ openPop5: !openPop5 })
            }
            // this.setState({ isChecBox5: true })
        }

        // this.setState({ checkboxList: checkboxList })
        setTimeout(() => {

            console.log(i, checkboxList, isChecBox1, isChecBox3, isChecBox4, isChecBox5)
        }, 500);
        // }, 50);
    }

    onCheckBox2Click(id) {
        const { isChecBox2, checkboxList, openPop4, openPop5 } = this.state
        if (id == 1) {
            checkboxList.map((data) => (
                data.ExhRegistrationNotesId == 19 ? data.checked = true : null
            ))
            this.setState({ openPop4: !openPop4, isChecBox4: true })
        } else if (id == 2) {
            checkboxList.map((data) => (
                data.ExhRegistrationNotesId == 21 ? data.checked = true : null
            ))
            this.setState({ openPop5: !openPop5, isChecBox5: true })
        }
    }

    // onCheckBox3Click() {
    //     const { isChecBox3 } = this.state
    //     this.setState({ isChecBox3: !isChecBox3 })
    // }

    // onCheckBox4Click() {
    //     const { isChecBox4 } = this.state
    //     this.setState({ isChecBox4: !isChecBox4 })
    // }


    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === paymentMasterListActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            paymentMasterList: data.data, isLoading: false,
                        })
                        data.data.length > 0 && data.data.map(item => {
                            item.PaymentModuleID == 2 && this.setState({ isPaymentEnable: item.IsPaymentEnable })
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    getDescriptionDetails() {
        const { exhRegistrationID, exhibitionID, comment, isChecBox1, isChecBox3, isChecBox4 } = this.state

        this.setState({ isLoading: true })
        var exbid = sessionStorage.getItem("sessionExhibitionID");
        var regid = sessionStorage.getItem("sessionExhRegistrationID")

        this.props
            .getdescriptionDetails_action(regid, exbid) // DYNAMIC PARAMS HAS TO BE PLACE LATER <<==
            .then((data) => {
                if (data.error != 1) {
                    console.log(data.data)
                    // var tempcheckboxArr = data.data['extraDetails'][0].CheckBoxNotes.split('\n')
                    var checkboxArr = []
                    if (data.data['extraDetails'] != undefined) {
                        for (var i = 0; i < data.data['extraDetails'].length; i++) {
                            if (data.data['extraDetails'][i].NoteType == 'Estimate Checkbox Note') {
                                var obj = {}
                                obj.label = data.data['extraDetails'][i].Description
                                obj.value = data.data['extraDetails'][i].ExhRegistrationNotesId
                                obj.checked = false
                                obj.ExhRegistrationNotesId = data.data['extraDetails'][i].ExhRegistrationNotesId
                                // if (sessionStorage.getItem("sessionContryName") != 'India ') {
                                data.data['extraDetails'][i].ExhRegistrationNotesId == 19 && checkboxArr.push(obj)
                                // }
                                // else {
                                //     checkboxArr.push(obj)
                                // }
                            }
                        }
                        // else {
                        // if (sessionStorage.getItem("sessionContryName") != 'India ') {
                        //     checkboxArr.pop()
                        //     checkboxArr.pop()
                        // }

                        // }
                    }
                    for (var i = 0; i < checkboxArr.length; i++) {
                        if (checkboxArr[i].ExhRegistrationNotesId == 18) {
                            checkboxArr[i].checked = data.data['otherDetails'][0]['Is_AgreeClaim'] != null ? data.data['otherDetails'][0]['Is_AgreeClaim'] : false
                        }
                        if (checkboxArr[i].ExhRegistrationNotesId == 19) {
                            checkboxArr[i].checked = data.data['otherDetails'][0]['Is_AgreeRules'] != null ? data.data['otherDetails'][0]['Is_AgreeRules'] : false
                        }
                        if (checkboxArr[i].ExhRegistrationNotesId == 20) {
                            checkboxArr[i].checked = data.data['otherDetails'][0]['Is_AgreeMFG'] != null ? data.data['otherDetails'][0]['Is_AgreeMFG'] : false
                        }
                        if (checkboxArr[i].ExhRegistrationNotesId == 21) {
                            checkboxArr[i].checked = data.data['otherDetails'][0]['isAgreeUndertaking'] != null ? data.data['otherDetails'][0]['isAgreeUndertaking'] : false
                        }
                    }
                    var undertakingContent = []
                    data.data['otherDetails'][0]['UndertakingContent1'] != null && data.data['otherDetails'][0]['UndertakingContent1'] != undefined && undertakingContent.push(data.data['otherDetails'][0]['UndertakingContent1'])
                    data.data['otherDetails'][0]['UndertakingContent2'] != null && data.data['otherDetails'][0]['UndertakingContent2'] != undefined && undertakingContent.push(data.data['otherDetails'][0]['UndertakingContent2'])
                    data.data['otherDetails'][0]['UndertakingContent3'] != null && data.data['otherDetails'][0]['UndertakingContent3'] != undefined && undertakingContent.push(data.data['otherDetails'][0]['UndertakingContent3'])
                    console.log('checkboxArr', checkboxArr)
                    this.setState({
                        descriptionList: data.data['description'],
                        paymentList: data.data['paymentMethod'],
                        checkboxList: checkboxArr,
                        undertakingContent: undertakingContent,
                        // remittancesInfavour: data.data['extraDetails'][0].RemittanceInFavour,
                        estimateDescription: data.data['estimateDetails'][0]['Estimate_Description'] != null ? data.data['estimateDetails'][0]['Estimate_Description'] : '',
                        comment: data.data['otherDetails'][0]['Comment'] != null ? data.data['otherDetails'][0]['Comment'] : '',
                        isChecBox1: data.data['otherDetails'][0]['Is_AgreeClaim'] != null ? data.data['otherDetails'][0]['Is_AgreeClaim'] : false,
                        isChecBox3: data.data['otherDetails'][0]['Is_AgreeMFG'] != null ? data.data['otherDetails'][0]['Is_AgreeMFG'] : false,
                        isChecBox4: data.data['otherDetails'][0]['Is_AgreeRules'] != null ? data.data['otherDetails'][0]['Is_AgreeRules'] : false,
                        isChecBox5: data.data['otherDetails'][0]['isAgreeUndertaking'] != null ? data.data['otherDetails'][0]['isAgreeUndertaking'] : false,
                        isChecBox5: data.data['otherDetails'][0]['isAgreeUndertaking'] != null ? data.data['otherDetails'][0]['isAgreeUndertaking'] : false,
                        AuthorizedPersonName: data.data['otherDetails'][0]['ExhUndertakingPersonName'] != null ? data.data['otherDetails'][0]['ExhUndertakingPersonName'] : '',
                        DesignationAuthorizedPerson: data.data['otherDetails'][0]['ExhUndertakingDesignation'] != null ? data.data['otherDetails'][0]['ExhUndertakingDesignation'] : '',
                        ExhibitorCompanyName: data.data['otherDetails'][0]['ExhUndertakingCompanyName'] != null ? data.data['otherDetails'][0]['ExhUndertakingCompanyName'] : '',
                        EmailAuthorizedPerson: data.data['otherDetails'][0]['ExhUndertakingEmail'] != null ? data.data['otherDetails'][0]['ExhUndertakingEmail'] : '',
                        MobileAuthorizedPerson: data.data['otherDetails'][0]['ExhUndertakingMobileNo'] != null ? data.data['otherDetails'][0]['ExhUndertakingMobileNo'] : '',
                        RandRpath: data.data['otherDetails'][0]['RulesAndRegulations'] != null ? data.data['otherDetails'][0]['RulesAndRegulations'] : '',

                        toPay: data.data['paymentDetails'][0]['PayableAmount'] != null ? data.data['paymentDetails'][0]['PayableAmount'] : false,
                        toPaydummy: data.data['paymentDetails'][0]['PayableAmount'] != null ? data.data['paymentDetails'][0]['PayableAmount'] : false,

                        minPay: data.data['paymentDetails'][0]['MinAmount'] != null ? data.data['paymentDetails'][0]['MinAmount'] : false,
                        isCustompayment: data.data['paymentDetails'][0]['isCustomPayAllowed'] != null ? data.data['paymentDetails'][0]['isCustomPayAllowed'] : false,

                    })
                    this.getFillList(paymentMasterListActionId, exbid, '', '', '', '')
                    setTimeout(() => {
                        this.setState({ isLoading: false })

                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onSubmitClick() {
        const { isDecscriptionUpdate } = this.state
        if (isDecscriptionUpdate == true) {
            this.onSendUpdateDescrpData();
        } else {
            this.onSendDescrpData();
        }
    }

    onPreviewClick() {
        // e.preventDefault()
        const { exhibitionID, exhRegistrationID, toPay, toPaydummy, minPay, isCustompayment, countryId } = this.state
        this.setState({ isLoading: true })
        this.props
            .descriptionPreview_action(exhRegistrationID)
            .then((data) => {
                if (data != null) {
                    this.setState({ isLoading: false })
                    console.log('Report data : ', data);
                    var url = `${downloadReport}?SessionIdentifier=${data}`;

                    this.props.navigate('/lastSubmit',
                        {
                            state: {
                                // geturl: url,
                                toPay: toPay,
                                minPay: minPay,
                                isCustompayment: isCustompayment
                            }
                        })
                    // setTimeout(() => {
                    //     this.props.navigate('/lastSubmit',
                    //         {
                    //             state: {
                    //                 geturl: url,

                    //             }
                    //         })
                    // }, 200);
                    // console.log('Report download url : ', url);
                    // const win = window.location.replace(url, '_blank');
                    // if (win != null) {
                    //     win.focus();
                    // }
                } else {
                    this.setState({ isLoading: false })
                    toast.error('There is some problem to download report. Please try again later!', {
                        theme: 'colored',
                        autoClose: 3000,
                        hideProgressBar: true,
                    })
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({ isLoading: false })
            });

    }

    onSubmit(e) {
        var exbid = sessionStorage.getItem("sessionExhibitionID");
        var regid = sessionStorage.getItem("sessionExhRegistrationID");

        this.setState({ isLoading: true });
        this.props
            .lastSubmitAdd_action(exbid, regid)
            .then((data) => {
                this.setState({ isLoading: false });
                if (data.error != 1) {
                    this.props.navigate("/thankYou");

                    // toastSuccess("Your space booking application has been submitted successfully.");
                } else {
                    toastError(data.msg);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onSendDescrpData(status) {
        const { isLoading, exhibitionID, AuthorizedPersonName, AuthorizedPersonNameErr, MobileAuthorizedPerson, MobileAuthorizedPersonErr, EmailAuthorizedPerson, EmailAuthorizedPersonErr, DesignationAuthorizedPerson, DesignationAuthorizedPersonErr, ExhibitorCompanyName, ExhibitorCompanyNameErr, exhRegistrationID, comment, commentErr, isChecBox1, isChecBox2, isChecBox3, countryId, isChecBox5, isChecBox4 } = this.state;
        return new Promise((resolve, reject) => {

            var errMsg = false
            var mobvalidate = ''
            if (countryId == 91) {
                mobvalidate = emailValidator(EmailAuthorizedPerson)
                emailValidator(EmailAuthorizedPerson) != '' && this.setState({ EmailAuthorizedPersonErr: emailValidator(EmailAuthorizedPerson) })
                errMsg = emailValidator(EmailAuthorizedPerson) != '' ? true : false
                if (AuthorizedPersonName == '' || AuthorizedPersonName == null) {
                    this.setState({ AuthorizedPersonNameErr: 'Please enter Authorized Person Name' })
                    errMsg = true
                }
                if (MobileAuthorizedPerson == '' || MobileAuthorizedPerson == null) {
                    this.setState({ MobileAuthorizedPersonErr: 'Please enter Authorized Person Mobile' })
                    errMsg = true
                }
                if (EmailAuthorizedPerson == '' || EmailAuthorizedPerson == null) {
                    this.setState({ EmailAuthorizedPersonErr: 'Please enter Authorized Person Email' })
                    errMsg = true
                }
                if (DesignationAuthorizedPerson == '' || DesignationAuthorizedPerson == null) {
                    this.setState({ DesignationAuthorizedPersonErr: 'Please enter Authorized Person Designation' })
                    errMsg = true
                }
                if (ExhibitorCompanyName == '' || ExhibitorCompanyName == null) {
                    this.setState({ ExhibitorCompanyNameErr: 'Please enter Exhibitor Company Name' })
                    errMsg = true
                }
            }
            if (!errMsg) {
                this.setState({ isLoading: true })
                this.props
                    .descriptionAdd_action(exhibitionID, exhRegistrationID, comment, isChecBox1, isChecBox3, isChecBox4, isChecBox5, AuthorizedPersonName, DesignationAuthorizedPerson, ExhibitorCompanyName, EmailAuthorizedPerson, MobileAuthorizedPerson
                    )
                    .then((data) => {
                        if (data.error != 1) {
                            // console.log(data.data[0]['exhibitorID']);
                            // toastSuccess("Data saved successfully");
                            if (status == 0) {
                                this.setState({ isLoading: false })
                                this.onPreviewClick();
                            } else if (status == 1) {
                                this.setState({ isLoading: false })
                                this.onSubmit()
                            } else if (status == 2) {
                                resolve(true)
                            }

                            // this.props.navigate('/lastSubmit',
                            // {
                            //     state: {
                            //         geturl: 'https://www.google.co.in/?gfe_rd=cr&ei=js5SWM7OOKbv8weeqpKICw'
                            //     }
                            // })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                            reject(true)
                        }
                    })
                    .catch((e) => {
                        reject(true)
                        console.log(e);
                    });
            }
            else {
                toastError('Please enter mandatory fields')
            }

            //         }
            //         else {
            //             toastError('Enter mandatory fields')
            //         }
            //     }
            // } else {
            //     toastError('Enter mandatory fields')
            // }

        })
    }

    onSendUpdateDescrpData() {
        const { isLoading, exhibitionID, exhRegistrationID, comment, commentErr, isChecBox1, isChecBox2, isChecBox3, isChecBox4, isChecBox5 } = this.state;

        var errMsg = ''

        // if (comment) {
        //     if (errMsg != '') {
        //         toastError(errMsg)
        //         errMsg = ''
        //     } else {
        //         if (commentErr == '') {
        this.setState({ isLoading: true })
        this.props
            .descriptionUpdate_action(exhibitionID, exhRegistrationID, comment, isChecBox1, isChecBox3, isChecBox4, isChecBox5
            )
            .then((data) => {

                if (data.error != 1) {
                    console.log(data.data[0]['exhibitorID']);
                    toastSuccess("Data saved successfully");
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
        //         }
        //         else {
        //             toastError('Enter mandatory fields')
        //         }
        //     }
        // } else {
        //     toastError('Enter mandatory fields')
        // }

    }

    getTermsAndCondition() {
        const { exhRegistrationID, exhibitionID, } = this.state

        this.setState({ isLoading: true })
        this.props
            .getdescriptionTermsAndCondition_action(exhRegistrationID, exhibitionID)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        comment: data.data['Comment'],
                        // isChecBox1: data.data['Claim'],
                        //isChecBox2: data.data['paymentMethod'],
                        // isChecBox3: data.data['isMfg'],
                        isChecBox4: data.data['isRules'],

                    })
                    setTimeout(() => {
                        this.setState({ isLoading: false })
                    }, 300);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    record(list) {
        // const { isNameSort, campList } = this.state
        return (
            <table className="table table-bordered tb-action du-table">
                <thead>
                    <tr key={Math.random()}>
                        <th></th>
                        <th><b>INSTALLMENT 1 IN USD (25%) BEFORE 15.05.2023</b>  BEFORE 30.05.2023</th>
                        <th><b>INSTALLMENT 2 IN USD (75%) BEFORE 30.08.2023</b>Installment 2 in (INR OR USD) B</th>
                        <th>Total Stall charges in INR RO USD (A+B)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        list.length > 0 ?
                            list.map((data, index) => (<tr key={Math.random()} >
                                <td>{data.others}</td>
                                <td>{data.a}</td>
                                <td>{data.b}</td>
                                <td>{Number(data.a) + Number(data.b)}</td>
                            </tr>
                            )) : <><tr><td colSpan={2} className='text-center'>Record not found</td></tr></>
                    }
                </tbody>
            </table>
        )
    }

    onPreviousClick() {
        sessionStorage.setItem("renderPage", "primaryChapter")
        this.props.navigate('/primary_chapter', { state: { is_update: true } })
    }

    preventMinus = (e) => {
        if (e.code === "Minus") {
            e.preventDefault();
        }
    };

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    };

    onPayAndSubmit() {
        const { openPaymentPop, countryId, toPaydummy, EmailAuthorizedPerson, MobileAuthorizedPerson, ExhibitorCompanyName, DesignationAuthorizedPerson, AuthorizedPersonName } = this.state
        var errMsg = false
        var mobvalidate = ''
        if (countryId == 91) {
            mobvalidate = emailValidator(EmailAuthorizedPerson)
            emailValidator(EmailAuthorizedPerson) != '' && this.setState({ EmailAuthorizedPersonErr: emailValidator(EmailAuthorizedPerson) })
            errMsg = emailValidator(EmailAuthorizedPerson) != '' ? true : false
            if (AuthorizedPersonName == '' || AuthorizedPersonName == null) {
                this.setState({ AuthorizedPersonNameErr: 'Please enter Authorized Person Name' })
                errMsg = true
            }
            if (MobileAuthorizedPerson == '' || MobileAuthorizedPerson == null) {
                this.setState({ MobileAuthorizedPersonErr: 'Please enter Authorized Person Mobile' })
                errMsg = true
            }
            if (EmailAuthorizedPerson == '' || EmailAuthorizedPerson == null) {
                this.setState({ EmailAuthorizedPersonErr: 'Please enter Authorized Person Email' })
                errMsg = true
            }
            if (DesignationAuthorizedPerson == '' || DesignationAuthorizedPerson == null) {
                this.setState({ DesignationAuthorizedPersonErr: 'Please enter Authorized Person Designation' })
                errMsg = true
            }
            if (ExhibitorCompanyName == '' || ExhibitorCompanyName == null) {
                this.setState({ ExhibitorCompanyNameErr: 'Please enter Exhibitor Company Name' })
                errMsg = true
            }
        }
        if (!errMsg) {
            this.setState({ openPaymentPop: !openPaymentPop })
        } else {
            toastError('Please enter mandatory fields')
        }
    }

    onPaymentTypeSelect(type) {
        const { openPaymentPop, toPaydummy } = this.state

        this.setState({ paymentType: type })
        if (type == 1) {
            this.setState({ toPay: toPaydummy })
        }
        else if (type == 2) {
            this.setState({ customPayment: '' })
        }
    }

    navPayment(e) {
        e.preventDefault()
        const { userId, selectedPIList, minPay, proformaInv, remark, paymentType, toPay, currency, } = this.state
        var ERROR = null
        if (toPay < minPay) {
            ERROR = `Total Payment amount should be greater than ${minPay}`
        }
        console.log(ERROR)
        if (ERROR == null) {
            this.setState({ isLoading: true })
            this.onSendDescrpData(2).then(desRes => {
                var moreInfo = null
                var exbid = sessionStorage.getItem("sessionExhibitionID");
                var regid = sessionStorage.getItem("sessionExhRegistrationID")
                console.log("++++++++++++++++++++++++++++++++++", sessionStorage.getItem("sessionContryName"))
                this.props.sendPaymentForm_action(exbid, regid, toPay, sessionStorage.getItem("countryId") == 91 ? 'INR' : 'USD', [], 'estimates', moreInfo, remark)
                    .then((data) => {
                        console.log(data)
                        if (data.error != 1) {
                            if (data != null) {
                                console.log("payment response : ", data)

                                this.setState({ encPayload: data.data }, () => {
                                    this.submitfrm.current.submit()
                                })
                            }
                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError('There is some problem. Please try again later!')
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }).catch(desErr => {

            })
        }
        else {
            toastError(ERROR)
        }

    }


    render() {
        const { isLoading, comment, commentErr, PreMobileAuthorizedPerson, isChecBox1, isChecBox2, isChecBox3, isChecBox4, countryId, descriptionList, paymentList, openPop1, openPaymentPop, paymentType, customPayment, toPay, encPayload,
            openPop2, openPop3, openPop4, estimateDescription, AuthorizedPersonName, MobileAuthorizedPerson, EmailAuthorizedPerson, ExhibitorCompanyName, DesignationAuthorizedPerson, ExhibitorCompanyNameErr, AuthorizedPersonNameErr, MobileAuthorizedPersonErr, EmailAuthorizedPersonErr, DesignationAuthorizedPersonErr, openPop5, RandRpath } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {/* PAGE */}
                <div className="page">
                    <div className="page-main">
                        <Header />
                        {console.log("sessionStorage.getItem()", sessionStorage.getItem("sessionContryName").toString().trim().toLowerCase())}
                        {/*app-content open*/}
                        <div className="main-content app-content mt-0">
                            <div className="side-app">
                                {/* <div className="main-container form-start container-fluid p-0">
                                    <div className="row ">
                                        <div className="step-name">
                                            <p>Exhibitor Registration</p>
                                        </div>
                                    </div>
                                    <StepHeader />
                                </div> */}
                                <div className="page-height">
                                    <div className="main-container container-fluid pt-2 pb-2 p-3 second-form">
                                        <div className="row">
                                            <strong>
                                                {/* Estimate */}
                                                {sessionStorage.getItem("sessionContryName").toString().trim().toLowerCase() == 'india' ? `Estimate : ${estimateDescription}` : 'Estimate'}
                                            </strong>
                                            <div className="table-responsive m-1">

                                                <table
                                                    className="table table-bordered"
                                                    style={{ border: "1px solid #d8d8d8 !important" }}
                                                >
                                                    <thead>
                                                        {/* <tr>
                                            <th style={{ width: '300px' }}>DESCRIPTION</th>
                                            <th>
                                                INSTALLMENT 1 IN USD (25%) BEFORE 15.05.2023
                                            </th>
                                            <th>
                                                INSTALLMENT 2 IN USD (75%) BEFORE 30.08.2023
                                            </th>
                                            <th style={{ width: '300px' }}>TOTAL AMOUNT</th>
                                        </tr> */}
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            descriptionList.length > 0 ?
                                                                descriptionList.map((data, index) => (<tr key={Math.random()} >
                                                                    <td style={{ width: '300px' }} >{data.Description}</td>
                                                                    <td>{data.Installment1}</td>
                                                                    <td>{data.Installment2}</td>
                                                                    <td>{data.OpenSide}</td>
                                                                    <td style={{ width: '300px' }} >{data.Total_amt}</td>
                                                                </tr>
                                                                )) :
                                                                <>
                                                                    <tr className='text-center'><td colSpan={4} className='text-center'>Record not found</td></tr>
                                                                </>

                                                        }

                                                    </tbody>
                                                </table>

                                                {/* {this.record(currentRecords)} */}

                                            </div>
                                            <h6>Details for Remittance</h6>
                                            <strong>Below Are The Payment Methods</strong>
                                            <div className="main-container container-fluid">
                                                <div className="form-row mt-1">
                                                    <p className="m-0 p-1">
                                                        Please remit the funds in favour of{" "}
                                                        <strong>
                                                            "{paymentList.length > 0 ? paymentList.map(item => (item.Description == "NAME OF THE BENEFICIARY" && `${item.Details}`)) : sessionStorage.getItem("sessionExhibitionName")}". For RTGS/ NEFT
                                                            please refer details Below
                                                        </strong>
                                                    </p>
                                                    <div className="table-responsive m-1 mb-0">

                                                        <table
                                                            className="table table-bordered"
                                                            style={{ border: "1px solid #d8d8d8 !important" }}
                                                        >
                                                            <thead></thead>
                                                            <tbody>
                                                                {
                                                                    paymentList.length > 0 ?
                                                                        paymentList.map((data, index) => (
                                                                            <tr key={Math.random()}>
                                                                                <td>
                                                                                    <strong>{data.Description}</strong>
                                                                                </td>
                                                                                <td>{data.Details}</td>
                                                                            </tr>

                                                                        )) : <tr className='text-center'><td colSpan={2} className='text-center'>Record not found</td></tr>
                                                                }

                                                            </tbody>
                                                        </table>

                                                        {/* {this.record2(currentRecords2)} */}

                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <label>Remarks/Any Notes</label>
                                                    <textarea className="form-control" value={comment} maxLength={500}
                                                        onChange={e => this.setState({ comment: e.target.value, commentErr: '' })} />
                                                </div>
                                                <hr></hr>
                                                {this.state.undertakingContent.length > 0 && countryId == 91 && <>
                                                    <div className="form-row">
                                                        <h6 className='mb-3'><strong>Exhibitor Undertaking for {sessionStorage.getItem("sessionExhibitionName")}</strong></h6>
                                                        {this.state.undertakingContent.map((data) => (
                                                            <p className='m-1 p-0'>{data}</p>
                                                        ))}
                                                    </div>
                                                    <div className="form-row mt-2">
                                                        <div className="col-xl-4 mb-1">
                                                            <label htmlFor="validationCustom01">
                                                                Authorized Person Name<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                maxLength={100}
                                                                className="form-control"
                                                                value={AuthorizedPersonName}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        AuthorizedPersonName: e.target.value,
                                                                        AuthorizedPersonNameErr: "",
                                                                    })
                                                                }
                                                            />
                                                            {
                                                                <span className="text-danger text-start text-left d-block mt-1">
                                                                    {AuthorizedPersonNameErr}
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="col-xl-4 mb-1">
                                                            <label htmlFor="validationCustom01">
                                                                Designation of the Authorized person<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                maxLength={100}
                                                                className="form-control"
                                                                value={DesignationAuthorizedPerson}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        DesignationAuthorizedPerson: e.target.value,
                                                                        DesignationAuthorizedPersonErr: "",
                                                                    })
                                                                }
                                                            />
                                                            {
                                                                <span className="text-danger text-start text-left d-block mt-1">
                                                                    {DesignationAuthorizedPersonErr}
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="col-xl-4 mb-1">
                                                            <label htmlFor="validationCustom01">
                                                                Exhibitor company Name<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                maxLength={100}
                                                                className="form-control"
                                                                value={ExhibitorCompanyName}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        ExhibitorCompanyName: e.target.value,
                                                                        ExhibitorCompanyNameErr: "",
                                                                    })
                                                                }
                                                            />
                                                            {
                                                                <span className="text-danger text-start text-left d-block mt-1">
                                                                    {ExhibitorCompanyNameErr}
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="col-xl-4 mb-1">
                                                            <label htmlFor="validationCustom01">
                                                                Email of the Authorized person<span className="text-red">*</span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                maxLength={100}
                                                                className="form-control"
                                                                value={EmailAuthorizedPerson}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        EmailAuthorizedPerson: e.target.value,
                                                                        EmailAuthorizedPersonErr: "",
                                                                    })
                                                                }
                                                            />
                                                            {
                                                                <span className="text-danger text-start text-left d-block mt-1">
                                                                    {EmailAuthorizedPersonErr}
                                                                </span>
                                                            }
                                                        </div>
                                                        <div className="col-xl-4 mb-1">
                                                            <div
                                                                // className="col-xl-3 mb-1"
                                                                style={{ position: "relative" }}
                                                            >
                                                                <label htmlFor="validationCustom01">
                                                                    Mobile No. of the Authorized person<span className="text-red">*</span>
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    maxLength={
                                                                        sessionStorage.getItem("countryId") === "43"
                                                                            ? 11
                                                                            : 10
                                                                    }
                                                                    value={MobileAuthorizedPerson}
                                                                    onChange={(e) =>
                                                                        this.setState({
                                                                            MobileAuthorizedPerson: e.target.value,
                                                                            MobileAuthorizedPersonErr: "",
                                                                        })
                                                                    }
                                                                    onInput={(e) => sessionStorage.getItem("countryId") == 91 ? this.maxLengthCheck(e, 10) : null}
                                                                    size={"10"}
                                                                    onKeyDown={(e) =>
                                                                        (e.keyCode === 69 || e.keyCode === 190) &&
                                                                        e.preventDefault()
                                                                    }
                                                                    onWheel={(e) => e.target.blur()}
                                                                    onKeyPress={(e) => this.preventMinus(e)}
                                                                    style={{ paddingLeft: "50px" }}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className="form-control-1"
                                                                    placeholder="ISD"
                                                                    value={PreMobileAuthorizedPerson}
                                                                    // disabled={isCorrespondenceDetails}
                                                                    onChange={(e) =>
                                                                        this.setState({
                                                                            PreMobileAuthorizedPerson:
                                                                                e.target.value,
                                                                        })
                                                                    }
                                                                    // placeholder={+91}
                                                                    style={{
                                                                        width: "45px",
                                                                        position: "absolute",
                                                                        top: "25px",
                                                                        paddingLeft: "5px",
                                                                    }}
                                                                />
                                                                {
                                                                    <span className="text-danger text-start text-left d-block mt-1">
                                                                        {MobileAuthorizedPersonErr}
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr></hr>
                                                </>
                                                }
                                                {this.state.checkboxList.length > 0 && this.state.checkboxList.map((data, i) => (
                                                    <div className="form-row mt-3">
                                                        <div >
                                                            <label className="d-flex" >
                                                                <input type="checkbox" style={{ height: 20 }}
                                                                    name={data.ExhRegistrationNotesId}
                                                                    value={data.checked}
                                                                    checked={data.checked}
                                                                    onClick={e => this.onCheckBoxClick(data, data.ExhRegistrationNotesId)}
                                                                />
                                                                {data.label}  {data.ExhRegistrationNotesId == 19 ?
                                                                    <p className='text-primary' >{" "} Click Here</p> : null
                                                                } {data.ExhRegistrationNotesId == 21 ?
                                                                    <p className='text-primary' >{" "} Click Here</p> : null
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/* <div className="form-row mt-3">
                                                    <label className="d-flex">
                                                        <input type="checkbox" style={{ height: 20 }}
                                                            name="checkbox1"
                                                            value={isChecBox1}
                                                            checked={isChecBox1}
                                                            // onClick={e => this.onCheckBox1Click()}
                                                        />
                                                        {/* onClick={e => this.setState({ openPop1: !openPop1 })} */}
                                                {/* We hereby agree to indemnity the Exhibition Authority and its Office-Bearers, Steering Committee Members, Executive Director, officers, employees and members and hold them and each of them harmless from and against any and all claims arising out of the acts of the exhibitor, his agents, representatives and employees. and all contractors, erectors, delivery persons, suppliers and material persons, furnishing services, delivery work or materials at the instance of the exhibitor
                                                    </label>
                                                </div> */}
                                                {/* <div className="form-row mt-3">
                                    <input type="checkbox" name="checkbox2"
                                        value={isChecBox2}
                                        checked={isChecBox2}
                                        onClick={e => this.onCheckBox2Click()} />
                                    <label>
                                        I have read the space
                                        booking Rules and Regulation for ITME AFRICA 2023
                                        &amp; agree to abide by them <a className='text-primary' onClick={e => this.setState({ openPop2: !openPop2 })} >{" "}Click Here</a>
                                    </label>
                                </div> */}
                                                {/* <div className="form-row mt-2">
                                                    <label className="d-flex">
                                                        <input type="checkbox" name="checkbox4" style={{ height: 20 }}
                                                            value={isChecBox4}
                                                            checked={isChecBox4}
                                                            // onClick={e => this.onCheckBox4Click()} />
                                                        I have read the space booking Rules and Regulation for ITME AFRICA & M.E. 2023 & agree to abide by them. <a className='text-primary' onClick={e => this.setState({ openPop4: !openPop4 })} >{" "} &nbsp; Click Here</a>
                                                    </label>
                                                </div> */}
                                                {/* {
                                                    sessionStorage.getItem("sessionContryName") == 'India ' &&
                                                    <div className="form-row mt-2">
                                                        <label className="d-flex">
                                                            <input type="checkbox" style={{ height: 20 }}
                                                                name="checkbox3"
                                                                value={isChecBox3}
                                                                checked={isChecBox3}
                                                                onClick={e => this.onCheckBox3Click()} />
                                                            We hereby confirm that exhibits in the allocated booth to us based on the National exhibitor application submitted are all manufactured in India and we are not showcasing /promoting any foreign products/brand under our company banner.
                                                        </label>
                                                    </div>
                                                } */}


                                                <div className="mt-3 mb-3">
                                                    <button className="btn btn-previous" type="submit" onClick={e => this.onPreviousClick(e)}>
                                                        Previous
                                                    </button>
                                                    {
                                                        (isChecBox4 && sessionStorage.getItem("sessionContryName") == 'India ')
                                                            || (isChecBox4 && sessionStorage.getItem("sessionContryName") != 'India ') ?
                                                            (<button className="btn btn-preview mx-2" type="submit" onClick={e => this.onSendDescrpData(0)} >
                                                                Preview
                                                            </button>) : (<button className="btn btn-preview mx-2 bg-secondary" type="submit" onClick={e => toastError('Agree and tick all boxes points to proceed further')} >
                                                                Preview
                                                            </button>)

                                                    }
                                                    {
                                                        (isChecBox4 && sessionStorage.getItem("sessionContryName") == 'India ')
                                                            || (isChecBox4 && sessionStorage.getItem("sessionContryName") != 'India ') ?
                                                            (<button className="btn btn-submit mx-2" type="submit" onClick={e => this.onSendDescrpData(1)} >
                                                                {
                                                                    sessionStorage.getItem("countryId") == 91 &&
                                                                        this.state.isPaymentEnable ? 'Submit and Pay later' : 'Submit'}
                                                            </button>) : (<button className="btn btn-submit mx-2 bg-secondary" type="submit" onClick={e => toastError('Agree and tick all boxes points to proceed further')} >
                                                                {
                                                                    sessionStorage.getItem("countryId") == 91 &&
                                                                        this.state.isPaymentEnable ? 'Submit and Pay later' : 'Submit'}
                                                            </button>)

                                                    }
                                                    {this.state.isPaymentEnable &&
                                                        sessionStorage.getItem("countryId") == 91 &&
                                                        ((isChecBox4 && sessionStorage.getItem("sessionContryName") == 'India ')
                                                            || (isChecBox4 && sessionStorage.getItem("sessionContryName") != 'India ') ?
                                                            (<button className="btn btn-submit" type="submit" onClick={e => this.onPayAndSubmit()} >
                                                                Pay and Submit
                                                            </button>) : (<button className="btn btn-submit bg-secondary" type="submit"
                                                                onClick={e => toastError('Agree and tick all boxes points to proceed further')}
                                                            >
                                                                Pay and Submit
                                                            </button>)
                                                        )
                                                    }

                                                    {/* <button className="btn btn-next-bot  m-1" type="submit" onClick={e => this.onSubmitClick()} >
                                        Submit &&
                                                        ( sessionStorage.getItem("sessionContryName") == 'India ' && isChecBox4 )&&
                                    </button> */}
                                                </div>
                                                <div className="bottom-height"></div>
                                                <Footer />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <Modal isOpen={openPop1} onRequestClose={e => this.setState({ openPop1: !openPop1 })}>
                    <div className="modal-dialog custom_modal_dialog" style={{ left: 'left', top: '8%' }}>
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "10px 10px" }}>
                                <h5 className="modal-title" id="termsandconLabel">
                                    <b>Terms and Conditions</b>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body" style={{ padding: "0px 12px 9px 12px" }}>
                                <div className="form-row mt-3">

                                    <label>
                                        When backdrop is set to static, the modal will not close when clicking
                                        outside it. Click the button below to try it.
                                    </label>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ padding: 3 }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ padding: "4px 15px" }}
                                    onClick={e => this.setState({ openPop1: !openPop1, isChecBox1: true })}
                                >
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal isOpen={openPop2} onRequestClose={e => this.setState({ openPop2: !openPop2 })}>
                    <div className="modal-dialog custom_modal_dialog ">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "10px 10px" }}>
                                <h5 className="modal-title" id="termsandconLabel">
                                    <b>Terms and Conditions</b>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body" style={{ padding: "0px 12px 9px 12px" }}>
                                <div className="form-row mt-3">
                                    <label>
                                        When backdrop is set to static, the modal will not close when clicking
                                        outside it. Click the button below to try it.
                                    </label>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ padding: 3 }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ padding: "4px 15px" }}
                                    onClick={e => this.setState({ openPop2: !openPop2, isChecBox2: true })}
                                >
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal isOpen={openPop3} onRequestClose={e => this.setState({ openPop3: !openPop3 })}>
                    <div className="modal-dialog custom_modal_dialog ">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "10px 10px" }}>
                                <h5 className="modal-title" id="termsandconLabel">
                                    <b>Terms and Conditions</b>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body" style={{ padding: "0px 12px 9px 12px" }}>
                                <div className="form-row mt-3">

                                    <label>
                                        When backdrop is set to static, the modal will not close when clicking
                                        outside it. Click the button below to try it.
                                    </label>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ padding: 3 }}>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    style={{ padding: "4px 15px" }}
                                    onClick={e => this.setState({ openPop3: !openPop3, isChecBox3: true })}
                                >
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal>
                {console.log(RandRpath)}

                <Modal isOpen={openPop4} onRequestClose={e => this.setState({ openPop4: !openPop4 })}>
                    <div className="modal-dialog custom_modal_dialog" style={{ left: '0px', top: '50px' }}>
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "7px 10px" }}>
                                <h5 className="modal-title" id="termsandconLabel">
                                    <b>Rules & Regulations</b>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body" style={{ padding: "0px 12px 9px 12px" }}>
                                <iframe
                                    id="myIframe" style={{ marginTop: '0px' }}
                                    // src={`files/${sessionStorage.getItem("sessionExhibitionID")}/Rules-and-Regulations.pdf#toolbar=0&navpanes=0`}
                                    src={`${RandRpath}#toolbar=0&navpanes=0`}
                                    width="1000px"
                                    height="435px"
                                    title="Bing"
                                />
                                <div className="form-row mt-3">

                                    <div className="col-md-10">
                                        <p> I have read the space booking Rules and Regulation for "{sessionStorage.getItem("sessionExhibitionName")}" & agree to abide by them.</p>
                                    </div>
                                    <div className="col-md-2">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            style={{ padding: '4px 15px', float: 'right' }}
                                            onClick={e => this.onCheckBox2Click(1)}
                                        >
                                            Accept
                                        </button>
                                    </div>


                                </div>
                            </div>
                            {/* <div className="modal-footer" style={{ padding: 3 }}>

                            </div> */}
                        </div>
                    </div>

                </Modal>

                <Modal isOpen={openPop5} onRequestClose={e => this.setState({ openPop5: !openPop5 })}>
                    <div className="modal-dialog custom_modal_dialog" style={{ left: '0px', top: '50px' }}>
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "7px 10px" }}>
                                <h5 className="modal-title" id="termsandconLabel">
                                    <b>Undertaking</b>
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body" style={{ padding: "0px 12px 9px 12px" }}>
                                <iframe
                                    id="myIframe" style={{ marginTop: '0px' }}
                                    src={`files/${sessionStorage.getItem("sessionExhibitionID")}/Undertaking.pdf#toolbar=0&navpanes=0`}
                                    width="1000px"
                                    height="435px"
                                    title="Bing"
                                />
                                <div className="form-row mt-3">

                                    <div className="col-md-10">
                                        <p> I have read the Undertaking for "{sessionStorage.getItem("sessionExhibitionName")}" & agree to abide by it.</p>
                                    </div>
                                    <div className="col-md-2">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                            style={{ padding: '4px 15px', float: 'right' }}
                                            onClick={e => this.onCheckBox2Click(2)}
                                        >
                                            Accept
                                        </button>
                                    </div>


                                </div>
                            </div>
                            {/* <div className="modal-footer" style={{ padding: 3 }}>

                            </div> */}
                        </div>
                    </div>

                </Modal>

                <Modal isOpen={openPaymentPop} onRequestClose={() => this.setState({ openPaymentPop: false })}>
                    <div className="modal-dialog" style={{ marginTop: '20%' }}>
                        <div className="modal-content confirm-logout">
                            <div class="modal-header text-right p-3">
                                <h5 class="modal-title modal_title_text text-Primary">Payment</h5>
                                <button
                                    type="button"
                                    className="btn-close mr-3"
                                    data-bs-dismiss="modal"
                                    style={{ fontSize: 12 }}
                                    aria-label="Close"
                                    onClick={(e) => this.setState({ openPaymentPop: false, toPay: this.state.toPaydummy, paymentType: 1, customPayment: '' })}
                                />
                            </div>
                            <div className="row m-3" >
                                <div style={{ textAlign: 'left', fontWeight: '500', fontSize: '18px' }} className="col-md-6">
                                    <input type="radio" style={{ width: '18px', height: '18px' }}
                                        value={paymentType}
                                        checked={paymentType == 1}
                                        onClick={(e) => this.onPaymentTypeSelect(1)}
                                    />
                                    Full Payment
                                </div>
                                <div style={{ textAlign: 'left', fontWeight: '500', fontSize: '18px' }} className="col-md-6">
                                    <input type="radio" style={{ width: '18px', height: '18px' }}
                                        value={paymentType}
                                        // disabled={this.state.isCustompayment != 1}
                                        checked={paymentType == 2}
                                        onClick={(e) => this.onPaymentTypeSelect(2)}
                                    />
                                    Custom Payment
                                    {paymentType == 2 && <input type="number" className="form-control"
                                        value={customPayment}
                                        // checked={generatePriorityNoType == 1}
                                        onChange={(e) => this.setState({ customPayment: e.target.value, toPay: e.target.value == '' ? 0 : e.target.value })}
                                    />}
                                </div>
                                <div className="" >
                                    <div style={{ textAlign: 'left' }} className="col-md-12 mt-2">
                                        <label>Remark</label>
                                        <textarea value={this.state.remark} className="form-control" onChange={(e) => this.setState({ remark: e.target.value })}>

                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1" style={{ justifyContent: 'normal' }}>
                                <div style={{ textAlign: 'left', fontWeight: '500', fontSize: '22px', padding: '0', float: 'left', left: '0' }} className="col-8 ms-2">
                                    Total: {toPay}
                                </div>
                                {/* <div className="row" > */}
                                <div className=" mt-1 col-3">
                                    <div style={{ float: 'right' }} className="mt-1 mb-1 mr-3 ">
                                        <form
                                            onSubmit={(e) => this.navPayment(e)}
                                            ref={this.submitfrm}
                                            id="nonseamless" method="post" name="redirect"
                                            action={paymentPortalLink}
                                        >

                                            <input type="hidden" id="encRequest" name="encRequest"
                                                value={encPayload}
                                            />
                                            <input type="hidden" name="access_code" id="access_code"
                                                value={paymentAccessCode}
                                            />
                                            <button className="btn btn-success rounded-1" disabled={toPay <= 0 || toPay == '' || toPay == null || paymentType == ''} style={{ border: 'none', borderRadius: '2px', }}>       <span style={{ color: 'black', padding: "4px 20px", fontWeight: 500, }}>
                                                pay
                                            </span>
                                                {/* {!isPaid && isCarParking == 1 ? 'Make Payment' : 'Update'} */}

                                            </button>
                                        </form>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>

                        </div>
                    </div>
                </Modal >

            </>
        );
    }
}

export default withRouter(connect(null, {
    fill_action, getdescriptionDetails_action, descriptionAdd_action, descriptionUpdate_action, getdescriptionTermsAndCondition_action,
    descriptionPreview_action, getPendingRegDetails_action, lastSubmitAdd_action, sendPaymentForm_action
})(Description));

