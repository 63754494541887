import { axiosGet, axiosPost } from "../../../react_axios/Axios"
import { headers, getOldPaymentListUrl, GET_OLD_PAYMENT_LIST_ACTION_TYPE, getProformaListUrl, GET_PROFORMA_LIST_ACTION_TYPE, insertPaymentUrl, INSERT_PAYMENT_ACTION_TYPE, getPaymentDetailsUrl, GET_PAYMENT_DETAILS_ACTION_TYPE, updatePaymentUrl, UPDATE_PAYMENT_ACTION_TYPE } from "../../../utils/constants"




export const getOldPaymentList_action = () => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhRegistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),


        }
        console.log("getOldPaymentList_action Action------->", params)

        const res = await axiosGet(getOldPaymentListUrl, params, headers)

        dispatch({
            type: GET_OLD_PAYMENT_LIST_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getProformaList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhAccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            token: sessionStorage.getItem('tokenSS')
        }
        console.log("ReceiptList-Action--------------------------->", params)

        const res = await axiosGet(getProformaListUrl, params, headers);
        dispatch({
            type: GET_PROFORMA_LIST_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const insertPayment_action = (grossAmt, tdsAmt, netAmt, instrumentTypeId, instrumentNo, instrumentDate, bank,
    branch, list) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
                ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
                CurrencyID: JSON.parse(sessionStorage.getItem('ApplicationDataSS')).CurrencyID,
                GrossAmount: grossAmt,
                TDSAmount: tdsAmt,
                NetAmount: netAmt,
                InstrumentTypeID: instrumentTypeId,
                InstrumentNo: instrumentNo,
                InstrumentDate: instrumentDate != "" ? instrumentDate : null,
                BankName: bank,
                BranchName: branch,
                proformaList: list
            }
            console.log("insertPayment_action-Action--------------------------->", mapdata)
            const res = await axiosPost(insertPaymentUrl, mapdata, headers);

            dispatch({
                type: INSERT_PAYMENT_ACTION_TYPE,
                payload: res,
            })
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    }

export const getPaymentDetails_action = (Id) => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountPaymentID: Id,
        }
        console.log("paymentDetails_action-Action--------------------------->", params)

        const res = await axiosGet(getPaymentDetailsUrl, params, headers);
        dispatch({
            type: GET_PAYMENT_DETAILS_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }

}

export const updatePayment_action = (AccountPaymentID, grossAmt, tdsAmt, netAmt, instrumentTypeId, instrumentNo,
    instrumentDate,
    bank, branch, list) => async (dispatch) => {
        try {
            const mapdata = {
                UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
                AccountPaymentID: AccountPaymentID,
                AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
                ExhregistrationID: sessionStorage.getItem('ExhRegistrationIDSS'),
                CurrencyID: JSON.parse(sessionStorage.getItem('ApplicationDataSS')).CurrencyID,
                GrossAmount: grossAmt,
                TDSAmount: tdsAmt,
                NetAmount: netAmt,
                InstrumentTypeID: instrumentTypeId,
                InstrumentNo: instrumentNo,
                InstrumentDate: instrumentDate != "" ? instrumentDate : null,
                BankName: bank,
                BranchName: branch,
                proformaList: list
            }
            console.log("updatePayment_action -Action--------------------------->", mapdata)
            const res = await axiosPost(updatePaymentUrl, mapdata, headers);

            dispatch({
                type: UPDATE_PAYMENT_ACTION_TYPE,
                payload: res,
            })
            return Promise.resolve(res);
        } catch (err) {
            return Promise.reject(err);
        }
    }



