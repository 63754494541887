import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../utils/Loader";
import { userManualDownloadUrl } from '../utils/constants';
import Modal from "react-modal";
import moment from 'moment';
import StepHeader from '../screens/registration/stepHeader'

Modal.setAppElement("#root");


// {head,subHeader}
const Header = () => {

  const history = useNavigate();
  const [isLoading, setLoader] = useState(false);


  const loadScripts = () => {
    // This array contains all the files/CDNs 
    // const dynamicScripts = [
    //   'js/jquery.sticky.js',
    //   'js/metisMenu.min.js',
    //   'js/metisMenu-active.js',
    //   'js/main.js',
    //   'js/azia.js'
    // ];

    // for (let i = 0; i < dynamicScripts.length; i++) {
    //   const node = document.createElement('script');
    //   node.src = dynamicScripts[i];
    //   node.type = 'text/javascript';
    //   node.async = false;
    //   document.getElementsByTagName('head')[0].appendChild(node);
    // }
  }

  useEffect(() => {
    loadScripts();

  }, []);



  const onLogoutClick = () => {
    localStorage.clear();
    // history.push('/login');
  }

  const openPdf = () => {
    var URL = userManualDownloadUrl /* http://0.0.0.0:8000/ or http://127.0.0.1:8000/; */
    window.open(URL, null);
  }

  // Exhibition From Date
  const dateString = sessionStorage.getItem('exhibitionDateFrom');
  const dateStringMoment = moment.utc(dateString).format('DD MMMM YYYY')
  const dateObject = new Date(dateStringMoment);
  const fromDay = dateObject.getDate();
  const fromMonth = dateObject.toLocaleString('default', { month: 'long' });
  const fromYear = dateObject.getFullYear();
  const fromDate = `${fromDay} ${fromMonth} ${fromYear}`;

  // Exhibition to Date
  const dateString1 = sessionStorage.getItem('exhibitionDateTo');
  const dateString1Moment = moment.utc(dateString1).format('DD MMMM YYYY')
  const dateObject1 = new Date(dateString1Moment);
  const toDay = dateObject1.getDate();
  const toMonth = dateObject1.toLocaleString('default', { month: 'long' });
  const toYear = dateObject1.getFullYear();
  const toDate = `${toDay} ${toMonth} ${toYear}`;

  // Exhibition Logo File Path
  const logoPath = sessionStorage.getItem('sessionLogoPath')
  const ExhibitionEmail = JSON.parse(sessionStorage.getItem('sessionExhibitionEmail'))
  const ExhibitionMobileNo = JSON.parse(sessionStorage.getItem('sessionExhibitionMobileNo'))

  // Exhibition Address
  const address = sessionStorage.getItem('exhibitionAddress')

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : (
        null
      )}
      {/* app-Header */}
      <div className="app-header header sticky">
        <div className="app-header header sticky">
          <div className="container-fluid main-container">
            <div className="d-flex">
              <a className="logo-horizontal " href="index.html">
                <img
                  src="../images/itme.png" className="header-brand-img desktop-logo" alt="logo" />
              </a>
              <div class="side-header">
                <a class="header-brand1" href={sessionStorage.getItem("sessionExhMailWebsite")}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={logoPath} class="header-brand-img header-brand-img-1 light-logo1" alt="top-left logo" />
                </a>
              </div>
              <p className="m-0 header-logo-name p-0" style={{width:'40%', fontSize:'14px'}}>{fromDate} - {toDate} <br></br> {address}<br></br><a href={sessionStorage.getItem("sessionExhMailWebsite")}
                target="_blank"
                rel="noopener noreferrer">{sessionStorage.getItem("sessionExhMailWebsite") != null && sessionStorage.getItem("sessionExhMailWebsite").replace(/^https?:\/\//, '')}</a>
              </p>
              <p className=" header-assistance">
                For Assistance
                <br>
                </br>
                {ExhibitionMobileNo.length > 0 ? ExhibitionMobileNo.map((data, i) => (<>
                  {data}{i != ExhibitionMobileNo.length - 1 ? ',' : null}
                </>
                )) : null}
                <br>
                  {/* ` ${(data)}`(i != ExhibitionMobileNo.length - 1 ? ',' : null) */}
                </br>
                {ExhibitionEmail.length > 0 ? ExhibitionEmail.map((data, i) => (
                  <a href={data} > {data} {i != ExhibitionEmail.length - 1 ? '/' : null} </a>
                )) : null}
              </p>


              <div class="right-logo">
                <p className='right-logo-text'>Owned and Organised by</p>
                <img src="../images/itme-right-logo.png" className="header-brand-img desktop-logo" alt="logo" />
              </div>
            </div>
          </div>
        </div>
        {sessionStorage.getItem('completed') ? (null) : (<StepHeader />)}

      </div>
      {/* /app-Header */}
    </>
  );
}
export default Header;

