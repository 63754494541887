import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Footer from '../../common_components/Footer';
import Drawer from '../../common_components/Drawer';
import {
    primaryChapterAdd_action, getPreviewDetails_action, getDoubleStoreySSI_action, deleteSSicertificate_action, deleteTMMAITAMMA_action
} from '../../actions/primary_chapter_action'
import {
    getAgentDetails_action
} from '../../actions/registration_action'
import Resizer from "react-image-file-resizer";

import {
    getPendingRegDetails_action
} from '../../actions/registration_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import {
    clearSessionStorage, primaryChapterListActionId,
    toastError, toastSuccess, stallTypeActionId, stallSizeActionId, GSTvalidationActionId
} from '../../utils/constants'
import { fill_action } from '../../actions/fillCombo_action'
import { json } from 'body-parser';
import StepHeader from './stepHeader'
import $ from 'jquery'

/* 
* mapDispatchToProps
*/
// const mapDispatchToProps = dispatch => ({
//     loginAction: () => dispatch(loginAction())
// })

/* 
* mapStateToProps
*/
const mapStateToProps = state => ({
    ...state
})

class PrimaryChapter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            exhibitionID: "",
            exhRegistrationID: "",
            primaryChapterListData: [],
            level0: [],
            level2: [],
            level3: [],
            level4: [],
            schemeType: '',
            schemeTypeList: [],
            stallAreaList: [],
            selectedPrimaryChapterId: '',
            stallAreaId: '',
            stallAreaName: '',
            PrimaryChapterId: '',
            // openSlideRadio: '',
            countryId: "",
            countryName: "",
            primaryChapterRadio: "",
            openSlides: "1",
            exhibitDetailsList: [],
            schemeTypeErr: "",
            primaryChapterIdErr: "",
            stallAreaIdErr: "",
            openSlidesErr: "",
            exhibitDetailsTableErr: "",
            lengthWidthErr: '',
            exhRegistrationNo: '',
            area: '',
            minAreaPercent: '',
            minArea: '',
            doubleStoreyArea: '',
            doubleStoreyAreaErr: '',
            isDoubleStorey: false,
            isSSi: false,
            isSSifile: '',
            isSSifileErr: '',
            isSSifileType: '',
            isSSifilePath: '',
            isSSIandTMMAITAMMMA: false,
            TMMAITAMMA: '',
            TMMAITAMMAfile: '',
            TMMAITAMMAfileErr: '',
            TMMAITAMMAfileType: '',
            TMMAITAMMAfilePath: '',

            isDoubleStoreyExhibition: false,
            isChargesforOpenSides: false,
            isSSIExhibition: false,
            isSSIdeleted: true,
            isTMMAITAMMAdeleted: true,
            isDel1Open: false,
            isDel2Open: false,
            isDataLoad: false,
            isShowChapterNotes: false,
            isShowHighlightChapters: false,
            isShowMachineryGuidelines: false,
            guideLines: [],
            note: [],
            SSIMaxArea: '',
            gstValidationList: [],
            agentChapterIDList: []
        }
        this.getFillList = this.getFillList.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.filterLevelData = this.filterLevelData.bind(this)
        this.record = this.record.bind(this)
        this.recordFour = this.recordFour.bind(this)
        this.onSchemeTypeChange = this.onSchemeTypeChange.bind(this)
        this.onLengthTypeChange = this.onLengthTypeChange.bind(this)
        this.onEnterHSNCode = this.onEnterHSNCode.bind(this)
        this.onEnterProductName = this.onEnterProductName.bind(this)
        this.handleStep2Insert = this.handleStep2Insert.bind(this)
        this.onAddManualChapter = this.onAddManualChapter.bind(this)
        this.handleClickSelectPrimaryChapter = this.handleClickSelectPrimaryChapter.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.loadscript = this.loadscript.bind(this)
        this.getPendingRegDetailsByCode = this.getPendingRegDetailsByCode.bind(this)
        this.getAgentDetails = this.getAgentDetails.bind(this)
    }

    componentDidMount() {
        console.log('prop is :', this.props);
        console.log('sessionExhRegistrationNo is :', sessionStorage.getItem('sessionExhRegistrationNo'));
        //if pending link call.........................................
        if (this.props.location.pathname.includes('/primary_chapter/')) {
            var code = this.props.location.pathname.replace('/primary_chapter/', '');
            console.log('prop is :', code);
            sessionStorage.setItem("renderPage", "primaryChapter")
            this.getPendingRegDetailsByCode(code).then(agentCode => {
                agentCode != null && sessionStorage.setItem("agentCode", agentCode)
                if (agentCode != null) {
                    (
                        this.getAgentDetails().then(res => {
                            res && this.getDoubleStroeySSI()
                            res && this.getPreviewDetails()
                        })
                    )
                } else {
                    this.getDoubleStroeySSI()
                    this.getPreviewDetails()
                }
            })
        } else {
            sessionStorage.setItem("renderPage", "primaryChapter")
            this.setState({
                isDataLoad: true,
                countryId: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.countryId != " " ? this.props.location.state.countryId : '',
                countryName: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.countryName != " " ? this.props.location.state.countryName : '',
                exhibitionID: sessionStorage.getItem("sessionExhibitionID"),
                exhRegistrationID: sessionStorage.getItem("sessionExhRegistrationID"),
                is_update: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_update != " " ? this.props.location.state.is_update : '',

            })
            var countryid = '';
            if (this.props.location.state != null && this.props.location.state != "" && this.props.location.state.countryId != undefined && this.props.location.state.countryId != "") {
                countryid = this.props.location.state.countryId;
            } else {
                countryid = sessionStorage.getItem("countryId");
            }
            this.setState({
                countryId: countryid
            })
            console.log('prepared country id : ', countryid);
            // this.getFillList(primaryChapterListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', sessionStorage.getItem("sessionExhRegistrationID"))
            this.getFillList(stallTypeActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')
            this.getFillList(GSTvalidationActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')
            this.props.location.state.is_update && this.getPreviewDetails();
            this.loadscript()
            setTimeout(() => {
                if (
                    this.props.location != null &&
                    this.props.location.state != null &&
                    sessionStorage.getItem("agentCode") != null &&
                    sessionStorage.getItem("agentCode") != ""
                ) {
                    this.getAgentDetails().then(res => {
                        res && this.getDoubleStroeySSI()
                    });
                } else {
                    this.getDoubleStroeySSI()
                }

            }, 200);
        }
    }

    getDoubleStroeySSI() {
        const { exhRegistrationID, exhibitionID } = this.state

        this.setState({ isLoading: true });

        this.props
            .getDoubleStoreySSI_action(exhRegistrationID, exhibitionID)
            .then((data) => {
                if (data.error != 1) {
                    console.log('data is : ', data.data);
                    console.log(data.data[1], sessionStorage.getItem("sessionExhRegistrationNo"))
                    // var exNo = data.data[0][0].ExhRegistrationNo.replace(`/`, '_')
                    var guideLines = []
                    var notes = []
                    for (var i = 0; i < data.data[1].length; i++) {
                        if (data.data[1][i].NoteType == 'Guidelines') {
                            guideLines.push(data.data[1][i])
                        }
                        if (data.data[1][i].NoteType == 'Chapter Notes') {
                            notes.push(data.data[1][i])
                        }
                    }
                    console.log(guideLines, notes)
                    this.setState({
                        minAreaPercent: data.data[0][0].DoubleStoreyAreaPercentage,
                        minArea: data.data[0][0].DoubleStoreyArea,
                        isDoubleStoreyExhibition: data.data[0][0].isDoubleStorey,
                        isSSIExhibition: data.data[0][0].isSSICertificate,
                        guideLines: guideLines,
                        note: notes,
                        isShowChapterNotes: data.data[0][0].isShowChapterNotes,
                        isShowHighlightChapters: data.data[0][0].isShowHighlightChapters,
                        isShowMachineryGuidelines: data.data[0][0].isShowMachineryGuidelines,
                        isChargesforOpenSides: data.data[0][0].isChargesForOpenSide,
                        SSIMaxArea: data.data[0][0].SSIArea
                    })
                    this.getFillList(primaryChapterListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', sessionStorage.getItem("sessionExhRegistrationID")).then(res => {
                        this.setState({ isLoading: false })
                    })

                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    getPendingRegDetailsByCode(code) {
        console.log('code is : ', code);
        const { } = this.state

        this.setState({ isLoading: true });
        return new Promise((resolve, reject) => {

            this.props
                .getPendingRegDetails_action(code)
                .then((data) => {
                    if (data.error != 1) {
                        console.log('data is : ', data.data[0]);
                        console.log('ExhRegistrationID is : ', data.data[0].ExhRegistrationID);
                        sessionStorage.setItem("sessionExhibitionID", data.data.length > 0 ? data.data[0].ExhibitionID : '')
                        sessionStorage.setItem("sessionExhRegistrationID", data.data.length > 0 ? data.data[0].ExhRegistrationID : '')
                        sessionStorage.setItem("sessionContryNamePre", data.data.length > 0 ? data.data[0].CountryCode : '')
                        sessionStorage.setItem("isCountrySelected", true)
                        sessionStorage.setItem("isFirstStepCompleted", true)
                        sessionStorage.setItem("sessionContryName", data.data.length > 0 ? data.data[0].CountryName : '')
                        sessionStorage.setItem("countryId", data.data.length > 0 ? data.data[0].CountryID : '')
                        sessionStorage.setItem("sessionExhRegistrationNo", data.data.length > 0 ? data.data[0].ExhRegistrationNo : "");
                        var emailArr = data.data.length > 0 && data.data[0].ExhMailEmail != '' && data.data[0].ExhMailEmail != null ? data.data[0].ExhMailEmail.split(",") : []
                        var mobileArr = data.data.length > 0 && data.data[0].ExhMailMobile != '' && data.data[0].ExhMailMobile != null ? data.data[0].ExhMailMobile.split(",") : []
                        sessionStorage.setItem("exhibitionAddress", data.data.length > 0 ? data.data[0]['Address'] : '')
                        sessionStorage.setItem("sessionBannerPath", data.data.length > 0 ? data.data[0]['Banner'] : '')
                        sessionStorage.setItem("exhibitionDateFrom", data.data.length > 0 ? data.data[0]['ExhibitionDateFrom'] : '')
                        sessionStorage.setItem("exhibitionDateTo", data.data.length > 0 ? data.data[0]['ExhibitionDateTo'] : '')
                        sessionStorage.setItem("sessionLogoPath", data.data.length > 0 ? data.data[0]['Logo'] : '')
                        sessionStorage.setItem("sessionExhibitionName", data.data.length > 0 ? data.data[0]['ExhibitionName'] : '')
                        sessionStorage.setItem("sessionExhibitionEmail", JSON.stringify(emailArr))
                        sessionStorage.setItem("sessionExhibitionMobileNo", JSON.stringify(mobileArr))



                        // this.setState({ isLoading: false })
                        var agentCode = data.data.length > 0 ? data.data[0].agentcode != '' && data.data[0].agentcode != null ? data.data[0].agentcode : null : null
                        this.setState({
                            isDataLoad: true,
                            countryId: data.data.length > 0 ? data.data[0].CountryID : '',
                            countryName: data.data.length > 0 ? data.data[0].CountryName : '',
                            exhibitionID: data.data.length > 0 ? data.data[0].ExhibitionID : '',
                            exhRegistrationID: data.data.length > 0 ? data.data[0].ExhRegistrationID : '',
                            exhRegistrationNo: data.data.length > 0 ? data.data[0].ExhRegistrationNo : "",
                            is_update: true,
                        })
                        setTimeout(() => {
                            var countryid = '';
                            if (this.props.location.state != null && this.props.location.state != "" && this.props.location.state.countryId != undefined && this.props.location.state.countryId != "") {
                                countryid = this.props.location.state.countryId;
                            } else {
                                countryid = sessionStorage.getItem("countryId");
                            }
                            this.setState({
                                countryId: countryid
                            })
                            // console.log('prepared country id : ', countryid);
                            // this.getFillList(primaryChapterListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', sessionStorage.getItem("sessionExhRegistrationID")).then(() => {
                            // this.setState({ isLoading: false })
                            // })
                            this.getFillList(stallTypeActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')
                            // this.getPreviewDetails();
                            this.loadscript()
                            // this.getDoubleStroeySSI()
                            resolve(agentCode)
                        }, 300);
                    }
                    else {
                        this.setState({ isLoading: false })
                        if (data.msg.includes('Registration completed with ')) {
                            sessionStorage.setItem("sessionExhRegistrationNo", data.msg.replace('Registration completed with ', ''))
                            this.props.navigate('/thankYou')
                        }

                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    getAgentDetails() {
        this.setState({ isLoading: true });
        return new Promise((resolve, reject) => {
            this.props
                .getAgentDetails_action(sessionStorage.getItem("agentCode"))
                .then((data) => {
                    if (data.error != 1 && data.data != null && data.data.length > 0) {
                        console.log(data.data, 'agent')
                        var chapterIDArr = data.data[0]["ChapterID"] != '' && data.data[0]["ChapterID"] != null ? data.data[0]["ChapterID"].split(',') : []
                        console.log(chapterIDArr, 'chapterIDArr')
                        for (var i = 0; i < chapterIDArr.length; i++) {
                            chapterIDArr[i] = chapterIDArr[i].trim()
                            chapterIDArr[i] = parseInt(chapterIDArr[i])
                        }
                        this.setState({ agentChapterIDList: chapterIDArr });
                        resolve(true)
                    } else {
                        this.setState({ isLoading: false });
                        toastError(data.msg);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    inputValidate() {
        const { schemeType, stallAreaId, openSlides, exhibitDetailsList } = this.state
        if (schemeType == '') {
            this.setState({
                schemeTypeErr: 'Please select scheme type'
            })
        }
        if (stallAreaId == '') {
            this.setState({
                stallAreaIdErr: 'Please select stall area'
            })
        }
        if (openSlides == '') {
            this.setState({
                openSlidesErr: 'Please select open sides'
            })
        }
        if (exhibitDetailsList.length == 0) {
            console.log("exhibitDetailsList", exhibitDetailsList)
            this.setState({
                exhibitDetailsTableErr: 'Please select atleast one chapter'
            })
        }
    }
    // get preview Details
    getPreviewDetails() {
        const { level2, level3, level4, exhibitDetailsList, stallAreaId, schemeType, stallAreaList } = this.state
        this.setState({ isLoading: true })
        var exbid = sessionStorage.getItem("sessionExhibitionID");
        var regid = sessionStorage.getItem("sessionExhRegistrationID")

        this.props
            .getPreviewDetails_action(regid, exbid)
            .then((data) => {
                if (data.error != 1) {
                    console.log("stallAreaId", data.data)
                    if (data.data[0]['ExhibitionStallSizeID'] != null && data.data[0]['StallTypeID'] != null && data.data[0]['OpenSides'] != null) {
                        this.setState({
                            stallAreaId: data.data[0]['ExhibitionStallSizeID'] != null ? data.data[0]['ExhibitionStallSizeID'] : '',
                            schemeType: data.data[0]['StallTypeID'] != null ? data.data[0]['StallTypeID'] : '',
                            openSlides: data.data[0]['OpenSides'] != null ? data.data[0]['OpenSides'] : '',
                            primaryChapterRadio: data.data[0]['PrimaryChapter'] != null ? data.data[0]['PrimaryChapter'].toString() : '',
                            selectedPrimaryChapterId: data.data[0]['PrimaryChapter'] != null ? data.data[0]['PrimaryChapter'].toString() : '',
                            area: data.data[0]['StallArea'] != null ? data.data[0]['StallArea'] : ''
                            // primaryChapterRadio : data.data[0]['PrimaryChapter']
                        })
                        for (var i = 0; i < stallAreaList.length; i++) {
                            if (stallAreaList[i].Code == data.data[0]['ExhibitionStallSizeID']) {
                                console.log('Description : ', stallAreaList[i].Description);
                                this.setState({ stallAreaName: stallAreaList[i].Description, })
                            }
                        }
                    }
                    if (data.data[0].SSIDocumentPath != null && data.data[0].SSIDocumentPath != '') {
                        var filePath = data.data[0].SSIDocumentPath
                        var array = data.data[0].SSIDocumentPath != null &&
                            data.data[0].SSIDocumentPath != "" && filePath.split("/")
                        const FileName = array[array.length - 1]
                        console.log('filePath', filePath)
                        this.setState({
                            isSSifilePath: filePath,
                            isSSifile: FileName,
                            isSSIdeleted: false,
                        })
                    }
                    if (data.data[0].TMMA_ITAMMA_DocumentPath != null && data.data[0].TMMA_ITAMMA_DocumentPath != '') {
                        var filePath = data.data[0].TMMA_ITAMMA_DocumentPath
                        var array = data.data[0].TMMA_ITAMMA_DocumentPath != null &&
                            data.data[0].TMMA_ITAMMA_DocumentPath != "" && filePath.split("/")
                        const FileName = array[array.length - 1]
                        this.setState({
                            TMMAITAMMAfilePath: filePath,
                            TMMAITAMMAfile: FileName,
                            isTMMAITAMMAdeleted: false,
                        })
                    }
                    this.setState({
                        isDoubleStorey: data.data[0].isDoubleStorey,
                        isSSi: data.data[0].isSSICertified,
                        isSSIandTMMAITAMMMA: data.data[0].isSSICertified == true || data.data[0].TMMA_ITAMMA != null ? true : false,
                        doubleStoreyArea: data.data[0].DoubleStoreyArea,
                        TMMAITAMMA: data.data[0].TMMA_ITAMMA != null ? data.data[0].TMMA_ITAMMA == 'TMMA' ? 1 : 2 : '',
                        // TMMAITAMMAfilePath: data.data[0].TMMA_ITAMMA_DocumentPath
                    })
                    setTimeout(() => {
                        // this.setState({ isLoading: false })
                        this.getFillList(primaryChapterListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '', sessionStorage.getItem("sessionExhRegistrationID")).then(() => {
                            this.setState({ isLoading: false })
                        })

                        const { countryId } = this.state
                        this.getFillList(stallSizeActionId, sessionStorage.getItem("sessionExhibitionID"), '', data.data[0]['StallTypeID'], countryId, '')
                        // this.setState({ isLoading: false })

                        console.log("stallAreaId state", stallAreaId)
                        console.log("schemeType state", schemeType)

                    }, 500);
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleSSIDelete(e) {
        const { isSSIandTMMAITAMMMA } = this.state
        console.log(e)
        e != undefined && e.preventDefault()
        const { isDelOpen, logoImg, exhRegistrationID, isDel1Open, exhibitionID, SSIDocumentPath, isSSifile, isSSIdeleted } = this.state
        this.setState({ isLoading: true })
        this.props.deleteSSicertificate_action(parseInt(exhRegistrationID), parseInt(exhibitionID),)
            .then((data) => {
                if (data.error != 1) {
                    isSSIandTMMAITAMMMA && toastSuccess(" Document Deleted SuccessFully")
                    this.setState({ isLoading: false, isDel1Open: false, isSSifile: "", isSSifilePath: '', isSSIdeleted: true })
                } else {
                    isSSIandTMMAITAMMMA && toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    handleTMMAITAMMADelete(e) {
        const { isSSIandTMMAITAMMMA } = this.state
        e != undefined && e.preventDefault()
        const { isDel2Open, logoImg, exhRegistrationID, isDel1Open, exhibitionID, SSIDocumentPath, isSSifile, isSSIdeleted, TMMAITAMMAfile, isTMMAITAMMAdeleted } = this.state
        this.setState({ isLoading: true })
        this.props.deleteTMMAITAMMA_action(parseInt(exhRegistrationID), parseInt(exhibitionID),)
            .then((data) => {
                if (data.error != 1) {
                    isSSIandTMMAITAMMMA && toastSuccess(" Document Deleted SuccessFully")
                    this.setState({ isLoading: false, isDel2Open: false, TMMAITAMMAfile: "", TMMAITAMMAfilePath: '', isTMMAITAMMAdeleted: true })
                } else {
                    isSSIandTMMAITAMMMA && toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    onSSIDocDelete() {
        const { isDel1Open } = this.state
        this.setState({ isDel1Open: !isDel1Open })
    }
    onTMMAITAMMADocDelete() {
        const { isDel2Open } = this.state
        this.setState({ isDel2Open: !isDel2Open })
    }

    preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    handleClickSelectPrimaryChapter(data, id) {
        const { primaryChapterRadio, primaryChapterListData, isShowHighlightChapters, level2 } = this.state
        data.IsSelect = !data.IsSelect
        // data.isChecked = !data.IsSelect
        var listData = [...primaryChapterListData]
        if (id != '') {
            for (var i = 0; i < listData.length; i++) {
                if (listData[i].ChapterID == id) {
                    this.setState({
                        selectedPrimaryChapterId: id,
                        primaryChapterIdErr: ''
                    })
                    listData[i].isChecked = true;
                } else {
                    listData[i].isChecked = false;
                }
            }
            console.log('updated list : ', listData);
            this.setState({
                primaryChapterListData: listData,
            })
            var lev2List = [...level2]
            var highLightId = id == 2033 ? 2046 : id
            console.log('isShowHighlightChapters', isShowHighlightChapters)
            if (isShowHighlightChapters) {

                for (var i = 0; i < lev2List.length; i++) {
                    if (lev2List[i].ParentChapterID == highLightId) {
                        lev2List[i]['isHighlight'] = true;
                    } else {
                        lev2List[i]['isHighlight'] = false;
                    }
                }
            }
            this.setState({
                level2: lev2List,
            })
        }



        this.setState({ primaryChapterRadio: id == 2033 ? 2046 : id })
        // primaryChapterListData.length > 0 && primaryChapterListData.map(data => {
        //     if (data.ChapterID == id) {
        //         data.IsSelect = !data.IsSelect
        //         this.setState({ primaryChapterListData : primaryChapterListData})

        //     }
        // })

    }

    // onClickOpenSideRadio(value) {
    //     const { openSlides } = this.state
    //     if (value == 'openSlides3') {
    //         this.setState({ openSlides: 'openSlides3' })
    //     }
    //     else  if (value == 'openSlides1') {
    //         this.setState({ openSlides: 'openSlides1' })
    //     }
    //     else  if (value == 'openSlides2') {
    //         this.setState({ openSlides: 'openSlides2' })
    //     }
    // }


    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        // object.target.value.length == maxLength &&
        //   setTimeout(() => {
        //     this.contactAadhaarValidation()
        //   }, 200);
    }

    handleStep2Insert() {
        const { selectedPrimaryChapterId, exhibitionID, exhRegistrationID, stallAreaId, schemeType, openSlides,
            exhibitDetailsList, isDoubleStorey, isSSi, primaryChapterRadio, isSSIandTMMAITAMMMA, stallAreaIdErr, schemeTypeErr, openSlidesErr, countryId, exhibitDetailsTableErr, lengthWidthErr, stallAreaName, isSSIdeleted, isTMMAITAMMAdeleted, isSSifile, isSSifilePath, isSSifileType, TMMAITAMMAfile, TMMAITAMMAfilePath, TMMAITAMMAfileType, PrimaryChapterId, level0, level2, level3, level4, primaryChapterListData } = this.state
        // if(stallAreaId == '' ||  schemeType == '' || openSlides == ''){
        //     toastError("Please select mandatory fields.");
        //     return;
        // }
        var iserror = false;
        if (selectedPrimaryChapterId == null || selectedPrimaryChapterId == '') {
            this.setState({ primaryChapterIdErr: 'Please select primary chapter.' })
            iserror = true;
        }
        if (stallAreaId == null || stallAreaId == '') {
            this.setState({ stallAreaIdErr: 'Please select stall area.' })
            iserror = true;
        }
        if (schemeType == null || schemeType == '') {
            this.setState({ schemeTypeErr: 'Please select scheme type.' })
            iserror = true;
        }
        if (openSlides == null || openSlides == '') {
            this.setState({ openSlidesErr: 'Please select open side.' })
            iserror = true;
        }
        if (exhibitDetailsList == null || exhibitDetailsList.length == 0) {
            this.setState({ exhibitDetailsTableErr: 'Please select atleast one chapter' })
            iserror = true;
        }
        if (isDoubleStorey) {
            if (this.state.doubleStoreyArea == '' || this.state.doubleStoreyArea == null) {
                this.setState({ doubleStoreyAreaErr: 'Please fill Double storey area' })
                iserror = true;
            }
        }
        var isSSIfile = null
        var isSSIfileType = null
        var TMMA_ITAMMAfile = null
        var TMMA_ITAMMAfiletype = null
        if (isSSi) {

            if (this.state.isSSifile == '' || this.state.isSSifile == null) {
                this.setState({ isSSifileErr: 'Please upload SSI certificate' })
                iserror = true;
            }
            else {
                isSSIfile = this.state.isSSifile
                isSSIfileType = this.state.isSSifileType
                // this.setState({ isSSifilePath: '' })
            }
            if (this.state.TMMAITAMMA != '' && this.state.TMMAITAMMA != null) {
                if (this.state.TMMAITAMMAfile == '' || this.state.TMMAITAMMAfile == null) {
                    this.setState({ TMMAITAMMAfileErr: 'Please upload TMMA or ITAMMA certificate' })
                    iserror = true;
                }
                else {
                    TMMA_ITAMMAfile = this.state.TMMAITAMMAfile
                    TMMA_ITAMMAfiletype = this.state.TMMAITAMMAfileType
                    // this.setState({ TMMAITAMMAfilePath: '' })
                }
            }
            else {
                TMMA_ITAMMAfile = null
                TMMA_ITAMMAfiletype = null
                this.setState({ TMMAITAMMAfilePath: '' })
            }
        }
        else {
            isSSIfile = null
            isSSIfileType = null
            this.setState({ isSSifilePath: '' })
        }
        if (exhibitDetailsList.length > 0 && stallAreaName != null && stallAreaName != '') {
            for (var i = 0; i < exhibitDetailsList.length; i++) {
                if (exhibitDetailsList[i].Length != null && exhibitDetailsList[i].Length != 0 && exhibitDetailsList[i].Breadth != null && exhibitDetailsList[i].Breadth != 0) {
                    var totalSpace;
                    var totalEnterSpace;
                    var lengthWidthList
                    if (stallAreaName.includes('x')) {
                        lengthWidthList = stallAreaName.split('x');
                    } else if (stallAreaName.includes('X')) {
                        lengthWidthList = stallAreaName.split('X');
                    }
                    totalSpace = parseFloat(lengthWidthList[0]) * parseFloat(lengthWidthList[1]);
                    totalEnterSpace = parseFloat(exhibitDetailsList[i].Length) * parseFloat(exhibitDetailsList[i].Breadth);
                    if (totalEnterSpace > totalSpace) {
                        this.setState({ lengthWidthErr: 'Length and breadth must be below or equal to stall area.' })
                        return;
                    } else {
                        this.setState({ lengthWidthErr: '' })
                    }
                }

                //check for heaight....................................
                // if (exhibitDetailsList[i].Height != null && exhibitDetailsList[i].Height != '' && exhibitDetailsList[i].Height != 0 && exhibitDetailsList[i].Height > 3) {
                //     this.setState({ lengthWidthErr: 'Height must be less than or equal to 3.' })
                //     return;
                // } else {
                //     this.setState({ lengthWidthErr: '' })
                // }
            }
        }


        // debugger

        if (iserror) {
            toastError("Please fill all the mandatory fields")
            return;
        }


        var list = []
        var concatedChapterList = level0.concat(level0, level2, level3, level4)
        //first add with chapter id available data..............
        exhibitDetailsList.map(data => {
            if (data.ChapterID != 0) {
                var data = {
                    "ExhibitionID": parseInt(exhibitionID),
                    "ExhRegistrationID": exhRegistrationID,
                    "ChapterID": data.ChapterID,
                    'ChapterName': data.ChapterName,
                    "HSNCode": data.HSNCode,
                    "MachineDetails": data.MachineDetails,
                    "Length": data.Length,
                    "Breadth": data.Breadth,
                    "Height": data.Height,
                    "FloorCapacity": data.FloorCapacity,
                    "MachineWeight": data.MachineWeight,
                    "ManufacturerName": data.ManufacturerName,
                    "ManufacturingCountry": data.ManufacturingCountry,
                }
                list.push(data)
            }
        })
        //then without chapter id data...................................
        exhibitDetailsList.map(data => {
            if (data.ChapterID == 0 && data.ChapterName != null && data.ChapterName != '') {
                var data = {
                    "ExhibitionID": parseInt(exhibitionID),
                    "ExhRegistrationID": exhRegistrationID,
                    "ChapterID": data.ChapterID,
                    'ChapterName': data.ChapterName,
                    "HSNCode": data.HSNCode,
                    "MachineDetails": data.MachineDetails,
                    "Length": data.Length,
                    "Breadth": data.Breadth,
                    "Height": data.Height,
                    "FloorCapacity": data.FloorCapacity,
                    "MachineWeight": data.MachineWeight,
                    "ManufacturerName": data.ManufacturerName,
                    "ManufacturingCountry": data.ManufacturingCountry,
                }
                list.push(data)
            }
        })
        var chapterSelecterr = true
        for (var i = 0; i < list.length; i++) {
            var currentChpId = list[i].ChapterID
            concatedChapterList.map((item1) => {
                if (item1.ChapterID == currentChpId) {
                    if (item1.primaryChapterId == primaryChapterRadio) {
                        chapterSelecterr = false
                    }
                    console.log(primaryChapterRadio, item1, 'list', list, chapterSelecterr)
                }
            })
        }
        if (!isSSIandTMMAITAMMMA) {
            if (!isSSIdeleted) {
                this.handleSSIDelete()
            }
            if (!isTMMAITAMMAdeleted) {
                this.handleTMMAITAMMADelete()
            }
        }
        setTimeout(() => {
            console.log('==>', this.state.isSSifilePath, this.state.isSSifile)
            this.inputValidate()
            if ((stallAreaId == "" && schemeType == "" && openSlides == "" && exhibitDetailsList.length == 0 && this.state.area != '' && this.state.area != null &&
                stallAreaIdErr != '' && schemeTypeErr != '' && openSlidesErr != '' && exhibitDetailsTableErr != '')
                // || chapterSelecterr == true
            ) {
                console.log("exhibitDetailsTableErr", exhibitDetailsTableErr)
                // if (chapterSelecterr == true) {
                // toastError("Please select  atleast 1 product of the selected primary chapter")
                // } else {
                toastError("Please select mandatory fields")
                // }
            } else {
                // console.log(isSSifile, isSSifilePath, isSSifileType, TMMAITAMMAfile, TMMAITAMMAfilePath, TMMAITAMMAfileType)
                this.setState({ isLoading: true }) //ExhRegistrationID,ExhibitionStallSizeID,exhRegistrationID
                this.props
                    .primaryChapterAdd_action(parseInt(exhibitionID),
                        parseInt(exhRegistrationID),
                        parseInt(stallAreaId),
                        this.state.area,
                        parseInt(schemeType),
                        parseInt(openSlides),
                        list,
                        parseInt(primaryChapterRadio),
                        this.state.isDoubleStorey,
                        this.state.doubleStoreyArea != '' && this.state.doubleStoreyArea != null ? this.state.doubleStoreyArea : null,
                        this.state.isSSi,
                        isSSIandTMMAITAMMMA ? (this.state.TMMAITAMMA == 1 ? 'TMMA' : this.state.TMMAITAMMA == 2 ? 'ITAMMA' : null) : null,
                        sessionStorage.getItem("sessionExhRegistrationNo"),
                        isSSIandTMMAITAMMMA ? (this.state.isSSi == true ? isSSIfile : null) : null,
                        isSSIandTMMAITAMMMA ? ((this.state.TMMAITAMMA != '' && this.state.TMMAITAMMA != null) ? TMMA_ITAMMAfile : null) : null,
                        isSSIandTMMAITAMMMA ? (this.state.isSSi == true ? isSSIfileType : null) : null,
                        isSSIandTMMAITAMMMA ? ((this.state.TMMAITAMMA != '' && this.state.TMMAITAMMA != null) ? TMMA_ITAMMAfiletype : null) : null,
                        this.state.isSSifilePath,
                        this.state.TMMAITAMMAfilePath)
                    .then((data) => {
                        if (data.error != 1) {
                            sessionStorage.setItem("renderPage", "description")
                            this.props.navigate('/description')
                            // this.setState({
                            //     countryList: data.data, isLoading: false,
                            // })

                        }
                        else {
                            this.setState({ isLoading: false })
                            toastError(data.msg)
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        }, 200);


    }

    onPreviousClick() {
        sessionStorage.setItem("renderPage", "registerForm")
        sessionStorage.setItem("registeris_update", true)
        setTimeout(() => {
            this.props.navigate('/registration', { state: { is_update: true } })
        }, 200);
    }


    filterLevelData() {
        const { level2, level3, level4 } = this.state
        var newLevel = [...level2]
        for (var i = 0; i < level3.length; i++) {
            var tempLevel4 = [];
            for (var j = 0; j < level4.length; j++) {
                if (level3[i]['ChapterID'] == level4[j]['ParentChapterID']) {
                    tempLevel4.push(level4[j]);
                }
            }
            level3[i]['level_four_List'] = tempLevel4;
            // console.log(" level3[i]['level_four_List']", tempLevel4);
        }

        for (var i = 0; i < newLevel.length; i++) {
            var tempLevel3 = [];
            for (var j = 0; j < level3.length; j++) {
                if (newLevel[i]['ChapterID'] == level3[j]['ParentChapterID']) {
                    tempLevel3.push(level3[j]);
                }
            }
            newLevel[i]['level_third_List'] = tempLevel3;
            // console.log("level2[i]['level_third_List']", tempLevel3);

        }

        ///////////============
        console.log("level2", newLevel)
        this.setState({ level2: newLevel })
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        const { level3, level4, level2, level0, is_update, agentChapterIDList, PrimaryChapterId, isShowHighlightChapters, primaryChapterRadio, stallAreaId } = this.state
        return new Promise((resolve, reject) => {
            if (!is_update) {
                this.setState({ isLoading: true })
            }

            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value, sessionStorage.getItem("sessionExhRegistrationID"))
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === primaryChapterListActionId) {
                            // this.setState({ isLoading: true })
                            async function seqnoFormat(arr) {
                                for (var i = 0; i < arr.length; i++) {
                                    if (arr[i].seqno != '' && arr[i].seqno != null) {
                                        for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                            if (arr[i].ChapterName[j] == ' ') {
                                                var str = arr[i].ChapterName.slice(j)
                                                var finalName = arr[i].seqno + str
                                                arr[i].ChapterName = finalName
                                                // console.log("primaryChapterListActionId 121212", i, finalName)
                                                break
                                            }
                                        }
                                    }
                                }
                                return
                            }
                            if (agentChapterIDList.length > 0) {
                                var agentChpterList = []
                                var agentPrimaryChpterIDarr = []
                                var agentPrimaryChapterIDList = []
                                for (var a = 0; a < agentChapterIDList.length; a++) {
                                    for (var i = 0; i < data.data['level2'].length; i++) {
                                        console.log('agentChapterIDListOOOOOOOOOOOOOO', data.data['level2'])
                                        if (agentChapterIDList[a] == data.data['level2'][i].ChapterID) {
                                            agentChpterList.push(data.data['level2'][i])
                                            !agentPrimaryChpterIDarr.includes(data.data['level2'][i].ParentChapterID) && agentPrimaryChpterIDarr.push(data.data['level2'][i].ParentChapterID)
                                        }
                                    }
                                }
                                if (!agentPrimaryChpterIDarr.includes(2046) && agentPrimaryChpterIDarr.includes(2033)) {
                                    agentPrimaryChpterIDarr.push(2046)
                                } else if (!agentPrimaryChpterIDarr.includes(2033) && agentPrimaryChpterIDarr.includes(2046)) {
                                    agentPrimaryChpterIDarr.push(2033)
                                }
                                for (var a = 0; a < agentPrimaryChpterIDarr.length; a++) {
                                    for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                                        if (agentPrimaryChpterIDarr[a] == data.data['primaryChapter'][i].ChapterID) {
                                            agentPrimaryChapterIDList.push(data.data['primaryChapter'][i])
                                        }
                                    }
                                }
                                console.log(agentChpterList, 'agentChapterIDList', agentPrimaryChapterIDList)
                                data.data['level2'] = agentChpterList
                                data.data['primaryChapter'] = agentPrimaryChapterIDList
                            }
                            for (var i = 0; i < data.data['level2'].length; i++) {
                                for (var j = 0; j < data.data['level2'].length - 1 - i; j++) {
                                    var seq1 = data.data['level2'][j].seqno
                                    var seq2 = data.data['level2'][j + 1].seqno
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level2'][j]
                                        data.data['level2'][j] = data.data['level2'][j + 1]
                                        data.data['level2'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                data.data['level2'][i].primaryChapterId = data.data['level2'][i].ParentChapterID
                                seqnoFormat(data.data['level2'])
                            }
                            for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                                for (var j = 0; j < data.data['primaryChapter'].length - 1 - i; j++) {
                                    var seq1 = data.data['primaryChapter'][j].seqno
                                    var seq2 = data.data['primaryChapter'][j + 1].seqno
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['primaryChapter'][j]
                                        data.data['primaryChapter'][j] = data.data['primaryChapter'][j + 1]
                                        data.data['primaryChapter'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                if (data.data['primaryChapter'][i].seqno == null || data.data['primaryChapter'][i].seqno == undefined) {
                                    console.log(data.data['primaryChapter'][i].seqno, i)
                                }
                                // seqnoFormat(data.data['primaryChapter'])
                            }
                            console.log("prr",)
                            for (var i = 0; i < data.data['level3'].length; i++) {
                                for (var j = 0; j < data.data['level3'].length - 1 - i; j++) {

                                    var seq1 = data.data['level3'][j].seqno
                                    seq1 = seq1 != null && seq1.replaceAll('.', '')
                                    var seq2 = data.data['level3'][j + 1].seqno
                                    seq2 = seq2 != null && seq2.replaceAll('.', '')

                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level3'][j]
                                        data.data['level3'][j] = data.data['level3'][j + 1]
                                        data.data['level3'][j + 1] = temp;
                                    }
                                }
                                for (var j = 0; j < data.data['level2'].length; j++) {
                                    if (data.data['level2'][j].ChapterID == data.data['level3'][i].ParentChapterID) {
                                        data.data['level3'][i].primaryChapterId = data.data['level2'][j].ParentChapterID
                                        break
                                    }
                                }
                                seqnoFormat(data.data['level3'])
                            }
                            for (var i = 0; i < data.data['level4'].length; i++) {
                                for (var j = 0; j < data.data['level4'].length - 1 - i; j++) {
                                    var seq1 = data.data['level4'][j].seqno
                                    seq1 = seq1 != null && seq1.replaceAll('.', '')
                                    var seq2 = data.data['level4'][j + 1].seqno
                                    seq2 = seq2 != null && seq2.replaceAll('.', '')
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level4'][j]
                                        data.data['level4'][j] = data.data['level4'][j + 1]
                                        data.data['level4'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                for (var j = 0; j < data.data['level3'].length; j++) {
                                    if (data.data['level3'][j].ChapterID == data.data['level4'][i].ParentChapterID) {
                                        for (var k = 0; k < data.data['level2'].length; k++) {
                                            if (data.data['level2'][k].ChapterID == data.data['level3'][j].ParentChapterID) {
                                                data.data['level4'][i].primaryChapterId = data.data['level2'][k].ParentChapterID
                                                break
                                            }
                                        }
                                        break
                                    }
                                }
                                seqnoFormat(data.data['level4'])
                            }

                            for (var i = 0; i < data.data['level2'].length; i++) {
                                data.data['level2'][i].isHighlight = false;
                            }
                            this.setState({
                                primaryChapterListData: data.data['primaryChapter'],
                                level0: data.data['level0'],
                                level2: data.data['level2'],
                                level3: data.data['level3'],
                                level4: data.data['level4'],
                                // isLoading: false,
                            })
                            if (is_update) {

                                // this.setState({
                                //     primaryChapterRadio: primaryChapterRadio
                                //     // primaryChapterRadio : data.data[0]['PrimaryChapter']
                                // })

                                if (primaryChapterRadio != '') {
                                    for (var i = 0; i < data.data['primaryChapter'].length; i++) {
                                        if (data.data['primaryChapter'][i].ChapterID == primaryChapterRadio) {
                                            data.data['primaryChapter'][i].isChecked = true;
                                        } else {
                                            data.data['primaryChapter'][i].isChecked = false;
                                        }
                                    }
                                    this.setState({
                                        primaryChapterListData: data.data['primaryChapter'],
                                    })
                                }

                                var lev2List = [...data.data['level2']]
                                if (isShowHighlightChapters) {
                                    for (var i = 0; i < lev2List.length; i++) {
                                        if (lev2List[i].ParentChapterID == primaryChapterRadio) {
                                            lev2List[i]['isHighlight'] = true;
                                        } else {
                                            lev2List[i]['isHighlight'] = false;
                                        }
                                    }
                                }
                                this.setState({
                                    level2: lev2List,
                                })


                                var list = []
                                // console.log("level2 get", level2)
                                data.data['level2'].length > 0 && data.data['level2'].map(level2Data => {
                                    if (level2Data.IsSelect && level2Data.IsCheckbox) {
                                        // console.log("level2Data get", level2Data)

                                        list.push(level2Data)
                                    }
                                })
                                data.data['level3'].length > 0 && data.data['level3'].map(level3Data => {
                                    if (level3Data.IsSelect && level3Data.IsCheckbox) {
                                        list.push(level3Data)
                                    }
                                })
                                data.data['level4'].length > 0 && data.data['level4'].map(level4Data => {
                                    if (level4Data.IsSelect && level4Data.IsCheckbox) {
                                        list.push(level4Data)
                                    }
                                })
                                list.sort((a, b) => (a.ChapterID > b.ChapterID) ? 1 : -1);
                                data.data['level0'].length > 0 && data.data['level0'].map(level0Data => {
                                    if (level0Data.IsSelect) {
                                        // console.log("level2Data get", level2Data)

                                        list.push(level0Data)
                                    }
                                })
                                // console.log("exhibitDetailsList get", list)
                                this.setState({ exhibitDetailsList: list })
                            }
                            setTimeout(() => {
                                this.filterLevelData()
                            }, 300);
                            resolve(true)
                        }
                        // scheme Type
                        if (actionID === stallTypeActionId) {
                            // console.log("country data", data.data)
                            this.setState({
                                schemeTypeList: data.data,
                            })
                            if (data.data.length == 1) {
                                this.onSchemeTypeChange(null, data.data[0].Code)
                            }
                            resolve(true)
                        }
                        // stall Size
                        if (actionID === stallSizeActionId) {
                            // console.log("country data", data.data)
                            this.setState({
                                stallAreaList: data.data,
                            })
                            if (stallAreaId != '') {
                                for (var i = 0; i < data.data.length; i++) {
                                    if (data.data[i].Code == stallAreaId) {
                                        console.log('Description : ', data.data[i].Description);
                                        this.setState({ stallAreaName: data.data[i].Description })
                                    }
                                }
                            }
                            resolve(true)
                        }

                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }
    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        // object.target.value.length == maxLength &&
        //   setTimeout(() => {
        //     this.contactAadhaarValidation()
        //   }, 200);
    }

    onEnterProductName(e, index) {
        const { level4, exhibitDetailsList } = this.state

        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                console.log("e.target.value", e.target.value)
                data.ChapterName = e.target.value
            }
        })

        exhibitDetailsList.map((data, i) => {

        })

        for (var i = 0; i < exhibitDetailsList.length; i++) {
            console.log("onEnterProductName validate : ", index, ' ', i, ' ', exhibitDetailsList[i].ChapterName.toString().trim().toLowerCase(), ' ', e.target.value.toString().trim().toLowerCase())
            if (index != i && exhibitDetailsList[i].ChapterName.toString().trim().toLowerCase() == e.target.value.toString().trim().toLowerCase()) {
                this.setState({ lengthWidthErr: 'Product name must be unique.' })
                return;
            } else {
                this.setState({ lengthWidthErr: '' })
            }
        }


        setTimeout(() => {
            console.log("exhibitDetailsList", exhibitDetailsList)
            this.setState({ exhibitDetailsList: exhibitDetailsList })

        }, 200);
    }

    onMachineDetailsChange(e, id, index) {
        const { level4, exhibitDetailsList } = this.state
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.MachineDetails = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);
    }

    onEnterHSNCode(e, id, index) {
        const { level4, exhibitDetailsList } = this.state
        console.log("id", id)

        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                console.log("data.ChapterID", data.ChapterID)
                console.log("e.target.value", e.target.value)
                data.HSNCode = e.target.value
            }
        })
        setTimeout(() => {
            console.log("exhibitDetailsList", exhibitDetailsList)
            this.setState({ exhibitDetailsList: exhibitDetailsList })

        }, 200);
    }

    onLengthChange(e, id, index) {
        const { level4, exhibitDetailsList, stallAreaName } = this.state
        var breadth = 0;
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.Length = e.target.value
                breadth = data.Breadth;
            }
        })
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);
        //6x3

        if (stallAreaName != null && stallAreaName != '' && e.target.value != null && e.target.value != '' && e.target.value != 0 &&
            breadth != null && breadth != 0) {
            var totalSpace;
            var totalEnterSpace;
            var lengthWidthList
            if (stallAreaName.includes('x')) {
                lengthWidthList = stallAreaName.split('x');
            } else if (stallAreaName.includes('X')) {
                lengthWidthList = stallAreaName.split('X');
            }
            totalSpace = parseFloat(lengthWidthList[0]) * parseFloat(lengthWidthList[1]);
            totalEnterSpace = parseFloat(e.target.value) * parseFloat(breadth);
            if (totalEnterSpace > totalSpace) {
                this.setState({ lengthWidthErr: 'Length and breadth must be below or equal to stall area.' })
            } else {
                this.setState({ lengthWidthErr: '' })
            }
        }

    }

    onBreadthChange(e, id, index) {
        const { level4, exhibitDetailsList, stallAreaName } = this.state
        var length = 0;
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.Breadth = e.target.value
                length = data.Length;
            }
        })


        if (stallAreaName != null && stallAreaName != '' && e.target.value != null && e.target.value != '' && e.target.value != 0 &&
            length != null && length != 0) {
            var totalSpace;
            var totalEnterSpace;
            var lengthWidthList
            if (stallAreaName.includes('x')) {
                lengthWidthList = stallAreaName.split('x');
            } else if (stallAreaName.includes('X')) {
                lengthWidthList = stallAreaName.split('X');
            }

            totalSpace = parseFloat(lengthWidthList[0]) * parseFloat(lengthWidthList[1]);
            console.log('totalSpace : ', totalSpace);
            totalEnterSpace = parseFloat(e.target.value) * parseFloat(length);
            if (totalEnterSpace > totalSpace) {
                exhibitDetailsList.map((data, i) => {
                    if (index == i) {
                        data.isLengthValidate = true;
                    }
                })
                this.setState({ lengthWidthErr: 'Length and breadth must be below or equal to stall area.' })
            } else {
                exhibitDetailsList.map((data, i) => {
                    if (index == i) {
                        data.isLengthValidate = false;
                    }
                })
                this.setState({ lengthWidthErr: '' })
            }
        }
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);
    }

    onHeightChange(e, id, index) {
        const { level4, exhibitDetailsList } = this.state
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.Height = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);

        // if (e.target.value != null && e.target.value != '' && e.target.value != 0 && e.target.value > 3) {
        //     this.setState({ lengthWidthErr: 'Height must be less than or equal to 3.' })
        // } else {
        //     this.setState({ lengthWidthErr: '' })
        // }
    }

    onFloorCapacityChange(e, id, index) {
        const { level4, exhibitDetailsList } = this.state
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.FloorCapacity = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);
    }

    onMachineWeightChange(e, id, index) {
        const { level4, exhibitDetailsList } = this.state
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.MachineWeight = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);
    }

    onManufacturerNameChange(e, id, index) {
        const { level4, exhibitDetailsList } = this.state
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.ManufacturerName = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);
    }

    onManufacturingCountryChange(e, id, index) {
        const { level4, exhibitDetailsList } = this.state
        exhibitDetailsList.map((data, i) => {
            if (index == i) {
                data.ManufacturingCountry = e.target.value
            }
        })
        setTimeout(() => {
            this.setState({ exhibitDetailsList: exhibitDetailsList })
        }, 100);
    }

    onAddManualChapter(e) {
        e.preventDefault();
        const { exhibitDetailsList } = this.state
        var map = {
            Breadth: 0,
            ChapterID: 0,
            ChapterName: "",
            CssClass: null,
            ExhRegistrationID: sessionStorage.getItem("sessionExhRegistrationID"),
            ExhibitionID: sessionStorage.getItem("sessionExhibitionID"),
            FloorCapacity: 0,
            HSNCode: "",
            Height: 0,
            IsCheckbox: false,
            IsSelect: false,
            Length: 0,
            Level: 4,
            MachineDetails: "",
            MachineWeight: 0,
            ManufacturerName: "",
            ManufacturingCountry: "",
            ParentChapterID: 0,
            isLengthValidate: false
        }
        exhibitDetailsList.push(map)
        this.setState({ exhibitDetailsList: exhibitDetailsList })
    }

    // on check level 4
    onSelectLevel4(e, id) {
        const { level4, exhibitDetailsList } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!exhibitDetailsList.includes(level4[i])) {
                        level4[i].isLengthValidate = false;
                        exhibitDetailsList.push(level4[i])
                        this.setState({ exhibitDetailsList: exhibitDetailsList })
                    }
                }
                else {
                    this.setState({ exhibitDetailsList: exhibitDetailsList.filter(item => item.ChapterID != level4[i]['ChapterID']) })
                }

                level4[i]['IsSelect'] = !level4[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ level4: level4, exhibitDetailsTableErr: '' })
        }, 200);

        console.log('exhibitDetailsList : ', exhibitDetailsList);
    }

    // on check level 2
    onCheckLevel2(e, id) {
        const { level2, exhibitDetailsList } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level2.length; i++) {
            if (level2[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!exhibitDetailsList.includes(level2[i])) {
                        level2[i].isLengthValidate = false;
                        exhibitDetailsList.push(level2[i])
                        this.setState({ exhibitDetailsList: exhibitDetailsList })
                    }
                }
                else {
                    this.setState({ exhibitDetailsList: exhibitDetailsList.filter(item => item.ChapterID != level2[i]['ChapterID']) })
                }

                level2[i]['IsSelect'] = !level2[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);
    }

    // on check level 3
    onCheckLevel3(e, id) {
        const { level3, exhibitDetailsList } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {

                if (e.target.checked) {
                    if (!exhibitDetailsList.includes(level3[i])) {
                        level3[i].isLengthValidate = false;
                        exhibitDetailsList.push(level3[i])
                        this.setState({ exhibitDetailsList: exhibitDetailsList })
                    }
                }
                else {
                    this.setState({ exhibitDetailsList: exhibitDetailsList.filter(item => item.ChapterID != level3[i]['ChapterID']) })
                }

                level3[i]['IsSelect'] = !level3[i]['IsSelect']
            }
        }
        setTimeout(() => {
            this.setState({ level3: level3 })
        }, 200);
    }

    // to call level third list 
    record(level2, level3, level4) {
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapse_l2_' + level3data.ChapterID} aria-expanded="false" aria-controls={'collapse_l2_' + level3data.ChapterID}>
                                                {/* // checkbox for level3 */}
                                                {
                                                    level3data.IsCheckbox && <input type="checkbox" className="from-left-sp"
                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID)} />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordFour(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    // to call level four list
    recordFour(level3data, level4) {
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">
                                {/* level4data.IsCheckbox && */}
                                <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                    onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID)} />
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }

    onSchemeTypeChange(e, id) {
        console.log('id :', id);
        if (e) {
            e.preventDefault();
        }
        const { countryId } = this.state
        this.setState({ schemeType: id, schemeTypeErr: '', isDoubleStorey: false, doubleStoreyArea: '' })
        this.getFillList(stallSizeActionId, sessionStorage.getItem("sessionExhibitionID"), '', id, countryId, '').then(() => {
            this.setState({ isLoading: false })
        })
    }

    onLengthTypeChange(e) {
        this.setState({ stallAreaId: e.target.value, stallAreaIdErr: '' })
        console.log(e.target.value)
        const { stallAreaList, stallAreaName } = this.state
        for (var i = 0; i < stallAreaList.length; i++) {
            if (stallAreaList[i].Code == e.target.value) {

                console.log('Description : ', stallAreaList[i].Description);
                this.setState({ stallAreaName: stallAreaList[i].Description, area: stallAreaList[i].StallArea })
                this.setState({
                    isDoubleStorey: false,
                    doubleStoreyArea: '',
                    isSSIandTMMAITAMMMA: '',
                })

            }
        }
    }

    onCheckdoubleStorey(e) {
        const { area, minArea, minAreaPercent, isDoubleStorey, doubleStoreyArea } = this.state
        console.log(area, minArea,)
        if (area < minArea) {
            toastError(`Please select Area that is more than or equal to ${minArea} to enable Double Storey`)
        }
        else {
            var tempArea = (area * (minAreaPercent / 100))
            this.setState({ isDoubleStorey: !isDoubleStorey, doubleStoreyArea: tempArea })
        }
    }

    onSSITMMAITAMMAClick() {
        const { isSSIandTMMAITAMMMA, TMMAITAMMA, isSSi, SSIMaxArea, area, isSSIdeleted, isTMMAITAMMAdeleted, isSSifile, isSSifilePath, isSSifileType, TMMAITAMMAfile, TMMAITAMMAfilePath, TMMAITAMMAfileType, is_update } = this.state
        console.log(area)
        if (area != '' && area != null) {

            if (area <= SSIMaxArea) {
                if (isSSIandTMMAITAMMMA == false) {
                    this.setState({ isSSIandTMMAITAMMMA: true, isSSi: true, TMMAITAMMA: (isTMMAITAMMAdeleted) ? '1' : TMMAITAMMA })
                }
                else {
                    this.setState({
                        isSSIandTMMAITAMMMA: false, TMMAITAMMA: (isTMMAITAMMAdeleted) ? '' : TMMAITAMMA, isSSi: false,
                    })
                    if (isSSIdeleted) {
                        this.setState({ isSSifile: '', isSSifilePath: '', isSSifileType: '' })
                    }
                    if (isTMMAITAMMAdeleted) {
                        this.setState({ TMMAITAMMAfile: '', TMMAITAMMAfilePath: '', TMMAITAMMAfileType: '' })
                    }
                    // this.onCrossClickSSI()
                    // this.onCrossClickITMATAMMA()
                }
            } else {
                toastError(`Maximum area allowed for SSI Discount is ${SSIMaxArea}sqm`)
            }
        } else {
            toastError('please select Area')
        }
    }

    onFilePickSSI = async (e, file) => {
        e.preventDefault()
        const { } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFileSSI(file[0]);
                        this.setState({ isSSifile: image })
                    }
                    else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                        image = await this.onPdfSelectSSI(file[0]);
                        this.setState({ isSSifile: image })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    this.setState({ isSSifileType: fileTypeList.length > 0 ? fileTypeList[1] : "", isSSifileErr: '', })
                } else {
                    toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }
    resizeFileSSI = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });
    onPdfSelectSSI = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });
    onCrossClickSSI() {
        this.setState({ isSSifile: "" })
        document.getElementById("path").value = ""
    }

    onChangeDoubleStorey(e) {
        const { area, minAreaPercent, doubleStoreyArea } = this.state
        var newArea = e.target.value
        var maxArea = (area * (minAreaPercent / 100))
        if (area != '' && area != null) {
            if (newArea > maxArea) {
                this.setState({ doubleStoreyArea: '' })
                toastError(`Double storey area cannot be more than ${minAreaPercent}% of selected Area`)
            }
            else {
                this.setState({ doubleStoreyArea: newArea, doubleStoreyAreaErr: '' })
            }
        }
        else {
            toastError("please select area")
        }
    }


    onFilePickITMATAMMA = async (e, file) => {
        e.preventDefault()
        const { } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (file[0]['size'] >= 5 * 1024 * 1024) {
                toastError('File size more than 5mb are not allow.')
            } else {
                var image = '';
                if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFileITMATAMMA(file[0]);
                        this.setState({ TMMAITAMMAfile: image })
                    }
                    else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                        image = await this.onPdfSelectITMATAMMA(file[0]);
                        this.setState({ TMMAITAMMAfile: image })
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    this.setState({ TMMAITAMMAfileType: fileTypeList.length > 0 ? fileTypeList[1] : "", TMMAITAMMAfileErr: '' })
                } else {
                    toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }
    resizeFileITMATAMMA = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });
    onPdfSelectITMATAMMA = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });
    onCrossClickITMATAMMA() {
        this.setState({ TMMAITAMMAfile: "" })
        document.getElementById("path").value = ""
    }


    render() {
        const { isLoading, primaryChapterListData, primaryChapterRadio,
            level2, level3, level4, schemeType, schemeTypeList, countryId,
            stallAreaId, stallAreaList, openSlideRadio, openSlides, exhibitDetailsList,
            openSlidesErr, schemeTypeErr, primaryChapterIdErr, stallAreaIdErr, countryid, exhibitDetailsTableErr, lengthWidthErr, isDataLoad } = this.state
        console.log('primaryChapterRadio : ', primaryChapterRadio)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {/* PAGE */}
                <div className="page">
                    <div className="page-main">
                        {isDataLoad ? <Header /> : null}
                        {/*app-content open*/}
                        <div className="main-content app-content mt-0">
                            <div className="side-app">
                                {/* <div className="main-container form-start container-fluid p-0">
<div className="row ">
<div className="step-name">
<p>Exhibitor Registration</p>
</div>
</div>
<StepHeader />
</div> */}
                                <div className="page-height">
                                    <div className="main-container container-fluid pt-2 pb-2 p-3 second-form">

                                        <div className="asc" style={{ border: "1px solid #ddd" }}>
                                            <div
                                                className="card-header p-2 mb-2"
                                                style={{ padding: "9px!important" }}
                                            >
                                                <h3 className="card-title" style={{ fontSize: 14 }}>
                                                    Select Primary Chapter
                                                </h3>
                                                {<span className='text-danger text-start text-left d-block mt-1' style={{ marginLeft: '10px' }}>{primaryChapterIdErr}</span>}
                                            </div>

                                            <div
                                                className=""
                                                style={{ paddingLeft: 10, paddingRight: 10 }}
                                            >

                                                {
                                                    primaryChapterListData.length > 0 && primaryChapterListData.map((data, i) => (
                                                        <>
                                                            {/* {data.ChapterName.trim() != 'ANY OTHER' && */}
                                                            <div className="form-row" key={i}>
                                                                <label key={i}>
                                                                    <input type="radio" key={data.ChapterID}
                                                                        name={i}
                                                                        // select= {data.IsSelect}
                                                                        checked={data.isChecked}
                                                                        // value={data.isChecked}
                                                                        onClick={e => this.handleClickSelectPrimaryChapter(data, data.ChapterID)}
                                                                    />
                                                                    {data.ChapterName}
                                                                </label>
                                                            </div>
                                                            {/* }  */}
                                                        </>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-lg-12 col-md-12 mt-4">
                                                <div className="asc" style={{ border: "1px solid #ddd" }}>
                                                    <div
                                                        className="card-header p-2 mb-2"
                                                        style={{ padding: "9px!important" }}
                                                    >
                                                        <h3 className="card-title" style={{ fontSize: 14 }}>
                                                            Select Below Product
                                                        </h3>
                                                    </div>
                                                    <div className="" style={{ paddingLeft: 10 }}>
                                                        <form className="needs-validation pb-2" noValidate="">
                                                            <div className="form-row">

                                                                <div class="accordion" id="accordionExample">
                                                                    {
                                                                        level2.length > 0 && level2.map(level2Data => (
                                                                            <>
                                                                                {
                                                                                    // level2Data.ParentChapterID == primaryChapterRadio &&
                                                                                    <div class="accordion-item">
                                                                                        <h2 class="accordion-header" id={'heading_l1_' + level2Data.ChapterID}>
                                                                                            <button class={level2Data.isHighlight ? "accordion-button collapsed selected-chapter" : "accordion-button collapsed"}
                                                                                                type="button" data-bs-toggle="collapse"
                                                                                                data-bs-target={'#collapse_l1_' + level2Data.ChapterID} aria-expanded="false"
                                                                                                aria-controls={'collapse_l1_' + level2Data.ChapterID}>
                                                                                                {/* // checkbox for level2 */}
                                                                                                {
                                                                                                    level2Data.IsCheckbox &&
                                                                                                    <input type="checkbox" className="from-left-sp" name="" key={level2Data.ChapterID}
                                                                                                        checked={level2Data.IsSelect}
                                                                                                        onClick={e => this.onCheckLevel2(e, level2Data.ChapterID)} />
                                                                                                }
                                                                                                {level2Data.ChapterName} </button>
                                                                                        </h2>
                                                                                        {this.record(level2Data, level3, level4)}
                                                                                    </div>
                                                                                }

                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>

                                                                <div className="card card-cus card-cus-2 mt-2 mb-2 cus-width">
                                                                    <div
                                                                        className="card-header cus-hed"

                                                                    >
                                                                        <strong>Scheme Type <span className="text-red">*</span></strong>
                                                                    </div>
                                                                    <div className="form-row mt-1 p-2">

                                                                        {
                                                                            schemeTypeList.length > 0 && schemeTypeList.map((schemeTypeListData) => (
                                                                                <div className="col-xl-2">
                                                                                    <label>
                                                                                        <input type="radio"
                                                                                            id={'schemeType'}
                                                                                            name={'schemeType'}
                                                                                            radioGroup={schemeType == schemeTypeListData.Code && true}
                                                                                            select={schemeType == schemeTypeListData.Code && true}
                                                                                            checked={schemeType == schemeTypeListData.Code && true}
                                                                                            value={schemeType}
                                                                                            onChange={e => this.onSchemeTypeChange(e, schemeTypeListData.Code)}
                                                                                        />
                                                                                        {schemeTypeListData.Description}
                                                                                    </label>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        {<span className='text-danger '>{schemeTypeErr}</span>}

                                                                    </div>
                                                                    <div
                                                                        className="card-header cus-hed"
                                                                        style={{

                                                                            borderTop: "1px solid #eee"
                                                                        }}
                                                                    >
                                                                        <strong>Stall Area <span className="text-red">*</span></strong>
                                                                    </div>
                                                                    <div className="form-row mt-1 p-2">
                                                                        <div className="col-xl-3">
                                                                            <label>Length * width in Meters</label>
                                                                            <select
                                                                                className="form-control select2 select-hidden-accessible pl-0"
                                                                                id="roledb"
                                                                                value={stallAreaId}
                                                                                onChange={e => this.onLengthTypeChange(e)}
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {
                                                                                    stallAreaList.length > 0 && stallAreaList.map(stallAreaData => (
                                                                                        <option value={stallAreaData.Code} key={stallAreaData.Code}>{stallAreaData.Description}</option>
                                                                                    ))
                                                                                }


                                                                            </select>
                                                                            {<span className='text-danger text-start text-left d-block mt-1'>{stallAreaIdErr}</span>}


                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="card-header  cus-hed mt-2"
                                                                        style={{

                                                                            borderTop: "1px solid #eee",
                                                                            position: "relative"
                                                                        }}
                                                                    >
                                                                        <strong>
                                                                            Open Sides (Please Tick the no required) <span className="text-red">*</span>
                                                                        </strong>
                                                                        <p>
                                                                            <a

                                                                                href={`files/${sessionStorage.getItem("sessionExhibitionID")}/Open-side-Charges.pdf`}
                                                                                target="_blank"
                                                                                rel="noreferrer"><span class="tool" style={{ color: '#0056ff' }}>Please Refer Rule </span>
                                                                            </a></p>
                                                                    </div>
                                                                    <div className="form-row mt-1 p-2">
                                                                        <div className="col-xl-2">
                                                                            <label>
                                                                                <input type="radio" name="openSlides" value={openSlides}
                                                                                    checked={openSlides == '1'}
                                                                                    onClick={e => this.setState({ openSlides: '1', openSlidesErr: '' })} /> 1 Open Side
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-xl-2">
                                                                            <label>
                                                                                <input type="radio" name="openSlides" value={openSlides}
                                                                                    checked={openSlides == '2'}
                                                                                    onClick={e => this.setState({ openSlides: '2', openSlidesErr: '' })}
                                                                                /> 2 Open Side
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-xl-2 mb-3">
                                                                            <label>
                                                                                <input type="radio" name="openSlides" value={openSlides}
                                                                                    checked={openSlides == '3'}
                                                                                    onClick={e => this.setState({ openSlides: '3', openSlidesErr: '' })} /> 3 Open Side
                                                                            </label>
                                                                        </div>
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{openSlidesErr}</span>}

                                                                    </div>
                                                                    {schemeType != 2 && this.state.isDoubleStoreyExhibition && <>
                                                                        <div
                                                                            className="card-header  cus-hed mt-2"
                                                                            style={{

                                                                                borderTop: "1px solid #eee",
                                                                                position: "relative"
                                                                            }}
                                                                        >
                                                                            <div className="d-flex">
                                                                                <input
                                                                                    className="toggle-box mt-1"
                                                                                    id="identifier-2"
                                                                                    type="checkbox"
                                                                                    style={{ float: "left" }}
                                                                                    checked={this.state.isDoubleStorey}
                                                                                    onClick={(e) => this.onCheckdoubleStorey(e)}
                                                                                />
                                                                                <div >
                                                                                    <label htmlFor="identifier-2" style={{ fontSize: 16 }}>
                                                                                        <strong> Double storey </strong>

                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <p><a
                                                                                href={`files/${sessionStorage.getItem("sessionExhibitionID")}/Double-Storey-Charges.pdf`}
                                                                                target="_blank"
                                                                                rel="noreferrer"><span class="tool" style={{ color: '#0056ff' }}>Please Refer Rule</span></a> </p>
                                                                        </div>
                                                                        {this.state.isDoubleStorey &&
                                                                            <div className="form-row mt-1 p-2">
                                                                                <div className="col-xl-3">
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        // disabled={!this.state.isDoubleStorey}
                                                                                        // maxLength={'10'}
                                                                                        // disabled
                                                                                        value={this.state.doubleStoreyArea}
                                                                                        // onInput={e => this.maxLengthCheck(e, 10)}
                                                                                        onChange={e => this.onChangeDoubleStorey(e)}
                                                                                    />
                                                                                </div>
                                                                                {<span className='text-danger text-start text-left d-block mt-1'>{this.state.doubleStoreyAreaErr}</span>}

                                                                            </div>}
                                                                    </>}
                                                                    {/* this.state.isSSIExhibition */}
                                                                    {countryId == 91 &&
                                                                        this.state.isSSIExhibition && <>  <div
                                                                            className="card-header  cus-hed mt-2"
                                                                            // style={{ }}
                                                                            style={{
                                                                                fontSize: 16,
                                                                                borderTop: "1px solid #eee",
                                                                                position: "relative"
                                                                            }}
                                                                        >
                                                                            <input
                                                                                className="toggle-box mt-1"
                                                                                id="identifier-2"
                                                                                type="checkbox"
                                                                                style={{ float: "left" }}
                                                                                checked={this.state.isSSIandTMMAITAMMMA}
                                                                                onClick={(e) => this.onSSITMMAITAMMAClick()}
                                                                            />
                                                                            <strong >
                                                                                SSI certificate and membership certificate of TMMA/ITAMMA
                                                                            </strong>
                                                                        </div>
                                                                            {this.state.isSSIandTMMAITAMMMA &&

                                                                                <div>

                                                                                    <div
                                                                                        className="card-header  cus-hed mt-2"
                                                                                        style={{

                                                                                            borderTop: "1px solid #eee",
                                                                                            position: "relative"
                                                                                        }}
                                                                                    >
                                                                                        <div className="col-xl-6">
                                                                                            <div className="d-flex">
                                                                                                <input
                                                                                                    className="toggle-box mt-1"
                                                                                                    id="identifier-2"
                                                                                                    type="checkbox"
                                                                                                    style={{ float: "left" }}
                                                                                                    checked={this.state.isSSi}
                                                                                                    onClick={(e) => this.onSSITMMAITAMMAClick()}
                                                                                                />
                                                                                                <div >
                                                                                                    <label htmlFor="identifier-2" style={{ fontSize: 16 }}>
                                                                                                        SSI certificate?

                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-m-3">
                                                                                            {this.state.isSSIdeleted == false ?
                                                                                                <div style={{
                                                                                                    border: '1px solid #ccc',
                                                                                                    padding: '10px',
                                                                                                    display: 'inline-flex',
                                                                                                    alignItems: 'center',
                                                                                                    // marginTop: '10px'
                                                                                                }}>
                                                                                                    {console.log(this.state.isSSifile)}
                                                                                                    <a
                                                                                                        href={this.state.isSSifilePath}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                    >
                                                                                                        <span style={{ marginRight: '10px' }}
                                                                                                        >{this.state.isSSifile}</span>
                                                                                                    </a>
                                                                                                    <i className={`fa fa-times text-danger img-can`}
                                                                                                        onClick={(e) => this.onSSIDocDelete()}
                                                                                                    ></i>
                                                                                                </div> :
                                                                                                <div className="upload-options mt-1">
                                                                                                    <label>
                                                                                                        <input
                                                                                                            type="file"
                                                                                                            id="path"
                                                                                                            className="image-upload"
                                                                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                                                                            onChange={e => this.onFilePickSSI(e, e.target.files)}
                                                                                                        />
                                                                                                        {
                                                                                                            this.state.isSSifile != "" && this.state.isSSifile != null &&
                                                                                                            <i class="fa fa-times text-danger img-can"
                                                                                                                onClick={() => this.onCrossClickSSI()}
                                                                                                            ></i>
                                                                                                        }
                                                                                                    </label>
                                                                                                    <span className='text-danger ' style={{ marginLeft: '7px' }}> File size up to 5MB (.pdf , .jpeg, .png,) </span>
                                                                                                </div>}
                                                                                        </div>
                                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{this.state.isSSifileErr}</span>}

                                                                                    </div>
                                                                                    <div
                                                                                        className="card-header  cus-hed mt-2"
                                                                                        style={{

                                                                                            borderTop: "1px solid #eee",
                                                                                            position: "relative"
                                                                                        }}
                                                                                    >
                                                                                        <div className="col-xl-3">
                                                                                            <label>
                                                                                                <input type="radio" name="ITMATTAMA"
                                                                                                    value={this.state.TMMAITAMMA}
                                                                                                    checked={this.state.TMMAITAMMA == '1'}
                                                                                                    onClick={e => this.setState({ TMMAITAMMA: '1', })}
                                                                                                /> Membership certificate of TMMA
                                                                                            </label>

                                                                                        </div>
                                                                                        <div className="col-xl-3">
                                                                                            <label>
                                                                                                <input type="radio" name="ITMATTAMA"
                                                                                                    value={this.state.TMMAITAMMA}
                                                                                                    checked={this.state.TMMAITAMMA == '2'}
                                                                                                    onClick={e => this.setState({ TMMAITAMMA: '2', })}
                                                                                                /> Membership certificate of ITAMMA
                                                                                            </label>
                                                                                        </div>

                                                                                        <div className="upload-options mt-1">
                                                                                            {this.state.isTMMAITAMMAdeleted == false ?
                                                                                                <div style={{
                                                                                                    border: '1px solid #ccc',
                                                                                                    padding: '10px',
                                                                                                    display: 'inline-flex',
                                                                                                    alignItems: 'center',
                                                                                                    // marginTop: '10px'
                                                                                                }}>
                                                                                                    <a
                                                                                                        href={this.state.TMMAITAMMAfilePath}
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                    >
                                                                                                        <span style={{ marginRight: '10px' }}
                                                                                                        >{this.state.TMMAITAMMAfile}</span>
                                                                                                    </a>
                                                                                                    <i className={`fa fa-times text-danger img-can`}
                                                                                                        onClick={(e) => this.onTMMAITAMMADocDelete()}
                                                                                                    ></i>
                                                                                                </div> : <>
                                                                                                    <label>
                                                                                                        <input
                                                                                                            type="file"
                                                                                                            id="path"
                                                                                                            className="image-upload"
                                                                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                                                                            onChange={e => this.onFilePickITMATAMMA(e, e.target.files)}
                                                                                                        // disabled={isView}
                                                                                                        />
                                                                                                        {
                                                                                                            this.state.TMMAITAMMAfile != "" && this.state.TMMAITAMMAfile != null &&
                                                                                                            <i class="fa fa-times text-danger img-can"
                                                                                                                onClick={() => this.onCrossClickITMATAMMA()}
                                                                                                            ></i>
                                                                                                        }
                                                                                                    </label>
                                                                                                    <span className='text-danger ' style={{ marginLeft: '7px' }}> File size up to 5MB (.pdf , .jpeg, .png,) </span>
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                        {/* </div> */}


                                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{this.state.TMMAITAMMAfileErr}</span>}

                                                                                    </div>
                                                                                </div>}
                                                                        </>}
                                                                </div>
                                                                <div
                                                                    className="form-row col-md-12">
                                                                    <div className="table-responsive m-2">
                                                                        <h6 style={{ float: 'left' }}>Exhibit Details</h6>{<span className='text-danger ml-2' style={{ float: 'left' }}>{lengthWidthErr}</span>}
                                                                        <button className="btn btn-next-bot mb-2" style={{ float: 'right' }} onClick={e => this.onAddManualChapter(e)}>
                                                                            Add Exhibit
                                                                        </button>
                                                                        <table
                                                                            className="table table-bordered"
                                                                            style={{
                                                                                border: "1px solid #d8d8d8 !important"
                                                                            }}
                                                                        >
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Product</th>
                                                                                    <th>Machine Details</th>
                                                                                    <th>HSN Code</th>
                                                                                    <th colspan="3">Machine Dimension (mtr)</th>
                                                                                    {/* <th>Length</th>
                                                                                    <th>Breadth</th>
                                                                                    <th>Height</th> */}
                                                                                    <th>Floor Capacity (Kgs per sq mtr)</th>
                                                                                    <th>Machine Weight (Kg)</th>
                                                                                    <th>Manufacturer Name</th>
                                                                                    <th>Manufacturing Country</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td className="tbody-th-head">Length</td>
                                                                                    <td className="tbody-th-head">Breadth</td>
                                                                                    <td className="tbody-th-head">Height</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                                {
                                                                                    exhibitDetailsList.length > 0 ? exhibitDetailsList.map((data, index) => (

                                                                                        <tr>
                                                                                            {data.ChapterID != 0 ? (
                                                                                                <td>{data.ChapterName}</td>
                                                                                            ) : (
                                                                                                <td><input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    // maxLength={'10'}
                                                                                                    value={data.ChapterName}
                                                                                                    // onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onEnterProductName(e, index)}
                                                                                                /></td>
                                                                                            )}

                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'200'}
                                                                                                    value={data.MachineDetails}
                                                                                                    // onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onMachineDetailsChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>

                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'10'}
                                                                                                    value={data.HSNCode}
                                                                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onEnterHSNCode(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'9'}
                                                                                                    value={data.Length}
                                                                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onLengthChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'9'}
                                                                                                    value={data.Breadth}
                                                                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onBreadthChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'9'}
                                                                                                    value={data.Height}
                                                                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onHeightChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    title=' Note :
                                                                                                    1- Very heavy-duty equipment and machinery are not allowed into the Hall and shall be allocated in the external exhibition space.
                                                                                                    2- If any exhibitor wants to show live machinery, please contact the organizer for special arrangements.'
                                                                                                    className="form-control"
                                                                                                    maxLength={'9'}
                                                                                                    value={data.FloorCapacity}
                                                                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onFloorCapacityChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'9'}
                                                                                                    value={data.MachineWeight}
                                                                                                    onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onMachineWeightChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'200'}
                                                                                                    value={data.ManufacturerName}
                                                                                                    // onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onManufacturerNameChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="form-control"
                                                                                                    maxLength={'100'}
                                                                                                    value={data.ManufacturingCountry}
                                                                                                    // onInput={e => this.maxLengthCheck(e, 10)}
                                                                                                    onChange={e => this.onManufacturingCountryChange(e, data.ChapterID, index)}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )) : <tr >
                                                                                        <td colSpan={10} className="text-center">Record not available</td></tr>
                                                                                }

                                                                            </tbody>

                                                                        </table>
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{exhibitDetailsTableErr}</span>}
                                                                        {this.state.isShowMachineryGuidelines && <>
                                                                            {sessionStorage.getItem("countryId") == 91 ? (
                                                                                <p className="m-0 mt-5">
                                                                                    <strong>Guideline for Machinery Display at {sessionStorage.getItem("exhibitionAddress")}</strong><br />
                                                                                    {this.state.guideLines.length > 0 && this.state.guideLines.map((data, i) => (
                                                                                        <>
                                                                                            {i + 1}- {data.Description}< br />
                                                                                        </>
                                                                                    ))}
                                                                                    {/* 1- Display of machinery is allowed only in static mode.<br />
                                                                                2- Very heavy-duty equipment and machinery are not allowed into the Hall and shall be allocated in the external exhibition space.<br />
                                                                                3- Floor load capacity: The floor space can hold 3 tons per sqm inside the hall.<br />
                                                                                4- Compressed Air and water supply are not available in the halls.<br />
                                                                                5- Loading door dimensions: The length is 9.56 mtr and height is 3.18 mtr.<br /> */}
                                                                                </p>
                                                                            ) : (
                                                                                <p className="m-0 mt-5">
                                                                                    <strong>Guideline for Machinery Display at {sessionStorage.getItem("exhibitionAddress")}</strong><br />
                                                                                    {this.state.guideLines.length > 0 && this.state.guideLines.map((data, i) => (
                                                                                        <>
                                                                                            {i + 1}- {data.Description}< br />
                                                                                        </>
                                                                                    ))}
                                                                                    {/* 1- Display of machinery is allowed only in static mode.<br />
                                                                                2- Very heavy-duty equipment and machinery are not allowed into the Hall and shall be allocated in the external exhibition space.<br />
                                                                                3- Floor load capacity: The floor space can hold 3 tons per sqm inside the hall.<br />
                                                                                4- Compressed Air and water supply are not available in the halls.<br />
                                                                                5- Loading door dimensions: The length is 9.56 mtr and height is 3.18 mtr.<br />
                                                                                <strong>Note: </strong>If any exhibitor wants to show live machinery, please contact the organizer for special arrangements.<br /> */}
                                                                                </p>
                                                                            )}</>}

                                                                        {this.state.isShowChapterNotes && <>
                                                                            {sessionStorage.getItem("countryId") == 91 ? (
                                                                                <p className="m-0 mt-5">
                                                                                    <strong>Note: </strong><br />
                                                                                    {this.state.note.length > 0 && this.state.note.map((data, i) => (
                                                                                        <>
                                                                                            {i + 1}- {data.Description}< br />
                                                                                        </>
                                                                                    ))}
                                                                                </p>
                                                                            ) : (null)}
                                                                        </>}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="mt-1 mb-4">
                                                <button className="btn btn-previous m-1" type="submit" onClick={e => this.onPreviousClick()}>
                                                    {" "}
                                                    Previous{" "}
                                                </button>
                                                <button className="btn btn-next-bot m-1" onClick={e => this.handleStep2Insert()}>
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bottom-height"></div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isDel1Open} onRequestClose={() => this.setState({ isDel1Open: false })}>
                    <div className="modal-dialog custom_modal_dialog" style={{ left: '0px', top: '300px', }}>
                        <div className="modal-content" style={{ marginLeft: '-200px' }}>
                            <div className="modal-header" style={{ padding: "7px 10px", }}>
                                <h5 className="modal-title" id="termsandconLabel">
                                    Confirm Delete
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={(e) => this.setState({ isDel1Open: false })}
                                />
                            </div>
                            <div className="modal-body" >
                                <div className="form-row mt-3">

                                    <div className="col-md-10">
                                        <p class="pop_label">Do you really want to delete this Document?</p>

                                    </div>
                                    <div class="modal-footer p-1">
                                        <div class="form-group text-right mod-fot">
                                            <button type="button" class="btn btn-del-pop"
                                                onClick={(e) => this.handleSSIDelete(e)}
                                            >Yes</button>
                                            <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDel1Open: false })}>No</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </Modal >
                <Modal isOpen={this.state.isDel2Open} onRequestClose={() => this.setState({ isDel2Open: false })}>
                    <div className="modal-dialog custom_modal_dialog" style={{ left: '0px', top: '300px', }}>
                        <div className="modal-content" style={{ marginLeft: '-200px' }}>
                            <div className="modal-header" style={{ padding: "7px 10px" }}>
                                <h5 className="modal-title" id="termsandconLabel">
                                    Confirm Delete
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    onClick={(e) => this.setState({ isDel2Open: false })}

                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body" style={{ padding: "0px 12px 9px 12px" }}>
                                <div className="form-row mt-3">

                                    <div className="col-md-10">
                                        <p class="pop_label">Do you really want to delete this Document?</p>

                                    </div>
                                    <div class="modal-footer p-1">
                                        <div class="form-group text-right mod-fot">
                                            <button type="button" class="btn btn-del-pop"
                                                onClick={(e) => this.handleTMMAITAMMADelete(e)}
                                            >Yes</button>
                                            <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDel2Open: false })}>No</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(connect(null, {
    fill_action, primaryChapterAdd_action, getPreviewDetails_action, getAgentDetails_action, getPendingRegDetails_action, getDoubleStoreySSI_action, deleteSSicertificate_action, deleteTMMAITAMMA_action
})(PrimaryChapter));

