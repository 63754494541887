import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import $, { get } from 'jquery';
import Modal from "react-modal";
import { withRouter } from '../utils/withRouter';

import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
// import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Home } from '@mui/icons-material';

const ExhibitionDrawer = ({ head, userId }) => {
  const history = useNavigate();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false)
  const [loginAccesslist, setLoginAccesslist] = useState([])

  //toggle model for logout.................................
  const toggleModal = () => {
    setIsOpen(!isOpen)
  }

  const loadScripts = () => {
    // This array contains all the files/CDNs 
    // const dynamicScripts = [
    //   'js/jquery.sticky.js',
    //   'js/metisMenu.min.js',
    //   'js/metisMenu-active.js',
    //   'js/main.js'
    // ];

    // for (let i = 0; i < dynamicScripts.length; i++) {
    //   const node = document.createElement('script');
    //   node.src = dynamicScripts[i];
    //   node.type = 'text/javascript';
    //   node.async = false;
    //   document.getElementsByTagName('head')[0].appendChild(node);
    // }
  }
  const logout = () => {
    sessionStorage.clear()
    navigate('/')
  }
  useEffect(() => {
    loadScripts();
    console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAAA", JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'])
    setLoginAccesslist(JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'])
  }, []);

  const onRedirect = (path) => {
    history.push({ pathname: path, state: { 'isSearchClear': true } });
  }

  const [collapsed, setCollapsed] = useState(true);

  const handleToggleSidebar = () => {
    setCollapsed(!collapsed);
  }

  const getModuleAccess = (ModuleID) => {
    const moduleAccess = loginAccesslist.find((item) => item.ModuleID == ModuleID);
    if (moduleAccess) {
      return moduleAccess.ReadAccess;
    } else {
      return false
    }
    return false;
  };

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={e => toggleModal()}>
        <div className="modal-dialog custom_modal_dialog" >
          <div className="modal-content confirm-logout
" style={{ borderRadius: '0px' }}>
            <div class="modal-header">
              <h5 class="modal-title modal_title_text">Confirm Logout</h5>
              {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
              <button type="button " class="close pop-close text-dark" data-dismiss="modal" onClick={e => toggleModal()} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>

            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="pop_label">Do you really want to logout?</h3>
                </div>
              </div>
            </div>

            <div class="row ">
              <div class="col-md-12">
                <div class="form-group text-right mod-fot">
                  <a class="btn save_btn del-pop-save" onClick={logout}> Yes</a>
                  <a class="btn cancel_btn del-pop-cancel" data-dismiss="modal"
                    style={{ marginLeft: '5px' }} onClick={toggleModal}> No</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Modal>
      <div className="sticky">
        <div className="app-sidebar__overlay" data-bs-toggle="sidebar" />
        <div className="app-sidebar ps ps--active-y">
          <div className="side-header top-logo-almnt" style={{ width: '214px' }}>
            <a className="header-brand1" href="index.html">
              <img
                src={`${sessionStorage.getItem('ExhibitionLogoSS')}`}
                className="header-brand-img light-logo"
                alt="logo"
                style={{ width: '40px' }}
              />
              <img
                src={`${sessionStorage.getItem('ExhibitionLogoSS')}`}
                className="header-brand-img light-logo1"
                alt="logo"
                style={{ width: '40px' }}
              />
            </a>
            {/* LOGO */}
          </div>
          <div className="main-sidemenu">
            <div className="slide-left disabled d-none" id="slide-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#7b8191"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
              </svg>
            </div>
            <ul className="side-menu top-space" style={{ marginRight: '0px' }}>
              {getModuleAccess('1') &&
                <li className="slide">
                  <Link to='/dashboard' >
                    <a
                      className="side-menu__item has-link"
                      data-bs-toggle="slide"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-home" aria-hidden="true" /> Dashboard
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {(getModuleAccess('2') || getModuleAccess('3') || getModuleAccess('4') || getModuleAccess('5') || getModuleAccess('6') ||
                getModuleAccess('7')) &&
                <li className="slide" >
                  <a style={{ marginLeft: '0px', paddingLeft: '0px' }}
                    className="nav-link side-menu__item"
                    data-bs-toggle="collapse"
                    href="#accountsCollapse"
                    aria-expanded="false"
                    aria-controls="accountsCollapse"
                  >
                    <span className="side-menu__label">
                      {" "}
                      <i className="fa fa-list-ul" aria-hidden="true" /> Accounts
                    </span>
                  </a>
                  <div className="collapse " id="accountsCollapse">
                    <ul className="flex-column sub-menu" style={{ marginRight: '0px' }}>
                      {getModuleAccess('2') &&
                        <li className="slide">  <Link to='/receiptlist'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Receipt
                            </span>
                          </a></Link>
                        </li>
                      }
                      {getModuleAccess('2') &&
                        <li className="slide">  <Link to='/agentReceiptList'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Agent Receipt
                            </span>
                          </a></Link>
                        </li>
                      }
                      {getModuleAccess('3') &&
                        <li className="slide">  <Link to='/proformalist'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Proforma Invoice list
                            </span>
                          </a></Link>
                        </li>
                      }
                      {getModuleAccess('4') &&
                        <li className="slide">  <Link to='/reminderEmails'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Reminder E-Mail
                            </span>
                          </a></Link>
                        </li>
                      }
                      {getModuleAccess('5') &&
                        <li className="slide">  <Link to='/ledgerList'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Ledger
                            </span>
                          </a></Link>
                        </li>
                      }
                      {getModuleAccess('6') &&
                        <li className="slide">  <Link to='/taxinvoicelist'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Tax Invoice List
                            </span>
                          </a></Link>
                        </li>
                      }
                      {getModuleAccess('7') &&
                        <li className="slide">  <Link to='/taxinvoiceexhibitors'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Exhibitors
                            </span>
                          </a></Link>
                        </li>
                      }
                      {getModuleAccess('29') &&
                        <li className="slide">  <Link to='/accountTally'>
                          <a className="side-menu__item"
                          >
                            <span className="side-menu__label">
                              {" "}
                              <i className="fa fa-list-ul" aria-hidden="true" /> Tally
                            </span>
                          </a></Link>
                        </li>
                      }
                    </ul>
                  </div>
                </li>
              }
              {getModuleAccess('8') &&
                <li className="slide">
                  <Link to='/agentlist' >
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Agent Entry
                      </span>
                    </a>
                  </Link>

                </li>
              }
              {
                getModuleAccess('21') &&
                <li className="slide">
                  <Link to={'/features'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        <i className="fa fa-list-ul" aria-hidden="true" /> Features
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {getModuleAccess('9') &&
                <li className="slide">
                  <Link to={'/exhibitorlist'} >
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Exhibitors
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {getModuleAccess('10') &&
                <li className="slide">
                  <Link to={'/visitorslist'} >
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Visitors
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {getModuleAccess('11') &&
                <li className="slide">
                  <Link to={'/manualformsummary'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Manual Forms Summary
                      </span>
                    </a>
                  </Link>
                </li>
              }

              {(getModuleAccess('12')) &&
                <li className="slide" >
                  <a style={{ marginLeft: '0px', paddingLeft: '0px' }}
                    className="nav-link side-menu__item"
                    data-bs-toggle="collapse"
                    href="#masters3"
                    aria-expanded="false"
                    aria-controls="masters3"
                  >
                    <span className="side-menu__label">
                      {" "}
                      <i className="fa fa-list-ul" aria-hidden="true" /> Manual Forms
                    </span>
                  </a>
                  <div className="collapse" id="masters3">
                    <ul className="flex-column sub-menu" style={{ marginRight: '0px' }}>
                      {getModuleAccess('12') &&
                        <>
                          <li className="slide">
                            <Link to={'/visainvitation'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> VA-Exhibitors
                                </span>
                              </a>
                            </Link>
                          </li>
                          <li className="slide">
                            <Link to={'/visitorVisaInvitation'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> VA-Visitors
                                </span>
                              </a>
                            </Link>
                          </li>
                          {/* <li className="slide">
                            <Link to={'/fasciaadmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Fascia Approval
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          <li className="slide">
                            <Link to={'/exhibitorBadgesAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Exhibitor Badges Approval
                                </span>
                              </a>
                            </Link>
                          </li>
                          <li className="slide">
                            <Link to={'/edirectoryAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> E-Directory Approval
                                </span>
                              </a>
                            </Link>
                          </li>
                          <li className="slide">
                            <Link to={'/stallDesign_admin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Stall Design Approval
                                </span>
                              </a>
                            </Link>
                          </li>
                          {/* <li className="slide">
                            <Link to={'/powerConnectionAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Power Connection
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          <li className="slide">
                            <Link to={'/powerConnectionAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Power Connection <br /> Approval
                                </span>
                              </a>
                            </Link>
                          </li>
                          <li className="slide">
                            <Link to={'/waterConnectionAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Water Connection
                                </span>
                              </a>
                            </Link>
                          </li>
                          <li className="slide">
                            <Link to={'/airConnectionApplicationAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Air Connection Application
                                </span>
                              </a>
                            </Link>
                          </li>
                          {/* <li className="slide">
                            <Link to={'/exhibitorContractorBadgeAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Exhibitor Contractor <br></br> Badge Approval
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/carPassesApplicationAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Car Passes Application
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/liquorLicenseAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Liquor License
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/leadRetrievalConnectionApplicationAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Lead Retrieval Connection <br /> Application
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          <li className="slide">
                            <Link to={'/meetingRoomRequestFormAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> meeting Room Request <br /> Form
                                </span>
                              </a>
                            </Link>
                          </li>
                          {/* <li className="slide">
                            <Link to={'/delegateMeetingAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Delegate Meeting
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/visitorMeetingApplicationAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Visitor Meeting Application
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/internetConnectionAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Internet Connection
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/housekeepingConnectionApplicationAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Housekeeping Connection <br /> Application
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/securityApplicationAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Security Application
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/cateringServicesAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Catering Services
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          {/* <li className="slide">
                            <Link to={'/hostAndHostessAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Host And Hostess
                                </span>
                              </a>
                            </Link>
                          </li> */}
                          <li className="slide">
                            <Link to={'/brandingOpportunityAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Branding Opportunity And <br></br> Advertising Form
                                </span>
                              </a>
                            </Link>
                          </li>
                          {/* <li className="slide">
                            <Link to={'/rentalAudioAndVedioAdmin'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Rental Audio And Vedio <br></br> Equipment Form
                                </span>
                              </a>
                            </Link>
                          </li> */}
                        </>
                      }
                    </ul>
                  </div>
                </li>
              }
              {/* {getModuleAccess('13') &&
                <li className="slide">
                  <Link to={'/visitorVisaInvitation'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> VA-Visitors
                      </span>
                    </a>
                  </Link>
                </li>
              } */}
              {getModuleAccess('14') &&
                <li className="slide">
                  <Link to={'/addcircular'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Circulars
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {
                getModuleAccess('24') &&
                <li className="slide">
                  <Link to={'/addVisitorCircular'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" />Visitors Circulars
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {getModuleAccess('15') &&
                <li className="slide">
                  <Link to={'/reportList'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Reports
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {
                getModuleAccess('27') &&
                <li className="slide" >
                  <a style={{ marginLeft: '0px', paddingLeft: '0px' }}
                    className="nav-link side-menu__item"
                    data-bs-toggle="collapse"
                    href="#masterCollapse"
                    aria-expanded="false"
                    aria-controls="masterCollapse"
                  >
                    <span className="side-menu__label">
                      {" "}
                      <i className="fa fa-list-ul" aria-hidden="true" /> Master
                    </span>
                  </a>
                  <div className="collapse " id="masterCollapse">
                    <ul className="flex-column sub-menu" style={{ marginRight: '0px' }}>
                      <li className="slide">  <Link to='/exhibitionMasterList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Exhibition
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/roleMasterList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Role
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/chapterList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Chapters
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/hallMasterList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Hall
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/stallMasterList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Stall
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/bankDetailsList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Bank Details
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/gstList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> GST
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/onlinePresenceList'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Online Presence
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/industrySegmentMaster'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Industry Segment
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">  <Link to='/purposeofVisitMaster'>
                        <a className="side-menu__item"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Purpose of Visit
                          </span>
                        </a></Link>
                      </li>
                      <li className="slide">
                        <a style={{ marginLeft: '0px', paddingLeft: '0px' }}
                          className="nav-link side-menu__item"
                          data-bs-toggle="collapse"
                          href="#manualFormsMasterCollapse"
                          aria-expanded="false"
                          aria-controls="masterCollapse"
                        >
                          <span className="side-menu__label">
                            {" "}
                            <i className="fa fa-list-ul" aria-hidden="true" /> Manual forms Master
                          </span>
                        </a>
                        <div className="collapse " id="manualFormsMasterCollapse">
                          <ul className="flex-column sub-menu" style={{ marginRight: '0px' }}>
                            <li className="slide">  <Link to='/liquorlicenseMaster'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Liquor License
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/leadRetrivalConnectionMaster'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Lead Retrival connection
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/rentalAudioVideoMaster'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Rental Audio & Video
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/hostAndHostessMaster'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Host and Hostess
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/visitorMeetingMaster'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Visitor Meeting
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/cateringServicesMasterList'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Catering Services
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/housekeepingApplicationMasterList'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Housekeeping Application
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/internetConnectionMasterList'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Internet Connection
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/securityApplicationMasterList'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Security Application
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/delegateMeetingMasterList'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Delegate Meeting
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/brandingOpportunityMaster'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Branding Opportunity
                                </span>
                              </a></Link>
                            </li>
                            <li className="slide">  <Link to='/meetingRoomMasterList'>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Meeting Room
                                </span>
                              </a></Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>}
              {/* {getModuleAccess('16') &&
                <li className="slide">
                  <Link to={'/fasciaadmin'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Fascia Approval
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {getModuleAccess('17') &&
                <li className="slide">
                  <Link to={'/exhibitorBadgesAdmin'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Exhibitor Badges Approval
                      </span>
                    </a>
                  </Link>
                </li>
              } */}
              {getModuleAccess('20') &&
                <li className="slide">
                  <Link to={'/manageArea'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Manage Area
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {(getModuleAccess('23')) &&
                <li className="slide" >
                  <a style={{ marginLeft: '0px', paddingLeft: '0px' }}
                    className="nav-link side-menu__item"
                    data-bs-toggle="collapse"
                    href="#masters2"
                    aria-expanded="false"
                    aria-controls="masters2"
                  >
                    <span className="side-menu__label">
                      {" "}
                      <i className="fa fa-list-ul" aria-hidden="true" /> Vendor
                    </span>
                  </a>
                  <div className="collapse " id="masters2">
                    <ul className="flex-column sub-menu" style={{ marginRight: '0px' }}>
                      {getModuleAccess('23') &&
                        <>
                          <li className="slide">
                            <Link to={'/VendorRegistrationList'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Vendor Registration
                                </span>
                              </a>
                            </Link>
                          </li>
                          <li className="slide">
                            <Link to={'/VendorAllocationList'}>
                              <a className="side-menu__item"
                              >
                                <span className="side-menu__label">
                                  {" "}
                                  <i className="fa fa-list-ul" aria-hidden="true" /> Vendor Allocation
                                </span>
                              </a>
                            </Link>
                          </li>
                        </>
                      }
                    </ul>
                  </div>
                </li>
              }
              {/* {getModuleAccess('18') &&
                <li className="slide">
                  <Link to={'/edirectoryAdmin'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> E-Directory Approval
                      </span>
                    </a>
                  </Link>
                </li>
              } */}
              {
                getModuleAccess('28') &&
                <li className="slide">
                  <Link to={'/userList'}>
                    <a className="side-menu__item"
                    >
                      <span className="side-menu__label">
                        {" "}
                        <i className="fa fa-list-ul" aria-hidden="true" /> Users
                      </span>
                    </a>
                  </Link>
                </li>
              }
              {/* <li className="slide">
                <Link to={'/powerreqadmin'}>
                  <a className="side-menu__item"
                  >
                    <span className="side-menu__label">
                      {" "}
                      <i className="fa fa-list-ul" aria-hidden="true" /> Power Connection Approval
                    </span>
                  </a>
                </Link>
              </li> */}

              {/* <li className="slide" style={{cursor:'pointer'}}   data-toggle="modal" data-target="#logout" onClick={toggleModal}>
                  <a className="side-menu__item"
                   >
                    <span className="side-menu__label">
                      {" "}
                      <i className="fa fa-list-ul" aria-hidden="true" /> Log Out

                      
                    </span>
                  </a>
              </li> */}

              {/*  <li class="slide">
                          <a class="side-menu__item has-link" data-bs-toggle="slide" href="#"><i class="side-menu__icon fe fe-activity "></i><span class="side-menu__label">Survey</span></a>
                      </li> */}
            </ul>
            <div className="slide-right" id="slide-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#7b8191"
                width={24}
                height={24}
                viewBox="0 0 24 24"
              >
                <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
              </svg>
            </div>
          </div>

          <div className="ps__rail-x" style={{ left: '0', bottom: '0' }}>
            <div className="ps__thumb-x" tabIndex={0} style={{ left: '0', width: '0' }} />
          </div>
          <div className="ps__rail-y" style={{ top: '0', right: '0', height: '625px' }}>
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: '0', height: '554px' }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default ExhibitionDrawer;