import { Component } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "../../../utils/Loader";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../utils/withRouter";
import { getManualForms_action } from "../../../actions/exhibitor_module_actions/manual_Forms_actions/manual_forms_action"
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { countryActionId, toastError, toastSuccess, exhibitorBadgeDaysActionId } from "../../../utils/constants";
import $ from 'jquery'
class ManualForms extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        //States
        this.state = {
            isLoading: false,
            List: [],

        }
        this.handleAddFascia = this.handleAddFascia.bind(this)
        this.handleUpdateFascia = this.handleUpdateFascia.bind(this)
        this.handleExhibitorBadgesList = this.handleExhibitorBadgesList.bind(this)
        this.handleExhibitorBadgesAdd = this.handleExhibitorBadgesAdd.bind(this)
        this.handleVisaApplicationList = this.handleVisaApplicationList.bind(this)
        this.handleExhibitDetials = this.handleExhibitDetials.bind(this)
        this.handlePowerConnection = this.handlePowerConnection.bind(this)
        this.handleKeywordsInformation = this.handleKeywordsInformation.bind(this)

        this.getManualFormsList = this.getManualFormsList.bind(this)
        this.handleOpenForm = this.handleOpenForm.bind(this)
        this.handleEDirectoryList = this.handleEDirectoryList.bind(this)
        this.handleStallDesign = this.handleStallDesign.bind(this)
        this.handleAdditionalFurniture = this.handleAdditionalFurniture.bind(this)
        this.handleStallPossessionForm = this.handleStallPossessionForm.bind(this)
        this.handleWater = this.handleWater.bind(this)
        this.handleFreightForward = this.handleFreightForward.bind(this)
    }

    componentDidMount() {
        this.getManualFormsList()
        this.loadscript()
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }


    handleOpenForm(ID, deadline, StatusID) {
        console.log(ID)
        if (ID == 1) {
            this.handleAddFascia(deadline, StatusID)
        }
        if (ID == 2) {
            this.handleExhibitorBadgesList(deadline, StatusID)
        }
        if (ID == 3) {
            this.handleExhibitDetials(deadline, StatusID)
        }
        if (ID == 4) {
            this.handleVisaApplicationList(deadline, StatusID)
        }
        if (ID == 5) {
            this.handleEDirectoryList(deadline, StatusID)
        }
        if (ID == 6) {
            this.handleKeywordsInformation(deadline, StatusID)
        }
        if (ID == 7) {
            this.handleStallDesign(deadline, StatusID)
        }
        if (ID == 8) {
            this.handleAdditionalFurniture(deadline, StatusID)
        }
        if (ID == 9) {
            this.handleStallPossessionForm(deadline, StatusID)
        }
        if (ID == 10) {
            this.handlePowerConnection(deadline, StatusID)
        }
        if (ID == 11) {
            this.handleFreightForward(deadline, StatusID)
        }
        if (ID == 12) {
            this.handleWater(deadline, StatusID)
        }
        if (ID == 13) {
            this.handleExhibitorContractorBadgeApplication(deadline, StatusID)
        }
        if (ID == 14) {
            this.handleCarPassesApplication(deadline, StatusID)
        }
        if (ID == 15) {
            this.handleLiquareLicense(deadline, StatusID)
        }
        if (ID == 16) {
            this.handleLeadRetrievalConnectionApplication(deadline, StatusID)
        }
        if (ID == 17) {
            this.handleMeetingRoomRequestForm(deadline, StatusID)
        }
        if (ID == 18) {
            this.handleDelegateMeeting(deadline, StatusID)
        }
        if (ID == 19) {
            this.handleVisitorMeetingApplication(deadline, StatusID)
        }
        if (ID == 20) {
            this.handleInternetConnection(deadline, StatusID)
        }
        if (ID == 21) {
            this.handleHousekeepingConnection(deadline, StatusID)
        }
        if (ID == 22) {
            this.handleSecurityApplication(deadline, StatusID)
        }
        if (ID == 23) {
            this.handleCateringServices(deadline, StatusID)
        }
        if (ID == 24) {
            this.handleHostHostessRequirements(deadline, StatusID)
        }
        if (ID == 25) {
            this.handleBrandingApportunityAndAdvertisement(deadline, StatusID)
        }
        if (ID == 26) {
            this.handleRentalAudioAndVedioEquipment(deadline, StatusID)
        }
        if (ID == 28) {
            this.handleAirConnectionApplication(deadline, StatusID)
        }
    }

    handleAddFascia(deadline, StatusID) {
        this.props.navigate('/fascia-form', { state: { is_add: true, Deadline: deadline, StatusID: StatusID } })
    }

    handleUpdateFascia(deadline, StatusID) {
        this.props.navigate('/fascia-form', { state: { is_update: true } })
    }

    handleExhibitorBadgesList(deadline, StatusID) {
        this.props.navigate('/exhibitorbadge', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handleExhibitorBadgesAdd(deadline, StatusID) {
        this.props.navigate('/exhibitorbadge', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handleVisaApplicationList(deadline, StatusID) {
        this.props.navigate('/visaApplication', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handleVisaApplicationAdd(deadline, StatusID) {
        this.props.navigate('/visaApplicationAU', { state: { is_add: true, } })
    }

    handleExhibitDetials(deadline, StatusID) {
        this.props.navigate('/exhibitorDetails', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handlePowerConnection(deadline, StatusID) {
        this.props.navigate('/powerConnection', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handleKeywordsInformation(deadline, StatusID) {
        this.props.navigate('/keywordsInformation', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleEDirectoryList(deadline, StatusID) {
        this.props.navigate('/edirectoryList', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleStallDesign(deadline, StatusID) {
        this.props.navigate('/stallDesign', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleAdditionalFurniture(deadline, StatusID) {
        this.props.navigate('/aditionalfurniture', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleStallPossessionForm(deadline, StatusID) {
        this.props.navigate('/stallpossesion', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handleCarPassesApplication(deadline, StatusID) {
        this.props.navigate('/carPassesApplication', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handleExhibitorContractorBadgeApplication(deadline, StatusID) {
        this.props.navigate('/exhibitorContractorBadgeApplication', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleLiquareLicense(deadline, StatusID) {
        this.props.navigate('/liquareLicense', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleLeadRetrievalConnectionApplication(deadline, StatusID) {
        this.props.navigate('/leadRetrievalConnectionApplication', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleDelegateMeeting(deadline, StatusID) {
        this.props.navigate('/delegateMeeting', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleVisitorMeetingApplication(deadline, StatusID) {
        this.props.navigate('/visitorMeetingApplication', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleMeetingRoomRequestForm(deadline, StatusID) {
        this.props.navigate('/meetingRoomRequestForm', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleInternetConnection(deadline, StatusID) {
        this.props.navigate('/internetConnection', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleHousekeepingConnection(deadline, StatusID) {
        this.props.navigate('/housekeepingConnection', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleSecurityApplication(deadline, StatusID) {
        this.props.navigate('/securityApplication', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleCateringServices(deadline, StatusID) {
        this.props.navigate('/cateringServices', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleHostHostessRequirements(deadline, StatusID) {
        this.props.navigate('/hostHostessRequirements', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleBrandingApportunityAndAdvertisement(deadline, StatusID) {
        this.props.navigate('/brandingApportunityAndAdvertisement', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleRentalAudioAndVedioEquipment(deadline, StatusID) {
        this.props.navigate('/rentalAudioAndVedioEquipment', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleAirConnectionApplication(deadline, StatusID) {
        this.props.navigate('/airConnectionApplication', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    handleFreightForward(deadline, StatusID) {
        this.props.navigate('/freightForward', { state: { Deadline: deadline, StatusID: StatusID } })
    }
    handleWater(deadline, StatusID) {
        this.props.navigate('/water', { state: { Deadline: deadline, StatusID: StatusID } })
    }

    getManualFormsList() {
        this.setState({ isLoading: true })
        this.props.getManualForms_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log('getManualForms_action', data)
                    if (data.data.length > 0) {
                        var dataList1 = []
                        var dataList2 = []
                        var dataList3 = []
                        var dataList4 = []
                        var dataList5 = []
                        var dataList6 = []
                        var dataListFinal = []
                        var j = 0.1;
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i]['FormType'] == 'MANDATORY FORM (FORMS TO BE FILL BY EXHIBITOR)') {
                                data.data[i]['SrNo'] = (1.0 + j).toFixed(1)
                                dataList1.push(data.data[i])
                            }
                            var j = j + 0.1;
                        }
                        // console.log('dataList1 : ', dataList1);
                        var k = 0.1;
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i]['FormType'] == 'UTILITIES REQUIREMENTS ( WATER, POWER AND AIR COMPRESSOR)') {
                                data.data[i]['SrNo'] = (2.0 + k).toFixed(1)
                                dataList2.push(data.data[i])
                                k = k + 0.1;
                            }
                        }
                        var l = 0.1;
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i]['FormType'] == 'BRANDING OPPORTUNITY & ADVERTISEMENT') {
                                data.data[i]['SrNo'] = (3.0 + l).toFixed(1)
                                dataList3.push(data.data[i])
                                l = l + 0.1;
                            }
                        }
                        var m = 0.1;
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i]['FormType'] == 'KEYWORDS') {
                                data.data[i]['SrNo'] = (4.0 + m).toFixed(1)
                                dataList4.push(data.data[i])
                                m = m + 0.1;
                            }
                        }
                        var n = 0.1;
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i]['FormType'] == 'OPTIONAL FORMS') {
                                data.data[i]['SrNo'] = (5.0 + n).toFixed(1)
                                dataList5.push(data.data[i])
                                n = n + 0.1;
                            }
                        }
                        var o = 0.1;
                        for (var i = 0; i < data.data.length; i++) {
                            if (data.data[i]['FormType'] == 'ONSITE SERVICES FOR EXHIBITOR') {
                                data.data[i]['SrNo'] = (6.0 + o).toFixed(1)
                                dataList6.push(data.data[i])
                                o = o + 0.1;
                            }
                        }
                        console.log('dataList2 : ', dataList2);
                        var mapData1 = {
                            Deadline: "",
                            ExhregistrationID: data.data[0]['ExhregistrationID'],
                            FormType: "",
                            ManualFormID: 0,
                            Name: "MANDATORY FORM (FORMS TO BE FILL BY EXHIBITOR)".toUpperCase(),
                            SrNo: 1,
                            Status: "",
                            StatusID: 0,
                            isHeader: true
                        }
                        dataListFinal.push(mapData1)
                        for (var i = 0; i < dataList1.length; i++) {
                            dataListFinal.push(dataList1[i])
                        }
                        var mapData2 = {
                            Deadline: "",
                            ExhregistrationID: data.data[0]['ExhregistrationID'],
                            FormType: "",
                            ManualFormID: 0,
                            Name: "UTILITIES REQUIREMENTS ( WATER, POWER AND AIR COMPRESSOR)".toUpperCase(),
                            SrNo: 2,
                            Status: "",
                            StatusID: 0,
                            isHeader: true
                        }
                        dataListFinal.push(mapData2)
                        for (var i = 0; i < dataList2.length; i++) {
                            dataListFinal.push(dataList2[i])
                        }
                        var mapData3 = {
                            Deadline: "",
                            ExhregistrationID: data.data[0]['ExhregistrationID'],
                            FormType: "",
                            ManualFormID: 0,
                            Name: "BRANDING OPPORTUNITY & ADVERTISEMENT".toUpperCase(),
                            SrNo: 3,
                            Status: "",
                            StatusID: 0,
                            isHeader: true
                        }
                        dataListFinal.push(mapData3)
                        for (var i = 0; i < dataList3.length; i++) {
                            dataListFinal.push(dataList3[i])
                        }
                        var mapData4 = {
                            Deadline: "",
                            ExhregistrationID: data.data[0]['ExhregistrationID'],
                            FormType: "",
                            ManualFormID: 0,
                            Name: "KEYWORDS".toUpperCase(),
                            SrNo: 4,
                            Status: "",
                            StatusID: 0,
                            isHeader: true
                        }
                        dataListFinal.push(mapData4)
                        for (var i = 0; i < dataList4.length; i++) {
                            dataListFinal.push(dataList4[i])
                        }
                        var mapData5 = {
                            Deadline: "",
                            ExhregistrationID: data.data[0]['ExhregistrationID'],
                            FormType: "",
                            ManualFormID: 0,
                            Name: "OPTIONAL FORMS".toUpperCase(),
                            SrNo: 5,
                            Status: "",
                            StatusID: 0,
                            isHeader: true
                        }
                        dataListFinal.push(mapData5)
                        for (var i = 0; i < dataList5.length; i++) {
                            dataListFinal.push(dataList5[i])
                        }
                        var mapData6 = {
                            Deadline: "",
                            ExhregistrationID: data.data[0]['ExhregistrationID'],
                            FormType: "",
                            ManualFormID: 0,
                            Name: "ONSITE SERVICES FOR EXHIBITOR".toUpperCase(),
                            SrNo: 6,
                            Status: "",
                            StatusID: 0,
                            isHeader: true
                        }
                        dataListFinal.push(mapData6)
                        for (var i = 0; i < dataList6.length; i++) {
                            dataListFinal.push(dataList6[i])
                        }
                        console.log('dataListFinal : ', dataListFinal);
                        this.setState({ List: dataListFinal, isLoading: false })
                    }
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        setTimeout(() => {
            console.log(this.state.List)
        }, 200);
    }

    render() {
        const { isLoading, List } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ExhibitorHeader />
                <ExhibitorDrawer />
                {/*app-content open*/}
                <div className="page">
                    <div className="page-main">
                        <div className="main-content with-dr-container">
                            {/* <div className="container-fluid">
                                <div className="row">
                                    <div className="step-name">
                                <p>Exhibitor Dashboard</p>
                            </div>
                                    <ExhibitorTopMenu />
                                </div>
                            </div> */}
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <ExhibitorTopMenu />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card rounded-0 pt-1">
                                        <div className="card-header p-3">
                                            <h3 className="card-title">Exhibitor Manual Forms</h3>
                                            <button
                                                className="btn btn-success p-1"
                                                type="btn"
                                                style={{ float: "right", position: "absolute", right: 15 }}
                                            >
                                                Not Applicable
                                            </button>
                                        </div>
                                        <div className="card-body p-2">
                                            <div className="col-status">
                                                <ul>
                                                    <li>
                                                        <i className="fa fa-file-o" aria-hidden="true" /> Not filled
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-file" aria-hidden="true" /> Submitted
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-check text-success" aria-hidden="true" />{" "}
                                                        Approved
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-times text-danger" aria-hidden="true" />{" "}
                                                        Disapproved
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="table-responsive mt-6">
                                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '60px', textAlign: 'center' }}>Sr No.</th>
                                                            <th >Exhibitor forms</th>
                                                            <th style={{ width: '200px', textAlign: 'center' }} >Form type</th>
                                                            <th style={{ width: '150px', textAlign: 'center' }} className="text-center">Deadline</th>
                                                            <th style={{ width: '150px', textAlign: 'center' }} className="text-center">Form Status</th>
                                                            <th style={{ width: '75px', textAlign: 'center' }} className="text-center">Select</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            List.length > 0 ? (List.map((data, i) => (
                                                                <tr style={{ cursor: "pointer" }} key={Math.random()}
                                                                    onClick={() => this.handleOpenForm(data.ManualFormID, data.Deadline, data.StatusID)} >
                                                                    <td className={data.ManualFormID == 0 ? "fw-bold" : null} style={{ textAlign: 'center' }}>{data.SrNo}</td>
                                                                    <td className={data.ManualFormID == 0 ? "fw-bold" : null}>{data.Name}</td>
                                                                    <td style={{ textAlign: 'center' }}>{data.subFormType}</td>
                                                                    {data.ManualFormID != 0 ? (
                                                                        <td style={{ textAlign: 'center' }} >{new Date(data.Deadline).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
                                                                    ) : (
                                                                        <td style={{ textAlign: 'center' }} >{''}</td>
                                                                    )}

                                                                    {data.Status == 'Not Filled' ?
                                                                        <td style={{ textAlign: 'center' }}><i className="fa fa-file-o" aria-hidden="true" /></td> :
                                                                        data.Status == 'Applied' ?
                                                                            <td style={{ textAlign: 'center' }}> <i className="fa fa-file" aria-hidden="true" /></td> :
                                                                            data.Status == 'Approved' ?
                                                                                <td style={{ textAlign: 'center' }}> <i className="fa fa-check text-success" aria-hidden="true" /></td> :
                                                                                data.Status == 'Disapproved' ?
                                                                                    <td style={{ textAlign: 'center' }}> <i className="fa fa-times text-danger" aria-hidden="true" /></td> : null}
                                                                    {
                                                                        data.ManualFormID != 0 &&
                                                                        <td style={{ textAlign: 'center' }}><input type="checkbox" onClick={() => this.handleOpenForm(data.ManualFormID, data.Deadline, data.StatusID)} className="cus-check" /></td>
                                                                    }
                                                                </tr>
                                                            ))) : <tr><td className="text-center" colSpan={8}>No record found</td></tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            {/* <div className="table-responsive mt-6">
                                                <table className="table border text-nowrap text-md-nowrap table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">Sr No.</th>
                                                            <th>Exhibitor Forms&nbsp;</th>
                                                            <th>Form Type</th>
                                                            <th>Deadline</th>
                                                            <th>Form Status</th>
                                                            <th>Select</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ backgroundColor: "#f5f5f5" }}>
                                                            <td className="text-center">1</td>
                                                            <td>
                                                                <strong>MANDATORY FORM</strong>
                                                            </td>
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.1</td>
                                                            <td>EXHIBITOR BADGES UNDER QUOTA&nbsp;**</td>
                                                            <td>Form</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.2</td>
                                                            <td>ADDITIONAL EXHIBITOR BADGES </td>
                                                            <td>Form</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true" onClick={() => this.handleExhibitorBadgesList()} />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.3</td>
                                                            <td>FASCIA NAME FOR SHELL SCHEME EXHIBITORS&nbsp;</td>
                                                            <td>Form</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true"
                                                                    onClick={() => this.handleUpdateFascia()}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" onChange={() => this.handleAddFascia()} />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.4</td>
                                                            <td>
                                                                STALL DESIGN APPROVAL BY ARCHITECT ( Only Bare space, optional
                                                                for Shell)
                                                            </td>
                                                            <td>Form</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.5</td>
                                                            <td>STALL POSSESSION - INCHARGE APPLICATION</td>
                                                            <td>Form</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.6</td>
                                                            <td>EXHIBIT DETAILS</td>
                                                            <td>Form</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true"
                                                                    onClick={() => this.handleExhibitDetials()}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" onChange={() => this.handleExhibitDetials()} />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">1.7</td>
                                                            <td>INVITATION LETTER FOR VISA APPLICATION</td>
                                                            <td>Form</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true"
                                                                    onClick={() => this.handleVisaApplicationList()}
                                                                />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" onChange={() => this.handleVisaApplicationAdd()} />
                                                            </td>
                                                            <td />
                                                            <td />
                                                        </tr>
                                                        <tr style={{ backgroundColor: "#f5f5f5" }}>
                                                            <td className="text-center">2</td>
                                                            <td>
                                                                <strong>
                                                                    UTILITIES REQUIREMENTS ( WATER, POWER AND AIR COMPRESSOR)
                                                                </strong>
                                                            </td>
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">2.1</td>
                                                            <td>E-CATLOUGE &amp; KEYWORDS</td>
                                                            <td>Not Available</td>
                                                            <td />
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">2.2</td>
                                                            <td>COMPRESSED AIR REQUIREMENT</td>
                                                            <td>Not Available</td>
                                                            <td />
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">2.3</td>
                                                            <td>POWER REQUIREMENT&nbsp;**</td>
                                                            <td>Commercial Form</td>
                                                            <td>30.08.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true" onClick={() => this.handlePowerConnection()} />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr style={{ backgroundColor: "#f5f5f5" }}>
                                                            <td className="text-center">3</td>
                                                            <td>
                                                                <strong>E-CATLOUGE &amp; KEYWORDS</strong>
                                                            </td>
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">3.1</td>
                                                            <td>KEYWORDS</td>
                                                            <td>Form</td>
                                                            <td>30.08.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" onClick={() => this.handleKeywordsInformation()} />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">3.2</td>
                                                            <td>
                                                                EXHIBITION E-CATLOUGE ADDITIONAL COMPANY NAME IN CATLOUGE
                                                            </td>
                                                            <td>Form</td>
                                                            <td>30.08.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr style={{ backgroundColor: "#f5f5f5" }}>
                                                            <td className="text-center">4</td>
                                                            <td>
                                                                <strong>ONSITE SERVICES FOR EXHIBITOR</strong>
                                                            </td>
                                                            <td />
                                                            <td />
                                                            <td />
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.1</td>
                                                            <td>FREIGHT FORWARDERS</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.2</td>
                                                            <td>MEETING ROOM APPLICATION</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.3</td>
                                                            <td>CUSTOMIZE STALL AGENCY</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-times text-danger" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.4</td>
                                                            <td>ADDITIONAL FURNITURE</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.5</td>
                                                            <td>EXHIBITOR CONTRACTOR BADGES</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.6</td>
                                                            <td>LEAD RETRIEVAL APP</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.7</td>
                                                            <td>INTERNET CONNECTION</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.8</td>
                                                            <td>LIQUOR LICENSE</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.9</td>
                                                            <td>CATERING SERVICES</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-times text-danger" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.10</td>
                                                            <td>HOUSEKEEPING</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.11</td>
                                                            <td>SECURITY APPLICATION</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.12</td>
                                                            <td>HOST HOSTESS APPLICATION</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.13</td>
                                                            <td>VISITOR MEETING APPLICATION</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-check text-success" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.14</td>
                                                            <td>CAR PASS APPLICATION</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file" aria-hidden="true" />
                                                            </td>
                                                            <td />
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center">4.15</td>
                                                            <td>DELEGATE MEETING (B2B)</td>
                                                            <td>Submit Online Only</td>
                                                            <td>30.09.2023</td>
                                                            <td className="text-center">
                                                                <i className="fa fa-file-o" aria-hidden="true" />
                                                            </td>
                                                            <td className="text-center">
                                                                <input type="checkbox" className="cus-check" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>

        )
    }
}

export default withRouter(connect(null, { getManualForms_action })(ManualForms));