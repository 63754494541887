import { Component } from "react";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../../../common_components/exhibitor_module_topmenu";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import { Description } from "@mui/icons-material";
import { getFrieghtForwardDetails_action, insertUpdateFrieghtForward_action } from '../../../../../actions/exhibitor_module_actions/manual_Forms_actions/forms_actions/frieghtForward_action/frieghtForward_action'
import { toastError, toastSuccess } from "../../../../../utils/constants";
import DatePicker from 'react-datepicker';
import moment from "moment";
import $ from 'jquery'
import { TRUE } from "sass";
class FreightForward extends Component {

    //Initialize Constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            FrieghtDetailsRowList: [
                {
                    id: 1,
                    Description: '',
                    Height: '',
                    Length: '',
                    Width: '',
                    Weight: '',
                    Quantity: '',
                }
            ],
            IsOnSiteServiceRequired: 1,
            IsHireOfEquipment: 1,
            BuildUpPeriodRowList: [
                {
                    id: 1,
                    DateFrom: '',
                    DateTo: '',
                    ForkliftUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                        }
                    ],
                    CraneUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                            isChecked_4: false,
                        }
                    ],
                    SpecialEquipment: '',
                    DimensionOrWeight: '',
                    Labour: '',
                }
            ],
            DismantlingPeriodRowList: [
                {
                    id: 1,
                    DateFrom: '',
                    DateTo: '',
                    ForkliftUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                        }
                    ],
                    CraneUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                            isChecked_4: false,
                        }
                    ],
                    SpecialEquipment: '',
                    DimensionOrWeight: '',
                    Labour: '',
                }
            ],
            checkboxList: [],
            hallChapterDataList: [],
            companyName: '',
            PersonIncharge: '',
            MobileNo: '',
            Address: '',
            GSTNo: '',
            Telephone: '',
            Is_AgreeErr: '',
            Is_Agree: false,
            TimetableForMoveIn: [
                {
                    id: 1,
                    MoveIn_Out_Date: null,
                    Air: false,
                    CubicMeters: false,
                    TwentyFeetUnit: false,
                    FourtyFeetUnit: false,
                    NoofTrucks: '',
                    isMovein: true
                }
            ],
            TimetableForMoveOut: [
                {
                    id: 1,
                    MoveIn_Out_Date: null,
                    Air: false,
                    CubicMeters: false,
                    TwentyFeetUnit: false,
                    FourtyFeetUnit: false,
                    NoofTrucks: '',
                    isMovein: false
                }
            ],
            DeadlineDate: '',
            StatusID: '',
            sortHallChapterData: [],
            hallChapterEmailIdSend: ''
        }
    }

    componentDidMount() {
        this.loadscript()
        this.getFrieghtForwardDetails()
        this.setState({
            DeadlineDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.Deadline != "" ? new Date(this.props.location.state.Deadline) : '',
            StatusID: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.StatusID != "" ? this.props.location.state.StatusID : '',
        })
    }

    loadscript() {
        $(document).ready(function () {
            $(this).scrollTop(0);
        });
    }

    getFrieghtForwardDetails() {
        const { Remark, Is_Agree, isQuantity, Is_Required } = this.state
        this.setState({ isLoading: true })
        this.props
            .getFrieghtForwardDetails_action()
            .then((data) => {
                if (data.error != 1) {

                    if (data.Data1 != '') {
                        var companyName
                        data.hallChapterData.map((item) => {
                            if (item.FreightForwardersAgencyContactDetailID == data.Data1[0]['FreightForwardersAgencyContactDetailID']) {
                                item.Is_redioChecked = true
                                companyName = item.CompanyName
                                this.setState({ hallChapterEmailIdSend: item.Email })
                            }else{
                                item.Is_redioChecked = 0 
                            }
                        })
                        console.log("data.hallChapterData-->", data.hallChapterData)
                        const filteredArray = []
                        data.hallChapterData.filter(item => {
                            if (item.CompanyName === companyName && item.Is_redioChecked == 0) {
                                filteredArray.push(item.Email)
                            }
                        })
                        console.log("filteredArray-->", filteredArray)
                        this.setState({ sortHallChapterData: filteredArray })
                        this.setState({
                            Remark: data.Data1[0]['Remark'],
                            Is_Agree: data.Data1[0]['isAgree'],
                            IsHireOfEquipment: data.Data1[0]['IsHireOfEquipment'],
                            IsOnSiteServiceRequired: data.Data1[0]['IsOnSiteServiceRequired'],
                        })

                    } else {
                        var companyName
                        data.hallChapterData.map((item, i) => {
                            if (i === 0) {
                                item.Is_redioChecked = 1
                                companyName = item.CompanyName
                                this.setState({ hallChapterEmailIdSend: item.Email })
                            } else {
                                item.Is_redioChecked = 0
                            }
                        })
                        const filteredArray = []
                        data.hallChapterData.filter(item => {
                            if (item.CompanyName === companyName && item.Is_redioChecked == 0) {
                                filteredArray.push(item.Email)
                            }
                        })
                        console.log("filteredArray-->", filteredArray)
                        this.setState({ sortHallChapterData: filteredArray })
                    }

                    if (data.Data2 != '') {
                        data.checkboxList.map((item) => {
                            data.Data2.map((obj, i) => {
                                if (obj.FreightForwardersParticularsID == item.FreightForwardersParticularsID) {
                                    item.Is_cheked = 1
                                }
                            })
                        })
                    } else {
                        data.checkboxList.map(item => {
                            item.Is_cheked = 0
                        })
                    }

                    if (data.Data3 != '') {
                        this.setState({ FrieghtDetailsRowList: data.Data3 })
                    }

                    if (data.Data4 != '') {
                        const list = []
                        data.Data4.map((item, i) => {
                            list.push(
                                {
                                    id: i,
                                    DateFrom: item.FromDate != null ? new Date(item.FromDate) : null,
                                    DateTo: item.ToDate != null ? new Date(item.ToDate) : null,
                                    ForkliftUpTo: [
                                        {
                                            isChecked_1: item.Forklift_3 != null ? item.Forklift_3 : false,
                                            isChecked_2: item.Forklift_5 != null ? item.Forklift_5 : false,
                                            isChecked_3: item.Forklift_10 != null ? item.Forklift_10 : false,
                                        }
                                    ],
                                    CraneUpTo: [
                                        {
                                            isChecked_1: item.Crane_10 != null ? item.Crane_10 : false,
                                            isChecked_2: item.Crane_12 != null ? item.Crane_12 : false,
                                            isChecked_3: item.Crane_15 != null ? item.Crane_15 : false,
                                            isChecked_4: item.Crane_20 != null ? item.Crane_20 : false,
                                        }
                                    ],
                                    SpecialEquipment: item.SpecialHandlingEquipment,
                                    DimensionOrWeight: item.OverSizeCargo,
                                    Labour: item.Labour,
                                }
                            )
                        })
                        this.setState({ BuildUpPeriodRowList: list })
                    }

                    if (data.Data5 != '') {
                        const list = []
                        data.Data5.map((item, i) => {
                            list.push(
                                {
                                    id: i,
                                    DateFrom: item.FromDate != null ? new Date(item.FromDate) : null,
                                    DateTo: item.ToDate != null ? new Date(item.ToDate) : null,
                                    ForkliftUpTo: [
                                        {
                                            isChecked_1: item.Forklift_3 != null ? item.Forklift_3 : false,
                                            isChecked_2: item.Forklift_5 != null ? item.Forklift_5 : false,
                                            isChecked_3: item.Forklift_10 != null ? item.Forklift_10 : false,
                                        }
                                    ],
                                    CraneUpTo: [
                                        {
                                            isChecked_1: item.Crane_10 != null ? item.Crane_10 : false,
                                            isChecked_2: item.Crane_12 != null ? item.Crane_12 : false,
                                            isChecked_3: item.Crane_15 != null ? item.Crane_15 : false,
                                            isChecked_4: item.Crane_20 != null ? item.Crane_20 : false,
                                        }
                                    ],
                                    SpecialEquipment: item.SpecialHandlingEquipment,
                                    DimensionOrWeight: item.OverSizeCargo,
                                    Labour: item.Labour,
                                }
                            )
                        })
                        this.setState({ DismantlingPeriodRowList: list })
                    }

                    if (data.Data6 != '') {

                        const moveInArray = []
                        const moveOutArray = []

                        data.Data6.map((item, i) => {
                            if (item.isMovein) {
                                moveInArray.push(
                                    {
                                        id: i,
                                        MoveIn_Out_Date: item.MoveIn_Out_Date != null ? new Date(item.MoveIn_Out_Date) : null,
                                        Air: item.Air,
                                        CubicMeters: item.CubicMeters,
                                        TwentyFeetUnit: item.TwentyFeetUnit,
                                        FourtyFeetUnit: item.FourtyFeetUnit,
                                        NoofTrucks: item.NoofTrucks,
                                        isMovein: true
                                    }
                                )
                            } else {
                                moveOutArray.push(
                                    {
                                        id: i,
                                        MoveIn_Out_Date: item.MoveIn_Out_Date != null ? new Date(item.MoveIn_Out_Date) : null,
                                        Air: item.Air,
                                        CubicMeters: item.CubicMeters,
                                        TwentyFeetUnit: item.TwentyFeetUnit,
                                        FourtyFeetUnit: item.FourtyFeetUnit,
                                        NoofTrucks: item.NoofTrucks,
                                        isMovein: false
                                    }
                                )
                            }
                        })

                        this.setState({
                            TimetableForMoveIn: moveInArray,
                            TimetableForMoveOut: moveOutArray
                        })
                    }

                    this.setState({
                        isLoading: false,
                        checkboxList: data.checkboxList,
                        hallChapterDataList: data.hallChapterData,
                        companyName: data.exhibitorDetails[0]['CompanyName'],
                        PersonIncharge: data.exhibitorDetails[0]['PersonIncharge'],
                        MobileNo: data.exhibitorDetails[0]['MobileNo'],
                        Address: data.exhibitorDetails[0]['Address1'],
                        GSTNo: data.exhibitorDetails[0]['GSTNo'],
                        Telephone: data.exhibitorDetails[0]['Telephone'],
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // FrieghtDetailsRow
    addFrieghtDetailsRow() {
        const { FrieghtDetailsRowList } = this.state
        if (FrieghtDetailsRowList.length != 25) {
            FrieghtDetailsRowList.push(
                {
                    id: FrieghtDetailsRowList.length + 1,
                    Description: '',
                    Height: '',
                    Length: '',
                    Width: '',
                    Weight: '',
                    Quantity: '',
                }
            )
            this.setState({ FrieghtDetailsRowList: FrieghtDetailsRowList })
        }
    }

    deleteFrieghtDetailsRowList(id) {
        const { FrieghtDetailsRowList } = this.state

        if (FrieghtDetailsRowList.length == 1) {
            this.setState({
                FrieghtDetailsRowList: [
                    {
                        id: FrieghtDetailsRowList.length + 1,
                        Description: '',
                        Height: '',
                        Length: '',
                        Width: '',
                        Weight: '',
                        Quantity: '',
                    }
                ]
            })
        } else {
            const filterList = FrieghtDetailsRowList.filter(item => item.id != id)
            filterList.map((data, i) => {
                data.id = 1 + i++
            })
            this.setState({ FrieghtDetailsRowList: filterList })
        }
    }

    onFrieghtDetails(value, index, data) {
        const { FrieghtDetailsRowList } = this.state
        FrieghtDetailsRowList.map((item, i) => {
            if (index == i) {
                if (value != '') {
                    if (data == 'Description') {
                        item.Description = value
                    } else if (data == 'Height') {
                        item.Height = value
                    } else if (data == 'Length') {
                        item.Length = value
                    } else if (data == 'Width') {
                        item.Width = value
                    } else if (data == 'Weight') {
                        item.Weight = value
                    } else {
                        item.Quantity = value
                    }
                } else {
                    if (data == 'Description') {
                        item.Description = ''
                    } else if (data == 'Height') {
                        item.Height = ''
                    } else if (data == 'Length') {
                        item.Length = ''
                    } else if (data == 'Width') {
                        item.Width = ''
                    } else if (data == 'Weight') {
                        item.Weight = ''
                    } else {
                        item.Quantity = ''
                    }
                }
            }
        })
        this.setState({ FrieghtDetailsRowList: FrieghtDetailsRowList })
    }


    // BuildUpPeriodRowList
    addBuildUpPeriodRow() {
        const { BuildUpPeriodRowList } = this.state

        if (BuildUpPeriodRowList.length != 25) {
            BuildUpPeriodRowList.push(
                {
                    id: BuildUpPeriodRowList.length + 1,
                    DateFrom: '',
                    DateTo: '',
                    ForkliftUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                        }
                    ],
                    CraneUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                            isChecked_4: false,
                        }
                    ],
                    SpecialEquipment: '',
                    DimensionOrWeight: '',
                    Labour: '',
                }
            )
            this.setState({ BuildUpPeriodRowList: BuildUpPeriodRowList })
        }
    }

    deleteBuildUpPeriodRowList(id) {
        const { BuildUpPeriodRowList } = this.state
        if (BuildUpPeriodRowList.length == 1) {
            this.setState({
                BuildUpPeriodRowList: [
                    {
                        id: 1,
                        DateFrom: '',
                        DateTo: '',
                        ForkliftUpTo: [
                            {
                                isChecked_1: false,
                                isChecked_2: false,
                                isChecked_3: false,
                            }
                        ],
                        CraneUpTo: [
                            {
                                isChecked_1: false,
                                isChecked_2: false,
                                isChecked_3: false,
                                isChecked_4: false,
                            }
                        ],
                        SpecialEquipment: '',
                        DimensionOrWeight: '',
                        Labour: '',
                    }
                ]
            })
        } else {
            const filterList = BuildUpPeriodRowList.filter(item => item.id != id)
            filterList.map((data, i) => {
                data.id = 1 + i++
            })
            this.setState({ BuildUpPeriodRowList: filterList })
        }

    }

    onBuildUpPeriodRowList(value, index, data) {
        const { BuildUpPeriodRowList } = this.state
        console.log("value---", value)
        BuildUpPeriodRowList.map((item, i) => {
            if (index == i) {

                if (data == 'ForkliftUpTo_isChecked_1' || data == 'ForkliftUpTo_isChecked_2' || data == 'ForkliftUpTo_isChecked_3' || data == 'CraneUpTo_isChecked_1' || data == 'CraneUpTo_isChecked_2' || data == 'CraneUpTo_isChecked_3' || data == 'CraneUpTo_isChecked_4') {

                    if (value) {
                        if (data == 'ForkliftUpTo_isChecked_1') {
                            item.ForkliftUpTo[0]['isChecked_1'] = true
                        } else if (data == 'ForkliftUpTo_isChecked_2') {
                            item.ForkliftUpTo[0]['isChecked_2'] = true
                        } else if (data == 'ForkliftUpTo_isChecked_3') {
                            item.ForkliftUpTo[0]['isChecked_3'] = true
                        } else if (data == 'CraneUpTo_isChecked_1') {
                            item.CraneUpTo[0]['isChecked_1'] = true
                        } else if (data == 'CraneUpTo_isChecked_2') {
                            item.CraneUpTo[0]['isChecked_2'] = true
                        } else if (data == 'CraneUpTo_isChecked_3') {
                            item.CraneUpTo[0]['isChecked_3'] = true
                        } else if (data == 'CraneUpTo_isChecked_4') {
                            item.CraneUpTo[0]['isChecked_4'] = true
                        }
                    } else {
                        if (data == 'ForkliftUpTo_isChecked_1') {
                            item.ForkliftUpTo[0]['isChecked_1'] = false
                        } else if (data == 'ForkliftUpTo_isChecked_2') {
                            item.ForkliftUpTo[0]['isChecked_2'] = false
                        } else if (data == 'ForkliftUpTo_isChecked_3') {
                            item.ForkliftUpTo[0]['isChecked_3'] = false
                        } else if (data == 'CraneUpTo_isChecked_1') {
                            item.CraneUpTo[0]['isChecked_1'] = false
                        } else if (data == 'CraneUpTo_isChecked_2') {
                            item.CraneUpTo[0]['isChecked_2'] = false
                        } else if (data == 'CraneUpTo_isChecked_3') {
                            item.CraneUpTo[0]['isChecked_3'] = false
                        } else if (data == 'CraneUpTo_isChecked_4') {
                            item.CraneUpTo[0]['isChecked_4'] = false
                        }
                    }

                } else {
                    if (value != '') {
                        if (data == 'DateFrom') {
                            item.DateFrom = value
                        } else if (data == 'DateTo') {
                            item.DateTo = value
                        } else if (data == 'SpecialEquipment') {
                            item.SpecialEquipment = value
                        } else if (data == 'DimensionOrWeight') {
                            item.DimensionOrWeight = value
                        } else {
                            item.Labour = value
                        }
                    } else {
                        if (data == 'DateFrom') {
                            item.DateFrom = value
                        } else if (data == 'DateTo') {
                            item.DateTo = value
                        } else if (data == 'SpecialEquipment') {
                            item.SpecialEquipment = value
                        } else if (data == 'DimensionOrWeight') {
                            item.DimensionOrWeight = value
                        } else {
                            item.Labour = value
                        }
                    }
                }
            }
        })
        this.setState({ BuildUpPeriodRowList: BuildUpPeriodRowList })
    }


    // DismantlingPeriodRowList
    addDismantlingPeriodRow() {
        const { DismantlingPeriodRowList } = this.state

        if (DismantlingPeriodRowList.length != 25) {
            DismantlingPeriodRowList.push(
                {
                    id: DismantlingPeriodRowList.length + 1,
                    DateFrom: '',
                    DateTo: '',
                    ForkliftUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                        }
                    ],
                    CraneUpTo: [
                        {
                            isChecked_1: false,
                            isChecked_2: false,
                            isChecked_3: false,
                            isChecked_4: false,
                        }
                    ],
                    SpecialEquipment: '',
                    DimensionOrWeight: '',
                    Labour: '',
                }
            )
            this.setState({ DismantlingPeriodRowList: DismantlingPeriodRowList })
        }
    }

    deleteDismantlingPeriodRowList(id) {
        const { DismantlingPeriodRowList } = this.state
        if (DismantlingPeriodRowList.length == 1) {
            this.setState({
                DismantlingPeriodRowList: [
                    {
                        id: 1,
                        DateFrom: '',
                        DateTo: '',
                        ForkliftUpTo: [
                            {
                                isChecked_1: false,
                                isChecked_2: false,
                                isChecked_3: false,
                            }
                        ],
                        CraneUpTo: [
                            {
                                isChecked_1: false,
                                isChecked_2: false,
                                isChecked_3: false,
                                isChecked_4: false,
                            }
                        ],
                        SpecialEquipment: '',
                        DimensionOrWeight: '',
                        Labour: '',
                    }
                ]
            })
        } else {
            const filterList = DismantlingPeriodRowList.filter(item => item.id != id)
            filterList.map((data, i) => {
                data.id = 1 + i++
            })
            this.setState({ DismantlingPeriodRowList: filterList })
        }

    }

    onDismantlingPeriodRowList(value, index, data) {
        const { DismantlingPeriodRowList } = this.state
        DismantlingPeriodRowList.map((item, i) => {
            if (index == i) {

                if (data == 'ForkliftUpTo_isChecked_1' || data == 'ForkliftUpTo_isChecked_2' || data == 'ForkliftUpTo_isChecked_3' || data == 'CraneUpTo_isChecked_1' || data == 'CraneUpTo_isChecked_2' || data == 'CraneUpTo_isChecked_3' || data == 'CraneUpTo_isChecked_4') {
                    console.log("value=--", value)

                    if (value) {
                        if (data == 'ForkliftUpTo_isChecked_1') {
                            item.ForkliftUpTo[0]['isChecked_1'] = true
                        } else if (data == 'ForkliftUpTo_isChecked_2') {
                            item.ForkliftUpTo[0]['isChecked_2'] = true
                        } else if (data == 'ForkliftUpTo_isChecked_3') {
                            item.ForkliftUpTo[0]['isChecked_3'] = true
                        } else if (data == 'CraneUpTo_isChecked_1') {
                            item.CraneUpTo[0]['isChecked_1'] = true
                        } else if (data == 'CraneUpTo_isChecked_2') {
                            item.CraneUpTo[0]['isChecked_2'] = true
                        } else if (data == 'CraneUpTo_isChecked_3') {
                            item.CraneUpTo[0]['isChecked_3'] = true
                        } else if (data == 'CraneUpTo_isChecked_4') {
                            item.CraneUpTo[0]['isChecked_4'] = true
                        }
                    } else {
                        if (data == 'ForkliftUpTo_isChecked_1') {
                            item.ForkliftUpTo[0]['isChecked_1'] = false
                        } else if (data == 'ForkliftUpTo_isChecked_2') {
                            item.ForkliftUpTo[0]['isChecked_2'] = false
                        } else if (data == 'ForkliftUpTo_isChecked_3') {
                            item.ForkliftUpTo[0]['isChecked_3'] = false
                        } else if (data == 'CraneUpTo_isChecked_1') {
                            item.CraneUpTo[0]['isChecked_1'] = false
                        } else if (data == 'CraneUpTo_isChecked_2') {
                            item.CraneUpTo[0]['isChecked_2'] = false
                        } else if (data == 'CraneUpTo_isChecked_3') {
                            item.CraneUpTo[0]['isChecked_3'] = false
                        } else if (data == 'CraneUpTo_isChecked_4') {
                            item.CraneUpTo[0]['isChecked_4'] = false
                        }
                    }

                } else {
                    if (value != '') {
                        if (data == 'DateFrom') {
                            item.DateFrom = value
                        } else if (data == 'DateTo') {
                            item.DateTo = value
                        } else if (data == 'SpecialEquipment') {
                            item.SpecialEquipment = value
                        } else if (data == 'DimensionOrWeight') {
                            item.DimensionOrWeight = value
                        } else {
                            item.Labour = value
                        }
                    } else {
                        if (data == 'DateFrom') {
                            item.DateFrom = value
                        } else if (data == 'DateTo') {
                            item.DateTo = value
                        } else if (data == 'SpecialEquipment') {
                            item.SpecialEquipment = value
                        } else if (data == 'DimensionOrWeight') {
                            item.DimensionOrWeight = value
                        } else {
                            item.Labour = value
                        }
                    }
                }
            }
        })
        this.setState({ DismantlingPeriodRowList: DismantlingPeriodRowList })
    }

    handleCancel() {
        this.props.navigate('/manualForms')
    }

    onChecked(e, index) {
        const { checkboxList } = this.state
        const event = e.target.checked
        checkboxList.map((data, i) => {
            if (i === index) {
                if (event) {
                    data.Is_cheked = true
                } else {
                    data.Is_cheked = false
                }
            }
        })
        this.setState({ checkboxList: checkboxList })
    }

    inputValidate() {
        const { Is_Agree } = this.state

        if (Is_Agree == 0 || Is_Agree == false) {
            this.setState({ Is_AgreeErr: 'kindly agree the rules & guidelines for Onsite handling in India ITME 2024' })
        }
    }


    handleSubmit() {
        const { IsOnSiteServiceRequired, IsHireOfEquipment, hallChapterDataList, checkboxList, FrieghtDetailsRowList, BuildUpPeriodRowList, DismantlingPeriodRowList, Remark, Is_Agree, TimetableForMoveIn, TimetableForMoveOut, sortHallChapterData, hallChapterEmailIdSend } = this.state
        const chekedList = []
        const list1 = []
        const list2 = []
        const list3 = []
        this.inputValidate()
        checkboxList.map((data) => {
            if (data.Is_cheked) {
                chekedList.push({
                    FreightForwardersParticularsID: data.FreightForwardersParticularsID
                })
            }
        })

        FrieghtDetailsRowList.map((data) => {
            list1.push(
                {
                    Description: data.Description,
                    Height: data.Height,
                    Length: data.Length,
                    Width: data.Width,
                    Weight: data.Weight,
                    Quantity: data.Quantity,
                }
            )
        })

        BuildUpPeriodRowList.map((data) => {
            list2.push(
                {
                    FromDate: data.DateFrom,
                    ToDate: data.DateTo,
                    Forklift_3: data.ForkliftUpTo[0]['isChecked_1'],
                    Forklift_5: data.ForkliftUpTo[0]['isChecked_2'],
                    Forklift_10: data.ForkliftUpTo[0]['isChecked_3'],
                    Crane_10: data.CraneUpTo[0]['isChecked_1'],
                    Crane_12: data.CraneUpTo[0]['isChecked_2'],
                    Crane_15: data.CraneUpTo[0]['isChecked_3'],
                    Crane_20: data.CraneUpTo[0]['isChecked_4'],
                    SpecialHandlingEquipment: data.SpecialEquipment,
                    OverSizeCargo: data.DimensionOrWeight,
                    Labour: data.Labour,
                }
            )
        })

        DismantlingPeriodRowList.map((data) => {
            list3.push(
                {
                    FromDate: data.DateFrom,
                    ToDate: data.DateTo,
                    Forklift_3: data.ForkliftUpTo[0]['isChecked_1'],
                    Forklift_5: data.ForkliftUpTo[0]['isChecked_2'],
                    Forklift_10: data.ForkliftUpTo[0]['isChecked_3'],
                    Crane_10: data.CraneUpTo[0]['isChecked_1'],
                    Crane_12: data.CraneUpTo[0]['isChecked_2'],
                    Crane_15: data.CraneUpTo[0]['isChecked_3'],
                    Crane_20: data.CraneUpTo[0]['isChecked_4'],
                    SpecialHandlingEquipment: data.SpecialEquipment,
                    OverSizeCargo: data.DimensionOrWeight,
                    Labour: data.Labour,
                }
            )
        })

        console.log("FreightForwardersAgencyContactDetailID", FreightForwardersAgencyContactDetailID)
        console.log("chekedList", chekedList)
        console.log("list1", list1)
        console.log("list2", list2)
        console.log("list3", list3)

        var FreightForwardersAgencyContactDetailID = ''

        hallChapterDataList.map((data) => {
            if (data.Is_redioChecked) {
                FreightForwardersAgencyContactDetailID = data.FreightForwardersAgencyContactDetailID
            }
        })

        const list4 = TimetableForMoveIn.concat(TimetableForMoveOut)

        this.setState({ isLoading: true })
        if (Is_Agree) {
            this.props
                .insertUpdateFrieghtForward_action(FreightForwardersAgencyContactDetailID, IsOnSiteServiceRequired, IsHireOfEquipment, Remark, Is_Agree, chekedList, list1, list2, list3, list4, sortHallChapterData, hallChapterEmailIdSend)
                .then((data) => {
                    if (data.error != 1) {
                        this.handleCancel()
                        this.setState({ isLoading: false })
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } else {
            this.inputValidate()
            toastError("kindly agree the rules & guidelines")
            this.setState({ isLoading: false })
        }

    }

    isRadioCheked(e, i) {
        const { hallChapterDataList } = this.state
        console.log("e.target.checked", e.target.checked)
        console.log("hallChapterDataList", hallChapterDataList)
        var companyName
        hallChapterDataList.map((item, index) => {
            if (i === index) {
                if (e.target.checked) {
                    item.Is_redioChecked = true
                    companyName = item.CompanyName
                    this.setState({ hallChapterEmailIdSend: item.Email })
                } else {
                    item.Is_redioChecked = false
                }
            } else {
                item.Is_redioChecked = false
            }
        })
        const filteredArray = []
        hallChapterDataList.filter(item => {
            if (item.CompanyName === companyName && item.Is_redioChecked == 0) {
                filteredArray.push(item.Email)
            }
        })
        console.log("filteredArray-->", filteredArray)

        this.setState({ hallChapterDataList: hallChapterDataList, sortHallChapterData: filteredArray })
    }

    onTimetableForMoveIn(e, index, data) {
        const { TimetableForMoveIn } = this.state
        const event = e
        TimetableForMoveIn.map((item, i) => {
            if (i === index) {
                if (data == 'Air' || data == 'CubicMeters' || data == 'TwentyFeetUnit' || data == 'FourtyFeetUnit') {
                    if (event) {
                        if (data == 'Air') {
                            item.Air = true
                        } else if (data == 'CubicMeters') {
                            item.CubicMeters = true
                        } else if (data == 'TwentyFeetUnit') {
                            item.TwentyFeetUnit = true
                        } else {
                            item.FourtyFeetUnit = true
                        }
                    } else {
                        if (data == 'Air') {
                            item.Air = false
                        } else if (data == 'CubicMeters') {
                            item.CubicMeters = false
                        } else if (data == 'TwentyFeetUnit') {
                            item.TwentyFeetUnit = false
                        } else {
                            item.FourtyFeetUnit = false
                        }
                    }
                } else if (data == 'MoveIn_Out_Date') {
                    if (event != '') {
                        item.MoveIn_Out_Date = event
                    } else {
                        item.MoveIn_Out_Date = ''
                    }
                } else {
                    if (event != '') {
                        item.NoofTrucks = event
                    } else {
                        item.NoofTrucks = ''
                    }
                }
            }
        })
        this.setState({ TimetableForMoveIn: TimetableForMoveIn })
    }

    addTimetableForMoveIn() {
        const { TimetableForMoveIn } = this.state

        if (TimetableForMoveIn.length != 25) {
            TimetableForMoveIn.push(
                {
                    id: TimetableForMoveIn.length + 1,
                    MoveIn_Out_Date: null,
                    Air: false,
                    CubicMeters: false,
                    TwentyFeetUnit: false,
                    FourtyFeetUnit: false,
                    NoofTrucks: '',
                    isMovein: 1
                }
            )
            this.setState({ TimetableForMoveIn: TimetableForMoveIn })
        }
    }

    onTimetableForMoveOut(e, index, data) {
        const { TimetableForMoveOut } = this.state
        const event = e
        TimetableForMoveOut.map((item, i) => {
            if (i === index) {
                if (data == 'Air' || data == 'CubicMeters' || data == 'TwentyFeetUnit' || data == 'FourtyFeetUnit') {
                    if (event) {
                        if (data == 'Air') {
                            item.Air = true
                        } else if (data == 'CubicMeters') {
                            item.CubicMeters = true
                        } else if (data == 'TwentyFeetUnit') {
                            item.TwentyFeetUnit = true
                        } else {
                            item.FourtyFeetUnit = true
                        }
                    } else {
                        if (data == 'Air') {
                            item.Air = false
                        } else if (data == 'CubicMeters') {
                            item.CubicMeters = false
                        } else if (data == 'TwentyFeetUnit') {
                            item.TwentyFeetUnit = false
                        } else {
                            item.FourtyFeetUnit = false
                        }
                    }
                } else if (data == 'MoveIn_Out_Date') {
                    if (event != '') {
                        item.MoveIn_Out_Date = event
                    } else {
                        item.MoveIn_Out_Date = ''
                    }
                } else {
                    if (event != '') {
                        item.NoofTrucks = event
                    } else {
                        item.NoofTrucks = ''
                    }
                }
            }
        })
        this.setState({ TimetableForMoveOut: TimetableForMoveOut })
    }

    addTimetableForMoveOut() {
        const { TimetableForMoveOut } = this.state

        if (TimetableForMoveOut.length != 25) {
            TimetableForMoveOut.push(
                {
                    id: TimetableForMoveOut.length + 1,
                    MoveIn_Out_Date: null,
                    Air: false,
                    CubicMeters: false,
                    TwentyFeetUnit: false,
                    FourtyFeetUnit: false,
                    NoofTrucks: '',
                    isMovein: 0
                }
            )
            this.setState({ TimetableForMoveOut: TimetableForMoveOut })
        }
    }

    deleteTimetableForMoveIn(id) {
        const { TimetableForMoveIn } = this.state
        console.log("id------------------movIn", id)
        if (TimetableForMoveIn.length == 1) {
            this.setState({
                TimetableForMoveIn: [
                    {
                        id: 1,
                        MoveIn_Out_Date: null,
                        Air: false,
                        CubicMeters: false,
                        TwentyFeetUnit: false,
                        FourtyFeetUnit: false,
                        NoofTrucks: '',
                        isMovein: true
                    }
                ]
            })
        } else {
            const filterList = TimetableForMoveIn.filter(item => item.id != id)
            filterList.map((data, i) => {
                data.id = 1 + i++
            })
            this.setState({ TimetableForMoveIn: filterList })
        }
    }

    deleteTimetableForMoveOut(id) {
        const { TimetableForMoveOut } = this.state
        if (TimetableForMoveOut.length == 1) {
            this.setState({
                TimetableForMoveOut: [
                    {
                        id: 1,
                        MoveIn_Out_Date: null,
                        Air: false,
                        CubicMeters: false,
                        TwentyFeetUnit: false,
                        FourtyFeetUnit: false,
                        NoofTrucks: '',
                        isMovein: true
                    }
                ]
            })
        } else {
            const filterList = TimetableForMoveOut.filter(item => item.id != id)
            filterList.map((data, i) => {
                data.id = 1 + i++
            })
            this.setState({ TimetableForMoveOut: filterList })
        }
    }

    render() {
        const { isLoading, Is_Agree, Remark, Is_AgreeErr, currentDate, DeadlineDate, is_View, FrieghtDetailsRowList, IsOnSiteServiceRequired, IsHireOfEquipment, BuildUpPeriodRowList, DismantlingPeriodRowList, checkboxList, hallChapterDataList, companyName, PersonIncharge, MobileNo, Address, GSTNo, Telephone, TimetableForMoveIn, TimetableForMoveOut, StatusID } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorDrawer /> : <AdminDrawer />
                }
                <div className="main-content app-content  with-dr-container" style={{ marginTop: !is_View ? '0px' : '' }}>
                    <div className="">
                        <div className="row">
                            {
                                JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                    <ExhibitorTopMenu /> : null
                            }
                        </div>
                        <div className=" mt-2">
                            <div className="row">
                                <div class="card rounded-0 pt-1 m-0">
                                    <div class="card-header p-3" style={{ paddingleft: "7px !important" }}>
                                        <h3 class="card-title" style={{ fontsize: "12px" }}>Freight Forward Form</h3>
                                    </div>
                                    <p class="card-body p-1 m-2">
                                        <ol style={{ lineHeight: '25px', textAlign: 'left', padding: '0 0 0 20px' }}>
                                            <li>Exhibitors must arrange their own insurance cover for their stalls/machines and all commodities involved throughout all stages of the exhibition, i.e., from move in, move out, loading, unloading, assembling, transportation etc. The third-party insurance, accident insurance of exhibitor's personal, handling damage insurance etc. should also covered by exhibitors.</li>
                                            <li>Onsite Handling: Only exhibits which can be hand carried (not exceeding 50 kg.) by one person may be delivered to/removed from the Exhibition Hall by the Exhibitor. Those requiring mechanical assistance must be handed to the Official Freight Forwarder on-site. The Official Freight Forwarder will use the diagram on the Reverse of this Form regarding placement of exhibits in your stall</li>
                                            <li>Exhibitors should contact either of the official contractor for further details about hire of equipment and Schedule of arrival of exhibits, etc.</li>
                                            <li>Exhibitors are requested to contact official freight and forwarding agents for shipment details and charges of shipment to and for their port and on-site handling.</li>
                                            <li>The above charges are applicable from 0900 to 2000 hrs. For services required after these hours, a surcharge of 25% shall be applicable
                                                on the above tariff.</li>
                                            <li>Exhibitors should instruct their transporter to carry the 'Weigh Bridge Certificate', to avoid disputes regarding assessment of the weight of the exhibits to be handled.</li>
                                            <li>Exhibitors should send product along with the packing list to the hired agency to enable them to study the exhibits under movement.</li>
                                            <li>Handling of equipment by any other agency, within exhibition complex is not permitted.</li>
                                            <li>For Volume shipment 1 CBM=500 kgs. Exhibitors should hire either of below given agents only for handling their exhibition material or exhibits on site. Their tariffs for handling the exhibits, exclusive of applicable taxes and levies are as under.</li>
                                            <li>Exhibitor will be charged on the basis of Weight or Volume Weight whichever is higher.</li>
                                            <li>GST 18% applicable on the Services provided or as applicable.</li>
                                            <li>The exhibitors are not allowed to order equipment's/ agencies for onsite handling works from outside. It is compulsory to use the facility made available by Official Freight Forwarder on-site.</li>
                                        </ol>
                                        <p style={{ lineHeight: '25px' }}>
                                            <p className="m-0"><strong>Note:</strong></p>
                                            <ul style={{ listStyleType: 'disc' }}>
                                                <li>All costs relating from late arrival or non-presence of the Exhibitor's representative will be charged accordingly.</li>
                                                <li>The requested schedule will be re-confirmed by the LOGISTICS agency.</li>
                                                <li>All exhibitors are requested not to dispatch their exhibits without proper e-waybill. </li>
                                            </ul>
                                        </p>
                                    </p>
                                </div>
                                <p style={{ margin: '0 0 0 10px' }}><strong>Exhibitor Details:</strong></p>
                                <div className="row mb-2 m-0">
                                    <div className="col-md-4">
                                        <label>Company Name</label>
                                        <input type="text" className="form-control"
                                            value={companyName}
                                            disabled={true}
                                            onChange={(e) => this.setState({ companyName: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 ">
                                        <label htmlFor="validationCustom03">Contact person Name</label>
                                        <input type="text" className="form-control"
                                            value={PersonIncharge}
                                            disabled={true}
                                            onChange={(e) => this.setState({ companyName: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 ">
                                        <label htmlFor="validationCustom03">Mobile No</label>
                                        <input type="text" className="form-control"
                                            value={MobileNo}
                                            disabled={true}
                                            onChange={(e) => this.setState({ companyName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-4 m-0">
                                    <div className="col-md-4">
                                        <label>Address</label>
                                        <input type="text" className="form-control"
                                            value={Address}
                                            disabled={true}
                                            onChange={(e) => this.setState({ companyName: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 ">
                                        <label htmlFor="validationCustom03">GST No</label>
                                        <input type="text" className="form-control"
                                            value={GSTNo}
                                            disabled={true}
                                            onChange={(e) => this.setState({ companyName: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-4 ">
                                        <label htmlFor="validationCustom03">Telephone</label>
                                        <input type="text" className="form-control"
                                            value={Telephone}
                                            disabled={true}
                                            onChange={(e) => this.setState({ companyName: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div class="table-responsive" style={{ padding: '0 25px 10px 25px' }}>
                                    <p ><strong>Agency Contact Details:</strong></p>
                                    <table class="table border text-nowrap text-md-nowrap table-bordered">
                                        <thead>
                                            <tr >
                                                <th>HALL NUMBER</th>
                                                <th>CHAPTER</th>
                                                <th>FREIGHT FORWARDERS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {hallChapterDataList.map((data) => (

                                                <tr>
                                                    <td>{data.HallNos}</td>
                                                    <td>{data.Chapters}</td>
                                                    <td>{data.CompanyName}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                    <p ><strong>Note: No outside agencies or service provider will be permitted to operate in areas not allocated to them</strong></p>
                                </div>

                                <div class="table-responsive d-flex" style={{ padding: '0 25px 10px 25px' }}>
                                    {hallChapterDataList.map((data, i) => (
                                        <>
                                            <div className="d-flex w-50" style={{ flexWrap: 'wrap' }}>
                                                <table class="table border text-nowrap text-md-nowrap table-bordered m-0">
                                                    <thead>
                                                        <tr >
                                                            <th >
                                                                <input type="radio"
                                                                    checked={data.Is_redioChecked}
                                                                    style={{ cursor: 'pointer' }} onClick={(e) => this.isRadioCheked(e, i)}
                                                                />
                                                                {data.CompanyName}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>
                                                                <strong>{data.PersonInchargeName}</strong><br />
                                                                Mobile No.: {data.MobileNo}<br />
                                                                Telephone No.: {data.Telephone}<br />
                                                                Email: {data.Email}<br />
                                                                Website: {data.Website}<br />
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ))}
                                </div>

                                <div class="table-responsive" style={{ padding: '0 25px 10px 25px' }}>
                                    <table class="table border text-nowrap text-md-nowrap table-bordered">
                                        <thead>
                                            <tr >
                                                <th >PARTICULARS</th>
                                                <th >UNIT</th>
                                                <th >RATE INR</th>
                                                <th style={{ width: '1%' }}>SELECT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <strong > DESCRIPTION OF ITEMS UPTO 5000 KGS.</strong>
                                                </td>
                                            </tr>
                                            {checkboxList.map((data, i) => (
                                                data.MainParticularsName == 'DESCRIPTION OF ITEMS UPTO 5000 KGS' ?
                                                    <tr>
                                                        <td >
                                                            {data.Particulars}
                                                        </td>
                                                        <td >
                                                            {data.Unit}
                                                        </td>
                                                        <td >
                                                            {data.RateINR}
                                                        </td>
                                                        <td >
                                                            <input type="checkbox" checked={data.Is_cheked}
                                                                onChange={(e) => this.onChecked(e, i)}
                                                            />
                                                        </td>
                                                    </tr>
                                                    : <></>
                                            ))}
                                            <tr>
                                                <td colSpan={4}>
                                                    <strong > HEAVY LIFT CHARGES.</strong>
                                                </td>
                                            </tr>
                                            {checkboxList.map((data, i) => (
                                                data.MainParticularsName == 'HEAVY LIFT CHARGES' ?
                                                    <tr>
                                                        <td >
                                                            {data.Particulars}
                                                        </td>
                                                        <td >
                                                            {data.Unit}
                                                        </td>
                                                        <td >
                                                            {data.RateINR}
                                                        </td>
                                                        <td >
                                                            <input type="checkbox" checked={data.Is_cheked}
                                                                onChange={(e) => this.onChecked(e, i)}
                                                            />
                                                        </td>
                                                    </tr>
                                                    : <></>
                                            ))}
                                            <tr>
                                                <td colSpan={4}>
                                                    <strong > HIRE OF EQUIPMENTS FOR ASSEMBLY AND ERECTION OF MACHINES.</strong>
                                                </td>
                                            </tr>
                                            {checkboxList.map((data, i) => (
                                                data.MainParticularsName == 'HIRE OF EQUIPMENTS FOR ASSEMBLY AND ERECTION OF MACHINES' ?
                                                    <tr>
                                                        <td >
                                                            {data.Particulars}
                                                        </td>
                                                        <td >
                                                            {data.Unit}
                                                        </td>
                                                        <td >
                                                            {data.RateINR}
                                                        </td>
                                                        <td >
                                                            <input type="checkbox" checked={data.Is_cheked}
                                                                onChange={(e) => this.onChecked(e, i)}
                                                            />
                                                        </td>
                                                    </tr>
                                                    : <></>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div class="table-responsive" style={{ padding: '0 25px 10px 25px' }}>
                                    <table class="table border text-nowrap text-md-nowrap table-bordered">
                                        <thead>
                                            <tr style={{ textAlign: 'center', }}>
                                                <th colSpan={8} style={{ border: '1px solid lightgrey' }}>Freight Details</th>
                                            </tr>
                                            <tr style={{ textAlign: 'center' }}>
                                                <th>Sr. No.</th>
                                                <th>Description</th>
                                                <th>Height(m)</th>
                                                <th>Length(m)</th>
                                                <th>Width(m)</th>
                                                <th>Weight(kgs)</th>
                                                <th>Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                FrieghtDetailsRowList != '' && FrieghtDetailsRowList != null ? (
                                                    FrieghtDetailsRowList.map((data, i) => (
                                                        <tr>
                                                            <td>
                                                                {i + 1}
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Description}
                                                                    onChange={(e) => this.onFrieghtDetails(e.target.value, i, 'Description')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Height}
                                                                    onChange={(e) => this.onFrieghtDetails(e.target.value.replace(/\D/, ''), i, 'Height')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Length}
                                                                    onChange={(e) => this.onFrieghtDetails(e.target.value.replace(/\D/, ''), i, 'Length')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Width}
                                                                    onChange={(e) => this.onFrieghtDetails(e.target.value.replace(/\D/, ''), i, 'Width')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Weight}
                                                                    onChange={(e) => this.onFrieghtDetails(e.target.value.replace(/\D/, ''), i, 'Weight')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Quantity}
                                                                    onChange={(e) => this.onFrieghtDetails(e.target.value.replace(/\D/, ''), i, 'Quantity')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <a
                                                                    class="btn btn-sm delete_record"
                                                                    onClick={() => this.deleteFrieghtDetailsRowList(data.id)}
                                                                ><i class="fa fa-trash action_btn_table"></i></a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ textAlign: 'right' }}>
                                        <button
                                            className="btn rounded-0 btn-dark rounded-2"
                                            style={{ padding: "7px 20px" }}
                                            onClick={(e) => this.addFrieghtDetailsRow()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>


                                <p ><strong style={{ margin: '12px' }}>On Site Services Required:</strong>
                                    <span style={{ marginRight: '10px' }}>
                                        <input type="radio" style={{ cursor: 'pointer' }}
                                            checked={IsOnSiteServiceRequired == 1}
                                            onChange={(e) => this.setState({ IsOnSiteServiceRequired: 1 })}
                                        /><strong>  Yes </strong>
                                    </span>
                                    <span >
                                        <input type="radio" style={{ cursor: 'pointer' }}
                                            checked={IsOnSiteServiceRequired == 0}
                                            onChange={(e) => this.setState({ IsOnSiteServiceRequired: 0 })}
                                        /><strong>  No </strong>
                                    </span>
                                </p>

                                <p ><strong style={{ margin: '12px' }}>Hire of Equipment For Assembly and Erection of Machines:</strong>
                                    <span style={{ marginRight: '10px' }}>
                                        <input type="radio" style={{ cursor: 'pointer' }}
                                            checked={IsHireOfEquipment == 1}
                                            onChange={(e) => this.setState({ IsHireOfEquipment: 1 })}
                                        /><strong>  Yes </strong>
                                    </span>
                                    <span >
                                        <input type="radio" style={{ cursor: 'pointer' }}
                                            checked={IsHireOfEquipment == 0}
                                            onChange={(e) => this.setState({ IsHireOfEquipment: 0 })}
                                        /><strong>  No </strong>
                                    </span>
                                </p>

                                {IsHireOfEquipment == 1 && IsHireOfEquipment ?
                                    <div class="table-responsive" style={{ padding: '0 25px 10px 25px' }}>
                                        <p>1. TIMETABLE FOR MOVE IN : </p>
                                        <table class="table border text-nowrap text-md-nowrap table-bordered">
                                            <thead>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th rowSpan={4}>Sr. No.</th>
                                                    <th rowSpan={4}>REQUESTED MOVE-IN DATE</th>
                                                </tr>
                                                <tr style={{ textAlign: 'center', }}>
                                                    <th colSpan={8} style={{ border: '1px solid lightgrey' }}>MEANS OF TRANSPORTATION AND QUANTITIES</th>
                                                </tr>
                                                <tr style={{ textAlign: 'center', }}>
                                                    <th rowSpan={2} style={{ border: '1px solid lightgrey' }}>Air</th>
                                                    <th colSpan={2} style={{ border: '1px solid lightgrey' }}>SEA (LCL)</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>SEA (FCL)</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>TRUCK</th>
                                                    <th rowSpan={2} style={{ border: '1px solid lightgrey' }}>Action</th>
                                                </tr>
                                                <tr style={{ textAlign: 'center', }}>
                                                    <th style={{ border: '1px solid lightgrey' }}>Cubic Meters</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>20 Feet Unit</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>40 Feet Unit</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>Number of trucks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TimetableForMoveIn != '' && TimetableForMoveIn != null ? (
                                                        TimetableForMoveIn.map((data, i) => (
                                                            <tr>
                                                                <td>
                                                                    {i + 1}
                                                                </td>
                                                                <td>
                                                                    <DatePicker className="form-control"
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText="DD-MM-YYYY"
                                                                        selected={data.MoveIn_Out_Date}
                                                                        onChange={(e) => this.onTimetableForMoveIn(e, i, 'MoveIn_Out_Date')}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.Air}
                                                                        onChange={(e) => this.onTimetableForMoveIn(e.target.checked, i, 'Air')}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.CubicMeters}
                                                                        onChange={(e) => this.onTimetableForMoveIn(e.target.checked, i, 'CubicMeters')}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.TwentyFeetUnit}
                                                                        onChange={(e) => this.onTimetableForMoveIn(e.target.checked, i, 'TwentyFeetUnit')}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.FourtyFeetUnit}
                                                                        onChange={(e) => this.onTimetableForMoveIn(e.target.checked, i, 'FourtyFeetUnit')}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input type="text"
                                                                        value={data.NoofTrucks}
                                                                        onChange={(e) => this.onTimetableForMoveIn(e.target.value.replace(/\D/, ''), i, 'NoofTrucks')}
                                                                        className="form-control"
                                                                        maxLength={10}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        class="btn btn-sm delete_record"
                                                                        onClick={() => this.deleteTimetableForMoveIn(data.id)}
                                                                    ><i class="fa fa-trash action_btn_table"></i></a>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)
                                                }
                                            </tbody>
                                        </table>
                                        <div style={{ textAlign: 'right' }}>
                                            <button
                                                className="btn rounded-0 btn-dark rounded-2"
                                                style={{ padding: "7px 20px" }}
                                                onClick={(e) => this.addTimetableForMoveIn()}
                                            >
                                                Add
                                            </button>
                                        </div>

                                        <p>2. TIMETABLE FOR MOVE OUT : </p>
                                        <table class="table border text-nowrap text-md-nowrap table-bordered">
                                            <thead>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th rowSpan={4}>Sr. No.</th>
                                                    <th rowSpan={4}>REQUESTED MOVE-IN DATE</th>
                                                </tr>
                                                <tr style={{ textAlign: 'center', }}>
                                                    <th colSpan={8} style={{ border: '1px solid lightgrey' }}>MEANS OF TRANSPORTATION AND QUANTITIES</th>
                                                </tr>
                                                <tr style={{ textAlign: 'center', }}>
                                                    <th rowSpan={2} style={{ border: '1px solid lightgrey' }}>Air</th>
                                                    <th colSpan={2} style={{ border: '1px solid lightgrey' }}>SEA (LCL)</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>SEA (FCL)</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>TRUCK</th>
                                                    <th rowSpan={2} style={{ border: '1px solid lightgrey' }}>Action</th>
                                                </tr>
                                                <tr style={{ textAlign: 'center', }}>
                                                    <th style={{ border: '1px solid lightgrey' }}>Cubic Meters</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>20 Feet Unit</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>40 Feet Unit</th>
                                                    <th style={{ border: '1px solid lightgrey' }}>Number of trucks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    TimetableForMoveOut != '' && TimetableForMoveOut != null ? (
                                                        TimetableForMoveOut.map((data, i) => (
                                                            <tr>
                                                                <td>
                                                                    {i + 1}
                                                                </td>
                                                                <td>
                                                                    <DatePicker className="form-control"
                                                                        dateFormat="dd-MM-yyyy"
                                                                        placeholderText="DD-MM-YYYY"
                                                                        selected={data.MoveIn_Out_Date}
                                                                        onChange={(e) => this.onTimetableForMoveOut(e, i, 'MoveIn_Out_Date')}
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.Air}
                                                                        onChange={(e) => this.onTimetableForMoveOut(e.target.checked, i, 'Air')}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.CubicMeters}
                                                                        onChange={(e) => this.onTimetableForMoveOut(e.target.checked, i, 'CubicMeters')}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.TwentyFeetUnit}
                                                                        onChange={(e) => this.onTimetableForMoveOut(e.target.checked, i, 'TwentyFeetUnit')}
                                                                    />
                                                                </td>
                                                                <td className="text-center">
                                                                    <input type='checkbox'
                                                                        checked={data.FourtyFeetUnit}
                                                                        onChange={(e) => this.onTimetableForMoveOut(e.target.checked, i, 'FourtyFeetUnit')}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input type="text"
                                                                        value={data.NoofTrucks}
                                                                        onChange={(e) => this.onTimetableForMoveOut(e.target.value.replace(/\D/, ''), i, 'NoofTrucks')}
                                                                        className="form-control"
                                                                        maxLength={10}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        class="btn btn-sm delete_record"
                                                                        onClick={() => this.deleteTimetableForMoveOut(data.id)}
                                                                    ><i class="fa fa-trash action_btn_table"></i></a>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)
                                                }
                                            </tbody>
                                        </table>
                                        <div style={{ textAlign: 'right' }}>
                                            <button
                                                className="btn rounded-0 btn-dark rounded-2"
                                                style={{ padding: "7px 20px" }}
                                                onClick={(e) => this.addTimetableForMoveOut()}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </div>
                                    : <></>}

                                <div class="table-responsive mt-2" style={{ padding: '0 25px 10px 25px' }}>
                                    <p className="m-0"><strong style={{ marginRight: '15px' }}>1) Order For Equipment For The Build Up Period:</strong> </p>
                                    <table class="table border text-nowrap text-md-nowrap table-bordered">
                                        <thead>
                                            <tr style={{ textAlign: 'center' }}>
                                                <th>Sr. No.</th>
                                                <th style={{ width: '12%' }}>Date From</th>
                                                <th style={{ width: '12%' }}>Date To</th>
                                                <th className="addone">Forklift up to... tons</th>
                                                <th className="addone">Crane up to ... tons</th>
                                                <th className="addone">Special handling equipment requested? (e.g.: Spreader bar, scissor lift etc.) Please specify</th>
                                                <th className="addone">Please indicate oversize cargo exceeding single piece dimension/weight: L400xW230xH230cm or 5,000 kg</th>
                                                <th>Labour</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                BuildUpPeriodRowList != '' && BuildUpPeriodRowList != null ? (
                                                    BuildUpPeriodRowList.map((data, i) => (
                                                        <tr>
                                                            <td>
                                                                {i + 1}
                                                            </td>
                                                            <td>
                                                                <DatePicker className="form-control"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="DD-MM-YYYY"
                                                                    selected={data.DateFrom}
                                                                    onChange={(e) => this.onBuildUpPeriodRowList(e, i, 'DateFrom')}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                />
                                                            </td>
                                                            <td>
                                                                <DatePicker className="form-control"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="DD-MM-YYYY"
                                                                    selected={data.DateTo}
                                                                    minDate={data.DateFrom}
                                                                    onChange={(e) => this.onBuildUpPeriodRowList(e, i, 'DateTo')}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                />
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <div>
                                                                        <span style={{ marginLeft: '19px', marginRight: '6px' }}>3</span>
                                                                        <span style={{ marginLeft: '6px', marginRight: '3px' }}>5</span>
                                                                        <span style={{ marginLeft: '5px', marginRight: '24px' }}>10</span>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            checked={data.ForkliftUpTo[0]['isChecked_1']}
                                                                            onChange={(e) => this.onBuildUpPeriodRowList(e.target.checked, i, 'ForkliftUpTo_isChecked_1')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.ForkliftUpTo[0]['isChecked_2']}
                                                                            onChange={(e) => this.onBuildUpPeriodRowList(e.target.checked, i, 'ForkliftUpTo_isChecked_2')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.ForkliftUpTo[0]['isChecked_3']}
                                                                            onChange={(e) => this.onBuildUpPeriodRowList(e.target.checked, i, 'ForkliftUpTo_isChecked_3')}
                                                                            type='checkbox' />
                                                                    </div>

                                                                </div>

                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <div style={{ marginRight: '5px' }}>
                                                                        <span style={{ marginRight: '5px' }}>10</span>
                                                                        <span style={{ marginRight: '5px' }}>12</span>
                                                                        <span style={{ marginRight: '5px' }}>15</span>
                                                                        <span style={{ marginRight: '5px' }}>20</span>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_1']}
                                                                            onChange={(e) => this.onBuildUpPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_1')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_2']}
                                                                            onChange={(e) => this.onBuildUpPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_2')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_3']}
                                                                            onChange={(e) => this.onBuildUpPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_3')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_4']}
                                                                            onChange={(e) => this.onBuildUpPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_4')}
                                                                            type='checkbox' />
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.SpecialEquipment}
                                                                    onChange={(e) => this.onBuildUpPeriodRowList(e.target.value, i, 'SpecialEquipment')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.DimensionOrWeight}
                                                                    onChange={(e) => this.onBuildUpPeriodRowList(e.target.value, i, 'DimensionOrWeight')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Labour}
                                                                    onChange={(e) => this.onBuildUpPeriodRowList(e.target.value, i, 'Labour')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <a
                                                                    class="btn btn-sm delete_record"
                                                                    onClick={() => this.deleteBuildUpPeriodRowList(data.id)}
                                                                ><i class="fa fa-trash action_btn_table"></i></a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ textAlign: 'right' }}>
                                        <button
                                            className="btn rounded-0 btn-dark rounded-2"
                                            style={{ padding: "7px 20px" }}
                                            onClick={(e) => this.addBuildUpPeriodRow()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>

                                <div class="table-responsive mt-2" style={{ padding: '0 25px 10px 25px' }}>
                                    <p className="m-0"><strong style={{ marginRight: '15px' }}>2) Order For Equipment For The Dismantling Period:</strong> </p>
                                    <table class="table border text-nowrap text-md-nowrap table-bordered">
                                        <thead>
                                            <tr style={{ textAlign: 'center' }}>
                                                <th>Sr. No.</th>
                                                <th style={{ width: '12%' }}>Date From</th>
                                                <th style={{ width: '12%' }}>Date To</th>
                                                <th className="addone">Forklift up to... tons</th>
                                                <th className="addone">Crane up to ... tons</th>
                                                <th className="addone">Special handling equipment requested? (e.g.: Spreader bar, scissor lift etc.) Please specify</th>
                                                <th className="addone">Please indicate oversize cargo exceeding single piece dimension/weight: L400xW230xH230cm or 5,000 kg</th>
                                                <th>Labour</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                DismantlingPeriodRowList != '' && DismantlingPeriodRowList != null ? (
                                                    DismantlingPeriodRowList.map((data, i) => (
                                                        <tr>
                                                            <td>
                                                                {i + 1}
                                                            </td>
                                                            <td>
                                                                <DatePicker className="form-control"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    placeholderText="DD-MM-YYYY"
                                                                    selected={data.DateFrom}
                                                                    onChange={(e) => this.onDismantlingPeriodRowList(e, i, 'DateFrom')}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                />
                                                            </td>
                                                            <td>
                                                                <DatePicker className="form-control"
                                                                    placeholderText="DD-MM-YYYY"
                                                                    dateFormat="dd-MM-yyyy"
                                                                    selected={data.DateTo}
                                                                    minDate={data.DateFrom}
                                                                    onChange={(e) => this.onDismantlingPeriodRowList(e, i, 'DateTo')}
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                />
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <div>
                                                                        <span style={{ marginLeft: '19px', marginRight: '6px' }}>3</span>
                                                                        <span style={{ marginLeft: '6px', marginRight: '3px' }}>5</span>
                                                                        <span style={{ marginLeft: '5px', marginRight: '24px' }}>10</span>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            checked={data.ForkliftUpTo[0]['isChecked_1']}
                                                                            onChange={(e) => this.onDismantlingPeriodRowList(e.target.checked, i, 'ForkliftUpTo_isChecked_1')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.ForkliftUpTo[0]['isChecked_2']}
                                                                            onChange={(e) => this.onDismantlingPeriodRowList(e.target.checked, i, 'ForkliftUpTo_isChecked_2')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.ForkliftUpTo[0]['isChecked_3']}
                                                                            onChange={(e) => this.onDismantlingPeriodRowList(e.target.checked, i, 'ForkliftUpTo_isChecked_3')}
                                                                            type='checkbox' />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>
                                                                <div className="d-flex" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <div style={{ marginRight: '5px' }}>
                                                                        <span style={{ marginRight: '5px' }}>10</span>
                                                                        <span style={{ marginRight: '5px' }}>12</span>
                                                                        <span style={{ marginRight: '5px' }}>15</span>
                                                                        <span style={{ marginRight: '5px' }}>20</span>
                                                                    </div>
                                                                    <div>
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_1']}
                                                                            onChange={(e) => this.onDismantlingPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_1')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_2']}
                                                                            onChange={(e) => this.onDismantlingPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_2')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_3']}
                                                                            onChange={(e) => this.onDismantlingPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_3')}
                                                                            type='checkbox' />
                                                                        <input
                                                                            checked={data.CraneUpTo[0]['isChecked_4']}
                                                                            onChange={(e) => this.onDismantlingPeriodRowList(e.target.checked, i, 'CraneUpTo_isChecked_4')}
                                                                            type='checkbox' />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.SpecialEquipment}
                                                                    onChange={(e) => this.onDismantlingPeriodRowList(e.target.value, i, 'SpecialEquipment')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.DimensionOrWeight}
                                                                    onChange={(e) => this.onDismantlingPeriodRowList(e.target.value, i, 'DimensionOrWeight')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control"
                                                                    value={data.Labour}
                                                                    onChange={(e) => this.onDismantlingPeriodRowList(e.target.value, i, 'Labour')}
                                                                />
                                                            </td>
                                                            <td>
                                                                <a
                                                                    class="btn btn-sm delete_record"
                                                                    onClick={() => this.deleteDismantlingPeriodRowList(data.id)}
                                                                ><i class="fa fa-trash action_btn_table"></i></a>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (<tr><td colSpan={12} className='text-center'>Record not found</td></tr>)
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{ textAlign: 'right' }}>
                                        <button
                                            className="btn rounded-0 btn-dark rounded-2"
                                            style={{ padding: "7px 20px" }}
                                            onClick={(e) => this.addDismantlingPeriodRow()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>

                                <div class="row m-1">
                                    <form>
                                        <strong> <label>Remark</label></strong>
                                        <textarea class="form-control"
                                            value={Remark}
                                            disabled={is_View}
                                            onChange={(e) => this.setState({ Remark: e.target.value })}
                                            maxLength={300}
                                            rows="3">
                                        </textarea>
                                        <label class="d-flex mt-1" style={{ lineheight: "15px;" }}>
                                            <input type="checkbox"
                                                checked={Is_Agree}
                                                disabled={is_View}
                                                onChange={(e) => this.setState({ Is_Agree: e.target.checked, Is_AgreeErr: '' })}
                                            />
                                            <strong>I agree the rules & guidelines for Onsite handling in India ITME 2024<span className="required">*</span>.</strong></label>{<span className='text-danger text-start text-left d-block mt-1'>{Is_AgreeErr}</span>}
                                    </form>
                                </div>

                            </div>
                            <div className="row">
                                {currentDate > DeadlineDate &&
                                    <span className="text-danger">THE DEADLINE TO FILL OUT THE FORM HAS PASSED.</span>
                                }
                            </div>
                            <div class='d-flex m-3'>
                                <button
                                    type="button"
                                    className="btn btn-primary rounded-0 d-block mt-3 mb-3"
                                    style={{ marginRight: 10 }}
                                    disabled={currentDate > DeadlineDate }
                                    onClick={() => this.handleSubmit()}
                                >
                                    Submit
                                </button>
                                <button className="btn rounded-0 d-block mt-3 mb-3 apper-cancel" onClick={() => this.handleCancel()}>Back</button>

                            </div>

                        </div>
                    </div>
                </div >
            </>
        )
    }
}
export default withRouter(connect(null, { getFrieghtForwardDetails_action, insertUpdateFrieghtForward_action })(FreightForward))