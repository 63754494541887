import { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import AdminHeader from '../../../common_components/admin_module_header';
import Footerlogin from '../../../common_components/Footerlogin';
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { selectApplication_action } from "../../../actions/exhibitor_module_actions/select_application_action/select_application_action"
import { toastError, toastSuccess } from "../../../utils/constants"
import ApplicationHeader from "../../../common_components/application_header";


class SelectApplication extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            selectApplicationList: [],
        }
        this.handleView = this.handleView.bind(this)
        this.getSelectApplication = this.getSelectApplication.bind(this)
    }


    componentDidMount() {
        this.getSelectApplication()
    }

    // get SelectApplication
    getSelectApplication() {
        this.setState({ isLoading: true })

        console.log("ooooooooooooooooooooooooooooooooooooo", sessionStorage.getItem("RoleIDSS",))
        this.props.selectApplication_action()
            .then((data) => {
                if (data.error != 1) {
                    if (data.data.length == 1) {
                        console.log("get APplication-----------", data.data)
                        sessionStorage.setItem("ApplicationDataSS", JSON.stringify(data.data[0]))
                        sessionStorage.setItem('ServiceIDSS', data.data[0]['ServiceID'])
                        sessionStorage.setItem('ExhRegistrationIDSS', data.data[0]['ExhRegistrationID'])
                        sessionStorage.setItem('ExhRegistrationNo', data.data[0]['ExhRegistrationNo'])
                        sessionStorage.setItem('isPaidByAgentRegistration', data.data[0]['ISPaidByAgentRegistration'] ? 1 : 0)
                        sessionStorage.setItem('CurrencyID', data.data[0]['CurrencyID'])
                        this.props.navigate('/exhibitor_dashboard')
                    }
                    this.setState({ selectApplicationList: data.data, isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    handleView(id, data) {
        sessionStorage.setItem("ApplicationDataSS", JSON.stringify(data))
        sessionStorage.setItem('ServiceIDSS', data.ServiceID)
        sessionStorage.setItem('ExhRegistrationIDSS', data.ExhRegistrationID)
        sessionStorage.setItem('ExhRegistrationNo', data.ExhRegistrationNo)
        this.props.navigate('/exhibitor_dashboard', { state: { serviceId: id } })
    }

    render() {
        const { isLoading, selectApplicationList } = this.state
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <ApplicationHeader />
                {/* <AdminHeader /> */}
                <div className="container-fluid top-space main-area-start">
                    <div className="row">
                        <div className="step-name">
                            <p>Choose your Application</p>
                        </div>
                    </div>
                </div>
                <div className="container top-space">
                    <div className="row ">
                        {selectApplicationList.length > 0 && selectApplicationList.map(data => (
                            <div className="col-md-3 ">
                                <div
                                    className="select-app text-center p-3"
                                    style={{ paddingBottom: "0px !important" }}
                                >
                                    <div className="app-single-row ">
                                        <h4 className="mb-0" style={{ color: "#e95921" }}>
                                            {data.ExhRegistrationNo}
                                        </h4>
                                        <p className="mb-2">Application Ref#</p>
                                    </div>
                                    <div className="app-single-row mt-4">
                                        <h4 className="mb-0" style={{ color: "#c12035" }}>
                                            {data.StallArea}-{data.StallTypeName}
                                        </h4>
                                        <p className="mb-2">Booth Area (m2)</p>
                                    </div>
                                    <div className="app-single-row mt-4">
                                        <h4 className="mb-0" style={{ color: "#3b8d58" }}>
                                            {data.ChapterName}
                                        </h4>
                                        <p className="mb-2">Main Category</p>
                                    </div>
                                    <div className="app-single-row mt-4" style={{ borderBottom: "none" }}>
                                        <h4 className="mb-0" style={{ color: "#a7671c" }}>
                                            Applied
                                        </h4>
                                        <p className="mb-1">Booking Status</p>
                                    </div>
                                    <a>
                                        <button
                                            type="button"
                                            className="btn btn-outline-light text-white mt-3"
                                            style={{
                                                background: "#3b8d58",
                                                width: "111%",
                                                borderRadius: 0,
                                                marginLeft: "-13px"
                                            }}
                                            onClick={(e) => this.handleView(data.ServiceID, data)}
                                        >
                                            View
                                        </button>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row p-3">
                        <a href="#">
                            <button
                                type="button"
                                className="btn btn-outline-light text-dark mt-3"
                                style={{
                                    background: "#f2e9e5",
                                    width: "24%",
                                    borderRadius: 0,
                                    border: "1px solid #eb7520",
                                    marginLeft: "-13px"
                                }}
                            >
                                Additional Stall Registration
                            </button>
                        </a>
                    </div>
                </div>
                <Footerlogin />
            </>

        )
    }
}



export default withRouter(connect(null, { selectApplication_action })(SelectApplication));