import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import $ from "jquery";
import Modal from "react-modal";
import { withRouter } from "../utils/withRouter";
import { axiosPost, axiosGet } from "../react_axios/Axios";
import {
  bonafideDocName,
  fetchAuthReport,
  downloadReport,
  ParticipationCertificateDocName,
  NoDuesCertificateDoc,
  toastError,
} from "../utils/constants";
import Loader from "../utils/Loader";
import { insertExhibitorBarcode_action } from "../actions/exhibitor_module_actions/exhibitor_dashboard_action/exhibitor_dashboard_action";
import { connect, useDispatch } from "react-redux";

const ExhibitorDrawer = ({ head, userId }) => {
  const history = useNavigate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginAccesslist, setLoginAccesslist] = useState([]);
  const [address, setAddress] = useState([]);

  //toggle model for logout.................................
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const loadScripts = () => {
    // This array contains all the files/CDNs
    // const dynamicScripts = [
    //   'js/jquery.sticky.js',
    //   'js/metisMenu.min.js',
    //   'js/metisMenu-active.js',
    //   'js/main.js'
    // ];
    // for (let i = 0; i < dynamicScripts.length; i++) {
    //   const node = document.createElement('script');
    //   node.src = dynamicScripts[i];
    //   node.type = 'text/javascript';
    //   node.async = false;
    //   document.getElementsByTagName('head')[0].appendChild(node);
    // }
  };
  const logout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  useEffect(() => {
    loadScripts();
    // console.log("233",JSON.parse(sessionStorage.getItem('LoginAccesslist'))[0]['IsRead'])
    // setLoginAccesslist(JSON.parse(sessionStorage.getItem('LoginAccesslist')))
  }, []);

  const onRedirect = (path) => {
    history.push({ pathname: path, state: { isSearchClear: true } });
  };

  const onBonafiedDownloadClick = async () => {
    setIsLoading(true);
    var list = [];
    const mapdata1 = {
      ParameterName: "@ExhibitionID",
      ParameterValue: sessionStorage.getItem("sessionExhibitionID"),
      ParameterDataType: "int",
    };
    const mapdata2 = {
      ParameterName: "@ExhRegistrationID",
      ParameterValue: sessionStorage.getItem("ExhRegistrationIDSS"),
      ParameterDataType: "int",
    };

    list.push(mapdata1);
    list.push(mapdata2);

    const mapData = {
      report_name: bonafideDocName,
      parameter_details: list,
    };
    const res = await axiosPost(fetchAuthReport, mapData);
    setIsLoading(false);
    if (res != null) {
      var url = `${downloadReport}?SessionIdentifier=${res}&Export=Yes`;
      const win = window.location.replace(url, "_blank");
      if (win != null) {
        win.focus();
      }
    }
  };

  const onParticipationDownloadClick = async () => {
    setIsLoading(true);
    var list = [];
    const mapdata1 = {
      ParameterName: "@ExhRegistrationID",
      ParameterValue: sessionStorage.getItem("ExhRegistrationIDSS"),
      ParameterDataType: "int",
    };
    const mapdata2 = {
      ParameterName: "@ExhibitionID",
      ParameterValue: sessionStorage.getItem("sessionExhibitionID"),
      ParameterDataType: "int",
    };

    list.push(mapdata1);
    list.push(mapdata2);

    const mapData = {
      report_name: ParticipationCertificateDocName,
      parameter_details: list,
    };
    const res = await axiosPost(fetchAuthReport, mapData);
    setIsLoading(false);
    if (res != null) {
      var url = `${downloadReport}?SessionIdentifier=${res}&Export=Yes`;
      const win = window.location.replace(url, "_blank");
      if (win != null) {
        win.focus();
      }
    }
  };

  const onDownloadNoDues = async () => {
    setIsLoading(true);
    try {
      const data = insertExhibitorBarcode_action();
      await dispatch(data);

      console.log("jjjjjjjjjjjjjjj");
      if (data.error != 1) {
        var list = [];
        const mapdata1 = {
          ParameterName: "@ExhibitionID",
          ParameterValue: parseInt(
            sessionStorage.getItem("sessionExhibitionID")
          ),
          ParameterDataType: "int",
        };
        const mapdata2 = {
          ParameterName: "@ExhRegistrationID",
          ParameterValue: parseInt(
            sessionStorage.getItem("ExhRegistrationIDSS")
          ),
          ParameterDataType: "int",
        };
        list.push(mapdata1);
        list.push(mapdata2);
        const mapData = {
          report_name: NoDuesCertificateDoc,
          parameter_details: list,
        };
        console.log("xxxxxxxxxxxxxxxxxxxxx", mapData);

        const res = await axiosPost(fetchAuthReport, mapData);
        setIsLoading(false);

        if (res != null) {
          console.log("jjjjjjjjjjjjjjj");
          var url = `${downloadReport}?SessionIdentifier=${res}&Export=Yes`;
          const win = window.location.replace(url, "_blank");
          if (win != null) {
            win.focus();
          }
        }
      } else {
        setIsLoading(false);
        toastError(data.msg);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sessionHeaderData = JSON.parse(
    sessionStorage.getItem("sessionHeaderData")
  );

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={(e) => toggleModal()}>
        <div className="modal-dialog custom_modal_dialog">
          <div className="modal-content" style={{ borderRadius: "0px" }}>
            <div class="modal-header">
              <h4 class="modal-title modal_title_text">Confirm Logout</h4>
              {/* <button type="button" class="close modal-close" data-dismiss="modal" onClick={this.toggleModal}>&times;</button> */}
              <button
                type="button "
                class="close pop-close text-dark"
                data-dismiss="modal"
                onClick={(e) => toggleModal()}
              >
                <span aria-hidden="true" class="pop-cancel">
                  &times;
                </span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="pop_label">Do you really want to logout?</h3>
                </div>
              </div>
            </div>

            <div class="row ">
              <div class="col-md-12">
                <div class="form-group text-right mod-fot">
                  <a class="btn save_btn del-pop-save" onClick={logout}>
                    {" "}
                    Yes
                  </a>
                  <a
                    class="btn cancel_btn del-pop-cancel"
                    data-dismiss="modal"
                    style={{ marginLeft: "5px" }}
                    onClick={toggleModal}
                  >
                    {" "}
                    No
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {isLoading ? <Loader></Loader> : null}
      <div
        className="app-sidebar ps ps--active-y"
        style={{ backgroundColor: "#fdfdfd" }}
      >
        <div className="side-header" style={{ width: "214px" }}>
          <a className="header-brand1" href="index.html">
            {/*  <img src="assets/images/brand/logo-white.png" class="header-brand-img desktop-logo" alt="logo">
                  <img src="assets/images/brand/icon-white.png" class="header-brand-img toggle-logo" alt="logo">*/}
            <img
              src={sessionStorage.getItem("ExhibitionLogoSS")}
              className="header-brand-img light-logo"
              alt="logo"
              style={{ width: "40px" }}
            />
            <img
              src={sessionStorage.getItem("ExhibitionLogoSS")}
              className="header-brand-img light-logo1"
              alt="logo"
              style={{ width: "40px" }}
            />
          </a>
          {/* LOGO */}
        </div>
        <div className="main-sidemenu">
          <div className="slide-left disabled d-none" id="slide-left" />
          <ul
            className="side-menu p-2 user-info"
            style={{ marginRight: "0px" }}
          >
            <h4
              className="user-name pt-2"
              style={{ textTransform: "uppercase" }}
            >
              Welcome -{" "}
              {JSON.parse(sessionStorage.getItem("loginDataSS"))[
                "CompanyName"
              ] != null &&
                JSON.parse(sessionStorage.getItem("loginDataSS"))[
                "CompanyName"
                ]}
            </h4>
            {/* <Link to={'/Change_Password'}>
                            <p className='mb-0 change-pas'><i class="fa fa-cog"></i> Change Password</p>
                        </Link> */}
            <p className="">
              {sessionStorage.getItem("sessionExhibitorDrawerData") != null &&
                JSON.parse(
                  sessionStorage.getItem("sessionExhibitorDrawerData")
                )[0]["Header"]}
            </p>
            <p>
              <strong>
                {sessionStorage.getItem("sessionExhibitorDrawerData") != null &&
                  JSON.parse(
                    sessionStorage.getItem("sessionExhibitorDrawerData")
                  )[0]["Header2"]}
              </strong>
              <br />{" "}
              {JSON.parse(sessionStorage.getItem("FormatedAddress")) != null &&
                `${JSON.parse(sessionStorage.getItem("FormatedAddress"))[0] !=
                null &&
                JSON.parse(sessionStorage.getItem("FormatedAddress"))[0]
                },
                                 ${JSON.parse(
                  sessionStorage.getItem("FormatedAddress")
                )[1] != null &&
                JSON.parse(
                  sessionStorage.getItem("FormatedAddress")
                )[1]
                },`}{" "}
              <br />
              {JSON.parse(sessionStorage.getItem("FormatedAddress")) != null &&
                `${JSON.parse(sessionStorage.getItem("FormatedAddress"))[2] !=
                null &&
                JSON.parse(sessionStorage.getItem("FormatedAddress"))[2]
                },
                            ${JSON.parse(
                  sessionStorage.getItem("FormatedAddress")
                )[3] != null &&
                JSON.parse(
                  sessionStorage.getItem("FormatedAddress")
                )[3]
                },`}{" "}
              <br />
              {JSON.parse(sessionStorage.getItem("FormatedAddress")) != null &&
                `${JSON.parse(sessionStorage.getItem("FormatedAddress"))[4] !=
                null &&
                JSON.parse(sessionStorage.getItem("FormatedAddress"))[4]
                },
                            ${JSON.parse(
                  sessionStorage.getItem("FormatedAddress")
                )[5] != null &&
                JSON.parse(
                  sessionStorage.getItem("FormatedAddress")
                )[5]
                },`}{" "}
              <br />
              {JSON.parse(sessionStorage.getItem("FormatedAddress")) != null &&
                `${JSON.parse(sessionStorage.getItem("FormatedAddress"))[6] !=
                null &&
                JSON.parse(sessionStorage.getItem("FormatedAddress"))[6]
                }`}
            </p>
            <p className="mb-1">
              {/* <strong style={{ color: "#000" }}>Phone:</strong> */}
              {sessionStorage.getItem("sessionExhibitorDrawerData") != null &&
                JSON.parse(
                  sessionStorage.getItem("sessionExhibitorDrawerData")
                )[0]["Phone"]}
            </p>
            <p className="mb-1">
              {/* <strong style={{ color: "#000" }}>Mobile No:</strong>  */}
              {sessionStorage.getItem("sessionExhibitorDrawerData") != null &&
                JSON.parse(
                  sessionStorage.getItem("sessionExhibitorDrawerData")
                )[0]["MobileNo"]}
            </p>
            <p className="mb-3">
              {/* <strong style={{ color: "#000" }}>E-mail:</strong> */}
              {sessionStorage.getItem("sessionExhibitorDrawerData") != null &&
                JSON.parse(
                  sessionStorage.getItem("sessionExhibitorDrawerData")
                )[0]["Email"]}
            </p>
            {(sessionHeaderData &&
              sessionHeaderData[0] &&
              (sessionHeaderData[0]["Is_100Percent"] ||
                sessionHeaderData[0]["Bonafide Certificate"] == "Yes")) ||
              JSON.parse(
                sessionStorage.getItem("isDownloadBonafideCertificate")
              ) == true ? (
              <div
                className="card mb-3"
                style={{
                  background: "#eee",
                  border: "1px solid #ddd !important",
                }}
              >
                <div className="card-header header-cer p-2">
                  Bonafide Certificate
                </div>
                <div className="card-body p-2 text-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => onBonafiedDownloadClick()}
                  >
                    Download
                  </button>
                </div>
              </div>
            ) : null}
            {/* {
                            JSON.parse(sessionStorage.getItem('sessionHeaderData')) &&
                            JSON.parse(sessionStorage.getItem('sessionHeaderData')) != null &&
                            JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['Is_100Percent'] != null &&
                            (JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['Is_100Percent'])
                            &&
                            <div
                                className="card mb-3"
                                style={{ background: "#eee", border: "1px solid #ddd !important" }}
                            >
                                <div className="card-header header-cer p-2">
                                    Participation Certificate
                                </div>
                                <div className="card-body p-2 text-center">
                                    <button type="button" className="btn btn-outline-primary"
                                        onClick={() => onParticipationDownloadClick()}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        } */}
            {(JSON.parse(sessionStorage.getItem("sessionHeaderData")) &&
              JSON.parse(sessionStorage.getItem("sessionHeaderData"))[0][
              "Is_100Percent"
              ] != null &&
              JSON.parse(sessionStorage.getItem("sessionHeaderData"))[0][
              "Is_100Percent"
              ]) ||
              (sessionStorage.getItem("isPaidByAgentRegistration") != null &&
                sessionStorage.getItem("isPaidByAgentRegistration") == 1) ||
              JSON.parse(
                sessionStorage.getItem("isParticipationCertificateDashboard")
              ) == true ? (
              <div
                className="card mb-3"
                style={{
                  background: "#eee",
                  border: "1px solid #ddd !important",
                }}
              >
                <div className="card-header header-cer p-2">
                  Participation Certificate
                </div>

                <div className="card-body p-2 text-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => onParticipationDownloadClick()}
                  >
                    Download
                  </button>
                </div>
              </div>
            ) : null}
            {/* <div
                            className="card mb-3"
                            style={{ background: "#eee", border: "1px solid #ddd !important" }}
                        >
                            <div className="card-header header-cer p-2">
                                No-Dues Certificate
                            </div>
                            <div className="card-body p-2 text-center">
                                <button type="button" className="btn btn-outline-primary"
                                // onClick={() => onBonafiedDownloadClick()}
                                >
                                    Download
                                </button>
                            </div>
                        </div> */}
            {(sessionHeaderData &&
              sessionHeaderData[0] &&
              (sessionHeaderData[0]["IS_NoDueCertificate_Sent"] ||
                sessionHeaderData[0]["Bonafide Certificate"] == "Yes")) ||
              JSON.parse(
                sessionStorage.getItem("isNoDuescertificateDashboard")
              ) == true ? (
              <div className="card">
                <div className="card-body p-0 text-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => onDownloadNoDues()}
                  >
                    No Dues certificate And <br />
                    Stall Possession
                  </button>
                </div>
              </div>
            ) : null}
            <div style={{ height: "70px" }}></div>
          </ul>
        </div>
        <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
          <div
            className="ps__thumb-x"
            tabIndex={0}
            style={{ left: 0, width: 0 }}
          />
        </div>
        <div className="ps__rail-y" style={{ top: 0, height: 625, right: 0 }}>
          <div
            className="ps__thumb-y"
            tabIndex={0}
            style={{ top: 0, height: 573 }}
          />
        </div>
      </div>
    </>
  );
};
export default withRouter(connect(null, {})(ExhibitorDrawer));
