import { Component } from "react";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import AgentDrawer from "../../../common_components/agent_module_drawer";
import AgentHeader from "../../../common_components/agent_module_header";
import AgentTopMenu from "../../../common_components/agent_module_topmenu";
import { getDrawerDetails_action } from "../../../actions/exhibitor_module_actions/exhibitor_dashboard_action/exhibitor_dashboard_action"
import { downloadReport, exhRegUrl, loginUrl, toastError, toastSuccess, primaryChapterListActionId } from "../../../utils/constants";
import { getAgentDashboard_action, getExhibitorDetail_action } from "../../../actions/agent_module_action/agent_dashboard_action/agent_dashboard_action";
import { cssNumber, data } from "jquery";
import Modal from "react-modal";
import { descriptionPreview_action } from "../../../actions/description_action";
import {
    sendUserCred_action
} from "../../../actions/admin_module_action/exhibitor_list_action/exhibitor_list_action";
import { fill_action } from '../../../actions/fillCombo_action'


class AgentDashboard extends Component {

    // Initialize Constructor
    constructor(props) {
        super(props)
        // States
        this.state = {
            isLoading: false,
            bookedChapterList: [],
            exhibitorList: [],
            isOpen: false,
            isOpen2: false,
            areaTotal: "",
            balanceTotal: "",
            bookedTotal: "",
            exhId: '',
        }
        this.getDrawerDetails = this.getDrawerDetails.bind(this)
        this.getAgentDashboard = this.getAgentDashboard.bind(this)
        this.addExhibitor = this.addExhibitor.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.getExhibitordetail = this.getExhibitordetail.bind(this)
        this.calculateTotals = this.calculateTotals.bind(this)
        this.onLoginClick = this.onLoginClick.bind(this)
        this.sendCredModal = this.sendCredModal.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.resendUserCred = this.resendUserCred.bind(this)

    }

    componentDidMount() {
        this.getDrawerDetails()
        // this.setState({ isLoading: true })
        this.getAgentDashboard().then(res => {
            res && this.getFillList(primaryChapterListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '', res).then(res2 => {
                res2 && this.setState({ isLoading: false })
            })
        })
    }

    // Get Drawer Details
    getDrawerDetails() {
        this.setState({ isLoading: true })
        this.props.getDrawerDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get getDrawerDetails", data.data)
                    sessionStorage.setItem("sessionAgentDrawerData", JSON.stringify(data.data))
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // Get Agent Details
    getAgentDashboard() {
        this.setState({ isLoading: true })
        return new Promise((resolve, reject) => {
            this.props.getAgentDashboard_action()
                .then((data) => {
                    if (data.error != 1) {
                        console.log("Get getVisitorDashboard", data.data['chapterDetails'])
                        sessionStorage.setItem("sessionExhibitionCode", data.data['exhibitionCode'] != null && data.data['exhibitionCode'] != '' ? data.data['exhibitionCode'] : '')
                        this.setState({
                            // bookedChapterList: data.data['chapterDetails'] != null ? data.data['chapterDetails'] : [],
                            exhibitorList: data.data['exhibitorDetails'] != null ? data.data['exhibitorDetails'] : [],
                            // isLoading: false
                        })
                        this.calculateTotals(data.data['chapterDetails'] != null ? data.data['chapterDetails'] : [])
                        resolve(data.data['chapterDetails'])
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                }).catch((err) => {
                    console.log(err)
                })
        })
    }

    // Calculate Totals
    calculateTotals(chapterData) {
        console.log("Area Data", data)
        try {
            let totalAreaTotal = 0;
            let bookedAreaTotal = 0;
            let balnceAreaTotal = 0;

            chapterData.forEach((data) => {

                totalAreaTotal += Number(data['TotalArea (Sqm)'])
                bookedAreaTotal += Number(data['BookedArea (Sqm)']);
                balnceAreaTotal += Number(data['BalanceArea (Sqm)']);

            });
            this.setState({
                areaTotal: totalAreaTotal,
                balanceTotal: balnceAreaTotal,
                bookedTotal: bookedAreaTotal
            })
            console.log("All totals", totalAreaTotal, bookedAreaTotal, balnceAreaTotal)
            return {

                totalAreaTotal,
                bookedAreaTotal,
                balnceAreaTotal,
            };

        } catch (e) {
            console.log('exception : ', e);
        }

    }

    resendUserCred(e, exhregId) {
        this.setState({ isLoading: true })
        this.props.sendUserCred_action(exhregId, 1)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false, isOpen2: false })
                    toastSuccess("UserID & Password Sent")
                } else {
                    this.setState({ isLoading: false, isOpen2: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Add Exhibitor Click
    addExhibitor() {
        var agentCode = sessionStorage.getItem('IsAgentDirectLogin') != null && sessionStorage.getItem('IsAgentDirectLogin') == "false" ?
            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS'))['AgentCode'] != null &&
            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS'))['AgentCode'] :
            JSON.parse(sessionStorage.getItem('loginDataSS'))['AgentCode'] != null &&
            JSON.parse(sessionStorage.getItem('loginDataSS'))['AgentCode']
        const url = `/#/${sessionStorage.getItem("sessionExhibitionCode")}-${agentCode}`
        window.open(url, "_blank");
    }

    // Modal Toggle
    toggleModal() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from, list) {
        const { isLoading, bookedChapterList } = this.state
        return new Promise((resolve, reject) => {
            this.props
                .fill_action(actionID, exhibitionID, moduleID, keyValue, value, sessionStorage.getItem("sessionExhRegistrationID"))
                .then((data) => {
                    if (data.error != 1) {
                        // country list
                        if (actionID === primaryChapterListActionId) {
                            this.setState({ isLoading: true })
                            async function seqnoFormat(arr) {
                                for (var i = 0; i < arr.length; i++) {
                                    if (arr[i].seqno != '' && arr[i].seqno != null) {
                                        for (var j = 0; j < arr[i].ChapterName.length; j++) {
                                            if (arr[i].ChapterName[j] == ' ') {
                                                var str = arr[i].ChapterName.slice(j)
                                                var finalName = arr[i].seqno + str
                                                arr[i].ChapterName = finalName
                                                // console.log("primaryChapterListActionId 121212", i, finalName)
                                                break
                                            }
                                        }
                                    }
                                }
                                return
                            }
                            for (var i = 0; i < data.data['level2'].length; i++) {
                                for (var j = 0; j < data.data['level2'].length - 1 - i; j++) {
                                    var seq1 = data.data['level2'][j].seqno
                                    var seq2 = data.data['level2'][j + 1].seqno
                                    if ((parseFloat(seq1) > parseFloat(seq2))) {
                                        var temp = data.data['level2'][j]
                                        data.data['level2'][j] = data.data['level2'][j + 1]
                                        data.data['level2'][j + 1] = temp;
                                        // console.log(i, j, arr[j])
                                    }
                                }
                                data.data['level2'][i].primaryChapterId = data.data['level2'][i].ParentChapterID
                                seqnoFormat(data.data['level2'])
                            }
                            var finalList = []
                            for (var j = 0; j < data.data['level2'].length; j++) {
                                for (var i = 0; i < list.length; i++) {
                                    if (list[i].ChapterID == data.data['level2'][j].ChapterID) {
                                        list[i].Chapters = data.data['level2'][j].ChapterName
                                        finalList.push(list[i])
                                    }
                                }
                            }
                            console.log("hererer", list)
                            this.setState({ bookedChapterList: finalList })
                            this.setState({ isLoading: false })
                            resolve(true)
                        }

                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        })
    }

    // Get Exhibitor details form Modal
    getExhibitordetail(e, id) {
        this.setState({ isLoading: true })
        this.props.descriptionPreview_action(id)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    console.log("ExhRegistration  Pdf Download", data)
                    var url = `${downloadReport}?SessionIdentifier=${data}&Export=true`;
                    const win = window.location.replace(url, '_blank');
                    if (win != null) {
                        win.focus();
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    sendCredModal(id) {
        const { isOpen2 } = this.state
        this.setState({ isOpen2: !isOpen2, exhId: id })
    }

    // On Client Click
    onLoginClick(data) {
        var exhId = data.ExhibitionID
        var id = data.UserName
        // var pass = data.Password
        // var password = pass.replace('/', '§');
        var loginContent = `${exhId}--${id}`
        // --${password}`
        var url = `${loginUrl}${loginContent}`
        window.open(url, "_blank");
    }

    render() {
        const { isLoading, bookedChapterList, exhibitorList, isOpen, areaTotal, isOpen2, balanceTotal, bookedTotal } = this.state
        return (

            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <AgentHeader />
                <AgentDrawer />
                <div className="main-content with-dr-container">
                    <div className="">
                        <div className="">
                            <div className=" width-dr-nav">
                                <div className="row">
                                    <AgentTopMenu />
                                </div>
                            </div>
                            <div className="col-xl-12 p-0">
                                <div className="card rounded-0 pt-1">
                                    <div className="card-header p-3">
                                        <h3 className="card-title">Agent Dashboard</h3>
                                    </div>
                                    <div className="card-body p-2">
                                        {/* <button
                                            className="btn btn-outline-primary p-1"
                                            type="btn"
                                            style={{ float: "right" }}
                                        >
                                            Proforma Invoice-2
                                        </button>
                                        <button
                                            className="btn btn-outline-primary p-1"
                                            type="btn"
                                            style={{ float: "right", marginRight: 5, marginLeft: 5 }}
                                        >
                                            Proforma Invoice
                                        </button> */}
                                        <button
                                            className="btn btn-outline-primary p-1"
                                            type="btn"
                                            style={{ float: "right" }}
                                            onClick={(e) => this.addExhibitor(e)}
                                        >
                                            Add Exhibitor
                                        </button>
                                        <h5 className="mt-6 mb-1 text-left">
                                            <strong>Chapter wise booking summary</strong>
                                        </h5>
                                        <div className="table-responsive">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th>Chapters</th>
                                                        <th className="text-center">Total Area (sqm)</th>
                                                        <th className="text-center">Booked Area (sqm)</th>
                                                        <th className="text-center">Balance Area (sqm)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bookedChapterList.length > 0 && bookedChapterList.map((data) => (
                                                            <tr key={Math.random()}>
                                                                <td>{data.Chapters}</td>
                                                                <td className="text-center">{data['TotalArea (Sqm)']}</td>
                                                                <td className="text-center">{data['BookedArea (Sqm)']}</td>
                                                                <td className="text-center">{data['BalanceArea (Sqm)']}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr>
                                                        <td><strong>Total</strong></td>
                                                        <td className="text-center"><strong>{areaTotal}</strong></td>
                                                        <td className="text-center"><strong>{bookedTotal}</strong></td>
                                                        <td className="text-center"><strong>{balanceTotal}</strong></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <h5 className="mt-5 mb-1 text-left">
                                            <strong>Applicant list</strong>
                                        </h5>
                                        <div className="table-responsive ">
                                            <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ width: 50 }}>
                                                            Sr.No
                                                        </th>
                                                        <th>Exhibitor name</th>
                                                        <th>Chapters</th>
                                                        <th className="text-center" style={{ width: 100 }}>
                                                            Area (sqm)
                                                        </th>
                                                        <th>Scheme</th>
                                                        <th className="text-center" style={{ width: 100 }}>
                                                            Info
                                                        </th>
                                                        <th className="text-center" style={{ width: 100 }}>

                                                        </th>
                                                        <th className="text-center" style={{ width: 100 }}>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        exhibitorList.length > 0 ? exhibitorList.map((data, index) => (
                                                            <tr key={Math.random()}>
                                                                <td className="text-center">{index + 1}</td>
                                                                <td>{data.ExhibitorName}</td>
                                                                <td>{data.ChapterName}</td>
                                                                <td className="text-center">{data.Area}</td>
                                                                <td>{data.Scheme}</td>
                                                                <td className="text-center">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        // data-bs-toggle="modal"
                                                                        // data-bs-target="#detail"
                                                                        style={{ padding: "1px 10px" }}
                                                                        // onClick={(e) => this.toggleModal(e)}
                                                                        onClick={(e) => this.getExhibitordetail(e, data.ExhRegistrationID)}
                                                                    >
                                                                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                    </button>
                                                                </td>

                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-outline-warning btn-sm"
                                                                        onClick={(e) => this.sendCredModal(data.ExhRegistrationID)}
                                                                    >
                                                                        Send Login Credentials
                                                                    </button>
                                                                </td>

                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-outline-info btn-sm"
                                                                        onClick={(e) => this.onLoginClick(data)}
                                                                    >
                                                                        Login
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )) :
                                                            <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* Button trigger modal */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={(e) => this.toggleModal(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Estimate Details</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal(e)}
                                    />
                                </div>
                                <div className="modal-body p-2">
                                    <div className="table-responsive ">
                                        <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                            <thead>
                                                <tr>
                                                    <th>Description (Total Area 9 SHELL SCHEME)</th>
                                                    <th className="text-center" style={{ width: 100 }}>
                                                        Total Stall Charges in USD
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Space rent @ 350.00/- per Sqm</td>
                                                    <td className="text-center">3150.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Taxable</td>
                                                    <td className="text-center">3150.00</td>
                                                </tr>
                                                <tr>
                                                    <td>IGST(18 %)</td>
                                                    <td className="text-center">567.00</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>Grand Total</strong>
                                                    </td>
                                                    <td className="text-center">
                                                        <strong>3717.00</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.toggleModal(e)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={isOpen2} onRequestClose={(e) => this.sendCredModal(null)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Send Credentials</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.sendCredModal(null)}
                                    />
                                </div>
                                <div className="modal-body p-2">
                                    <p>Do you really want to send Login Credentials to Exhibitor?</p>
                                </div>
                                <div className="modal-footer p-1">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.resendUserCred(e, this.state.exhId)}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => this.sendCredModal(null)}
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, {
    getDrawerDetails_action, getAgentDashboard_action, getExhibitorDetail_action,
    descriptionPreview_action, sendUserCred_action, fill_action
})(AgentDashboard))