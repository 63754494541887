import { Component } from "react"
import Loader from "../../../utils/Loader"
import { ToastContainer } from "react-toastify"
import HeaderWithLogo from "../../../common_components/header_with_logo"
import AdminDrawer from "../../../common_components/admin_module_drawer"
import { withRouter } from "../../../utils/withRouter"
import { connect } from "react-redux"
import { loginUrl, toastError, visitorStatusActionId, toastSuccess, badgeReportName, fetchAuthReport, downloadReport, exhibitionActionId } from "../../../utils/constants"
import { visitorList_action, sendUserCred_action, sendVisitorBadge_action, insertVisitorBadgeAccessLog_action, getVisitorBadgeAccessLog_action } from "../../../actions/admin_module_action/visitor_list_action/visitorList_action"
import Pagination from "../../../utils/pagination";
import { fill_action } from "../../../actions/fillCombo_action"
import Workbook from 'react-excel-workbook'
import { axiosPost } from "../../../react_axios/Axios"

class VisitorList extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            visitor: "",
            company: "",
            exhibition: "",
            visitorList: [],
            visitorStatusList: [],
            visitorStatusId: "",
            checkedAll: false,
            visRegNo: "",
            visitorBadgeLog: [],
            loginAccesslist: [],

            exhibitionList: [],
            exhibitionID: ''
        }
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.getVisitorList = this.getVisitorList.bind(this)
        this.updateItem = this.updateItem.bind(this)
        this.getFillList = this.getFillList.bind(this)
        this.onLogin = this.onLogin.bind(this)
        this.resendUserCred = this.resendUserCred.bind(this)
        this.onCreateExcel = this.onCreateExcel.bind(this)
        this.selectAllVisitors = this.selectAllVisitors.bind(this)
        this.handleCheckedVisitors = this.handleCheckedVisitors.bind(this)
        this.sendBadge = this.sendBadge.bind(this)
        this.onBadgeView = this.onBadgeView.bind(this)
        this.onBadgeDownload = this.onBadgeDownload.bind(this)
        this.insertVisitorBadgeAccessLog = this.insertVisitorBadgeAccessLog.bind(this)
        this.getVisitorBadgeAccessLog = this.getVisitorBadgeAccessLog.bind(this)
        this.onCreateExcel2 = this.onCreateExcel2.bind(this)
        this.getSendAccess = this.getSendAccess.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
        this.getSpecialAccess = this.getSpecialAccess.bind(this)
    }

    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getFillList(visitorStatusActionId, '', '', '', '', '');
        this.getFillList(exhibitionActionId, '', '', '', '', '');
        this.getVisitorList()
        this.getVisitorBadgeAccessLog()
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // Visitor Status
                    if (actionID === visitorStatusActionId) {
                        console.log("Visitor Status data", data.data)
                        this.setState({
                            visitorStatusList: data.data, isLoading: false,
                        })
                    }
                    //exhibition
                    if (actionID === exhibitionActionId) {
                        console.log("Stall Posession Status list data", data.data)
                        this.setState({
                            exhibitionList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // For Visitor List
    getVisitorList() {
        const { visitor, company, exhibitionID, visitorStatusId, visRegNo } = this.state
        this.setState({ isLoading: true })
        this.props.visitorList_action(visitor, company, exhibitionID,
            visitorStatusId != "" && visitorStatusId != "Select Visitor Status" ? visitorStatusId : "", visRegNo)
            .then((data) => {
                if (data.error != 1) {
                    data.data.length > 0 && data.data.map((item) => {
                        item.isSelect = false
                    })
                    this.setState({
                        visitorList: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    handleKeyDown(e) {
        if (e.code == 'Enter') {
            this.getVisitorList()
        }
    }

    // On Search Click
    onSearchClick() {
        const { visitor, exhibitionID, company, visitorStatusId, visRegNo } = this.state
        if (visitor != "" || exhibitionID != "" || company != "" || (visitorStatusId != "" && visitorStatusId != "Select Visitor Status") ||
            visRegNo != "") {
            this.getVisitorList()
        } else {
            toastError("Atleast Fill One field to Search")
        }
    }

    clearValue() {
        this.setState({
            visitor: "",
            exhibition: "",
            company: "",
            visitorStatusId: "",
            visRegNo: "",
            exhibitionID: ''
        })
        setTimeout(() => {
            this.getVisitorList()
        }, 500);
    }

    // Update Current Page
    updateItem(item) {
        const { visitorList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = visitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(visitorList.length / recordsPerPage)
    }

    // On Login Click
    onLogin(data) {
        var exhId = data.ExhibitionID
        var id = data.UserName
        var pass = data.Password
        var password = pass.replace('/', '§');
        console.log("passContent", password)
        var loginContent = `${exhId}--${id}--${password}`
        var url = `${loginUrl}${loginContent}`
        window.open(url, "_blank");
    }

    // On Resend User ID & Password to exhibitors
    resendUserCred(e, exhVisId, data) {
        if (data.UserName != null && data.UserName != '' && data.Password != null && data.Password != '') {
            this.setState({ isLoading: true })
            this.props.sendUserCred_action(exhVisId)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("UserID & Password Sent")
                    } else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError('Unable to resend the username and password.Please contact to IT person.')
        }

    }

    // to create excel file
    onCreateExcel() {
        const { visitorList } = this.state
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx", visitorList)
        var listData = []
        visitorList.length > 0 && visitorList.map((item, index) => {
            var mapData1 = {
                'SR No': index + 1,

            }
            var finalMapData = { ...mapData1, ...item }
            listData.push(finalMapData)
        })
        console.log('listData', listData);

        var excludeKeys = [
            // 'ExhRegistrationID', 'ExhibitionID',
        ]

        // Function to remove keys from objects
        function removeKeysFromObjects(arr, keys) {
            return arr.map((obj) => {
                const newObj = { ...obj }; // Create a new object to avoid modifying the original
                keys.forEach((key) => delete newObj[key]); // Remove specified keys from the new object
                return newObj;
            });
        }

        // Remove keys from the array of objects
        const newArray = removeKeysFromObjects(listData, excludeKeys);
        console.log("new arrya-->", newArray);

        var columnArray = [],
            rowData = [];
        // for column data 
        newArray.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        newArray.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                {visitorList.length > 0 ? <Workbook filename="VisitorList.xlsx" style={{}} element={
                    <button type="button" className="btn ad-fil-btn"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export to Excel
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook> : <button type="button" className="btn ad-fil-btn" disabled={true}>
                    Export to Excel
                </button>}
            </div>
        );
    }

    //on Select All Click
    selectAllVisitors(e) {
        const { checkedAll, visitorList } = this.state
        this.setState({ checkedAll: !checkedAll })
        const checked = e.target.checked
        visitorList.length > 0 && visitorList.map((data) => {
            if (checked) {
                data.isSelect = true
            } else {
                data.isSelect = false
            }
        })
        console.log("yyyyyyyyyyyyy", visitorList)
    }

    // On Checked exhibitors
    handleCheckedVisitors(e, id) {
        const { visitorList } = this.state
        const checked = e.target.checked

        visitorList.length > 0 && visitorList.map((data) => {
            if (data.ExhVisitorID == id) {
                if (checked) {
                    data.isSelect = true
                } else {
                    data.isSelect = false
                    this.setState({ checkedAll: false })
                }
            }
        })
        console.log("aaaaaaaaaaa", visitorList)
        this.setState({})
    }

    // On Add Edirectory
    sendBadge() {
        const { visitorList } = this.state
        var selectedVisitors = visitorList.length > 0 && visitorList.filter((item) => item.isSelect)
        if (selectedVisitors.length > 0) {
            var list = []
            selectedVisitors.length > 0 && selectedVisitors.map((element) => {
                var mapdata = {
                    "ExhVisitorID": element.ExhVisitorID
                }
                list.push(mapdata)
            })
            this.setState({ isLoading: true })
            this.props.sendVisitorBadge_action(list)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false })
                        toastSuccess("Visitor Badge SendSuccessfully")
                        this.getVisitorList()
                    } else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            toastError("Please Select Atleast one Visitor")
        }
    }

    // On Badge View Click
    onBadgeView = async (data) => {
        this.setState({ isLoading: true });
        try {
            var list = [];
            const mapdata1 = {
                "ParameterName": "@ExhibitionID",
                "ParameterValue": sessionStorage.getItem('sessionExhibitionID'),
                "ParameterDataType": "int"
            };
            list.push(mapdata1);
            const mapdata2 = {
                "ParameterName": "@VisitorID",
                "ParameterValue": data.ExhVisitorID,
                "ParameterDataType": "int"
            };
            list.push(mapdata2);
            const mapData = {
                "report_name": badgeReportName,
                "parameter_details": list
            };
            console.log("xxxxxxxxxxxxxxxxxxxxx", mapData)
            this.insertVisitorBadgeAccessLog(data.ExhVisitorID, 'View')
            const res = await axiosPost(fetchAuthReport, mapData);
            this.setState({ isLoading: false });

            if (res != null) {
                var url = `${downloadReport}?SessionIdentifier=${res}`;
                const win = window.open(url, '_blank', 'noopener,noreferrer');
                if (win != null) {
                    win.focus();
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    // On Badge Download Click
    onBadgeDownload = async (data) => {
        this.setState({ isLoading: true });
        try {
            var list = [];
            const mapdata1 = {
                "ParameterName": "@ExhibitionID",
                "ParameterValue": sessionStorage.getItem('sessionExhibitionID'),
                "ParameterDataType": "int"
            };
            list.push(mapdata1);
            const mapdata2 = {
                "ParameterName": "@VisitorID",
                "ParameterValue": data.ExhVisitorID,
                "ParameterDataType": "int"
            };
            list.push(mapdata2);
            const mapData = {
                "report_name": badgeReportName,
                "parameter_details": list
            };
            console.log("xxxxxxxxxxxxxxxxxxxxx", mapData)
            this.insertVisitorBadgeAccessLog(data.ExhVisitorID, 'Download')
            const res = await axiosPost(fetchAuthReport, mapData);
            this.setState({ isLoading: false });

            if (res != null) {
                var url = `${downloadReport}?SessionIdentifier=${res}&Export=true`;
                const win = window.location.replace(url, '_blank');
                if (win != null) {
                    win.focus();
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    // Insert Visitor Badge Access Log
    insertVisitorBadgeAccessLog(VisID, Action) {
        this.props.insertVisitorBadgeAccessLog_action(VisID, Action)
            .then((data) => {
                if (data.error != 1) {
                    console.log("Visitor Badge Access Log Inserted")
                } else {
                    console.log("Visitor Badge Access Log Inserted error")
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get Visitor Badge Access Log
    getVisitorBadgeAccessLog() {
        this.setState({ isLoading: true })
        this.props.getVisitorBadgeAccessLog_action()
            .then((data) => {
                if (data.error != 1) {
                    this.setState({
                        visitorBadgeLog: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // to create excel file for visitor badge log
    onCreateExcel2() {
        const { visitorBadgeLog } = this.state
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxx", visitorBadgeLog)
        var listData = []
        visitorBadgeLog.length > 0 && visitorBadgeLog.map((item, index) => {
            var mapData1 = {
                'SR No': index + 1,

            }
            var finalMapData = { ...mapData1, ...item }
            listData.push(finalMapData)
        })
        console.log('listData', listData);

        var excludeKeys = [
            // 'ExhRegistrationID', 'ExhibitionID',
        ]

        // Function to remove keys from objects
        function removeKeysFromObjects(arr, keys) {
            return arr.map((obj) => {
                const newObj = { ...obj }; // Create a new object to avoid modifying the original
                keys.forEach((key) => delete newObj[key]); // Remove specified keys from the new object
                return newObj;
            });
        }

        // Remove keys from the array of objects
        const newArray = removeKeysFromObjects(listData, excludeKeys);
        console.log("new arrya-->", newArray);

        var columnArray = [],
            rowData = [];
        // for column data 
        newArray.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        newArray.map((data, index) => {
            rowData.push(data)
        })

        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center">
                {visitorBadgeLog.length > 0 ? <Workbook filename="VisitorLog_Data.xlsx" style={{}} element={
                    <button type="button" className="btn ad-fil-btn"
                        disabled={!this.getDownloadAccess()}
                    >
                        Export Visitor Log
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook> : <button type="button" className="btn ad-fil-btn" disabled={true}>
                    Export Visitor Log
                </button>}
            </div>
        );
    }


    getDownloadAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '10');
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }

    getSendAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '10');
        if (moduleAccess) {
            return moduleAccess.SendAccess;
        } else {
            return false
        }
        return false;
    }

    getSpecialAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '10');
        if (moduleAccess) {
            return moduleAccess.Is_SpecialAccess;
        } else {
            return false
        }
        return false;
    }

    render() {
        const { isLoading, visitor, company, exhibition, visitorList, currentPage, visitorStatusId, visitorStatusList, checkedAll, visRegNo } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = visitorList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(visitorList.length / recordsPerPage)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <HeaderWithLogo />
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        <AdminDrawer />
                        <div className="row">
                            <div className="step-name">
                                <p>Visitors List</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2 mt-1">
                        <div className="row ">
                            <div className="col-xl-3 mb-3">
                                <label>Visitor</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={visitor}
                                    onKeyDown={e => this.handleKeyDown(e)}
                                    onChange={(e) => this.setState({ visitor: e.target.value })}
                                />
                            </div>
                            <div className="col-xl-3 mb-3">
                                <label>Visitor Company</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={company}
                                    onKeyDown={e => this.handleKeyDown(e)}
                                    onChange={(e) => this.setState({ company: e.target.value })}
                                />
                            </div>
                            <div className="col-xl-3 mb-3">
                                    <label htmlFor="validationCustom03">Exhibition</label>
                                    <select className="form-control"
                                        value={this.state.exhibitionID}
                                        onChange={(e) => this.setState({ exhibitionID: e.target.value })}
                                    >
                                        <option value={''}>Select Exhibition</option>
                                        {
                                            this.state.exhibitionList.length > 0 && this.state.exhibitionList.map((data) => (
                                                <option key={data.ExhibitionID} value={data.ExhibitionID}>{data.ExhibitionName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            <div className="col-md-3 ">
                                <label htmlFor="validationCustom03">Visitor Status</label>
                                <select className="form-control"
                                    value={visitorStatusId}
                                    onChange={(e) => this.setState({ visitorStatusId: e.target.value })}
                                >
                                    <option>Select Visitor Status</option>
                                    {
                                        visitorStatusList.length > 0 && visitorStatusList.map((data) => (
                                            <option key={data.ID} value={data.ID}>{data.Name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 mb-3">
                                <label>Visitor Registration No</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue=""
                                    required=""
                                    value={visRegNo}
                                    onKeyDown={e => this.handleKeyDown(e)}
                                    onChange={(e) => this.setState({ visRegNo: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="btn-group mb-1"
                                    role="group"
                                    aria-label="Basic example"
                                    style={{ float: "right" }}
                                >
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.onSearchClick()}
                                    >
                                        Search
                                    </button>
                                    <button type="button" className="btn ad-fil-btn"
                                        onClick={e => this.clearValue()}
                                    >
                                        Clear
                                    </button>
                                    <button type="button" className="btn ad-fil-btn"
                                        disabled={!this.getSendAccess()}
                                        onClick={e => this.sendBadge()}
                                    >
                                        Send Badge
                                    </button>
                                    {JSON.parse(sessionStorage.getItem("loginDataSS")).RoleID != 5 && this.onCreateExcel()}
                                    {JSON.parse(sessionStorage.getItem("loginDataSS")).RoleID != 5 && this.onCreateExcel2()}
                                </div>
                                <div className="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th className="text-center" style={{ width: 60 }}>
                                                    <input type="checkbox"
                                                        checked={checkedAll}
                                                        onChange={(e) => this.selectAllVisitors(e)}
                                                    />
                                                </th>
                                                <th className="text-center" style={{ width: 50 }}>
                                                    Sr.No
                                                </th>
                                                <th>Exhibition</th>
                                                <th>Visitor Registration No</th>
                                                <th>Visitor</th>
                                                <th>Company</th>
                                                <th>Primary Email</th>
                                                <th>Username</th>
                                                <th>Password</th>
                                                <th className="text-center" style={{ width: 100 }}>
                                                    Mobile No.
                                                </th>
                                                {this.getDownloadAccess() &&
                                                    <th>Badge</th>
                                                }
                                                {this.getSpecialAccess() &&
                                                    <th> Action</th>
                                                }
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentRecords.length > 0 && currentRecords.map((data, index) => (
                                                <tr key={Math.random()}>
                                                    <td className="text-center">
                                                        <input type="checkbox"
                                                            value={data.isSelect}
                                                            checked={data.isSelect}
                                                            onChange={(e) => this.handleCheckedVisitors(e, data.ExhVisitorID)}
                                                        />
                                                    </td>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td>{data.ExhibitionName}</td>
                                                    <td>{data.VisitorRegistrationNo}</td>
                                                    <td >
                                                        {data.personName}
                                                    </td>
                                                    <td className="text-center">{data.Institute_Organisation}</td>
                                                    <td>{data.Email}</td>
                                                    <td>{data.UserName}</td>
                                                    <td>{data.Password}</td>
                                                    <td>{data.MobileNo}</td>
                                                    {this.getDownloadAccess() &&
                                                        <td className="text-center">
                                                            <span>
                                                                <i className="fa fa-eye" aria-hidden="true"
                                                                    onClick={e => this.onBadgeView(data)}
                                                                />
                                                            </span>
                                                            <span className="ml-2">
                                                                <i className="fa fa-download" aria-hidden="true"
                                                                    onClick={e => this.onBadgeDownload(data)}
                                                                />
                                                            </span>
                                                        </td>
                                                    }
                                                    {this.getSpecialAccess() &&
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                style={{ padding: "1px 10px", marginRight: "10px" }}
                                                                onClick={(e) => this.resendUserCred(e, data.ExhVisitorID, data)}
                                                            >
                                                                Resend UserID & Password
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                style={{ padding: "1px 10px" }}
                                                                onClick={(e) => this.onLogin(data)}
                                                            >
                                                                Login
                                                            </button>
                                                        </td>
                                                    }
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-2 float-left">
                                    {
                                        visitorList.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
export default withRouter(connect(null, {
    visitorList_action, fill_action, sendUserCred_action, sendVisitorBadge_action, insertVisitorBadgeAccessLog_action,
    getVisitorBadgeAccessLog_action
})(VisitorList))