import { Component, forwardRef } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import Footerwithdr from "../../../common_components/Footerwithdr";
import { downloadReport, toastError, toastSuccess } from "../../../utils/constants";
import { proformaInvoice_action } from "../../../actions/admin_module_action/registration_receipt_actions/receipt_action";
import { getProformaPdf_action, proformaInvList_action, proformaInvShare_action } from "../../../actions/exhibitor_module_actions/ProformaInvoiceList_action/proformaInvList_action";
import { data } from "jquery";
import DatePicker from "react-datepicker";
import HeaderWithLogo from "../../../common_components/header_with_logo";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Pagination from "../../../utils/pagination";


const ExampleCustomInput = forwardRef(({ value, onClick, onChange, disabled }, ref) => (
    <input
        value={value}
        className="form-control"
        onClick={onClick}
        onChange={onChange}
        disabled={disabled}
        ref={ref}
    ></input>
));

class ProformaList extends Component {
    // intialize constructor
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            proformaInv: [],
            exhibitor: "",
            proformaDate: "",
            proformaNo: "",
            currentPage: 1,
            PIIDArr: [],
        }
        this.proformaInvoice = this.proformaInvoice.bind(this)
        this.onShare = this.onShare.bind(this)
        this.onPdfDownload = this.onPdfDownload.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.clearValue = this.clearValue.bind(this)
        this.updateItem = this.updateItem.bind(this)
    }

    componentDidMount() {
        this.proformaInvoice()

    }

    // handle Proforma invoice
    proformaInvoice() {
        const { exhibitor, proformaDate, proformaNo } = this.state
        var exhibitorCompanyName = JSON.parse(sessionStorage.getItem("loginDataSS"))["CompanyName"]
        this.setState({ isLoading: true, proformaInv: [] })
        this.props.proformaInvList_action(exhibitorCompanyName, proformaDate, proformaNo)
            .then((data) => {
                if (data.error != 1) {
                    var list = []
                    console.log("proInvoices1111111111", data.data)
                    data.data.length > 0 && data.data.map((item) => (
                        item.is_checked = 0
                    ))
                    this.setState({ isLoading: false, proformaInv: data.data }, () => { })


                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((e) => {
                console.log(e)
            })
    }

    // On Pdf Download Click
    onPdfDownload(ExhRegistrationID, countryId, installmentType) {
        this.setState({ isLoading: true })
        this.props.getProformaPdf_action(ExhRegistrationID, countryId, installmentType)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    console.log("Proforma Pdf Download", data)
                    var url = `${downloadReport}?SessionIdentifier=${data}&Export=true`;
                    const win = window.location.replace(url, '_blank');
                    if (win != null) {
                        win.focus();
                    }
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // On Search Proforma Click
    onSearchClick() {
        const { exhibitor, proformaDate, proformaNo } = this.state
        if (exhibitor != "" || proformaDate != "" || proformaNo != "") {
            this.proformaInvoice()
        } else {
            toastError("Atleast Fill One field to Search")
        }
    }

    handleKeyDown(e) {
        if (e.code == 'Enter') {
            this.searchReceipt()
        }
    }

    clearValue() {
        this.setState({
            exhibitor: "",
            proformaDate: "",
            proformaNo: "",
        })
        setTimeout(() => {
            this.proformaInvoice()
        }, 500);
    }

    // Update Current Page
    updateItem(item) {
        const { proformaInv } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = proformaInv.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(proformaInv.length / recordsPerPage)
    }


    onShare(PIID, type) {
        const { proformaInv } = this.state
        const PIIdArray = []
        console.log("ProformaInvoiceID ProformaInvoiceID ", PIID)
        proformaInv.map((data) => {
            if (data.is_checked === 1) {
                PIIdArray.push(
                    {
                        "ProformaInvoiceID": data.ProformaInvoiceID,
                        "ProformaInvoice_Type": data.ProformaInvoice_Type
                    }
                )
            }
        })

        const singlePIIdArray = []
        singlePIIdArray.push(
            {
                "ProformaInvoiceID": PIID,
                "ProformaInvoice_Type": type
            }
        )

        // console.log("receiptIdArray", receiptIdArray)

        this.setState({ isLoading: true })
        this.props
            .proformaInvShare_action(PIID != undefined ? singlePIIdArray : PIIdArray)
            .then((data) => {
                if (data.error != 1) {
                    console.log("receipt share !!!!!!!!!!!!", data.data);
                    this.setState({ isLoading: false })
                    toastSuccess("PI share successfully!", {
                        theme: 'colored',
                        autoClose: 500,
                        hideProgressBar: true,
                    })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                this.setState({ isLoading: false })
                console.log(e);
            });
    }


    render() {
        const { isLoading, proformaInv, exhibitor, proformaDate, proformaNo, currentPage } = this.state
        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = proformaInv.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(proformaInv.length / recordsPerPage)

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div className="main-container">
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorDrawer /> : <AdminDrawer />
                        }
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorTopMenu /> : null
                        }

                        <div className="row">
                            <div className="step-name">
                                <p>Proforma Invoice List</p>
                            </div>
                        </div>
                    </div>
                    <div className="main-container p-2 mt-1">
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                            <div className="row ">
                                <div className="col-xl-3 mb-3">
                                    <label>Exhibitor</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue=""
                                        required=""
                                        value={exhibitor}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ exhibitor: e.target.value })}
                                    />
                                </div>
                                <div className="col-xl-3 mb-3">
                                    <label>Proforma Date</label>
                                    <DatePicker className="form-control"
                                        dateFormat="dd-MM-yyyy"
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(date) => this.setState({ proformaDate: date })}
                                        selected={proformaDate}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Proforma Date"
                                        customInput={<ExampleCustomInput />}
                                        dayClassName={() => "example-datepicker-day-class"}
                                        popperClassName="example-datepicker-class"
                                    />
                                </div>
                                {/* <div className="col-xl-3 mb-3">
                                    <label>Receipt Date To</label>
                                    <DatePicker className="form-control"
                                        dateFormat="dd-MM-yyyy"
                                        minDate={this.state.recptDtFrom}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(date) => this.setState({ recptDtTo: date, recptDtToErr: "" })}
                                        selected={this.state.recptDtTo}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="To Date"
                                        customInput={<ExampleCustomInput />}
                                        dayClassName={() => "example-datepicker-day-class"}
                                        popperClassName="example-datepicker-class"
                                    />
                                </div> */}
                                <div className="col-xl-3 mb-3">
                                    <label>Proforma Invoice No</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="validationCustom03"
                                        value={proformaNo}
                                        onKeyDown={e => this.handleKeyDown(e)}
                                        onChange={(e) => this.setState({ proformaNo: e.target.value })}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                {
                                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID != 1 &&
                                    <div
                                        className="btn-group mb-1"
                                        role="group"
                                        aria-label="Basic example"
                                        style={{ float: "right" }}
                                    >
                                        <button type="button" className="btn ad-fil-btn" onClick={e => this.onSearchClick()}>
                                            Search
                                        </button>
                                        {/* <button type="button" className="btn ad-fil-btn" onClick={e => this.handleNewReceipt()} >
                      New Receipt
                    </button>
                    {this.onCreateExcel()} */}

                                        <button type="button" className="btn ad-fil-btn" onClick={e => this.clearValue()}>
                                            Clear
                                        </button>
                                    </div>
                                }
                                <div className="table-responsive">
                                    <table className="table border text-nowrap text-md-nowrap table-bordered mb-0 mt-1">
                                        <thead>
                                            <tr>
                                                <th>Proforma Invoice No.</th>
                                                <th>Exhibitor Name</th>
                                                <th>Proforma Date </th>
                                                <th>Service </th>
                                                <th>Taxable Amount </th>
                                                <th>Tax Type </th>
                                                <th>CGST </th>
                                                <th>SGST</th>
                                                <th>IGST	</th>
                                                <th>Total Payable</th>
                                                <th>Outstanding</th>
                                                <th colSpan={2}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                proformaInv.length > 0 ?
                                                    currentRecords.map((data, index) => (
                                                        <>
                                                            <tr key={index}>

                                                                <td>{data.ProformaInvoice_No}</td>
                                                                <td>{data.ExhibitorName}</td>
                                                                <td>{new Date(data.ProformaInvoice_Date).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })}</td>
                                                                <td>{data.Service}</td>
                                                                <td>{data.TaxableAmount}</td>
                                                                <td>{data.Tax_Type}</td>
                                                                <td>{data.CGST}</td>
                                                                <td>{data.SGST}</td>
                                                                <td>{data.IGST}</td>
                                                                <td>{data.Total_Payable}</td>
                                                                <td>{data.Outstanding}</td>
                                                                <td>
                                                                    <i className="fa fa-download" aria-hidden="true" onClick={(e) => this.onPdfDownload(data.ExhRegistrationID, data.CountryID, data.ProformaInvoice_Type)} />
                                                                </td>
                                                                <td>
                                                                    <i className="fa fa-share" aria-hidden="true" onClick={(e) => this.onShare(data.ProformaInvoiceID, data.ProformaInvoice_Type)} />
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )) : <tr><td colSpan={13} style={{ textAlign: 'center' }}>Record not found</td></tr>
                                            }
                                            {/* {
                                                currentRecords.length > 0 &&
                                                <tr>
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td />
                                                    <td>
                                                        <strong>{taxableAmount}</strong>
                                                    </td>
                                                    <td>
                                                        <strong></strong>
                                                    </td>
                                                    <td>
                                                        <strong></strong>
                                                    </td>
                                                    <td>
                                                        <strong></strong>
                                                    </td>
                                                    <td>
                                                        <strong>{totalReceivedAmt}</strong>
                                                    </td>
                                                    <td />
                                                    <td />
                                                </tr>
                                            } */}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="mt-2 float-left">
                                    {
                                        proformaInv.length > 0 ?
                                            <Pagination
                                                nPages={nPages}
                                                currentPage={currentPage}
                                                setCurrentPage={this.updateItem}
                                            /> : <></>
                                    }
                                    <div className="tab-rec " style={{ float: "right" }}>
                                        <p>{`${currentPage} of ${nPages} pages`}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Footerwithdr /> */}
            </>
        )
    }
}
export default withRouter(connect(null, { proformaInvList_action, getProformaPdf_action, proformaInvShare_action })(ProformaList))