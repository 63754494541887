import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action, fill_master_action } from '../../../../../actions/fillCombo_action';
import { cityActionId, countryActionId, toastError, stalltypeMasterListActionId, dashboardDownloadListId, countryMasterListActionId, toastSuccess } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Exh_chapter from '../exh_chapter/exh_chapter';
import Resizer from "react-image-file-resizer";
// import ManageArea from './manage_area/manage_area';
import Exh_timeline from '../exh_timeline/exh_timeline';
import ManageAreaMaster from '../manage_area_master/manage_area_master';
import ManualFormsMaster from "../manual_froms_master/manual_forms_master";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { useNavigate } from "react-router-dom";
import ExhibitorDrawer from "../../../../../common_components/exhibitor_module_drawer";
import ExhibitorHeader from "../../../../../common_components/exhibitor_module_header";
import { data } from "jquery";
import {
    addExhibitionMaster_action, getExhibitionMasterDetails_action, updateExhibitionMaster_action, deleteExhibitionDocument_action
} from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/exhibition_master_action";
import Exh_promoCode from "../exh_promoCode/exh_promoCode";
import Exh_configration from "../exh_configration/exh_configration";
import Exh_payment_config from "../exh_payment_config/exh_payment_config";

class MasterExhibitionAU extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isDelOpen: false,
            countryId: '',
            countryList: [],
            allocateCountryList: [],
            TempCountryList: [],
            Country: '',
            Name: '',
            Code: '',
            BankDetails: '',
            fromDate: '',
            toDate: '',
            is_add: false,
            ExihibitionID: '',
            is_update: false,
            summaryPage: 'exhibitionMaster',
            isDisplayCountryList: false,
            showCountryAllocate: false,
            stalltypeList: [],
            allocateStalltypeList: [],
            showStallTypeAllocate: false,
            isSelectedAll: false,
            isFirstTime: true,
            address: "",
            exhibitionLogo: '',
            logoFileType: "",
            logoName: "",
            logoFilePath: "",
            exhibitionBanner: "",
            bannerName: "",
            bannerFilePath: "",
            bannerFileType: "",
            deleteKey: "",
            PIseries: '',
            currencyId: '',
            ExhMailWebsite: '',
            ExhMailEmail: '',
            ExhMailMobile: '',
        }
        this.getFillList = this.getFillList.bind(this)
        this.addExhibitionMaster = this.addExhibitionMaster.bind(this)
        this.getExhibitionMasterDetails = this.getExhibitionMasterDetails.bind(this)
        this.handleBack = this.handleBack.bind(this)
        this.updateExhibitionMaster = this.updateExhibitionMaster.bind(this)
        this.exhibitionMasterAU = this.exhibitionMasterAU.bind(this)

        this.isRouteActive = this.isRouteActive.bind(this)
        this.toNavigate = this.toNavigate.bind(this)
        this.onCountryAllocate = this.onCountryAllocate.bind(this)
        this.onStallTypeAllocate = this.onStallTypeAllocate.bind(this)
        this.getMasterFillList = this.getMasterFillList.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.onDocDelete = this.onDocDelete.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    isRouteActive(routePath) {
        console.log(this.props.location)
        return this.props.location.pathname == routePath
    }

    toNavigate() {
        const { summaryPage, ExihibitionID } = this.state
        // console.log("toooo", ExihibitionID)
        if (summaryPage == 'exhibitionMaster') {
            return null
        }
        else if (summaryPage == 'exh_chapter') {
            return <Exh_chapter id={ExihibitionID} />
        }
        else if (summaryPage == 'manage_area') {
            return <ManageAreaMaster id={ExihibitionID} />
        }
        else if (summaryPage == 'manual_forms') {
            return <ManualFormsMaster id={ExihibitionID} />
        }
        else if (summaryPage == 'exh_timeline') {
            return <Exh_timeline id={ExihibitionID} />
        }
        else if (summaryPage == 'promo_code') {
            return <Exh_promoCode id={ExihibitionID} />
        }
        else if (summaryPage == 'exh_configration') {
            return <Exh_configration id={ExihibitionID} />
        }
        else if (summaryPage == 'exhibitionPaymentConfig') {
            return <Exh_payment_config id={ExihibitionID} />
        }
        else {
            return null
        }
    }

    componentDidMount() {
        const { ExihibitionID } = this.state
        if (this.state.isFirstTime == true) {
            var exiD = this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : ''
            this.setState({
                is_add: this.props.location.state != null && this.props.location.state.is_add != null ? this.props.location.state.is_add : false,
                is_update: this.props.location.state != null && this.props.location.state.is_update != null ? this.props.location.state.is_update : false,
                ExihibitionID: this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '',
            })
            console.log(this.props.location.state, ']]]]]]]]]]]]')
            this.setState({ isFirstTime: false })
        }
        setTimeout(() => {
            this.state.is_update && this.getExhibitionMasterDetails()
            console.log(exiD, this.state.is_update, this.state.is_add, ExihibitionID)

            this.getMasterFillList(countryMasterListActionId, '', '', '', '')
            this.getFillList(countryMasterListActionId, exiD, '', '', '')
            this.getFillList(stalltypeMasterListActionId, exiD, '', '', '')
            console.log(this.state.ExihibitionID, this.state.allocateCountryList, this.state.allocateStalltypeList)
        }, 500);
    }

    getMasterFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        // if (actionID == countryActionId) {
        this.props.fill_master_action(actionID, null, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === countryMasterListActionId) {
                        this.setState({
                            countryList: data.data,
                            TempCountryList: data.data,
                            isLoading: false,
                            // allocateCountryList: alloCountryList
                        })
                        // console.log("country data", alloCountryList)
                    }
                }
            })
        // }
    }

    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        console.log(exhibitionID)
        const { } = this.state
        this.setState({ isLoading: true })

        this.props.fill_master_action(actionID, exhibitionID != '' && exhibitionID != null ? exhibitionID : null, moduleID, keyValue, value)
            .then((data) => {
                if (data.error != 1) {
                    // Country List
                    if (actionID === countryMasterListActionId) {
                        var alloCountryList = data.data
                        if (data.data.length > 0) {
                            for (var i = 0; i < data.data.length; i++) {
                                var status = true
                                if (data.data[i].Status == false) {
                                    status = false
                                    break
                                }
                            }
                            alloCountryList.unshift({ "CountryID": 0, "CountryName": 'Select All', "Status": status })
                        }
                        this.setState({
                            // countryList: data.data,
                            // TempCountryList: data.data,
                            isLoading: false,
                            allocateCountryList: alloCountryList
                        })
                        console.log("country data", alloCountryList)
                    }
                    if (actionID === stalltypeMasterListActionId) {
                        var alloStalltypeList = data.data
                        this.setState({
                            isLoading: false,
                            allocateStalltypeList: alloStalltypeList
                        })
                        console.log("country data", alloStalltypeList)
                    }
                }
            }
            )
    }

    getExhibitionMasterDetails() {
        const { ExihibitionID, allocateStalltypeList, allocateCountryList } = this.state
        this.setState({ isLoading: true })
        this.props.getExhibitionMasterDetails_action(ExihibitionID)
            .then((data) => {
                if (data.error != 1) {
                    var maindata = data.data.mainData
                    // for (var i = 0; i < data.data.stalltypeList.length; i++) {
                    //     console.log('pppppp', data.data.stalltypeList, allocateStalltypeList)
                    //     if (data.data.stalltypeList[i].Status == 1) {
                    //         for (var a = 0; a < allocateStalltypeList.length; a++) {
                    //             if (data.data.stalltypeList[i].StallTypeID == allocateStalltypeList[a].StallTypeID) {
                    //                 allocateStalltypeList[a].Status = true
                    //             }
                    //         }
                    //     }
                    // }
                    // for (var i = 0; i < data.data.countryList.length; i++) {
                    //     if (data.data.countryList[i].Status == 1) {
                    //         for (var a = 0; a < allocateCountryList.length; a++) {
                    //             if (data.data.countryList[i].CountryID == allocateCountryList[a].Code) {
                    //                 allocateStalltypeList[a].Status = true
                    //             }
                    //         }
                    //     }
                    // }
                    console.log("main Data---------------------->", maindata[0] != null)
                    this.setState({
                        exhibitionID: maindata[0].ExhibitionID,
                        Name: maindata[0].ExhibitionName,
                        Code: maindata[0].Code,
                        countryId: maindata[0].CountryID,
                        BankDetails: maindata[0].BankDetails,
                        address: maindata[0].Address,
                        fromDate: moment(maindata[0].ExhibitionDateFrom, 'YYYY-MM-DD').toDate(),
                        toDate: moment(maindata[0].ExhibitionDateTo, 'YYYY-MM-DD').toDate(),
                        PIseries: maindata[0].PI_Series,
                        currencyId: maindata[0].CurrencyID,
                        ExhMailWebsite: maindata[0].ExhMailWebsite,
                        ExhMailEmail: maindata[0].ExhMailEmail,
                        ExhMailMobile: maindata[0].ExhMailMobile,
                        // allocateCountryList: allocateCountryList,
                        // allocateStalltypeList: allocateStalltypeList
                    })
                    if (maindata[0] != null) {
                        var logoPath = maindata[0]['Logo'] != null ? maindata[0]['Logo'] : ""
                        var array = logoPath != null && logoPath != "" && logoPath.split("/")
                        const lofoFileName = array[array.length - 1]

                        var bannerPath = maindata[0]['Banner'] != null ? maindata[0]['Banner'] : ""
                        var array2 = bannerPath != null && bannerPath != "" && bannerPath.split("/")
                        const bannerFileName = array2[array2.length - 1]
                        console.log("yyyyyyyyyyyyyyyy", lofoFileName)
                        this.setState({
                            logoFilePath: logoPath,
                            exhibitionLogo: logoPath,
                            logoName: lofoFileName,
                            bannerFilePath: bannerPath,
                            exhibitionBanner: bannerPath,
                            bannerName: bannerFileName
                        })
                    }
                    this.state.countryList.map(id => parseInt(id.CountryCode) == maindata[0].CountryID && this.setState({ Country: id.Description }))
                    this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    addExhibitionMaster() {
        const { Name, Code, countryId, fromDate, toDate, BankDetails, allocateCountryList, allocateStalltypeList, address, exhibitionLogo, exhibitionBanner, bannerFileType, logoFileType, logoFilePath, bannerFilePath, PIseries, ExhMailWebsite, ExhMailEmail, ExhMailMobile, } = this.state
        if (Name != '' && Name != null && Code != '' && Code != null && countryId != '' && countryId != null &&
            fromDate != '' && fromDate != null && toDate != '' && toDate != null && BankDetails != '' && BankDetails != null && address != "" && address != null && exhibitionBanner != '' && exhibitionBanner != null && exhibitionLogo != '' && exhibitionLogo != null && logoFileType != '' && bannerFileType != '' && allocateCountryList.length > 0 && allocateStalltypeList.length > 0 && PIseries != '' && PIseries != null && ExhMailWebsite != '' && ExhMailWebsite != null && ExhMailEmail != '' && ExhMailEmail != null && ExhMailMobile != '' && ExhMailMobile != null) {
            for (var i = 0; i < allocateStalltypeList.length; i++) {
                if (allocateStalltypeList[i].Status == true) {
                    allocateStalltypeList[i].Status = 1
                }
                else {
                    allocateStalltypeList[i].Status = 0
                }
            }
            for (var i = 0; i < allocateCountryList.length; i++) {
                if (allocateCountryList[i].Status == true) {
                    allocateCountryList[i].Status = 1
                }
                else {
                    allocateCountryList[i].Status = 0
                }
            }
            this.setState({ isLoading: true })
            var allocateCountryListtoSend = allocateCountryList
            allocateCountryListtoSend.shift()
            console.log(allocateCountryList, allocateCountryListtoSend, allocateStalltypeList)

            this.props.addExhibitionMaster_action(Name, Code, countryId, fromDate, toDate, BankDetails, address, allocateCountryList, allocateStalltypeList, exhibitionLogo, logoFileType, exhibitionBanner, bannerFileType, logoFilePath, bannerFilePath, PIseries, ExhMailWebsite, ExhMailEmail, ExhMailMobile)
                .then((data) => {
                    if (data.error != 1) {
                        var newExhibitionID = data.data
                        this.setState({ isLoading: false })
                        toastSuccess("data added successfully")
                        this.setState({ ExihibitionID: newExhibitionID, is_add: false, is_update: true })

                        setTimeout(() => {
                            // this.props.navigate(`/exhibitionMasterAU/${newExhibitionID}`,)
                            console.log("newExhibitionID", this.state.ExihibitionID)
                            this.getExhibitionMasterDetails()

                        }, 200);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Pleaser Enter Mandatory Fields")
        }
    }

    exhibitionMasterAU(no) {
        console.log(no)
        if (this.state.is_add == true) {
            this.addExhibitionMaster()
        }
        else {
            this.updateExhibitionMaster()
        }
    }

    handleBack() {
        this.props.navigate('/exhibitionMasterList')
    }

    updateExhibitionMaster() {
        const { Name, Code, countryId, fromDate, toDate, ExihibitionID, BankDetails, allocateCountryList, allocateStalltypeList, exhibitionBanner, exhibitionLogo, logoFileType, PIseries, bannerFileType, logoFilePath, bannerFilePath, address, currencyId, ExhMailWebsite, ExhMailEmail, ExhMailMobile, } = this.state
        if (Name != '' && Name != null && ExihibitionID != '' && ExihibitionID != null && Code != '' && Code != null && countryId != '' && countryId != null && fromDate != '' && fromDate != null && toDate != '' && toDate != null && BankDetails != '' && BankDetails != null && allocateCountryList.length > 0 && allocateStalltypeList.length > 0 && exhibitionLogo != "" && exhibitionBanner != "" && address != "" && address != null && PIseries != '' && PIseries != null && ExhMailWebsite != '' && ExhMailWebsite != null && ExhMailEmail != '' && ExhMailEmail != null && ExhMailMobile != '' && ExhMailMobile != null) {
            for (var i = 0; i < allocateStalltypeList.length; i++) {
                if (allocateStalltypeList[i].Status == true) {
                    allocateStalltypeList[i].Status = 1
                }
                else {
                    allocateStalltypeList[i].Status = 0
                }
            }
            for (var i = 0; i < allocateCountryList.length; i++) {
                if (allocateCountryList[i].Status == true) {
                    allocateCountryList[i].Status = 1
                }
                else {
                    allocateCountryList[i].Status = 0
                }
            }
            // this.setState({ isLoading: true })
            var allocateCountryListtoSend = [...allocateCountryList]
            allocateCountryListtoSend.shift()
            console.log(allocateCountryList, allocateCountryListtoSend, allocateStalltypeList)
            this.setState({ isLoading: true })
            this.props.updateExhibitionMaster_action(ExihibitionID, Name, Code, countryId, fromDate, toDate, BankDetails, allocateCountryListtoSend, allocateStalltypeList, address, exhibitionLogo, exhibitionBanner, logoFileType, bannerFileType, logoFilePath, bannerFilePath, PIseries, currencyId, ExhMailWebsite, ExhMailEmail, ExhMailMobile,)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({ isLoading: false, is_update: true })
                        toastSuccess("data updated successfully")
                        // this.props.navigate('/exhibitionMasterAU',)
                        this.getExhibitionMasterDetails()
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            toastError("Pleaser Enter Mandatory Fields")
        }
    }

    onCountryAllocate(code) {
        const { allocateCountryList, isSelectedAll } = this.state
        if (code == 0) {
            if (isSelectedAll == false) {
                for (var i = 0; i < allocateCountryList.length; i++) {
                    allocateCountryList[i].Status = true
                }
                this.setState({ isSelectedAll: true })
            }
            else {
                for (var i = 0; i < allocateCountryList.length; i++) {
                    allocateCountryList[i].Status = false
                }
                this.setState({ isSelectedAll: false })
            }
        }
        else {
            for (var i = 1; i < allocateCountryList.length; i++) {
                if (code == allocateCountryList[i].CountryID) {
                    allocateCountryList[i].Status = !allocateCountryList[i].Status
                }
            }
            for (var i = 1; i < allocateCountryList.length; i++) {
                if (allocateCountryList[i].Status == false) {
                    console.log(allocateCountryList[i])
                    allocateCountryList[0].Status = false
                    this.setState({ isSelectedAll: false })
                    break
                }
                else {
                    allocateCountryList[0].Status = true
                    this.setState({ isSelectedAll: true })
                }
            }
        }
        this.setState({ allocateCountryList: allocateCountryList })
        console.log(allocateCountryList)
    }

    onStallTypeAllocate(code) {
        const { allocateStalltypeList } = this.state
        for (var i = 0; i < allocateStalltypeList.length; i++) {
            if (code == allocateStalltypeList[i].StallTypeID) {
                allocateStalltypeList[i].Status = !allocateStalltypeList[i].Status
            }
        }
        this.setState({ allocateStalltypeList: allocateStalltypeList })
        console.log(allocateStalltypeList)
    }

    onFilePick = async (e, file, key) => {
        e.preventDefault()
        const { } = this.state
        console.log('file', file)
        if (file.length != 0) {
            if (key == 1 ? file[0]['size'] >= 5 * 1024 * 1024 : file[0]['size'] >= 10 * 1024 * 1024) {
                key == 1 ? toastError('File size more than 5mb are not allow.') :
                    toastError('File size more than 10mb are not allow.')
            } else {
                var image = '';
                if (
                    file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                    file[0]['name'].toString().toLowerCase().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().includes('.jpg')
                ) {
                    if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                        file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                    ) {
                        image = await this.resizeFile(file[0]);
                        if (key == 1) {
                            this.setState({ exhibitionLogo: image })
                        } else if (key == 2) {
                            this.setState({ exhibitionBanner: image })
                        }
                    }
                    var fileType = file[0]['type']
                    var fileTypeList;
                    if (fileType.includes("/")) {
                        fileTypeList = fileType.split("/")

                    }
                    if (key == 1) {
                        this.setState({ logoFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                    } else if (key == 2) {
                        this.setState({ bannerFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                    }
                } else {
                    toastError('Only .jpeg, .png, .jpg files are allowed')
                }
            }
        }
    }

    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    onCrossClick(key) {
        if (key == 1) {
            this.setState({ exhibitionLogo: "" })
            document.getElementById("path").value = ""
        } else if (key == 2) {
            this.setState({ exhibitionBanner: "" })
            document.getElementById("path2").value = ""
        }
    }

    // On Document Delete Modal Toggle
    onDocDelete(key) {
        const { isDelOpen } = this.state
        this.setState({ isDelOpen: !isDelOpen, deleteKey: key })
    }

    // Delete Document Delete
    handleDelete(e) {
        e.preventDefault()
        const { isDelOpen, exhibitionID, deleteKey } = this.state
        this.setState({ isLoading: true })
        this.props.deleteExhibitionDocument_action(exhibitionID, deleteKey)
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Document Deleted SuccessFully")
                    this.setState({ isLoading: false, isDelOpen: !isDelOpen })
                    if (deleteKey == 0) {
                        this.setState({ exhibitionLogo: "", logoFilePath: "", logoFileType: "", logoName: "" })
                    } else if (deleteKey == 1) {
                        this.setState({ exhibitionBanner: "", bannerFileName: "", bannerFilePath: "", bannerFileType: "" })
                    }
                    this.getExhibitionMasterDetails()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    render() {
        const { isLoading, countryId, ExihibitionID, summaryPage, Name, Code, BankDetails, toDate, fromDate, Country, TempCountryList,
            isDisplayCountryList, countryList, address, is_add, exhibitionLogo, logoFileType, logoFilePath, logoName, exhibitionBanner,
            bannerFilePath, bannerName, isDelOpen, ExhMailWebsite, ExhMailEmail, ExhMailMobile, } = this.state

        console.log("tetetetetetette", logoName)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />

                    <div className="app-content with-dr-container com-height">
                        {/* <AdminDashboardTopmenu /> */}
                        {/* <div className="navg mt-0"> */}
                        <div className="navg mt-0">
                            <ul className="nav nav-pills">
                                <li onClick={() => this.setState({ summaryPage: 'exhibitionMaster' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'exhibitionMaster' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Exhibition Master{" "}
                                    </a>{" "}
                                </li>
                                {/* </Link> */}
                                <li className="nav-item ">
                                    {" "}
                                    <button onClick={() => this.setState({ summaryPage: 'exh_chapter' })} disabled={ExihibitionID == '' || ExihibitionID == null} className={summaryPage == 'exh_chapter' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        <a className={summaryPage == 'exh_chapter' ? "text-white" : ""}>
                                            Chapter{" "}
                                        </a>{" "}
                                    </button>
                                </li>
                                {/* </Link> */}
                                <li className="nav-item ">
                                    {" "}
                                    <button onClick={() => this.setState({ summaryPage: 'manage_area' })} disabled={ExihibitionID == '' || ExihibitionID == null} className={summaryPage == 'manage_area' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        <a className={summaryPage == 'manage_area' ? "text-white" : ""}>
                                            Manage Area{" "}
                                        </a>{" "}
                                    </button>

                                </li>
                                <li className="nav-item ">
                                    {" "}
                                    <button onClick={() => this.setState({ summaryPage: 'exh_timeline' })} disabled={ExihibitionID == '' || ExihibitionID == null} className={summaryPage == 'exh_timeline' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        <a className={summaryPage == 'exh_timeline' ? "text-white" : ""}>
                                            Timeline{" "}
                                        </a>{" "}
                                    </button>
                                </li>
                                {/* </Link> */}
                                <li className="nav-item ">
                                    {" "}
                                    <button onClick={() => this.setState({ summaryPage: 'manual_forms' })} disabled={ExihibitionID == '' || ExihibitionID == null} className={summaryPage == 'manual_forms' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        <a className={summaryPage == 'manual_forms' ? "text-white" : ""}>
                                            Manual Forms{" "}
                                        </a>{" "}
                                    </button>
                                </li>
                                <li className="nav-item ">
                                    {" "}
                                    <button onClick={() => this.setState({ summaryPage: 'promo_code' })} disabled={ExihibitionID == '' || ExihibitionID == null} className={summaryPage == 'promo_code' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        <a className={summaryPage == 'promo_code' ? "text-white" : ""}>
                                            Promo Code{" "}
                                        </a>{" "}
                                    </button>
                                </li>
                                <li className="nav-item ">
                                    {" "}
                                    <button onClick={() => this.setState({ summaryPage: 'exh_configration' })} disabled={ExihibitionID == '' || ExihibitionID == null} className={summaryPage == 'exh_configration' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        <a className={summaryPage == 'exh_configration' ? "text-white" : ""}>
                                            Configration{" "}
                                        </a>{" "}
                                    </button>
                                </li>
                                <li className="nav-item ">
                                    {" "}
                                    <button onClick={() => this.setState({ summaryPage: 'exhibitionPaymentConfig' })} disabled={ExihibitionID == '' || ExihibitionID == null} className={summaryPage == 'exhibitionPaymentConfig' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        <a className={summaryPage == 'exhibitionPaymentConfig' ? "text-white" : ""}>
                                            Payment Configration{" "}
                                        </a>{" "}
                                    </button>
                                </li>
                                {/* </Link> */}
                                {/* <li onClick={() => this.setState({ summaryPage: 'hallMaster' })} className="nav-item ">
                                    {" "}
                                    <a className={summaryPage == 'manual_forms' ? "nav-link active px-4 py-2" : "nav-link  px-4 py-2"}>
                                        Hall Master{" "}
                                    </a>{" "}
                                </li> */}
                            </ul>
                        </div>
                        {/* </div> */}
                        {summaryPage == 'exhibitionMaster' &&
                            <div className={''}>
                                {console.log("======")}
                                <div className="main-container">
                                    <div class="card-body mt-2" style={{ background: '#f0f0f5' }}>
                                        <div class="row ">
                                            <div class="row ">
                                                <div class="col-md-4">
                                                    <label for="validationCustom03">Exhibition Name<span style={{ color: 'red' }}>*</span></label>
                                                    <input value={Name} onChange={(e) => this.setState({ Name: e.target.value })} type="text" class="form-control" placeholder="Name" />
                                                </div>
                                                <div class="col-md-4">
                                                    <label for="validationCustom03">Code<span style={{ color: 'red' }}>*</span></label>
                                                    <input value={Code} onChange={(e) => this.setState({ Code: e.target.value })} type="text" class="form-control" placeholder="Code" />
                                                </div>
                                                <div className="col-xl-4">
                                                    <label>Country<span className="text-red">*</span></label>
                                                    <select className="form-control"
                                                        value={countryId}
                                                        // disabled={isView}
                                                        onChange={e => this.setState({ countryId: e.target.value })} >
                                                        <option value={''}>Select</option>
                                                        {/* <option value={0}>All</option> */}
                                                        {
                                                            countryList.length > 0 && countryList.map(data => (
                                                                <option value={data.CountryID} key={data.CountryID}>{data.CountryName}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-md-4">
                                                    <label for="validationCustom03">Bank Details<span style={{ color: 'red' }}>*</span></label>
                                                    <input value={BankDetails} onChange={(e) => this.setState({ BankDetails: e.target.value })} type="text" class="form-control" placeholder="Bank Details" />
                                                </div>
                                                <div class="col-md-4">
                                                    <div className="col-xl-12 p-0">
                                                        <label htmlFor="validationCustom03">From Date<span style={{ color: 'red' }}>*</span></label>
                                                        <DatePicker
                                                            style={{ zIndex: '99999' }}
                                                            selected={fromDate}
                                                            dateFormat={"dd/MM/yyyy"}
                                                            className="form-control"
                                                            onChange={(date) => this.setState({ fromDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) })}
                                                            peekNextMonth
                                                            maxDate={toDate}
                                                            minDate={new Date()}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="From Date"
                                                        />
                                                        {/* {<span className='text-danger text-start text-left d-block mt-1'>{circularDateErr}</span>} */}
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div className="col-xl-12 p-0">
                                                        <label htmlFor="validationCustom03">To Date<span style={{ color: 'red' }}>*</span></label>
                                                        <DatePicker
                                                            style={{ zIndex: '99999' }}
                                                            selected={toDate}
                                                            dateFormat={"dd/MM/yyyy"}
                                                            className="form-control"
                                                            onChange={(date) => this.setState({ toDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) })}
                                                            peekNextMonth
                                                            minDate={fromDate}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="To Date"
                                                        />
                                                        {/* {<span className='text-danger text-start text-left d-block mt-1'>{circularDateErr}</span>} */}
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mt-3">
                                                    <label for="validationCustom03">PI Series<span style={{ color: 'red' }}>*</span></label>
                                                    <input value={this.state.PIseries} 
                                                    // disabled={this.state.is_update} 
                                                    onChange={(e) => this.setState({ PIseries: e.target.value })} type="text" class="form-control" placeholder="PI series" />
                                                </div>
                                                <div className="row mt-3">
                                                    <div class="col-md-12">
                                                        <label for="validationCustom03">Exhibition Address<span style={{ color: 'red' }}>*</span></label>
                                                        <input value={address} onChange={(e) => this.setState({ address: e.target.value })} type="text" class="form-control" placeholder="Exhibition Address" />
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-md-4">
                                                        <label for="validationCustom03">Exhibition Website<span style={{ color: 'red' }}>*</span></label>
                                                        <input value={ExhMailWebsite} onChange={(e) => this.setState({ ExhMailWebsite: e.target.value })} type="text" class="form-control" placeholder="Exhibition Website" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="validationCustom03">Exhibition Email's<span style={{ color: 'red' }}>*</span></label>
                                                        <input value={ExhMailEmail} onChange={(e) => this.setState({ ExhMailEmail: e.target.value })} type="text" class="form-control" placeholder="Exhibition Email's" />
                                                    </div>
                                                    <div class="col-md-4">
                                                        <label for="validationCustom03">Exhibition Mobile No's<span style={{ color: 'red' }}>*</span></label>
                                                        <input value={ExhMailMobile} onChange={(e) => this.setState({ ExhMailMobile: e.target.value })} type="text" class="form-control" placeholder="Exhibition Mobile No's" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03" className="mt-3">
                                                        Exhibition Logo (Upto 5MB){" "}
                                                    </label>
                                                    <div className="box">
                                                        <div className="js--image-preview" />

                                                        {
                                                            is_add ?
                                                                <div className="upload-options mt-1">
                                                                    <label>
                                                                        <input
                                                                            type="file"
                                                                            id="path"
                                                                            className="image-upload"
                                                                            accept=".jpg, .jpeg, .png,"
                                                                            onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                                        />
                                                                        {
                                                                            exhibitionLogo != "" &&
                                                                            <i class="fa fa-times text-danger img-can"
                                                                                onClick={() => this.onCrossClick(1)}></i>
                                                                        }
                                                                    </label>

                                                                </div>
                                                                : logoName != "" && logoName != null ?
                                                                    <div style={{
                                                                        border: '1px solid #ccc',
                                                                        padding: '10px',
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        // marginTop: '10px'
                                                                    }}>
                                                                        <a
                                                                            href={logoFilePath}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <span style={{ marginRight: '10px' }}
                                                                            >
                                                                                {logoName}
                                                                            </span>

                                                                        </a>
                                                                        <i className={`fa fa-times text-danger img-can}`}
                                                                            onClick={(e) => this.onDocDelete(0)}
                                                                        ></i>
                                                                    </div> : <label>
                                                                        <input
                                                                            type="file"
                                                                            id="path"
                                                                            className="image-upload"
                                                                            accept=".jpg, .jpeg, .png"
                                                                            onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                                        />
                                                                        {
                                                                            exhibitionLogo != "" &&
                                                                            <i class="fa fa-times text-danger img-can"
                                                                                onClick={() => this.onCrossClick(1)}
                                                                            ></i>
                                                                        }
                                                                    </label>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-xl-4">
                                                    <label htmlFor="validationCustom03" className="mt-3">
                                                        Exhibition Banner (Upto 10MB){" "}
                                                    </label>
                                                    <div className="box">
                                                        <div className="js--image-preview" />

                                                        {
                                                            is_add ?
                                                                <div className="upload-options mt-1">
                                                                    <label>
                                                                        <input
                                                                            type="file"
                                                                            id="path2"
                                                                            className="image-upload"
                                                                            accept=".jpg, .jpeg, .png,"
                                                                            onChange={e => this.onFilePick(e, e.target.files, 2)}
                                                                        />
                                                                        {
                                                                            exhibitionBanner != "" &&
                                                                            <i class="fa fa-times text-danger img-can"
                                                                                onClick={() => this.onCrossClick(2)}></i>
                                                                        }
                                                                    </label>

                                                                </div>
                                                                : bannerName != "" && bannerName != null ?
                                                                    <div style={{
                                                                        border: '1px solid #ccc',
                                                                        padding: '10px',
                                                                        display: 'inline-flex',
                                                                        alignItems: 'center',
                                                                        // marginTop: '10px'
                                                                    }}>
                                                                        <a
                                                                            href={bannerFilePath}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            <span style={{ marginRight: '10px' }}
                                                                            >
                                                                                {bannerName}
                                                                            </span>

                                                                        </a>
                                                                        <i className={`fa fa-times text-danger img-can}`}
                                                                            onClick={(e) => this.onDocDelete(1)}
                                                                        ></i>
                                                                    </div> : <label>
                                                                        <input
                                                                            type="file"
                                                                            id="path2"
                                                                            className="image-upload"
                                                                            accept=".jpg, .jpeg, .png"
                                                                            onChange={e => this.onFilePick(e, e.target.files, 2)}
                                                                        />
                                                                        {
                                                                            exhibitionBanner != "" &&
                                                                            <i class="fa fa-times text-danger img-can"
                                                                                onClick={() => this.onCrossClick(2)}
                                                                            ></i>
                                                                        }
                                                                    </label>
                                                        }
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-md-4 p-2">
                                                        <div className="" style={{ paddingLeft: 10 }}>
                                                            <form className="needs-validation pb-2" noValidate="">
                                                                <div className="form-row">
                                                                    <div class="accordion" id="accordionExample">
                                                                        {
                                                                            // level2Data.ParentChapterID == primaryChapterRadio &&
                                                                            <div class="accordion-item">
                                                                                <h2 class="accordion-header check-out" id={"heading" + 1}>

                                                                                    <button
                                                                                        class={this.state.showCountryAllocate ? "accordion-button collapsed selected-chapter  tit-name" : "accordion-button collapsed  tit-name"}
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={'#collapse' + 1} aria-expanded="false"
                                                                                        aria-controls={'collapse' + 1}
                                                                                        onClick={() => this.setState({ showCountryAllocate: !this.state.showCountryAllocate })}
                                                                                    >
                                                                                        {/* <input type="checkbox" name="" key={0}
                                                                                            // checked={data.Status}
                                                                                            onClick={this.state.allocateCountryList.length > 0 && this.state.allocateCountryList.map((data) => (
                                                                                                <>
                                                                                                    {data.Status = true}
                                                                                                </>
                                                                                            ))}
                                                                                        /> */}
                                                                                        Allocate Country

                                                                                    </button>
                                                                                </h2>
                                                                                {this.state.allocateCountryList.length > 0 && this.state.allocateCountryList.map((data, i) => (

                                                                                    <div style={{ padding: '5px' }} id={"collapse" + 1} class="accordion-collapse collapse" aria-labelledby={"heading" + 1}>
                                                                                        {/* level4data.IsCheckbox && */}
                                                                                        <input type="checkbox" name="" key={data.CountryID}
                                                                                            checked={data.Status}
                                                                                            onClick={(e) => this.onCountryAllocate(data.CountryID)}
                                                                                        />
                                                                                        {data.CountryName}
                                                                                    </div>))}
                                                                            </div>
                                                                        }

                                                                    </div >
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>


                                                    <div class="col-md-4 p-2">
                                                        <div className="" style={{ paddingLeft: 10 }}>
                                                            <form className="needs-validation pb-2" noValidate="">
                                                                <div className="form-row">
                                                                    <div class="accordion" id="accordionExample">
                                                                        {
                                                                            // level2Data.ParentChapterID == primaryChapterRadio &&
                                                                            <div class="accordion-item">
                                                                                <h2 class="accordion-header check-out" id={"heading" + 2}>

                                                                                    <button
                                                                                        class={this.state.showStallTypeAllocate ? "accordion-button collapsed selected-chapter  tit-name" : "accordion-button collapsed  tit-name"}
                                                                                        type="button"
                                                                                        data-bs-toggle="collapse"
                                                                                        data-bs-target={'#collapse' + 2} aria-expanded="false"
                                                                                        aria-controls={'collapse' + 2}
                                                                                        onClick={() => this.setState({ showStallTypeAllocate: !this.state.showStallTypeAllocate })}
                                                                                    >
                                                                                        Allocate Stall type
                                                                                    </button>
                                                                                </h2>
                                                                                {this.state.allocateStalltypeList.length > 0 && this.state.allocateStalltypeList.map((data, i) => (

                                                                                    <div style={{ padding: '5px' }} id={"collapse" + 2} class="accordion-collapse collapse" aria-labelledby={"heading" + 2}>
                                                                                        {/* level4data.IsCheckbox && */}
                                                                                        <input type="checkbox" name="" key={data.StallTypeID}
                                                                                            checked={data.Status}
                                                                                            onClick={(e) => this.onStallTypeAllocate(data.StallTypeID)}
                                                                                        />
                                                                                        {data.StallTypeName}
                                                                                    </div>))}
                                                                            </div>
                                                                        }

                                                                    </div >
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">

                                                        <div style={{ float: 'right', textAlign: 'right' }}>
                                                            <button onClick={() => this.exhibitionMasterAU()} class="btn rounded-0 btn-primary mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Save</button>
                                                            <button onClick={this.handleBack} class="btn rounded-0 btn-danger mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Back</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div >
                                    </div>
                                </div>

                            </div>}
                        {this.toNavigate() != null && this.toNavigate()}
                    </div>
                    <Modal isOpen={isDelOpen} onRequestClose={() => this.setState({ isDelOpen: false })}>
                        <div className="modal-dialog">
                            <div className="modal-content confirm-logout">
                                <div class="modal-header p-2">
                                    <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                    <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isDelOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="pop_label">Do you really want to delete this Document?</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer p-1">
                                    <div class="form-group text-right mod-fot">
                                        <button type="button" class="btn btn-del-pop"
                                            onClick={(e) => this.handleDelete(e)}
                                        >Yes</button>
                                        <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isDelOpen: false })}>No</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal>
                </>
            </>
        )
    }
}

export default withRouter(connect(null, {
    fill_action, addExhibitionMaster_action, fill_master_action, getExhibitionMasterDetails_action, deleteExhibitionDocument_action,
    updateExhibitionMaster_action
})(MasterExhibitionAU));