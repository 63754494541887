import { Component } from "react";
import Loader from "../../../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import { withRouter } from "../../../../../utils/withRouter";
import { connect } from "react-redux";
import { primaryChapterListActionId, toastError, toastSuccess, primaryChapterAllocationListActionId, primaryChapterMasterListActionId } from "../../../../../utils/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import AdminDrawer from "../../../../../common_components/admin_module_drawer";
import HeaderWithLogo from "../../../../../common_components/header_with_logo";
import { getLedgerList_action } from '../../../../../actions/admin_module_action/ledger_action/ledger_action';
import Pagination from "../../../../../utils/pagination";
import { fill_action, fill_master_action } from '../../../../../actions/fillCombo_action';
import { chapterAllocation_action } from "../../../../../actions/admin_module_action/master_action/exhibition_master_action/chapter_allocation_action";

class Exh_chapter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            primaryChapterId: '',
            primaryChapterListData: [],
            level0: [],
            level2: [],
            level3: [],
            level4: [],
            ExhibitionID: '',
            primaryChapterListLevel2: [],
            primaryChapterListLevel3: [],
            primaryChapterListLevel4: [],
            level4DummyId: '',
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.handleBack = this.handleBack.bind(this)

    }

    onSubmit() {
        const { primaryChapterListData, ExhibitionID, level2, level3, level4 } = this.state
        var finalArr = []
        for (var i = 0; i < primaryChapterListData.length; i++) {
            if (primaryChapterListData[i].IsSelect == true) {
                finalArr.push(primaryChapterListData[i])
            }
        }
        for (var i = 0; i < level2.length; i++) {
            if (level2[i].IsSelect == true) {
                finalArr.push(level2[i])
            }
        }
        for (var i = 0; i < level3.length; i++) {
            if (level3[i].IsSelect == true) {
                finalArr.push(level3[i])
            }
        }
        for (var i = 0; i < level4.length; i++) {
            if (level4[i].IsSelect == true) {
                finalArr.push(level4[i])
            }
        }
        console.log(finalArr)
        if (finalArr.length == 0) {
            console.log("finallll", finalArr, ExhibitionID)
            toastError("Please select atleast one chapter")
        } else {
            this.setState({ isLoading: true })

            this.props.chapterAllocation_action(ExhibitionID, finalArr)
                .then((data) => {
                    if (data.error != 1) {
                        this.setState({
                            primaryChapterListData: [],
                            level0: [],
                            level2: [],
                            level3: [],
                            level4: [],
                            // isLoading: false,
                        })
                        setTimeout(() => {

                            this.getFillList(primaryChapterAllocationListActionId, this.state.ExhibitionID, '', '', '', '');
                            this.setState({ isLoading: false })
                            toastSuccess("added successfully")
                        }, 100);
                    }
                    else {
                        this.setState({ isLoading: false })
                        toastError(data.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    // On Component Load
    componentDidMount() {
        console.log(this.props.id, this.props.location.state.ExihibitionID)
        this.setState({
            ExhibitionID: this.props.location.state.ExihibitionID != undefined ? (this.props.location.state != null && this.props.location.state.ExihibitionID != null ? this.props.location.state.ExihibitionID : '') : this.props.id != null && this.props.id != undefined ? this.props.id : '',
        })
        setTimeout(() => {
            this.getFillList(primaryChapterAllocationListActionId, this.state.ExhibitionID, '', '', '', '');

        }, 100);
    }

    handleBack() {
        this.props.navigate('/exhibitionMasterList')
    }
    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_master_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                console.log(exhibitionID)
                if (data.error != 1) {
                    // voucher list
                    if (actionID === primaryChapterAllocationListActionId) {
                        console.log("primaryChapterListActionId 121212", data.data)
                        // for (var i = 0; i < data.data['level2'].length; i++) {
                        //     data.data['level2'][i].isHighlight = false;
                        // }
                        this.setState({
                            primaryChapterListData: data.data['primaryChapter'],
                            level0: data.data['level0'],
                            level2: data.data['level2'],
                            level3: data.data['level3'],
                            level4: data.data['level4'],
                            isLoading: false,
                        })
                    }


                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleClickSelectPrimaryChapter(data, id) {
        const { primaryChapterRadio, primaryChapterListData, level2 } = this.state
        data.IsSelect = !data.IsSelect
        // data.isChecked = !data.IsSelect
        var listData = [...primaryChapterListData]
        if (id != '') {
            for (var i = 0; i < listData.length; i++) {
                if (listData[i].ChapterID == id) {
                    this.setState({
                        selectedPrimaryChapterId: id,
                    })
                    listData[i].isChecked = true;
                } else {
                    listData[i].isChecked = false;
                }
            }
            console.log('updated list : ', listData);
            this.setState({
                primaryChapterListData: listData,
            })
            var lev2List = [...level2]
            for (var i = 0; i < lev2List.length; i++) {
                if (lev2List[i].ParentChapterID == id) {
                    lev2List[i]['isHighlight'] = true;
                } else {
                    lev2List[i]['isHighlight'] = false;
                }
            }
            this.setState({
                level2: lev2List,
            })
        }



        this.setState({ primaryChapterRadio: id })
        // primaryChapterListData.length > 0 && primaryChapterListData.map(data => {
        //     if (data.ChapterID == id) {
        //         data.IsSelect = !data.IsSelect
        //         this.setState({ primaryChapterListData : primaryChapterListData})

        //     }
        // })

    }

    // on check level 4
    onSelectLevel4(e, id, tempLevel4) {
        const { level4, exhibitDetailsList, level3, level2, level4DummyId, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level4[i]['IsSelect'] = !level4[i]['IsSelect']
            }
        }

        var modelevel2 = []
        var modelevel3 = []

        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level3.map((s) => {
                    if (level4[i].ParentChapterID == s.ChapterID) {
                        level2.map(p => {
                            if (s.ParentChapterID == p.ChapterID) {
                                modelevel2.push(p)
                                primaryChapterListData.map(q => {
                                    if (p.ParentChapterID == q.ChapterID) {
                                        modelevel3.push(q)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }

        const finalListlevel3 = []
        level3.map(data => {
            modelevel2.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel3.push(data)
                }

            })
        })

        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })


        console.log("************** finalListlevel2", finalListlevel2)
        // console.log("************** finalListlevel3", finalListlevel3)


        for (let i = 0; i < tempLevel4.length; i++) {
            for (let index = 0; index < level3.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    for (let k = 0; k < level2.length; k++) {
                        if (tempLevel4[i]['ChapterID'] == id) {
                            if (level3[index]['ChapterID'] == tempLevel4[i]['ParentChapterID']) {
                                if (level2[k]['ChapterID'] == level3[index]['ParentChapterID']) {
                                    if (primaryChapterListData[j]['ChapterID'] == level2[k]['ParentChapterID']) {
                                        const allSelectedChecked = tempLevel4.every(item => item.IsSelect == 1)
                                        if (allSelectedChecked) {
                                            level3[index]['IsSelect'] = true

                                            const allSelectedfinalListlevel3 = finalListlevel3.every(item => item.IsSelect == 1)
                                            if (allSelectedfinalListlevel3) {
                                                level2[k]['IsSelect'] = true

                                                const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                                console.log("************** allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                                if (allSelectedfinalListlevel2) {
                                                    primaryChapterListData[j]['IsSelect'] = true
                                                } else {
                                                    primaryChapterListData[j]['IsSelect'] = false
                                                }

                                            } else {
                                                level2[k]['IsSelect'] = false
                                            }


                                        }
                                        else {
                                            level3[index]['IsSelect'] = false
                                            level2[k]['IsSelect'] = false
                                            primaryChapterListData[j]['IsSelect'] = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level4: level4, level3: level3, level2: level2 })
        }, 100);


    }

    // on check level 2
    onCheckLevel2(e, id, tempLevel1) {
        const { level2, primaryChapterListLevel3, level3, primaryChapterListLevel4, primaryChapterListData, level4 } = this.state
        const event = e.target.checked
        for (let i = 0; i < level2.length; i++) {
            if (level2[i]['ChapterID'] == id) {
                if (event == true) {
                    level2[i]['IsSelect'] = true

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = true
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = true
                                }
                            });
                        }
                    });

                } else {
                    level2[i]['IsSelect'] = false

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = false
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = false
                                }
                            });
                        }
                    });
                }

            }
        }
        console.log("tempLevel1", tempLevel1)

        for (let i = 0; i < primaryChapterListData.length; i++) {
            for (let j = 0; j < level2.length; j++) {
                if (level2[j]['ChapterID'] == id) {
                    if (primaryChapterListData[i]['ChapterID'] == level2[j]['ParentChapterID']) {
                        const allSelectedChecked = tempLevel1.every(item => item.IsSelect == 1)
                        console.log("allSelectedChecked", allSelectedChecked)
                        if (allSelectedChecked) {
                            primaryChapterListData[i]['IsSelect'] = true
                        } else {
                            primaryChapterListData[i]['IsSelect'] = false
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);


    }

    // on check level 3
    onCheckLevel3(e, id, tempLevel3) {
        const { level3, primaryChapterListLevel4, level2, level4, primaryChapterListData } = this.state
        this.setState({ level4DummyId: id })
        const event = e.target.checked

        var modelevel2 = []
        var modelevel3 = []
        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                console.log("***********", level3[i])
                level2.map((s) => {
                    if (level3[i].ParentChapterID == s.ChapterID) {
                        primaryChapterListData.map(q => {
                            if (s.ParentChapterID == q.ChapterID) {
                                modelevel3.push(q)
                            }
                        })
                    }
                })
            }
        }


        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })

        console.log("finalListlevel2", finalListlevel2)


        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                if (event == true) {
                    level3[i]['IsSelect'] = true

                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {

                            level4.IsSelect = true
                        }
                    });

                } else {
                    level3[i]['IsSelect'] = false
                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {
                            level4.IsSelect = false
                        }
                    });

                }
            }
        }

        for (let i = 0; i < level3.length; i++) {
            for (let index = 0; index < level2.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    if (level3[i]['ChapterID'] == id) {
                        if (level2[index]['ChapterID'] == level3[i]['ParentChapterID']) {
                            if (primaryChapterListData[j]['ChapterID'] == level2[index]['ParentChapterID']) {
                                const allSelectedChecked = tempLevel3.every(item => item.IsSelect == 1)
                                if (allSelectedChecked) {
                                    level2[index]['IsSelect'] = true
                                    const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                    console.log("allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                    if (allSelectedfinalListlevel2) {
                                        primaryChapterListData[j]['IsSelect'] = true
                                    } else {
                                        primaryChapterListData[j]['IsSelect'] = false
                                    }
                                }
                                else {
                                    level2[index]['IsSelect'] = false
                                    primaryChapterListData[j]['IsSelect'] = false
                                }
                            }
                        }
                    }
                }

            }

        }
        setTimeout(() => {
            this.setState({ level3: level3, level2: level2 })
        }, 100);

    }

    // on check level 1
    onCheckLevel1(e, id) {
        const { level2, primaryChapterListLevel2, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData, level3, level4 } = this.state
        const event = e.target.checked
        for (let i = 0; i < primaryChapterListData.length; i++) {
            if (primaryChapterListData[i]['ChapterID'] == id) {
                if (event == true) {
                    primaryChapterListData[i]['IsSelect'] = true

                    level2.forEach((level2) => {
                        if (level2.ParentChapterID == id) {
                            level2.IsSelect = true

                            level3.forEach((level3) => {
                                if (level2.ChapterID == level3.ParentChapterID) {
                                    level3.IsSelect = true

                                    level4.forEach((level4) => {
                                        if (level3.ChapterID == level4.ParentChapterID) {
                                            level4.IsSelect = true

                                        }
                                    });
                                }
                            });
                        }
                    });

                } else {
                    primaryChapterListData[i]['IsSelect'] = false

                    level2.forEach((level2) => {
                        if (level2.ParentChapterID == id) {
                            level2.IsSelect = false

                            level3.forEach((level3) => {
                                if (level2.ChapterID == level3.ParentChapterID) {
                                    level3.IsSelect = false

                                    level4.forEach((level4) => {
                                        if (level3.ChapterID == level4.ParentChapterID) {
                                            level4.IsSelect = false

                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        }
        setTimeout(() => {
            this.setState({ level2: level2 })
            console.log("level2, primaryChapterListLevel2, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData, level3, level4", this.state.level0, level2, level3, level4, primaryChapterListData)
        }, 200);

    }

    // to call level third list 
    recordTwo(level2, level3, level4) {
        const { primaryChapterListLevel3 } = this.state
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
                primaryChapterListLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} >
                    {
                        tempLevel3 && tempLevel3.map((level3data, i) => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed " type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#collapse_l2_' + level3data.ChapterID}
                                                aria-expanded="false"
                                                aria-controls={'collapse_l2_' + level3data.ChapterID}
                                            >
                                                {/* // checkbox for level3 */}
                                                {
                                                    <input type="checkbox" className="from-left-sp"

                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID, tempLevel3)} />
                                                }

                                                {level3data.ChapterName}
                                                {i != 0 && <i class="fa fa-caret-up ac-dc"></i>}
                                                {tempLevel3.length - 1 != i && <i class="fa fa-caret-down mt-3 ac-dc"></i>}

                                            </button>
                                        </h2>
                                        {this.recordThree(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    // to call level four list
    recordThree(level3data, level4) {
        const { primaryChapterListLevel4 } = this.state
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
                primaryChapterListLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map((level4data, i) => {
                        return (
                            <div class="accordion-body third-step mt-2" style={{ position: 'relative' }}>
                                {/* level4data.IsCheckbox && */}
                                <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                    onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID, tempLevel4)} />
                                {level4data.ChapterName}
                                {i != 0 && <i class="fa fa-caret-up ac-dc ac-dc2"></i>}
                                {tempLevel4.length - 1 != i && <i class="fa fa-caret-down mt-3 ac-dc ac-dc2"></i>}

                            </div>
                        )
                    })
                }
            </div>
        </>);
    }

    // to call level second list
    record(level2data) {
        const { primaryChapterListLevel2, level2, level3, level4 } = this.state
        var tempLevel1 = [];
        for (var j = 0; j < level2.length; j++) {
            if (level2data.ChapterID == level2[j]['ParentChapterID']) {
                tempLevel1.push(level2[j]);
                primaryChapterListLevel2.push(level2[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2data.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel1.length > 0 && tempLevel1.map((level2data, i) => (
                            <>
                                {
                                    // level2data.ParentChapterID == primaryChapterRadio &&
                                    <div class="accordion-body">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header check-out" id={'heading_l1_' + level2data.ChapterID}>

                                                <button class={level2data.isHighlight ? "accordion-button collapsed selected-chapter  tit-name" : "accordion-button collapsed  tit-name"}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={'#collapse_l1_' + level2data.ChapterID} aria-expanded="false"
                                                    aria-controls={'collapse_l1_' + level2data.ChapterID}>
                                                    {
                                                        <input type="checkbox" className="from-left-sp check-out-in" name="" key={level2data.ChapterID}
                                                            checked={level2data.IsSelect}
                                                            onClick={e => this.onCheckLevel2(e, level2data.ChapterID, tempLevel1)}
                                                        />
                                                    }
                                                    {level2data.ChapterName}
                                                    {i != 0 && <i class="fa fa-caret-up ac-dc"></i>}
                                                    {tempLevel1.length - 1 != i && <i class="fa fa-caret-down mt-3 ac-dc"></i>}
                                                </button>
                                            </h2>
                                            {this.recordTwo(level2data, level3, level4)}
                                        </div>

                                    </div>
                                }

                            </>
                        ))
                    }
                </div>
            </>
        );
    }

    render() {
        const { isLoading, primaryChapterListData, level2, level3, level4, parent_flag } = this.state


        return (
            <>

                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}

                <ToastContainer />
                <HeaderWithLogo />
                <>
                    <AdminDrawer />


                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 mt-4">
                                <div className="asc" style={{ border: "1px solid #ddd" }}>
                                    <div
                                        className="card-header p-2 mb-2"
                                        style={{ padding: "9px!important" }}
                                    >
                                        <h3 className="card-title" style={{ fontSize: 14 }}>
                                            Select Below Product
                                        </h3>
                                    </div>
                                    <div className="" style={{ paddingLeft: 10 }}>
                                        <form className="needs-validation pb-2" noValidate="">
                                            <div className="form-row">
                                                <div class="accordion" id="accordionExample">
                                                    {
                                                        primaryChapterListData.length > 0 && primaryChapterListData.map((level1Data, i) => (
                                                            <>
                                                                {
                                                                    // level2Data.ParentChapterID == primaryChapterRadio &&
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header check-out" id={'heading_l1_' + level1Data.ChapterID}>

                                                                            <button class={level1Data.isHighlight ? "accordion-button collapsed selected-chapter  tit-name" : "accordion-button collapsed  tit-name"}
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={'#collapse_l1_' + level1Data.ChapterID} aria-expanded="false"
                                                                                aria-controls={'collapse_l1_' + level1Data.ChapterID}>
                                                                                {/* // checkbox for level2 */}
                                                                                {
                                                                                    <input type="checkbox" className="from-left-sp check-out-in" name="" key={level1Data.ChapterID}
                                                                                        checked={level1Data.IsSelect}
                                                                                        onClick={e => this.onCheckLevel1(e, level1Data.ChapterID)}
                                                                                    />
                                                                                }
                                                                                {level1Data.ChapterName}
                                                                            </button>
                                                                        </h2>
                                                                        {this.record(level1Data)}
                                                                        {/* {this.record(level1Data, level3, level4)} */}
                                                                    </div>
                                                                }

                                                            </>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <button onClick={this.onSubmit} class="btn rounded-0 btn-primary mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Save</button>
                                <button onClick={this.handleBack} class="btn rounded-0 btn-danger mt-3" style={{ padding: "5px 7px", marginLeft: "10px", width: "100px" }}>Back</button>
                            </div>
                        </div>
                    </div>
                </>

                {/* <Footerwithdr /> */}

            </>
        );
    }


}

export default withRouter(connect(null, { fill_action, fill_master_action, getLedgerList_action, chapterAllocation_action })(Exh_chapter));