import React, { Component } from "react";
import Loader from "../../../utils/Loader";
import { ToastContainer, toast } from "react-toastify";
import VisitorHeader2 from "../../../common_components/visitor_module_header2";
import VisitorDrawer from "../../../common_components/visitor_module_drawer";
import { sendPaymentForm_action } from "../../../actions/payment_action/payment_action";
import VisitorTopMenu from "../../../common_components/visitor_module_topmenu";
import { BASE_URL, attendeeTypeActionId, cityActionId, countryActionId, exhibitorBadgeDaysActionId, industryChaptersActionId, paymentPortalLink, pincodeActionId, primaryChapterListActionId, seminarInterestActionId, stateActionId, toastError, toastSuccess, visitPurposeActionId, visitorInterestActionId, paymentMasterListActionId } from "../../../utils/constants";
import { fill_action } from "../../../actions/fillCombo_action"
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Resizer from "react-image-file-resizer";
import { data } from "jquery";
import { combineVisitorRegistrationDetail_action, visitorRegistrationUpdate_action, deleteVisaDoc_action } from "../../../actions/visitor_module_action/visitor_form_action/visitor_form_action"
import { emailValidator } from "../../../utils/Validator";
import Modal from "react-modal";
import { getVisitorRegistrationCredentials_action } from "../../../actions/visitor_module_action/visitor_dashboard_action/visitor_dashboard_action";
import { getDrawerDetails_action } from "../../../actions/exhibitor_module_actions/exhibitor_dashboard_action/exhibitor_dashboard_action";
import { payment_action } from "../../../actions/visitor_module_action/visitor_registration_action/visitor_registration_action"
import { getVisitorPreviewDetails_action, visitorPreviewSubmit_action } from "../../../actions/visitor_module_action/visitor_registration_action/visitor_registration_action";
import VisitorHeader from "../../../common_components/visitor_module_header";


class Visitor_Preview extends Component {

    // Initialize Constructor 
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isOtherPreName: false,
            isSameAsMobNo: false,
            isDisplayStateList: false,
            isDisplayCityList: false,
            isDisplayPincodeList: false,
            needVisaAssistance: false,
            isDisplayPassCountryList: false,
            isAgree: false,
            isVisaApplied: false,
            isOpen: false,
            isOpen2: false,
            isPassportFilePresent: "",
            isVisaUpdated: false,
            isGrpDelegation: false,
            isDisplayDelegationCountryList: false,
            isInterstOne: false,
            isInterestTwo: false,
            isParkingChargable: false,
            isCarParking: 0,
            parkingCharges: 0,
            isParkingAvailable: false,
            visitorCharges: 1000,
            promoCodeDiscount: 0,
            isPromoApply: false,
            promoCode: "",
            paymentAccessCode: 'AVVI64IL27CE56IVEC',
            encPayload: '',
            isPaid: false,

            // Fields 
            country: "",
            countryId: "",
            attendeeType: "",
            attendeeTypeId: "",
            otherName: "",
            preName: "",
            firstName: "",
            middleName: "",
            lastName: "",
            designation: "",
            institute: "",
            preMobNo: "",
            mobNo: "",
            preWhatsAppMobNo: "",
            whatsAppMobNo: "",
            regState: "",
            city: "",
            pincode: "",
            website: "",
            email: "",
            preTelNo: "",
            telNo: "",
            preTel1No: "",
            tel1No: "",
            passName: "",
            preApplicantMobNo: "",
            applicantMobNo: "",
            applicantEmail: "",
            passNo: "",
            passDate: "",
            passExipryDate: "",
            passCountryId: "",
            passCountry: "",
            passportFile: "",
            remarks: "",
            passPortFileName: "",
            passportFileType: "",
            leader: "",
            delegationCountry: "",
            delegationCountryId: "",
            arrivalDate: "",
            departureDate: "",
            delegationRemark: "",
            preffDate: "",
            prefTime: "",
            b2bRemark: "",

            // Lists
            countryList: [],
            attendeeList: [],
            daysList: [],
            stateList: [],
            tempStateList: [],
            cityList: [],
            tempCityList: [],
            pincodeList: [],
            tempPincodeList: [],
            level1: [],
            visitPurposeList: [],
            passCountryList: [],
            tempPassCountryList: [],
            interestList: [],
            industryChapterList: [],
            delgationCountryList: [],
            tempDelegationCountryList: [],
            seminarInterestList: [],
            primaryChapterListData: [],
            level0: [],
            level1: [],
            level2: [],
            level3: [],
            level4: [],
            primaryChapterListLevel2: [],
            primaryChapterListLevel3: [],
            primaryChapterListLevel4: [],

            // Err Messages
            attendeeTypeErr: "",
            daysErr: "",
            firstNameErr: "",
            designationErr: "",
            instituteErr: "",
            mobNoErr: "",
            whatsAppMobNoErr: "",
            regStateErr: "",
            cityErr: "",
            pincodeErr: "",
            chapterErr: "",
            visitPurposeErr: "",
            passNameErr: "",
            applicantMobNoErr: "",
            applicantEmailErr: "",
            passNoErr: "",
            passDateErr: "",
            passExipryDateErr: "",
            passCountryErr: "",
            passportFileErr: "",
            leaderErr: "",
            delegationCountryErr: "",
            arrivalDateErr: "",
            departureDateErr: "",
            delegationRemarkErr: "",
            preffDateErr: "",
            preffTimeErr: "",
            b2bRemarkErr: "",
            ChapterHallErr: "",

            remark: '',
            toPay: '',
            paymentMasterList: [],
            isPaymentEnable: false,
        }
        this.submitfrm = React.createRef()
        this.getFillList = this.getFillList.bind(this)
        this.onAttendeeSelect = this.onAttendeeSelect.bind(this)
        this.onSelectDates = this.onSelectDates.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
        this.preventMinus = this.preventMinus.bind(this)
        this.maxLengthCheck = this.maxLengthCheck.bind(this)
        this.onSameAsMobNoClick = this.onSameAsMobNoClick.bind(this)
        this.onStateSearch = this.onStateSearch.bind(this)
        this.renderStateList = this.renderStateList.bind(this)
        this.onCitySearch = this.onCitySearch.bind(this)
        this.renderCityList = this.renderCityList.bind(this)
        this.onPincodeSearch = this.onPincodeSearch.bind(this)
        this.renderPincodeList = this.renderPincodeList.bind(this)
        this.onSelectPrimaryChapter = this.onSelectPrimaryChapter.bind(this)
        this.onPassportCountrySearch = this.onPassportCountrySearch.bind(this)
        this.renderPassCountryList = this.renderPassCountryList.bind(this)
        this.onFilePick = this.onFilePick.bind(this)
        this.onCrossClick = this.onCrossClick.bind(this)
        this.resizeFile = this.resizeFile.bind(this)
        this.onPdfSelect = this.onPdfSelect.bind(this)
        this.onSelectInterest = this.onSelectInterest.bind(this)
        this.onPreviewSubmit = this.onPreviewSubmit.bind(this)
        this.getVisitorRegistrationDetails = this.getVisitorRegistrationDetails.bind(this)
        this.inputValidate = this.inputValidate.bind(this)
        this.onSelectPurpose = this.onSelectPurpose.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.getVisitorCredentials = this.getVisitorCredentials.bind(this)
        this.getDrawerDetails = this.getDrawerDetails.bind(this)
        this.otherDetailsChange = this.otherDetailsChange.bind(this)
        this.onDelegationCountrySearch = this.onDelegationCountrySearch.bind(this)
        this.renderDelegationCountryList = this.renderDelegationCountryList.bind(this)
        this.handlePrefTime = this.handlePrefTime.bind(this)
        this.onCheckedSeminar = this.onCheckedSeminar.bind(this)
        this.onFileClick = this.onFileClick.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.navToPayment = this.navToPayment.bind(this)
        this.record = this.record.bind(this)
        this.onCheckLevel1 = this.onCheckLevel1.bind(this)
        this.onCheckLevel2 = this.onCheckLevel2.bind(this)
        this.recordTwo = this.recordTwo.bind(this)
        this.onCheckLevel3 = this.onCheckLevel3.bind(this)
        this.recordThree = this.recordThree.bind(this)
        this.onSelectLevel4 = this.onSelectLevel4.bind(this)
        this.onApplyPromo = this.onApplyPromo.bind(this)
        this.onCancelPromo = this.onCancelPromo.bind(this)
        this.onPrevClick = this.onPrevClick.bind(this)
    }

    // On Component Load 
    componentDidMount() {
        console.log((sessionStorage.getItem('VisitorDataSS')))
        // Country List 
        this.getFillList(countryActionId, '', '', '', '', '')
        // Attendee List
        this.getFillList(attendeeTypeActionId, '', '', '', '', '')
        // Days List Action
        this.getFillList(exhibitorBadgeDaysActionId, "", "", "", "", "")

        // Calling City , State , Pincode filllist on country Select
        this.getFillList(pincodeActionId, '', '', '', '', '')
        this.getFillList(stateActionId, '', '', '', '', '')
        this.getFillList(cityActionId, '', '', '', '', '')

        // Primary Chapters
        this.getFillList(primaryChapterListActionId, '', '', '', '', '')
        // Industry Chapters
        this.getFillList(industryChaptersActionId, '', '', '', '', '')
        // Visit Purpose List 
        this.getFillList(visitPurposeActionId, '', '', '', '', '')
        // Visitor Interest List 
        this.getFillList(visitorInterestActionId, '', '', '', '', '')
        // Visitor Interest List 
        this.getFillList(seminarInterestActionId, '', '', '', '', '')

        this.getFillList(paymentMasterListActionId, sessionStorage.getItem("sessionExhibitionID"), '', '', '', '')

        this.getDrawerDetails()

        this.getVisitorCredentials()

        // Calling Get Visitor Details
        this.getVisitorRegistrationDetails()
        setTimeout(() => {
            var param = this.props.params != '' && Object.keys(this.props.params).length != 0 && this.props.params != undefined && this.props.params != null ? this.props.params : null
            var status = param != null ? param.status != undefined && param.status != '' && param.status != null ? param.status : null : null
            console.log('=====================>', status, Object.keys(this.props.params).length)
            if (status != null) {
                if (status == 'Success') {
                    toastSuccess("Payment Successful")
                } else if (status == 'Failure') {
                    toastError('Something went wrong! Please try again later.')
                } else if (status == 'Invalid') {
                    toastError('Payment for the order is already processed')
                }
            }
        }, 500);


    }

    // Get Drawer Details
    getDrawerDetails() {
        this.setState({ isLoading: true })
        this.props.getDrawerDetails_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get getDrawerDetails", data.data)
                    sessionStorage.setItem("sessionVisitorDrawerData", JSON.stringify(data.data))

                    // Formatting Address
                    var address = data.data[0] != null && data.data[0] != "" && data.data[0]['Address'] != null && data.data[0]['Address'] != "" &&
                        data.data[0]['Address']
                    var addArray = address != "" && address != null && address.split(",")
                    sessionStorage.setItem("FormatedAddress", JSON.stringify(addArray))

                    // Formating Mobile Numbers
                    var mobNo = data.data[0] != null && data.data[0] != "" && data.data[0]['MobileNo'] != null && data.data[0]['MobileNo'] != "" &&
                        data.data[0]['MobileNo']
                    var MobArray = mobNo != "" && mobNo != null && mobNo.split(",")
                    sessionStorage.setItem("FormatedMobNo", JSON.stringify(MobArray))
                    // this.setState({ isLoading: false })
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // Get Exhibitior Details
    getVisitorCredentials() {
        console.log("Get JSON.parse(sessionStorage.getItem('loginDataSS')).UserIDJSON.parse(sessionStorage.getItem('loginDataSS')).UserIDJSON.parse(sessionStorage.getItem('loginDataSS')).UserID", JSON.parse(sessionStorage.getItem('VisitorDataSS')))

        this.setState({ isLoading: true })
        this.props.getVisitorRegistrationCredentials_action()
            .then((data) => {
                if (data.error != 1) {
                    console.log("Get getVisitorCredentials", data.data[0])
                    sessionStorage.setItem('VisitorDataSS', JSON.stringify(data.data[0]))
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, from) {
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, from)
            .then((data) => {
                if (data.error != 1) {
                    // country list
                    if (actionID === countryActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            countryList: data != null ? data.data : [],
                            passCountryList: data != null ? data.data : [],
                            tempPassCountryList: data != null ? data.data : [],
                            delgationCountryList: data != null ? data.data : [],
                            tempDelegationCountryList: data != null ? data.data : [],
                            // isLoading: false,
                        })
                    }
                    // state list
                    if (actionID === stateActionId) {
                        console.log("stateList data", data.data)
                        this.setState({
                            stateList: data.data,
                            tempStateList: data.data,
                            // isLoading: false,
                        })
                    }
                    // city list
                    if (actionID === cityActionId) {
                        console.log("city data", data.data)
                        this.setState({
                            cityList: data.data,
                            tempCityList: data.data,
                            // isLoading: false,
                        })
                    }
                    // pincode
                    if (actionID === pincodeActionId) {
                        console.log("pincode data", data.data)
                        this.setState({
                            pincodeList: data.data,
                            tempPincodeList: data.data,
                            // isLoading: false,
                        })
                    }
                    // Days List
                    if (actionID === exhibitorBadgeDaysActionId) {
                        console.log("exhibitor data", data.data)
                        var DateList = []
                        data.data.map((item) => {
                            var mapData = {
                                "Code": item['DateValue'],
                                "description": item['DateValue'],
                                "isSelect": false,
                                "isReadOnly": false
                            }
                            DateList.push(mapData)
                        })
                        this.setState({
                            daysList: DateList,
                            // isLoading: false
                        })
                    }
                    // Attendee Type List
                    if (actionID === attendeeTypeActionId) {
                        console.log("Attendee Type data", data.data)
                        data.data.length > 0 && data.data.map((item) => {
                            item.isChecked = false
                        })
                        this.setState({
                            attendeeList: data.data,
                            // isLoading: false,
                        })
                    }
                    //Chapter List
                    if (actionID === primaryChapterListActionId) {
                        console.log("primaryChapterListActionId 121212", data.data)
                        this.setState({
                            primaryChapterListData: data.data['primaryChapter'] ? data.data['primaryChapter'] : [],
                            level0: data.data['level0'] ? data.data['level0'] : [],
                            level2: data.data['level2'] ? data.data['level2'] : [],
                            level3: data.data['level3'] ? data.data['level3'] : [],
                            level4: data.data['level4'] ? data.data['level4'] : [],
                            isLoading: false,
                        })
                        setTimeout(() => {
                            this.setState({
                                // isLoading: false,
                            })
                        }, 3000);
                    }
                    // Industry Chapter List
                    if (actionID === industryChaptersActionId) {
                        console.log("industryChaptersActionId 121212", data.data)
                        data.data.length > 0 && data.data.map((item) => {
                            item.IsSelect = false
                        })
                        this.setState({
                            industryChapterList: data.data,
                        })
                        setTimeout(() => {
                            this.setState({
                                // isLoading: false,
                            })
                        }, 3000);
                    }
                    // Visit Purpose list
                    if (actionID === visitPurposeActionId) {
                        console.log(" Visit Purpose List data", data.data)
                        data.data.length > 0 && data.data.map((item) => {
                            item.isSelect = false
                            item.otherDetail = ""
                        })
                        this.setState({
                            visitPurposeList: data.data,
                            // isLoading: false,
                        })
                    }
                    //Visitor Interest List
                    if (actionID === visitorInterestActionId) {
                        console.log("Visitor Interest List 121212", data.data)
                        data.data.length > 0 && data.data.map((item) => {
                            item.isSelect = false
                        })
                        this.setState({
                            interestList: data.data,
                            // isLoading: false,
                        })
                    }
                    //Visitor Semninar Interest List
                    if (actionID === seminarInterestActionId) {
                        console.log("Visitor Interest List 121212", data.data)
                        data.data.length > 0 && data.data.map((item) => {
                            item.isSelect = false
                        })
                        this.setState({
                            seminarInterestList: data.data,
                            // isLoading: false,
                        })
                    }
                    if (actionID === paymentMasterListActionId) {
                        console.log("country data", data.data)
                        this.setState({
                            paymentMasterList: data.data, isLoading: false,
                        })
                        data.data.length > 0 && data.data.map(item => {
                            item.PaymentModuleID == 3 && this.setState({ isPaymentEnable: item.IsPaymentEnable })
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // Input Fields Validation
    inputValidate() {
        const { attendeeTypeId, daysList, designation, institute, mobNo, whatsAppMobNo, regState, countryId, city, pincode, level1,
            visitPurposeList, needVisaAssistance, passName, applicantMobNo, applicantEmail, passNo, passDate, passExipryDate,
            passCountryId, passportFile, isGrpDelegation, leader, delegationCountryId, arrivalDate, departureDate, delegationRemark,
            industryChapterList, isInterestTwo, prefTime, preffDate, b2bRemark, primaryChapterListData, level2, level3, level4 } = this.state
        if (attendeeTypeId == "") {
            this.setState({ attendeeTypeErr: "Please Select Attendee Type" });
        }
        if (!(daysList.some(obj => obj.isSelect == true))) {
            this.setState({ daysErr: "Please Select Days" })
        }
        if (designation == "") {
            this.setState({ designationErr: "Please enter designation" });
        }
        if (institute == "") {
            this.setState({ instituteErr: "Please enter institue/Organization" });
        }
        if (mobNo == "") {
            this.setState({ mobNoErr: "Please enter mobile no" });
        }
        if (whatsAppMobNo == "") {
            this.setState({ whatsAppMobNoErr: "Please enter whatsapp no" });
        }
        if (regState == "" && countryId == "91") {
            this.setState({ regStateErr: "Please enter province/state" });
        }
        if (city == "") {
            this.setState({ cityErr: "Please enter city" });
        }
        if (pincode == "") {
            this.setState({ pincodeErr: "Please enter Pincode" });
        }
        if (!(industryChapterList.some(obj => obj.IsSelect == true))) {
            this.setState({ chapterErr: "Please Select Chapter" })
        }
        var allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
        var filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
        if (!(filerArray.some(obj => obj.IsSelect == true))) {
            this.setState({ ChapterHallErr: "Please Select Hall" })
        }
        if (!(visitPurposeList.some(obj => obj.isSelect == true))) {
            this.setState({ visitPurposeErr: "Please Select Purpose of visit" })
        }
        // Visa assistance validation
        if (needVisaAssistance) {
            if (passName == "") {
                this.setState({ passNameErr: "Please enter Name " });
            }
            if (applicantMobNo == "") {
                this.setState({ applicantMobNoErr: "Please enter Mobile NO " });
            }
            if (applicantEmail == "") {
                this.setState({ applicantEmailErr: "Please enter Email " });
            }

            if (passNo == "") {
                this.setState({ passNoErr: "Please enter Passport No " });
            }
            if (passDate == "") {
                this.setState({ passDateErr: "Please enter Passport Date " });
            }
            if (passExipryDate == "") {
                this.setState({ passExipryDateErr: "Please enter Passport Expiry Date " });
            }
            if (passCountryId == "") {
                this.setState({ passCountryErr: "Please Select Country " });
            }
            if (passportFile == "") {
                this.setState({ passportFileErr: "Please Upload File " });
            }
            if (applicantEmail != "") {
                var emailError = emailValidator(applicantEmail)
                if (emailError != '') {
                    this.setState({ applicantEmailErr: emailError })
                    return
                }
            }
        }
        else {
            this.setState({
                passNameErr: "",
                applicantMobNoErr: "",
                applicantEmailErr: "",
                passNoErr: "",
                passDateErr: "",
                passExipryDateErr: "",
                passCountryErr: "",
                passportFileErr: ""
            })
        }
        if (isGrpDelegation) {
            if (leader == "") {
                this.setState({ leaderErr: "Please enter Leader/Organisation" });
            }
            if (delegationCountryId == "") {
                this.setState({ delegationCountryErr: "Please enter Country" });
            }
            if (arrivalDate == "") {
                this.setState({ arrivalDateErr: "Please enter Arrival Date" });
            }
            if (departureDate == "") {
                this.setState({ departureDateErr: "Please enter Departure Date" });
            }
            if (delegationRemark == "") {
                this.setState({ delegationRemarkErr: "Please enter Delegation Remark" });
            }
        }
        else {
            this.setState({
                leaderErr: "",
                delegationCountryErr: "",
                arrivalDateErr: "",
                departureDateErr: "",
                delegationRemarkErr: "",
            })
        }
        if (isInterestTwo) {
            if (preffDate == "") {
                this.setState({ preffDateErr: "Please enter Preffered Date" });
            }
            if (prefTime == "") {
                this.setState({ preffTimeErr: "Please enter Preffered Time" });
            }
        } else {
            this.setState({
                preffTimeErr: "", preffTimeErr: ""
            })
        }

    }

    // On Select Attendee 
    onAttendeeSelect(e) {
        const { attendeeList } = this.state
        var checked = e.target.checked
        var id = e.target.value
        attendeeList.length > 0 && attendeeList.map((data) => {
            if (checked) {
                if (id == data.AttendeeTypeID) {
                    data.isChecked = true
                    this.setState({ attendeeTypeId: data.AttendeeTypeID, attendeeTypeErr: "" })
                } else {
                    data.isChecked = false
                }
            } else {
                data.isChecked = false
            }
        })
        this.setState({ attendeeList: attendeeList })
    }

    // On Select Dates
    onSelectDates(e) {
        const { daysList } = this.state
        var code = e.target.value
        var checked = e.target.checked
        daysList.length > 0 && daysList.map((data) => {
            if (checked) {
                if (code == data.Code) {
                    data.isSelect = true
                }
            } else {
                if (code == data.Code) {
                    data.isSelect = false
                }

            }
        })
        console.log("hgdddhdjdjdjdkdj", daysList)
        this.setState({ daysList: daysList, daysErr: "" })
    }

    maxLengthCheck = (object, maxLength) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    preventMinus = (e) => {
        (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    handlePaste = (event) => {
        const pastedValue = event.clipboardData.getData('text/plain');
        const sanitizedValue = pastedValue.replace(/[-.eE]/g, ''); // Remove '-', '.', 'e', 'E' characters

        // Replace the input value with the sanitized value
        document.execCommand('insertText', false, sanitizedValue);

        event.preventDefault(); // Prevent the default paste behavior
    }

    // On Same As Mobile No Click
    onSameAsMobNoClick(e) {
        const { isSameAsMobNo, mobNo, preMobNo } = this.state
        this.setState({ isSameAsMobNo: !isSameAsMobNo })
        var checked = e.target.checked
        if (checked) {
            this.setState({
                whatsAppMobNo: mobNo,
                preWhatsAppMobNo: preMobNo,
                whatsAppMobNoErr: ""
            })
        } else {
            this.setState({
                whatsAppMobNo: "",

            })

        }
    }

    // search state
    onStateSearch(e) {
        const { tempStateList, stateList } = this.state
        if (e.target.value != '') {
            var searchStateList = tempStateList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                regState: e.target.value,
                regStateErr: "",
                tempStateList: searchStateList,
                isDisplayStateList: true
            })
        } else {
            this.setState({
                regState: e.target.value,
                tempStateList: stateList,
                isDisplayStateList: false
            })
        }
    }

    // render state
    renderStateList() {
        const { tempStateList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempStateList.length > 0 && tempStateList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ regState: data.Description, isDisplayStateList: false })}
                        >{data.Description}</li>

                    ))
                }
            </ul>
        )
    }

    // search city
    onCitySearch(e) {
        const { cityList, tempCityList } = this.state
        if (e.target.value != '') {
            var searchCityList = tempCityList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                city: e.target.value,
                cityErr: "",
                tempCityList: searchCityList,
                isDisplayCityList: true
            })
        } else {
            this.setState({
                city: e.target.value,
                tempCityList: cityList,
                isDisplayCityList: false
            })
        }
    }

    // render city
    renderCityList() {
        const { tempCityList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempCityList.length > 0 && tempCityList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({ city: data.Description, isDisplayCityList: false })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    //Pincode Search
    onPincodeSearch(e) {
        const { pincodeList, tempPincodeList, pincode } = this.state
        if (e.target.value != '') {
            var searchPincodeList = tempPincodeList.filter(data => {
                return (
                    data
                        .Pincode
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            this.setState({
                pincode: e.target.value,
                pincodeErr: "",
                tempPincodeList: searchPincodeList,
                isDisplayPincodeList: true
            })
        } else {
            this.setState({
                pincode: e.target.value,
                tempPincodeList: pincodeList,
                isDisplayPincodeList: false
            })
        }
    }

    // render pincode
    renderPincodeList() {
        const { tempPincodeList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPincodeList.length > 0 && tempPincodeList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                pincode: data.Pincode,
                                isDisplayPincodeList: false
                            })}
                        >{data.Pincode}</li>
                    ))
                }
            </ul>
        )
    }

    // On Primary Chapter Select
    onSelectPrimaryChapter(e) {
        const { industryChapterList } = this.state
        var id = e.target.value
        var checked = e.target.checked

        industryChapterList.length > 0 && industryChapterList.map((data) => {
            if (checked) {
                if (id == data.IndustrySegmentID) {
                    data.IsSelect = true
                }
            }
            else {
                if (id == data.IndustrySegmentID) {
                    data.IsSelect = false
                }
            }
        })
        console.log("hgdddhdjdjdjdkdj", industryChapterList)
        this.setState({ industryChapterList: industryChapterList, chapterErr: "" })
    }

    // On Purpose Select
    onSelectPurpose(e) {
        const { visitPurposeList } = this.state
        var code = e.target.value
        var checked = e.target.checked

        visitPurposeList.length > 0 && visitPurposeList.map((data) => {
            if (checked) {
                if (code == data.PurposeofVisitID) {
                    data.isSelect = true

                }
            }
            else {
                if (code == data.PurposeofVisitID) {
                    data.isSelect = false
                    data.otherDetail = ""
                }
            }
        })
        console.log("hgdddhdjdjdjdkdj", visitPurposeList)
        this.setState({ visitPurposeList: visitPurposeList, visitPurposeErr: "" })
    }

    // On PassPort Country Search
    onPassportCountrySearch(e) {
        const { passCountryList, tempPassCountryList } = this.state
        // this.setState({ passCountry: '' })
        if (e.target.value != '') {
            console.log("tempPassCountryList ++++----------->", tempPassCountryList)

            var searchCoutryList = tempPassCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                passCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                passCountryErr: '',
                tempPassCountryList: searchCoutryList,
                isDisplayPassCountryList: true,
                isVisaUpdated: true
            })
        }
        else {

            this.setState({
                passCountry: e.target.value,
                tempPassCountryList: passCountryList,
                isDisplayPassCountryList: false
            })
        }
    }

    // Render Country for PassPort Country
    renderPassCountryList() {
        const { tempPassCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempPassCountryList.length > 0 && tempPassCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                passCountry: data.Description, passCountryId: data.Code,
                                isDisplayPassCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // On File Pick
    onFilePick = async (e, file, key) => {
        e.preventDefault()
        console.log("File01010101------", file, key)
        const { } = this.state
        this.setState({ isVisaUpdated: true })
        if (file[0]['size'] >= 5 * 1024 * 1024) {
            console.log("consdition1111111111111111111")
            toastError('File size more than 5mb are not allow.')
            if (key == 1) {
                this.setState({ passportFile: "" })
                document.getElementById("passportFile").value = ""
            } else if (key == 2) {
                this.setState({ visitorLetterFile: "" })
                document.getElementById("visitorLetter").value = ""
            }
        } else {
            var image = '';
            if (file[0]['name'].toString().toLowerCase().includes('.pdf') ||
                file[0]['name'].toString().toLowerCase().includes('.jpeg') ||
                file[0]['name'].toString().toLowerCase().includes('.png') ||
                file[0]['name'].toString().toLowerCase().includes('.jpg')
            ) {
                if (file[0]['name'].toString().toLowerCase().trim().includes('.jpg') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.png') ||
                    file[0]['name'].toString().toLowerCase().trim().includes('.jpeg')
                ) {
                    image = await this.resizeFile(file[0]);
                    if (key == 1) {
                        this.setState({ passportFile: image, passportFileErr: "" })

                    } else if (key == 2) {
                        this.setState({ visitorLetterFile: image })
                    }
                }
                else if (file[0]['name'].toString().toLowerCase().trim().includes('.pdf')) {
                    image = await this.onPdfSelect(file[0]);
                    if (key == 1) {
                        this.setState({ passportFile: image, passportFileErr: "" })
                    } else if (key == 2) {
                        this.setState({ visitorLetterFile: image })
                    }
                }

                var fileType = file[0]['type']
                var fileTypeList;
                if (fileType.includes("/")) {
                    fileTypeList = fileType.split("/")
                }
                if (key == 1) {
                    this.setState({ passportFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                } else if (key == 2) {
                    this.setState({ visitorLetterFileType: fileTypeList.length > 0 ? fileTypeList[1] : "" })
                }
            } else {
                toastError('Only .pdf , .jpeg, .png, .jpg files are allowed')
                if (key == 1) {
                    this.setState({ passportFile: "" })
                    document.getElementById("passportFile").value = ""
                } else if (key == 2) {
                    this.setState({ visitorLetterFile: "" })
                    document.getElementById("visitorLetter").value = ""
                }
            }
        }
    }

    // on Cross click
    onCrossClick(key) {
        const { passportFileType, isPassportFilePresent, passportFile } = this.state
        if (isPassportFilePresent) {
            const { isOpen } = this.state
            this.setState({ isOpen: !isOpen })
            console.log("condition------------1111111111")
        } else {
            console.log("condition------------2222222222")
            if (key == 1) {
                this.setState({ passportFile: "" })
                document.getElementById("passportFile").value = ""
            } else if (key == 2) {
                this.setState({ visitorLetterFile: "" })
                document.getElementById("visitorLetter").value = ""
            }
        }
    }

    // File resize
    resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                500,
                600,
                'JPEG',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64",
                300,
                300,
            );
        });

    // If Pdf is selected in file
    onPdfSelect = (file) =>
        new Promise((resolve) => {
            let base64;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                // Print data in console
                resolve(base64);
            };
            // Convert data to base64
            fileReader.readAsDataURL(file);
        });

    // On Select Interests
    onSelectInterest(e) {
        const { interestList } = this.state
        var code = e.target.value
        var checked = e.target.checked

        interestList.length > 0 && interestList.map((data) => {
            if (checked) {
                if (code == data.AreaofInterestID) {
                    data.isSelect = true
                    if (code == 1) {
                        this.setState({
                            isInterstOne: true
                        })
                    } else if (code == 2) {
                        this.setState({ isInterestTwo: true })
                    }
                }
            }
            else {
                data.isSelect = false
                if (code == 1) {
                    this.setState({
                        isInterstOne: false
                    })
                } else if (code == 2) {
                    this.setState({ isInterestTwo: false })
                }
            }
        })
        console.log("hgdddhdjdjdjdkdj", interestList)
        this.setState({ interestList: interestList })
    }

    // On Preview Submit Click
    onPreviewSubmit(e) {
        e.preventDefault()
        const { visitorCharges, isCarParking, isPaymentEnable, parkingCharges, promoCodeDiscount } = this.state
        const totalCharges = visitorCharges + (isCarParking ? parkingCharges : 0) - promoCodeDiscount
        this.setState({ isLoading: true })
        this.props.visitorPreviewSubmit_action(totalCharges)
            .then((data) => {
                if (data.error != 1) {
                    this.setState({ isLoading: false })
                    // toastSuccess("Visitor Submitted Successfully")
                    if (totalCharges > 0 && isPaymentEnable == 1) {
                        this.navToPayment()
                    } else {
                        this.props.navigate('/visitorthankyou')
                    }
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Nav To Payment
    navToPayment() {
        const { parkingCharges, countryId, remark, visitorCharges, isCarParking, promoCodeDiscount, toPay } = this.state
        const totalCharges = visitorCharges + (isCarParking ? parkingCharges : 0) - promoCodeDiscount

        var cancel_url = `${BASE_URL}/visitor/paymentResponse`
        var redirect_url = `${BASE_URL}/visitor/paymentResponse`;//need to ask to redirect

        var request = {
            order_id: `${JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID}`,
            currency: `${countryId == 91 ? 'INR' : 'USD'}`,
            amount: `${10}`,
            redirect_url: `${redirect_url}`,
            cancel_url: `${redirect_url}`,
            merchant_param1: `${'1'}`,
            language: `EN`
        }

        var mapData = {
            'orderParams': request
        }

        // this.props.payment_action(mapData)
        //     .then((data) => {
        //         if (data != null) {
        //             console.log("payment response : ", data)
        //             this.setState({ encPayload: data.data })
        //             setTimeout(() => {
        //                 console.log("submit form", this.submitfrm)
        //                 this.submitfrm.current.submit()
        //                 // this.props.navigate('/payment', { state: { encPayload: this.state.encPayload, } })
        //             }, 1000);
        //         } else {
        //             this.setState({ isLoading: false })
        //             toast.error('There is some problem. Please try again later!', {
        //                 theme: 'colored',
        //                 autoClose: 3000,
        //                 hideProgressBar: true,
        //             })
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        var moreInfo = null
        this.setState({ isLoading: true })

        this.props.sendPaymentForm_action(sessionStorage.getItem("sessionExhibitionID"), JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID, totalCharges, countryId == 91 ? 'INR' : 'USD', [], 'visitorRegistration', moreInfo, remark)
            .then((data) => {
                console.log(data)
                if (data.error != 1) {
                    if (data != null) {
                        console.log("payment response : ", data)

                        this.setState({ encPayload: data.data }, () => {
                            this.submitfrm.current.submit()
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError('There is some problem. Please try again later!')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get Visitor Registration Details
    getVisitorRegistrationDetails() {
        setTimeout(() => {
            const { attendeeList, daysList, industryChapterList, visitPurposeList, interestList, seminarInterestList, primaryChapterListData,
                level2, level3, level4 } = this.state
            // this.setState({ isLoading: true })
            this.props.getVisitorPreviewDetails_action()
                .then((data) => {
                    if (data.error != 1) {
                        console.log("getVisitorRegistrationDetails", data.data['registrationDetails'][0])
                        // Updating Attendee List
                        var attendeeId = data.data['registrationDetails'][0]['AttendeeTypeID'] != null ? data.data['registrationDetails'][0]['AttendeeTypeID'] : ''
                        attendeeList.length > 0 && attendeeList.map((item) => {
                            if (item.AttendeeTypeID == attendeeId) {
                                item.isChecked = true
                            }
                        })
                        // Days List Prepare on basis on get Days list
                        var selectedDaysList = data.data['SelectedVisitDays'] != null ? data.data['SelectedVisitDays'] : []
                        daysList.length > 0 && daysList.map((item) => {
                            selectedDaysList.length > 0 && selectedDaysList.map((element) => {
                                if (element.VisitDate == item.description) {
                                    item.isSelect = true
                                }
                            })
                        })

                        // Split Phone Numbers and country Code 
                        //  Mobile No
                        var mob1 = data.data['registrationDetails'][0]['MobileNo']
                        const parts = mob1.split("-");
                        const Mob1Code = parts[0]
                        const Mob1No = parts[1]
                        // WhatsApp Mobile No
                        var mob2 = data.data['registrationDetails'][0]['WhatsappNo']
                        const parts2 = mob2.split("-")
                        const Mob2Code = parts2[0]
                        const Mob2No = parts2[1]
                        // TelePhone No
                        var tel = data.data['registrationDetails'][0]['Telephone']
                        const parts3 = tel.split("-")
                        const telCode = parts3[0]
                        const telNumber = parts3[1]
                        // Telephone 1 Mobile No
                        var tel1 = data.data['registrationDetails'][0]['Telephone1']
                        const parts4 = tel1.split("-")
                        const tel1Code = parts4[0]
                        const tel1Number = parts4[1]
                        // Chapter List Prepare on basis on get Days list
                        var selectedChapters = data.data['chapterList'] != null ? data.data['chapterList'] : []
                        industryChapterList.length > 0 && industryChapterList.map((item) => {
                            selectedChapters.length > 0 && selectedChapters.map((element) => {
                                if (element.ChapterID == item.IndustrySegmentID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        // Hall Chapter List prepare 
                        var SelectedHallChapters = data.data['hallChapterList'] != null ? data.data['hallChapterList'] : []
                        primaryChapterListData.length > 0 && primaryChapterListData.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        level2.length > 0 && level2.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        level3.length > 0 && level3.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })
                        level4.length > 0 && level4.map((item) => {
                            SelectedHallChapters.length > 0 && SelectedHallChapters.map((element) => {
                                if (element.MappingChapterID == item.ChapterID) {
                                    item.IsSelect = true
                                }
                            })
                        })


                        // visit Purpose List Prepare on basis on get Days list
                        var selectedVisitPurpose = data.data['visitPurposeList'] != null ? data.data['visitPurposeList'] : []
                        visitPurposeList.length > 0 && visitPurposeList.map((item) => {
                            selectedVisitPurpose.length > 0 && selectedVisitPurpose.map((element) => {
                                if (element.PurposeofVisitID == item.PurposeofVisitID) {
                                    item.isSelect = true
                                    item.otherDetail = element.OtherDetails
                                }
                            })
                        })
                        // Applicant Mobile No
                        var appMobNo = data.data['registrationDetails'][0]['ApplicantMobileNo']
                        var parts5 = '';
                        var appMobCode = '';
                        var appMobileNo = '';
                        if (appMobNo != null && appMobNo != '') {
                            parts5 = appMobNo.includes('-') && appMobNo.split("-")
                            appMobCode = parts5[0]
                            appMobileNo = parts5[1]
                        }

                        // Area Of Interest  List Prepare on basis on get Days list
                        var selectedInterest = data.data['areaOfInterestList'] != null ? data.data['areaOfInterestList'] : []
                        interestList.length > 0 && interestList.map((item) => {
                            selectedInterest.length > 0 && selectedInterest.map((element) => {
                                if (element.AreaofInterestID == 1) {
                                    this.setState({ isInterstOne: true })
                                } else if (element.AreaofInterestID == 2) {
                                    this.setState({ isInterestTwo: true })
                                }
                                if (element.AreaofInterestID == item.AreaofInterestID) {
                                    item.isSelect = true
                                }
                            })
                        })

                        // Seminar Interest List Prepare on Basis on get Days list 
                        var selectedSeminarList = data.data['seminarInterestList'] != null ? data.data['seminarInterestList'] : []
                        seminarInterestList.length > 0 && seminarInterestList.map((data) => {
                            selectedSeminarList.length > 0 && selectedSeminarList.map((item) => {
                                if (item.SeminarID == data.SeminarID) {
                                    data.isSelect = true
                                }
                            })
                        })


                        var filePath = data.data['registrationDetails'][0]['PassportFilePath'] != null ? data.data['registrationDetails'][0]['PassportFilePath'] : ''
                        var array = data.data['registrationDetails'][0]['PassportFilePath'] != null &&
                            data.data['registrationDetails'][0]['PassportFilePath'] != "" && filePath.split("/")
                        const FileName = array[array.length - 1]

                        this.setState({ passPortFileName: FileName })


                        this.setState({

                            countryId: data.data['registrationDetails'][0]['CountryID'] != null ? data.data['registrationDetails'][0]['CountryID'] : '',
                            attendeeTypeId: data.data['registrationDetails'][0]['AttendeeTypeID'] != null ? data.data['registrationDetails'][0]['AttendeeTypeID'] : '',
                            attendeeList: attendeeList,
                            daysList: daysList,
                            preName: data.data['registrationDetails'][0]['Title'] != null ? data.data['registrationDetails'][0]['Title'] : '',
                            firstName: data.data['registrationDetails'][0]['FirstName'] != null ? data.data['registrationDetails'][0]['FirstName'] : '',
                            middleName: data.data['registrationDetails'][0]['MiddleName'] != null ? data.data['registrationDetails'][0]['MiddleName'] : '',
                            lastName: data.data['registrationDetails'][0]['LastName'] != null ? data.data['registrationDetails'][0]['LastName'] : '',
                            designation: data.data['registrationDetails'][0]['Designation'] != null ? data.data['registrationDetails'][0]['Designation'] : '',
                            institute: data.data['registrationDetails'][0]['Institute_Organisation'] != null ? data.data['registrationDetails'][0]['Institute_Organisation'] : '',
                            preMobNo: Mob1Code,
                            mobNo: Mob1No,
                            preWhatsAppMobNo: Mob2Code,
                            whatsAppMobNo: Mob2No,
                            isSameAsMobNo: data.data['registrationDetails'][0]['IsSameAsMobileNo'] != null ? data.data['registrationDetails'][0]['IsSameAsMobileNo'] : '',
                            regState: data.data['registrationDetails'][0]['State'] != null ? data.data['registrationDetails'][0]['State'] : '',
                            city: data.data['registrationDetails'][0]['City'] != null ? data.data['registrationDetails'][0]['City'] : '',
                            pincode: data.data['registrationDetails'][0]['Pincode'] != null ? data.data['registrationDetails'][0]['Pincode'] : '',
                            website: data.data['registrationDetails'][0]['Website'] != null ? data.data['registrationDetails'][0]['Website'] : '',
                            email: data.data['registrationDetails'][0]['Email'] != null ? data.data['registrationDetails'][0]['Email'] : '',
                            preTelNo: telCode,
                            telNo: telNumber,
                            preTel1No: tel1Code,
                            tel1No: tel1Number,
                            industryChapterList: industryChapterList,
                            visitPurposeList: visitPurposeList,
                            primaryChapterListData: primaryChapterListData,
                            level2: level2,
                            level3: level3,
                            level4: level4,
                            needVisaAssistance: data.data['registrationDetails'][0]['isVisaAssistance'] != null ? data.data['registrationDetails'][0]['isVisaAssistance'] : '',
                            passName: data.data['registrationDetails'][0]['NameOnPassport'] != null ? data.data['registrationDetails'][0]['NameOnPassport'] : '',
                            preApplicantMobNo: appMobCode,
                            applicantMobNo: appMobileNo,
                            applicantEmail: data.data['registrationDetails'][0]['ApplicantEmailID'] != null ? data.data['registrationDetails'][0]['ApplicantEmailID'] : '',
                            passNo: data.data['registrationDetails'][0]['PassportNo'] != null ? data.data['registrationDetails'][0]['PassportNo'] : '',
                            passDate: data.data['registrationDetails'][0]['PassportIssueDate'] != null ? new Date(data.data['registrationDetails'][0]['PassportIssueDate']) : '',
                            passExipryDate: data.data['registrationDetails'][0]['PassportExpiryDate'] != null ? new Date(data.data['registrationDetails'][0]['PassportExpiryDate']) : '',
                            passCountry: data.data['registrationDetails'][0]['PassportIssuingCountryName'] != null ? data.data['registrationDetails'][0]['PassportIssuingCountryName'] : '',
                            passCountryId: data.data['registrationDetails'][0]['PassportIssuingCountryID'] != null ? data.data['registrationDetails'][0]['PassportIssuingCountryID'] : '',
                            passportFile: data.data['registrationDetails'][0]['PassportFilePath'] != null ? data.data['registrationDetails'][0]['PassportFilePath'] : '',
                            interestList: interestList,
                            remarks: data.data['registrationDetails'][0]['Remarks'] != null ? data.data['registrationDetails'][0]['Remarks'] : '',
                            isAgree: data.data['registrationDetails'][0]['ISAgree'] != null ? data.data['registrationDetails'][0]['ISAgree'] : '',
                            isVisaApplied: data.data['registrationDetails'][0]['isVisaAssistance'] != null ? data.data['registrationDetails'][0]['isVisaAssistance'] : false,
                            isPassportFilePresent: data.data['registrationDetails'][0]['PassportFilePath'] != null && data.data['registrationDetails'][0]['PassportFilePath'] != "" ? true : false,
                            isGrpDelegation: data.data['registrationDetails'][0]['IsGroupDelegation'] != null ? data.data['registrationDetails'][0]['IsGroupDelegation'] : false,
                            leader: data.data['registrationDetails'][0]['DelegationName'] != null ? data.data['registrationDetails'][0]['DelegationName'] : "",
                            delegationCountry: data.data['registrationDetails'][0]['DelegationCountryName'] != null ? data.data['registrationDetails'][0]['DelegationCountryName'] : "",
                            delegationCountryId: data.data['registrationDetails'][0]['DelegationCountryID'] != null ? data.data['registrationDetails'][0]['DelegationCountryID'] : "",
                            arrivalDate: data.data['registrationDetails'][0]['DelegationArrivalDate'] != null ? new Date(data.data['registrationDetails'][0]['DelegationArrivalDate']) : '',
                            departureDate: data.data['registrationDetails'][0]['DelegationDepartureDate'] != null ? new Date(data.data['registrationDetails'][0]['DelegationDepartureDate']) : '',
                            delegationRemark: data.data['registrationDetails'][0]['DelegationRemark'] != null ? data.data['registrationDetails'][0]['DelegationRemark'] : "",
                            preffDate: data.data['registrationDetails'][0]['B2BDepartureDate'] != null ? new Date(data.data['registrationDetails'][0]['B2BDepartureDate']) : '',
                            prefTime: data.data['registrationDetails'][0]['B2BPreferredTime'] != null ? data.data['registrationDetails'][0]['B2BPreferredTime'] : "",
                            b2bRemark: data.data['registrationDetails'][0]['B2BRemark'] != null ? data.data['registrationDetails'][0]['B2BRemark'] : "",
                            isParkingAvailable: data.data['registrationDetails'][0]['CarparkingAvailable'] != null ? data.data['registrationDetails'][0]['CarparkingAvailable'] : "",
                            isParkingChargable: data.data['registrationDetails'][0]['ParkingChargable'] != null ? data.data['registrationDetails'][0]['ParkingChargable'] : false,
                            parkingCharges: data.data['registrationDetails'][0]['ParkingCharges'] != null ? data.data['registrationDetails'][0]['ParkingCharges'] : 0,
                            isCarParking: data.data['registrationDetails'][0]['CarParking'] != null ? data.data['registrationDetails'][0]['CarParking'] : 0,
                            isPaid: data.data['registrationDetails'][0]['CarParking'] != null ? data.data['registrationDetails'][0]['CarParking'] : 0,
                            seminarInterestList: seminarInterestList,
                            isLoading: false,
                        })

                    } else {
                        this.setState({ isLoading: false })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }, 3000);

    }

    // Delete Exhibitor BAdge Document Delete
    handleDelete(e) {
        e.preventDefault()
        const { isOpen } = this.state
        this.setState({ isLoading: true })
        this.props.deleteVisaDoc_action()
            .then((data) => {
                if (data.error != 1) {
                    toastSuccess("Passport Document Deleted SuccessFully")
                    this.setState({ isLoading: false, isOpen: !isOpen, passportFile: "", passPortFileName: "", isPassportFilePresent: false })
                    this.getVisitorRegistrationDetails()
                } else {
                    toastError(data.msg)
                    this.setState({ isLoading: false })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    otherDetailsChange(e) {
        const { visitPurposeList } = this.state
        var value = e.target.value

        visitPurposeList.length > 0 && visitPurposeList.map((data) => {
            if (data.PurposeofVisitID == 10 && data.isSelect) {
                data.otherDetail = value
            } else {
                data.otherDetail = ""
            }
        })
        this.setState({ visitPurposeList: visitPurposeList })
        console.log("sssssssssssssssssssssss", visitPurposeList)
    }

    // On Delegation Country Search
    onDelegationCountrySearch(e) {
        const { delgationCountryList, tempDelegationCountryList } = this.state
        // this.setState({ passCountry: '' })
        if (e.target.value != '') {
            console.log("tempDelegationCountryList ++++----------->", tempDelegationCountryList)

            var searchCoutryList = tempDelegationCountryList.filter(data => {
                return (
                    data
                        .Description
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            })
            console.log("searchCoutryList ++++----------->", searchCoutryList)

            this.setState({
                delegationCountry: e.target.value,
                // regCountryId: searchCoutryList[0]['CountryCode'] != null ? searchCoutryList[0]['CountryCode'] : "",
                delegationCountryErr: '',
                tempDelegationCountryList: searchCoutryList,
                isDisplayDelegationCountryList: true
            })
        }
        else {

            this.setState({
                delegationCountry: e.target.value,
                tempDelegationCountryList: delgationCountryList,
                isDisplayDelegationCountryList: false
            })
        }
    }

    // Render Country for Delegation Country
    renderDelegationCountryList() {
        const { tempDelegationCountryList } = this.state
        return (
            <ul class="list-group pincode-drop" style={{ position: 'absolute', zIndex: 1 }}>
                {
                    tempDelegationCountryList.length > 0 && tempDelegationCountryList.map(data => (
                        <li class="list-group-item" key={Math.random()}
                            onClick={e => this.setState({
                                delegationCountry: data.Description, delegationCountryId: data.Code,
                                isDisplayDelegationCountryList: false
                            })}
                        >{data.Description}</li>
                    ))
                }
            </ul>
        )
    }

    // On Check Preferred Time
    handlePrefTime(e) {
        const prefTiming = e.target.value;
        if (prefTiming >= '09:00' && prefTiming <= '17:00') {
            this.setState({ prefTime: prefTiming });
        } else {
            toastError('Preferred time must be between 9:00 and 17:00')
        }
    }

    // On Checked seminar
    onCheckedSeminar(e, id) {
        const { seminarInterestList } = this.state
        const checked = e.target.checked
        seminarInterestList.length > 0 && seminarInterestList.map((data) => {
            if (data.SeminarID == id) {
                if (checked) {
                    data.isSelect = true
                } else {
                    data.isSelect = false
                }
            }
        })
        console.log("aaaaaaaaaaa", seminarInterestList)
        this.setState({})
    }

    onFileClick() {
        this.toggleModal();
        // const url = "images/B2BEXHIBITOR.pdf"
        // window.open(url, "_blank");
    }

    toggleModal() {
        const { isOpen2 } = this.state
        this.setState({ isOpen2: !isOpen2 })
    }

    onCheckLevel1(e, id) {
        const { level2, primaryChapterListLevel2, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData, level3, level4 } = this.state
        const event = e.target.checked
        for (let i = 0; i < primaryChapterListData.length; i++) {
            if (primaryChapterListData[i]['ChapterID'] == id) {
                if (event == true) {
                    primaryChapterListData[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })
                    level2.forEach((level2) => {
                        if (level2.ParentChapterID == id) {
                            level2.IsSelect = true

                            level3.forEach((level3) => {
                                if (level2.ChapterID == level3.ParentChapterID) {
                                    level3.IsSelect = true

                                    level4.forEach((level4) => {
                                        if (level3.ChapterID == level4.ParentChapterID) {
                                            level4.IsSelect = true

                                        }
                                    });
                                }
                            });
                        }
                    });

                } else {
                    primaryChapterListData[i]['IsSelect'] = false

                    level2.forEach((level2) => {
                        if (level2.ParentChapterID == id) {
                            level2.IsSelect = false

                            level3.forEach((level3) => {
                                if (level2.ChapterID == level3.ParentChapterID) {
                                    level3.IsSelect = false

                                    level4.forEach((level4) => {
                                        if (level3.ChapterID == level4.ParentChapterID) {
                                            level4.IsSelect = false

                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            }
        }
        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);

    }

    onCheckLevel2(e, id, tempLevel1) {
        const { level2, primaryChapterListLevel3, level3, primaryChapterListLevel4, primaryChapterListData, level4 } = this.state
        const event = e.target.checked
        for (let i = 0; i < level2.length; i++) {
            if (level2[i]['ChapterID'] == id) {
                if (event == true) {
                    level2[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = true
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = true
                                }
                            });
                        }
                    });

                } else {
                    level2[i]['IsSelect'] = false

                    level3.forEach((level3) => {
                        if (level3.ParentChapterID == id) {
                            level3.IsSelect = false
                            level4.forEach((level4) => {
                                if (level3.ChapterID == level4.ParentChapterID) {
                                    level4.IsSelect = false
                                }
                            });
                        }
                    });
                }

            }
        }
        console.log("tempLevel1", tempLevel1)

        for (let i = 0; i < primaryChapterListData.length; i++) {
            for (let j = 0; j < level2.length; j++) {
                if (level2[j]['ChapterID'] == id) {
                    if (primaryChapterListData[i]['ChapterID'] == level2[j]['ParentChapterID']) {
                        const allSelectedChecked = tempLevel1.every(item => item.IsSelect == 1)
                        console.log("allSelectedChecked", allSelectedChecked)
                        if (allSelectedChecked) {
                            primaryChapterListData[i]['IsSelect'] = true
                        } else {
                            primaryChapterListData[i]['IsSelect'] = false
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level2: level2 })
        }, 200);


    }

    onCheckLevel3(e, id, tempLevel3) {
        const { level3, primaryChapterListLevel4, level2, level4, primaryChapterListData } = this.state
        this.setState({ level4DummyId: id })
        const event = e.target.checked

        var modelevel2 = []
        var modelevel3 = []
        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                console.log("***********", level3[i])
                level2.map((s) => {
                    if (level3[i].ParentChapterID == s.ChapterID) {
                        primaryChapterListData.map(q => {
                            if (s.ParentChapterID == q.ChapterID) {
                                modelevel3.push(q)
                            }
                        })
                    }
                })
            }
        }


        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })

        console.log("finalListlevel2", finalListlevel2)


        for (let i = 0; i < level3.length; i++) {
            if (level3[i]['ChapterID'] == id) {
                if (event == true) {
                    level3[i]['IsSelect'] = true
                    this.setState({ ChapterHallErr: "" })
                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {

                            level4.IsSelect = true
                        }
                    });

                } else {
                    level3[i]['IsSelect'] = false
                    level4.forEach((level4) => {
                        if (level3[i]['ChapterID'] == level4.ParentChapterID) {
                            level4.IsSelect = false
                        }
                    });

                }
            }
        }

        for (let i = 0; i < level3.length; i++) {
            for (let index = 0; index < level2.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    if (level3[i]['ChapterID'] == id) {
                        if (level2[index]['ChapterID'] == level3[i]['ParentChapterID']) {
                            if (primaryChapterListData[j]['ChapterID'] == level2[index]['ParentChapterID']) {
                                const allSelectedChecked = tempLevel3.every(item => item.IsSelect == 1)
                                if (allSelectedChecked) {
                                    level2[index]['IsSelect'] = true
                                    const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                    console.log("allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                    if (allSelectedfinalListlevel2) {
                                        primaryChapterListData[j]['IsSelect'] = true
                                    } else {
                                        primaryChapterListData[j]['IsSelect'] = false
                                    }
                                }
                                else {
                                    level2[index]['IsSelect'] = false
                                    primaryChapterListData[j]['IsSelect'] = false
                                }
                            }
                        }
                    }
                }

            }

        }
        setTimeout(() => {
            this.setState({ level3: level3, level2: level2 })
        }, 100);

    }

    record(level2data) {
        const { primaryChapterListLevel2, level2, level3, level4 } = this.state
        var tempLevel1 = [];
        for (var j = 0; j < level2.length; j++) {
            if (level2data.ChapterID == level2[j]['ParentChapterID']) {
                tempLevel1.push(level2[j]);
                primaryChapterListLevel2.push(level2[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2data.ChapterID} data-bs-parent="#accordionExample">
                    {
                        tempLevel1.length > 0 && tempLevel1.map((level2data, i) => (
                            <>
                                {
                                    // level2data.ParentChapterID == primaryChapterRadio &&
                                    <div class="accordion-body">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header check-out" id={'heading_l1_' + level2data.ChapterID}>

                                                <button class={"accordion-button collapsed  tit-name"}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={'#collapse_l1_' + level2data.ChapterID} aria-expanded="false"
                                                    aria-controls={'collapse_l1_' + level2data.ChapterID}>
                                                    {level2data.IsCheckbox &&
                                                        <input type="checkbox" className="from-left-sp check-out-in" name="" key={level2data.ChapterID}
                                                            checked={level2data.IsSelect}
                                                            onClick={e => this.onCheckLevel2(e, level2data.ChapterID, tempLevel1)}
                                                        />
                                                    }
                                                    {level2data.ChapterName}
                                                </button>
                                            </h2>
                                            {this.recordTwo(level2data, level3, level4)}
                                        </div>
                                    </div>
                                }

                            </>
                        ))
                    }
                </div>
            </>
        );
    }

    recordTwo(level2, level3, level4) {
        const { primaryChapterListLevel3 } = this.state
        // var newdata = JSON.stringify(data);
        var tempLevel3 = [];
        for (var j = 0; j < level3.length; j++) {
            if (level2.ChapterID == level3[j]['ParentChapterID']) {
                tempLevel3.push(level3[j]);
                primaryChapterListLevel3.push(level3[j]);
            }
        }
        return (
            <>
                <div id={'collapse_l1_' + level2.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l1_' + level2.ChapterID} >
                    {
                        tempLevel3 && tempLevel3.map(level3data => {
                            return (
                                <div class="accordion-body">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={'heading_l2_' + level3data.ChapterID}>
                                            <button class="accordion-button collapsed " type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#collapse_l2_' + level3data.ChapterID}
                                                aria-expanded="false"
                                                aria-controls={'collapse_l2_' + level3data.ChapterID}
                                            >
                                                {/* // checkbox for level3 */}
                                                {level3data.IsCheckbox &&
                                                    <input type="checkbox" className="from-left-sp"

                                                        name="" key={level3data.ChapterID} checked={level3data.IsSelect}
                                                        onClick={e => this.onCheckLevel3(e, level3data.ChapterID, tempLevel3)} />
                                                }

                                                {level3data.ChapterName} </button>
                                        </h2>
                                        {this.recordThree(level3data, level4)}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </>
        );
    }

    recordThree(level3data, level4) {
        const { primaryChapterListLevel4 } = this.state
        var tempLevel4 = [];
        for (var j = 0; j < level4.length; j++) {
            if (level3data.ChapterID == level4[j]['ParentChapterID']) {
                tempLevel4.push(level4[j]);
                primaryChapterListLevel4.push(level4[j]);
            }
        }
        return (<>
            <div id={'collapse_l2_' + level3data.ChapterID} class="accordion-collapse collapse" aria-labelledby={'heading_l2_' + level3data.ChapterID}>
                {
                    tempLevel4 && tempLevel4.map(level4data => {
                        return (
                            <div class="accordion-body third-step">

                                {level4data.IsCheckbox &&
                                    <input type="checkbox" name="" key={level4data.ChapterID} checked={level4data.IsSelect}
                                        onClick={(e) => this.onSelectLevel4(e, level4data.ChapterID, tempLevel4)} />
                                }
                                {level4data.ChapterName}
                            </div>
                        )
                    })
                }
            </div>
        </>);
    }

    onSelectLevel4(e, id, tempLevel4) {
        const { level4, exhibitDetailsList, level3, level2, level4DummyId, primaryChapterListLevel3, primaryChapterListLevel4, primaryChapterListData } = this.state
        var tempExhibitDetailsList = []
        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level4[i]['IsSelect'] = !level4[i]['IsSelect']
            }
        }
        this.setState({ ChapterHallErr: "" })
        var modelevel2 = []
        var modelevel3 = []

        for (let i = 0; i < level4.length; i++) {
            if (level4[i]['ChapterID'] == id) {
                level3.map((s) => {
                    if (level4[i].ParentChapterID == s.ChapterID) {
                        level2.map(p => {
                            if (s.ParentChapterID == p.ChapterID) {
                                modelevel2.push(p)
                                primaryChapterListData.map(q => {
                                    if (p.ParentChapterID == q.ChapterID) {
                                        modelevel3.push(q)
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }

        const finalListlevel3 = []
        level3.map(data => {
            modelevel2.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel3.push(data)
                }

            })
        })

        const finalListlevel2 = []
        level2.map(data => {
            modelevel3.map((item) => {
                if (item.ChapterID == data.ParentChapterID) {
                    finalListlevel2.push(data)
                }

            })
        })




        console.log("************** finalListlevel2", finalListlevel2)
        // console.log("************** finalListlevel3", finalListlevel3)


        for (let i = 0; i < tempLevel4.length; i++) {
            for (let index = 0; index < level3.length; index++) {
                for (let j = 0; j < primaryChapterListData.length; j++) {
                    for (let k = 0; k < level2.length; k++) {
                        if (tempLevel4[i]['ChapterID'] == id) {
                            if (level3[index]['ChapterID'] == tempLevel4[i]['ParentChapterID']) {
                                if (level2[k]['ChapterID'] == level3[index]['ParentChapterID']) {
                                    if (primaryChapterListData[j]['ChapterID'] == level2[k]['ParentChapterID']) {
                                        const allSelectedChecked = tempLevel4.every(item => item.IsSelect == 1)
                                        if (allSelectedChecked) {
                                            level3[index]['IsSelect'] = true

                                            const allSelectedfinalListlevel3 = finalListlevel3.every(item => item.IsSelect == 1)
                                            if (allSelectedfinalListlevel3) {
                                                level2[k]['IsSelect'] = true

                                                const allSelectedfinalListlevel2 = finalListlevel2.every(item => item.IsSelect == 1)
                                                console.log("************** allSelectedfinalListlevel2", allSelectedfinalListlevel2)
                                                if (allSelectedfinalListlevel2) {
                                                    primaryChapterListData[j]['IsSelect'] = true

                                                } else {
                                                    primaryChapterListData[j]['IsSelect'] = false
                                                }

                                            } else {
                                                level2[k]['IsSelect'] = false
                                            }


                                        }
                                        else {
                                            level3[index]['IsSelect'] = false
                                            level2[k]['IsSelect'] = false
                                            primaryChapterListData[j]['IsSelect'] = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        setTimeout(() => {
            this.setState({ level4: level4, level3: level3, level2: level2 })
        }, 100);


    }

    onApplyPromo() {
        this.setState({ isPromoApply: true, promoCodeDiscount: 200 })
    }

    onCancelPromo() {
        this.setState({ isPromoApply: false, promoCodeDiscount: 0 })
    }

    // On Previous Click
    onPrevClick(e) {
        e.preventDefault()
        this.props.navigate('/visitorindustrysegment', { state: { is_previous: true } })
    }

    render() {
        const { isLoading, country, countryId, countryList, attendeeType, attendeeTypeErr, attendeeList, daysErr, daysList, isOtherPreName,
            otherName, firstNameErr, firstName, preName, middleName, lastName, designation, designationErr, institute, instituteErr,
            mobNo, mobNoErr, preMobNo, preWhatsAppMobNo, whatsAppMobNo, whatsAppMobNoErr, isSameAsMobNo, regState, regStateErr,
            isDisplayStateList, city, cityErr, isDisplayCityList, pincode, pincodeErr, isDisplayPincodeList, website, email, telNo, preTelNo,
            tel1No, preTel1No, chapterErr, level1, visitPurposeErr, visitPurposeList, needVisaAssistance, passName, passNameErr, applicantMobNo,
            preApplicantMobNo, applicantMobNoErr, applicantEmail, applicantEmailErr, passNo, passNoErr, passDate, passDateErr, passExipryDate,
            passExipryDateErr, passCountry, passCountryErr, isDisplayPassCountryList, passportFile, passportFileErr, interestList, remarks,
            isAgree, isVisaApplied, passPortFileName, isOpen, isPassportFilePresent, isVisaUpdated, industryChapterList, isGrpDelegation,
            leader, leaderErr, delegationCountry, delegationCountryErr, isDisplayDelegationCountryList, arrivalDate, arrivalDateErr, departureDate,
            departureDateErr, delegationRemark, delegationRemarkErr, isInterestTwo, isInterstOne, preffDate, preffDateErr, prefTime, preffTimeErr,
            seminarInterestList, b2bRemark, b2bRemarkErr, isOpen2, isCarParking, isParkingChargable, parkingCharges, isParkingAvailable,
            paymentAccessCode, encPayload, isPaid, primaryChapterListData, level0, level2, level3, level4, ChapterHallErr, visitorCharges,
            promoCodeDiscount, isPromoApply, promoCode
        } = this.state
        const allArray = [...primaryChapterListData, ...level2, ...level3, ...level4]
        const filerArray = allArray.filter((data) => data.IsCheckbox && data.IsSelect)
        const industryChapFilter = industryChapterList.filter((item) => item.IsSelect)
        const seminarInterestFilter = seminarInterestList.filter((item) => item.isSelect || item.ParentSeminarID == 0)
        const isAnyInterest = interestList.some((obj) => obj.isSelect)
        const totalCharges = visitorCharges + (isCarParking ? parkingCharges : 0) - promoCodeDiscount

        const toInputUppercase = e => {
            e.target.value = ("" + e.target.value).toUpperCase();
        };

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                <VisitorHeader />
                {/* <VisitorDrawer /> */}
                <Modal isOpen={isOpen2} onRequestClose={(e) => this.toggleModal(e)}>
                    <div
                        className="modal-dialog modal-dialog-centered bd-example-modal-lg"
                        id="detail"
                    // tabIndex={-1}
                    // aria-labelledby="detailLabel"
                    // aria-hidden="true"
                    >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content estimate-details2">
                                <div className="modal-header pb-1">
                                    <h5 className="modal-title" id="detailLabel">
                                        <strong>Exhibitor list for meeting at respective booth.</strong>
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close pt-6"
                                        data-bs-dismiss="modal"
                                        style={{ fontSize: 12 }}
                                        aria-label="Close"
                                        onClick={(e) => this.toggleModal(e)}
                                    />
                                </div>
                                <iframe
                                    id="myIframe"
                                    src='images/B2BEXHIBITOR.pdf#toolbar=0'
                                    width="800px"
                                    height="800px"
                                // title="Bing"
                                />

                            </div>
                        </div>
                    </div>
                </Modal>
                <div
                    className="main-content app-content"
                    style={{ marginInlineStart: 0 }}
                >
                    <div>
                        {/* <VisitorTopMenu /> */}
                    </div>

                    <div className="side-app">
                        {/* <div className="container-fluid p-0">
                            <div className="row">
                                <div
                                    className="step-name" style={{ backgroundColor: "#bc202b", color: "#fff;", marginLeft: "225px" }}
                                >
                                    <p>ITME Africa &amp; Middle East 2023 Visitor Registration form</p>
                                </div>
                            </div>
                        </div> */}
                        <div className="combine-section">
                            <div className="needs-validation after-step mt-1 " style={{ marginTop: 115 }}>
                                <div id="Menu1">
                                    <div id="section-1">
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg" style={{ marginTop: "10px" }}>
                                                            <p className="mb-0">Country</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 mb-3">
                                                <div className="d-flex">
                                                    <label className="mt-3 w-auto" >
                                                        Select Country
                                                        <span className="text-red">*</span>

                                                    </label>
                                                    {/* {<span className='text-danger text-start text-left d-block mt-3 ml-2'>{countryIdErr}</span>} */}
                                                </div>
                                                <select className="form-control"
                                                    value={countryId} disabled={true}
                                                // onChange={(e) => this.onCountrySelect(e)}
                                                >
                                                    <option>Select Your Country</option>
                                                    {
                                                        countryList.length > 0 && countryList.map((data) => (
                                                            <option key={data.Code} value={data.Code} >{data.Description}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Attendee Type <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{attendeeTypeErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-8 mb-4">
                                                <div className="row mt-4">
                                                    {
                                                        attendeeList.length > 0 && attendeeList.map((data) => (
                                                            <div className="col-sm-4" key={data.AttendeeTypeID}>
                                                                {data.isChecked &&
                                                                    <label className="d-flex">
                                                                        <input type="radio" name="attendeeType" value={data.AttendeeTypeID}
                                                                            checked={data.isChecked} disabled={true}
                                                                            onChange={(e) => this.onAttendeeSelect(e)}
                                                                        />
                                                                        {data.Name}
                                                                    </label>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Date of Visit Single Day Pass / All Day Pass{" "}
                                                                <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{daysErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 mb-4">
                                                <div className="row mt-4" >
                                                    {
                                                        daysList.length > 0 && daysList.map((data, index) => (
                                                            <div className="col-sm-4" key={data.Code}>
                                                                {data.isSelect &&
                                                                    <label className="d-flex">
                                                                        <input type="checkbox" select={data.isSelect}
                                                                            checked={data.isSelect} value={data.Code} disabled={true}
                                                                            onChange={(e) => this.onSelectDates(e)}
                                                                        />
                                                                        {data.description}
                                                                    </label>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Personal Information</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-xl-3 mt-3">
                                                        <label>
                                                            First Name<span className="text-red">*</span>
                                                        </label>
                                                        {
                                                            isOtherPreName &&
                                                            <input
                                                                type="text"
                                                                className="form-control-1 other-fil"
                                                                maxLength={'40'}
                                                                value={otherName}
                                                                disabled
                                                                onChange={e => this.setState({ otherName: e.target.value })}
                                                                placeholder={'Other'}
                                                                style={{
                                                                    // position: "absolute",
                                                                    paddingLeft: '5px'
                                                                }}
                                                            />
                                                        }
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={'50'}
                                                            disabled
                                                            style={{ position: "relative", paddingLeft: 55 }}
                                                            value={firstName}
                                                        // onChange={(e) => this.setState({ firstName: e.target.value, firstNameErr: "" })}
                                                        />

                                                        <div className="col-xl-1 mb-3">
                                                            <div
                                                                className="form-group"
                                                                style={{
                                                                    width: 45,
                                                                    position: "absolute",
                                                                    top: "-57px",
                                                                    left: 0
                                                                }}
                                                            >
                                                                <label />
                                                                <select
                                                                    className="form-control-1 form-control select2 select-hidden-accessible"
                                                                    id="validationCustom03"
                                                                    required=""
                                                                    style={{
                                                                        borderTop: "none",
                                                                        borderBottom: "none",
                                                                        marginTop: 2,
                                                                        borderRadius: 0,
                                                                        borderLeft: 0,
                                                                        height: 33,
                                                                        background: "none"
                                                                    }}
                                                                    disabled
                                                                    value={preName}
                                                                // onChange={(e) => this.setState({
                                                                //     preName: e.target.value,
                                                                //     isOtherPreName: e.target.value == "Other" && true
                                                                // })}
                                                                >
                                                                    <option value="Mr">Mr</option>
                                                                    <option value="Ms">Ms</option>
                                                                    <option value="DR">DR</option>
                                                                    <option value="Prof">Prof</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        {/* {<span className='text-danger text-start text-left d-block'>{firstNameErr}</span>} */}

                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label className="mt-3">Middle Name</label>
                                                        <input type="text" className="form-control"
                                                            value={middleName} disabled
                                                            maxLength={'50'}
                                                        // onChange={(e) => this.setState({ middleName: e.target.value })}
                                                        />
                                                    </div>
                                                    <div className="col-xl-3 mb-1">
                                                        <label className="mt-3">Last Name
                                                            <span className="text-red">*</span>
                                                        </label>
                                                        <input type="text" className="form-control"
                                                            value={lastName} maxLength={'50'} disabled
                                                        // onChange={(e) => this.setState({ lastName: e.target.value, lastNameErr: "" })}
                                                        />
                                                        {/* {<span className='text-danger text-start text-left d-block mt-1'>{lastNameErr}</span>} */}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-3">
                                                            Designation <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control"
                                                            value={designation} maxLength={'50'} disabled
                                                            onChange={(e) => this.setState({ designation: e.target.value, designationErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{designationErr}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-4 mb-4">
                                            <div className="row mt-2">
                                                <div className="col-sm-2">
                                                    <label className="d-flex">
                                                        Gender:
                                                        <span className="text-red">*</span>
                                                    </label>
                                                </div>
                                                <div className="col-sm-2">
                                                    <label className="d-flex">
                                                        <input type="radio" name="gender"
                                                            checked={gender == "Male"}
                                                            onChange={e => this.setState({ gender: "Male" })}
                                                        />
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label className="d-flex">
                                                        <input type="radio" name="gender"
                                                            checked={gender == "Female"}
                                                            onChange={e => this.setState({ gender: "Female" })}
                                                        />
                                                        Female
                                                    </label>
                                                </div>
                                                {<span className='text-danger text-start text-left d-block mt-1'>{genderErr}</span>}
                                            </div>
                                        </div> */}
                                            <div className="col-xl-12 mb-4">
                                                <div className="row">

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            Institute/Organisation <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control"
                                                            value={institute} maxLength={'500'} disabled
                                                            onChange={(e) => this.setState({ institute: e.target.value, instituteErr: "" })}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{instituteErr}</span>}

                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Mobile No
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={mobNo}
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: 50 }} disabled
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ mobNo: e.target.value, mobNoErr: "" })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            placeholder="ISD" maxLength={'10'}
                                                            value={preMobNo} disabled
                                                            onChange={(e) => this.setState({ preMobNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{mobNoErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> WhatsApp Mobile No
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={whatsAppMobNo} disabled
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            style={{ paddingLeft: 50 }}
                                                            onChange={(e) => e.target.value >= 0 &&
                                                                this.setState({ whatsAppMobNo: e.target.value, whatsAppMobNoErr: "" })}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1"
                                                            value={preWhatsAppMobNo} disabled
                                                            placeholder="ISD"
                                                            maxLength={'10'}
                                                            onChange={(e) => this.setState({ preWhatsAppMobNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{whatsAppMobNoErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-6">
                                                            <input type="checkbox" name="fav_language"
                                                                value={isSameAsMobNo} checked={isSameAsMobNo} disabled
                                                                onClick={(e) => this.onSameAsMobNoClick(e)}
                                                            />
                                                            Same as Mobile No
                                                        </label>
                                                    </div>
                                                    {/* <div className="col-xl-4">
                                                    <label className="mt-4">
                                                        Upload Photo <span className="rad-star">*</span>
                                                    </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="photoFile" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 1)}
                                                        />
                                                        {
                                                            photoFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(1)}></i>
                                                        }
                                                    </form>

                                                    <p>
                                                        <strong>Note:</strong> Upload Maximum 2mb Size Only /
                                                        Allowed File Type: .jpg,.png
                                                    </p>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{photoFileErr}</span>}
                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="mt-4">
                                                        Upload Photo ID <span className="rad-star">*</span>
                                                    </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="photoIdFile" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 2)}
                                                        />
                                                        {
                                                            photoIdFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(2)}></i>
                                                        }
                                                    </form>

                                                    <p>
                                                        <strong>Note:</strong> Upload Maximum 2mb Size Only /
                                                        Allowed File Type: .jpg,.png
                                                    </p>
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{photoIdFileErr}</span>}

                                                </div>
                                                <div className="col-xl-4">
                                                    <label className="mt-4">
                                                        Upload Fully Vaccination Certificate
                                                    </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="vaccFile" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 3)}
                                                        />
                                                        {
                                                            vaccineCertificateFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(3)}></i>
                                                        }
                                                    </form>

                                                    <p>
                                                        <strong>Note:</strong> Upload Maximum 2mb Size Only /
                                                        Allowed File Type: .jpg,.png
                                                    </p>
                                                </div> */}
                                                    {/* <div className="col-sm-6">
                                                    <label className="d-flex mt-2"> Address Line 1</label>
                                                    <input type="text" className="form-control" value={add1}
                                                        onChange={(e) => this.setState({ add1: e.target.value })}
                                                    />
                                                </div> */}
                                                    {/* <div className="col-sm-6">
                                                    <label className="d-flex mt-2"> Address Line 2</label>
                                                    <input type="text" className="form-control" value={add2}
                                                        onChange={(e) => this.setState({ add2: e.target.value })}
                                                    />
                                                </div> */}
                                                    {/* <div className="col-sm-6">
                                                    <label className="d-flex mt-2"> Address Line 3</label>
                                                    <input type="text" className="form-control" value={add3}
                                                        onChange={(e) => this.setState({ add3: e.target.value })}
                                                    />
                                                </div> */}
                                                    {/* <div className="col-sm-3">
                                                    <label className="d-flex mt-2">
                                                        Country<span className="red-star text-danger">*</span>
                                                    </label>
                                                    <input type="text" className="form-control" value={personalCountry}
                                                        onChange={(e) => this.onPersonalCountrySearch(e)}
                                                    />
                                                    {isDisplayPersonalCountryList && this.renderPersonalCountryList()}
                                                    {<span className='text-danger text-start text-left d-block mt-1'>{personalCountryErr}</span>}
                                                </div> */}
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            Pincode/Zipcode
                                                            {countryId == "91" && <span className="red-star text-danger">*</span>}
                                                        </label>
                                                        {
                                                            countryId == "91" &&
                                                            <input type="text" className="form-control"
                                                                value={pincode} disabled
                                                                maxLength={6} onChange={(e) => this.onPincodeSearch(e)}
                                                            />
                                                        }
                                                        {
                                                            countryId != "91" &&
                                                            <input type="text" className="form-control" value={pincode}
                                                                maxLength={10} onChange={(e) => this.onPincodeSearch(e)}
                                                                onInput={toInputUppercase} disabled
                                                            />
                                                        }
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{pincodeErr}</span>}
                                                        {isDisplayPincodeList && this.renderPincodeList()}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">
                                                            State/Province
                                                            {countryId == "91" && <span className="red-star text-danger">*</span>}
                                                        </label>
                                                        <input type="text" className="form-control" value={regState} maxLength={'50'}
                                                            onChange={(e) => this.onStateSearch(e)} disabled
                                                        />
                                                        {isDisplayStateList && this.renderStateList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{regStateErr}</span>}
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">City
                                                            <span className="red-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control" value={city} maxLength={'50'} disabled
                                                            onChange={(e) => this.onCitySearch(e)} />
                                                        {isDisplayCityList && this.renderCityList()}
                                                        {<span className='text-danger text-start text-left d-block mt-1'>{cityErr}</span>}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> Website</label>
                                                        <input type="text" className="form-control"
                                                            value={website} maxLength={'50'} disabled
                                                            onChange={(e) => this.setState({ website: e.target.value })}
                                                        />
                                                    </div>
                                                    {/* <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> GST No</label>
                                                    <input type="text" className="form-control" value={gstNo}
                                                        onChange={(e) => this.setState({ gstNo: e.target.value })}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label className="d-flex mt-2"> PAN No</label>
                                                    <input type="text" className="form-control" value={panNo}
                                                        onChange={(e) => this.setState({ panNo: e.target.value })}
                                                    />
                                                </div> */}
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2"> Email
                                                            <span className="rad-star text-danger">*</span>
                                                        </label>
                                                        <input type="text" className="form-control"
                                                            value={email} maxLength={'50'} disabled
                                                        // onChange={(e) => this.setState({ email: e.target.value, emailErr: "" })}
                                                        />
                                                        {/* {<span className='text-danger text-start text-left d-block mt-1'>{emailErr}</span>} */}
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Telephone No</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={telNo} disabled
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.preventMinus(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ telNo: e.target.value })}
                                                            style={{ paddingLeft: 50 }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1" disabled
                                                            value={preTelNo} placeholder="STD" maxLength={'10'}
                                                            onChange={(e) => this.setState({ preTelNo: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label className="d-flex mt-2">Telephone No 1</label>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            value={tel1No} disabled
                                                            maxLength={'10'}
                                                            onInput={(e) => this.maxLengthCheck(e, 10)}
                                                            onKeyDown={(e) => this.preventMinus(e)}
                                                            onPaste={(e) => this.handlePaste(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => e.target.value >= 0 && this.setState({ tel1No: e.target.value })}
                                                            style={{ paddingLeft: 50 }}
                                                        />
                                                        <input
                                                            type="text"
                                                            className="form-control-1" disabled
                                                            value={preTel1No} placeholder="STD" maxLength={'10'}
                                                            onChange={(e) => this.setState({ preTel1No: e.target.value })}
                                                            style={{
                                                                width: 45,
                                                                position: "absolute",
                                                                top: 30,
                                                                padding: " 6px !important",
                                                                paddingLeft: 5,
                                                                borderLeft: 0,
                                                                borderTop: 0,
                                                                background: "none",
                                                                borderBottom: 0,
                                                                height: 34
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }} />
                                    </div>
                                </div>
                            </div>
                            <div className="needs-validation after-step mt-1" style={{ marginTop: 115 }}>
                                <div id="Menu2">
                                    <div className="containerfluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="p-1 title-bg d-flex">
                                                    <p className="mb-0">Industry Segment <span className="text-red">*</span>
                                                    </p>
                                                    {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{chapterErr}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" mt-2" id="section-2">
                                        <div className="one-section">
                                            <div className=" mb-2">
                                                <div className="row mt-1">
                                                    <div className="asc">
                                                        <div className="" style={{ paddingLeft: 10 }}>
                                                            <form className="needs-validation pb-2" noValidate="">
                                                                <div className="form-row mt-3">
                                                                    {
                                                                        industryChapFilter.length > 0 && industryChapFilter.map((data) => (
                                                                            <div className="col-sm-3 mb-3">
                                                                                <section className="accordion" key={data.IndustrySegmentID}>
                                                                                    {/* <input type="checkbox" name="collapse"
                                                                                    select={data.IsSelect} checked={data.IsSelect}
                                                                                    value={data.IndustrySegmentID}

                                                                                /> */}
                                                                                    <h2 className="handle-no-icon m-0" style={{ fontSize: '13px' }}>
                                                                                        <label>
                                                                                            <input type="checkbox"
                                                                                                select={data.IsSelect} checked={data.IsSelect}
                                                                                                value={data.IndustrySegmentID} disabled
                                                                                                onChange={(e) => this.onSelectPrimaryChapter(e)} />
                                                                                            {data['Industrial Segment']}
                                                                                        </label>
                                                                                    </h2>
                                                                                </section>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Hall to Visit <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{ChapterHallErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-12 mb-4">
                                                <div className="row mt-4">
                                                    <div class="accordion" id="accordionExample">
                                                        {
                                                            primaryChapterListData.length > 0 && primaryChapterListData.map((level1Data, i) => (
                                                                <>
                                                                    {
                                                                        <div class="accordion-item">
                                                                            <h2 class="accordion-header check-out" id={'heading_l1_' + level1Data.ChapterID}>

                                                                                <button class={"accordion-button collapsed  tit-name"}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={'#collapse_l1_' + level1Data.ChapterID} aria-expanded="false"
                                                                                    aria-controls={'collapse_l1_' + level1Data.ChapterID}>
                                                                                    {level1Data.IsCheckbox &&
                                                                                        <input type="checkbox" className="from-left-sp check-out-in" name="" key={level1Data.ChapterID}
                                                                                            checked={level1Data.IsSelect}
                                                                                            onClick={e => this.onCheckLevel1(e, level1Data.ChapterID)}
                                                                                        />
                                                                                    }
                                                                                    {level1Data.ChapterName}
                                                                                </button>
                                                                            </h2>
                                                                            {this.record(level1Data)}
                                                                        </div>
                                                                    }

                                                                </>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="" style={{ marginLeft: "10px" }}>
                                                <p className="mb-0" style={{ fontSize: 15 }}>
                                                    <strong> Selected Hall to Visit</strong>
                                                </p>
                                            </div>
                                            <div style={{ padding: "0px 0px 0px 10px" }}>
                                                {
                                                    filerArray.length > 0 ? filerArray.map((data) => (
                                                        <p>
                                                            {data.ChapterName}
                                                        </p>
                                                    )) :
                                                        <p>
                                                            {"No Results"}
                                                        </p>
                                                }
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg d-flex">
                                                            <p className="mb-0">
                                                                Purpose of Visit <span className="text-red">*</span>
                                                            </p>
                                                            {<span className='text-danger text-start text-left d-block mt-1 ml-2'>{visitPurposeErr}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mb-2">
                                                <div className="row mt-4">
                                                    {
                                                        visitPurposeList.length > 0 && visitPurposeList.map((data) => (
                                                            <div className="col-sm-3 mb-2" key={data.PurposeofVisitID}>
                                                                {data.isSelect &&
                                                                    <label className="d-flex">
                                                                        <input type="checkbox" name="fav_language"
                                                                            select={data.isSelect} checked={data.isSelect}
                                                                            value={data.PurposeofVisitID} disabled
                                                                            onChange={(e) => this.onSelectPurpose(e)}
                                                                        />
                                                                        {data.Name}
                                                                    </label>
                                                                }
                                                                {
                                                                    data.PurposeofVisitID == 10 && data.isSelect &&
                                                                    <input
                                                                        className="form-control"
                                                                        maxLength={'100'}
                                                                        value={data.otherDetail}
                                                                        onChange={(e) => this.otherDetailsChange(e)}
                                                                    >
                                                                    </input>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {isGrpDelegation &&
                                            <div className="one-section mb-3">
                                                <div className="col-sm-3 ">
                                                    <label className="d-flex">
                                                        <input className="do-you"
                                                            type="checkbox"
                                                            name="fav_language"
                                                            disabled
                                                            checked={isGrpDelegation}
                                                            onChange={(e) => this.setState({
                                                                isGrpDelegation: !isGrpDelegation

                                                            })}
                                                        />
                                                        <span className="do-you1" style={{ whiteSpace: "nowrap", fontSize: "16px" }}><strong>Are you part of Group Delegation ?</strong>
                                                            (If yes below section would open)</span>
                                                    </label>
                                                </div>
                                                {
                                                    isGrpDelegation &&
                                                    <div
                                                        className="col-md-12 mb-2"
                                                        id="divMsg"
                                                    >
                                                        <div className="row">
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">
                                                                    Delegation Leader/Organisation<span className="text-red">*</span>
                                                                </label>
                                                                <input type="text" className="form-control" value={leader} maxLength={'50'}
                                                                    disabled
                                                                    onChange={(e) => this.setState({ leader: e.target.value, leaderErr: "" })}
                                                                />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{leaderErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">
                                                                    Country/Region<span className="text-red">*</span>
                                                                </label>
                                                                <input type="text" className="form-control" value={delegationCountry} disabled
                                                                    onChange={(e) => this.onDelegationCountrySearch(e)}
                                                                />
                                                                {isDisplayDelegationCountryList && this.renderDelegationCountryList()}
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{delegationCountryErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">Arrival Date
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <DatePicker selected={arrivalDate}
                                                                    onChange={(date) =>
                                                                        this.setState({
                                                                            arrivalDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                            arrivalDateErr: ""
                                                                        })}
                                                                    disabled
                                                                    dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    placeholderText="Arrival Date"
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class" />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{arrivalDateErr}</span>}
                                                            </div>
                                                            <div className="col-xl-3">
                                                                <label className="mt-3">Departure Date
                                                                    <span className="text-red">*</span>
                                                                </label>
                                                                <DatePicker selected={departureDate}
                                                                    onChange={(date) =>
                                                                        this.setState({
                                                                            departureDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                            departureDateErr: ""
                                                                        })}
                                                                    disabled
                                                                    dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                    peekNextMonth
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    placeholderText="Departure Date"
                                                                    dayClassName={() => "example-datepicker-day-class"}
                                                                    popperClassName="example-datepicker-class" />
                                                                {<span className='text-danger text-start text-left d-block mt-1'>{departureDateErr}</span>}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-xl-12 mb-1">
                                                                    <label className="mt-3">Remark
                                                                        <span className="text-red">*</span>
                                                                    </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        rows={3}
                                                                        maxLength={'250'}
                                                                        defaultValue={""}
                                                                        disabled
                                                                        value={delegationRemark}
                                                                        onChange={(e) => this.setState({ delegationRemark: e.target.value, delegationRemarkErr: "" })}
                                                                    />
                                                                    {<span className='text-danger text-start text-left d-block mt-1'>{delegationRemarkErr}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {/* {
                                        !isStudentAttendee &&

                                        <div>
                                            <div>
                                                <p style={{ marginLeft: 12 }} className="mb-2">
                                                    <strong>
                                                        Letter from the employer giving the position of applicant in the
                                                        company, the reason for the visit with particulars of companies
                                                        to be visited.
                                                        <a
                                                            target="_blank"
                                                            href={Visitorletter}
                                                            download="Visitorletter"
                                                        >
                                                            <i
                                                                className="fa fa-file-pdf-o text-primary"
                                                                style={{ fontSize: 17 }}
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </strong>
                                                </p>
                                                <div className="col-xl-6">
                                                    <label className="">Upload file </label>
                                                    <form action="/action_page.php">
                                                        <input type="file" id="visitorLetter" name="filename"
                                                            accept=".jpg, .jpeg, .png, .pdf"
                                                            onChange={e => this.onFilePick(e, e.target.files, 2)}
                                                        />
                                                        {
                                                            visitorLetterFile != "" &&
                                                            <i class="fa fa-times text-danger img-can" onClick={() => this.onCrossClick(2)}></i>
                                                        }
                                                    </form>
                                                    <p>
                                                        <strong>NOTE:</strong> Upload Maximum 1mb Size Only / Allowed
                                                        File Type: pdf
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="one-section ">
                                        <div className="containerfluid">
                                            <div className="row">
                                                <div className="col-md-12 mt-2">
                                                    <div className="p-1 title-bg">
                                                        <p className="mb-0">Note</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 mb-4">
                                            <div className="row mt-4">
                                                <div className="col-sm-12 mb-1">
                                                    <label className="d-flex">
                                                        {" "}
                                                        1. In case you are applying for business visa kindly upload the
                                                        Permission/NOC from your company to travel to ITME Africa &amp; M.E.
                                                        2023 in Kenya
                                                    </label>
                                                </div>
                                                <div className="col-sm-12 mb-2">
                                                    <label className="d-flex">
                                                        {" "}
                                                        2. In case you are applying for non-business visa you need not
                                                        required to upload the document
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                        {isAnyInterest &&
                                            <div className="one-section ">
                                                <div className="containerfluid">
                                                    <div className="row">
                                                        <div className="col-md-12 mt-2">
                                                            <div className="p-1 title-bg">
                                                                <p className="mb-0">Interested</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 mb-4">
                                                    <div className="row mt-4">
                                                        {
                                                            interestList.length > 0 && interestList.map((data) => (
                                                                <div className="col-sm-9 mb-2" key={data.AreaofInterestID}>
                                                                    {data.isSelect &&
                                                                        <label className="d-flex">
                                                                            <input type="checkbox" name="fav_language" value={data.AreaofInterestID}
                                                                                select={data.isSelect} checked={data.isSelect} disabled
                                                                                onChange={(e) => this.onSelectInterest(e)}
                                                                            />
                                                                            {data.Name}
                                                                        </label>
                                                                    }
                                                                    {isInterestTwo && data.AreaofInterestID == 2 &&
                                                                        < div >
                                                                            <div className="col-sm-3 ">
                                                                                <label className="d-flex">
                                                                                    <span className="do-you1" style={{ whiteSpace: "nowrap", fontSize: "16px" }}><strong>If interested Please fill preferred Date & Time</strong>
                                                                                        (Exhibition time is 09:00hrs to 17:00hrs)
                                                                                        {/* <a className="text-primary"
                                                                                     onClick={(e) => this.onFileClick(e)}><spa className="text-danger blink-soft">Click here</spa> to view exhibitor list for meeting at respective booth.</a> */}
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                className="col-md-12 mb-2"
                                                                                id="divMsg"
                                                                            >
                                                                                <div className="row">
                                                                                    <div className="col-xl-3">
                                                                                        <label className="mt-1">Date
                                                                                            <span className="text-red">*</span>
                                                                                        </label>
                                                                                        <DatePicker selected={preffDate}
                                                                                            onChange={(date) =>
                                                                                                this.setState({
                                                                                                    preffDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                                                    preffDateErr: ""
                                                                                                })}
                                                                                            disabled
                                                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                                            peekNextMonth
                                                                                            showMonthDropdown
                                                                                            showYearDropdown
                                                                                            dropdownMode="select"
                                                                                            placeholderText="Preferred Date"
                                                                                            dayClassName={() => "example-datepicker-day-class"}
                                                                                            popperClassName="example-datepicker-class" />
                                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{preffDateErr}</span>}
                                                                                        <div>
                                                                                            <div >
                                                                                                <label>preferred Time:</label>
                                                                                                <input
                                                                                                    type="time"
                                                                                                    className="form-control"
                                                                                                    value={prefTime}
                                                                                                    disabled
                                                                                                    onChange={(e) => this.handlePrefTime(e)}
                                                                                                />
                                                                                                {<span className='text-danger text-start text-left d-block mt-1'>{preffTimeErr}</span>}
                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-xl-8 mb-1">
                                                                                            <label className="mt-1">Remark
                                                                                            </label>
                                                                                            <textarea
                                                                                                className="form-control"
                                                                                                rows={3}
                                                                                                maxLength={'250'}
                                                                                                defaultValue={""}
                                                                                                value={b2bRemark}
                                                                                                disabled
                                                                                                onChange={(e) => this.setState({ b2bRemark: e.target.value, b2bRemarkErr: "" })}
                                                                                            />
                                                                                            {<span className='text-danger text-start text-left d-block mt-1'>{b2bRemarkErr}</span>}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {isInterstOne && data.AreaofInterestID == 1 &&
                                                                        <div className="table-responsive" >
                                                                            <table className="table border table-bordered mb-0 mt-1" >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-center">Day</th>
                                                                                        <th style={{ width: '180px' }}>Date & Time</th>
                                                                                        <th style={{ width: '680px' }}>Description</th>
                                                                                        <th style={{ width: '380px' }}>Speaker</th>
                                                                                        <th className="text-center"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        seminarInterestFilter.length > 0 && seminarInterestFilter.map((data) => (
                                                                                            <tr key={Math.random()}>
                                                                                                <td className={data.ParentSeminarID != 0 ? "text-center" : "fw-bold"}>{data.Day}</td>
                                                                                                <td className={data.ParentSeminarID != 0 ? null : "fw-bold"}>{data['Date & Time']}</td>
                                                                                                <td className={data.ParentSeminarID != 0 ? null : "fw-bold"}>{data.Description}</td>
                                                                                                {data.SeminarID != 14 ? (
                                                                                                    <td>{data.Speaker}</td>
                                                                                                ) : (
                                                                                                    <td>{data.Speaker.split('==').map(item => (
                                                                                                        <tr className="text-start">{item}</tr>
                                                                                                    ))}</td>
                                                                                                )}
                                                                                                <td className="text-center">
                                                                                                    {data.ParentSeminarID != 0 &&
                                                                                                        <input type="checkbox"
                                                                                                            value={data.isSelect}
                                                                                                            checked={data.isSelect}
                                                                                                            disabled
                                                                                                            onChange={(e) => this.onCheckedSeminar(e, data.SeminarID)}
                                                                                                        />
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>

                                                                            <div />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {needVisaAssistance &&
                                                    <div className="one-section mb-3">
                                                        <div className="col-sm-3 ">
                                                            <label className="d-flex">
                                                                <input className="do-you" style={{ marginBottom: '25px' }}
                                                                    type="checkbox"
                                                                    name="fav_language"
                                                                    checked={needVisaAssistance}
                                                                    disabled
                                                                    onChange={(e) => this.setState({
                                                                        needVisaAssistance: !needVisaAssistance,
                                                                        isVisaUpdated: false
                                                                    })}
                                                                />
                                                                <span className="do-you1" style={{ whiteSpace: "nowrap" }}><strong>Do you need Visa Assistance?</strong>
                                                                    (<b>Note</b> : Visa Application letter will be issued from 1st October 2023. Visitor can avail tourist visa also to attend the exhibition.In case applying for Business visa kindly<br /> fill the below details to obtain Visa Invitation Letter.)</span>
                                                            </label>
                                                        </div>
                                                        {
                                                            needVisaAssistance &&
                                                            <div
                                                                className="col-md-12 mb-2"
                                                                id="divMsg"
                                                            >
                                                                <div className="row">
                                                                    <div className="col-xl-3">
                                                                        <label className="mt-3">
                                                                            Name as per Passport<span className="text-red">*</span>
                                                                        </label>
                                                                        <input type="text" className="form-control" value={passName} maxLength={'50'}
                                                                            disabled
                                                                            onChange={(e) => this.setState({ passName: e.target.value, passNameErr: "", isVisaUpdated: true })}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passNameErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label className="mt-3">
                                                                            Applicant mobile number<span className="text-red">*</span>
                                                                        </label>
                                                                        <input type="number" className="form-control" value={applicantMobNo}
                                                                            style={{ paddingLeft: "50px" }}
                                                                            maxLength={'10'} onInput={(e) => this.maxLengthCheck(e, 10)}
                                                                            onPaste={(e) => this.handlePaste(e)} disabled
                                                                            onKeyDown={(e) => this.preventMinus(e)} onWheel={(e) => e.target.blur()}
                                                                            onChange={(e) => e.target.value >= 0 &&
                                                                                this.setState({ applicantMobNo: e.target.value, applicantMobNoErr: "", isVisaUpdated: true })}
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            className="form-control-1 mt-2"
                                                                            // placeholder="ISD"
                                                                            value={preApplicantMobNo}
                                                                            disabled
                                                                            onChange={(e) => this.setState({ preApplicantMobNo: e.target.value, isVisaUpdated: true })}
                                                                            style={{
                                                                                width: 45,
                                                                                position: "absolute",
                                                                                top: 30,
                                                                                padding: " 6px !important",
                                                                                paddingLeft: 5,
                                                                                borderLeft: 0,
                                                                                borderTop: 0,
                                                                                background: "none",
                                                                                borderBottom: 0,
                                                                                height: 34
                                                                            }}
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{applicantMobNoErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label className="mt-3">
                                                                            Applicant email id<span className="text-red">*</span>
                                                                        </label>
                                                                        <input type="text" className="form-control" value={applicantEmail} maxLength={'50'}
                                                                            onChange={(e) => this.setState({ applicantEmail: e.target.value, applicantEmailErr: "", isVisaUpdated: true })}
                                                                            disabled
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{applicantEmailErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label className="mt-3">Passport No
                                                                            <span className="text-red">*</span>
                                                                        </label>
                                                                        <input type="text" className="form-control" value={passNo} maxLength={'10'}
                                                                            onChange={(e) => this.setState({ passNo: e.target.value, passNoErr: "", isVisaUpdated: true })}
                                                                            disabled
                                                                        />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passNoErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label className="mt-3">Passport issue Date
                                                                            <span className="text-red">*</span>
                                                                        </label>
                                                                        <DatePicker selected={passDate}
                                                                            value={passDate}
                                                                            disabled
                                                                            onChange={(date) =>
                                                                                this.setState({
                                                                                    passDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                                    passDateErr: "", isVisaUpdated: true
                                                                                })}
                                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            maxDate={passExipryDate}
                                                                            dropdownMode="select"
                                                                            placeholderText="Passport Issued Date"
                                                                            dayClassName={() => "example-datepicker-day-class"}
                                                                            popperClassName="example-datepicker-class" />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passDateErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label className="mt-3">Passport Expiry Date
                                                                            <span className="text-red">*</span>
                                                                        </label>
                                                                        <DatePicker selected={passExipryDate}
                                                                            value={passExipryDate}
                                                                            disabled
                                                                            onChange={(date) =>
                                                                                this.setState({
                                                                                    passExipryDate: new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())),
                                                                                    passExipryDateErr: "", isVisaUpdated: true
                                                                                })}
                                                                            dateFormat={"dd/MM/yyyy"} className="form-control"
                                                                            peekNextMonth
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            minDate={passDate}
                                                                            dropdownMode="select"
                                                                            placeholderText="Passport Expiry Date"
                                                                            dayClassName={() => "example-datepicker-day-class"}
                                                                            popperClassName="example-datepicker-class" />
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passExipryDateErr}</span>}
                                                                    </div>
                                                                    <div className="col-xl-3">
                                                                        <label className="mt-3">
                                                                            Passport issuing Country<span className="text-red">*</span>
                                                                        </label>
                                                                        <input type="text" className="form-control" value={passCountry} disabled
                                                                            onChange={(e) => this.onPassportCountrySearch(e)}
                                                                        />
                                                                        {isDisplayPassCountryList && this.renderPassCountryList()}
                                                                        {<span className='text-danger text-start text-left d-block mt-1'>{passCountryErr}</span>}
                                                                    </div>
                                                                    {!(isPassportFilePresent) &&
                                                                        <div className="col-xl-5">
                                                                            <label className="mt-3">
                                                                                Passport Front Page (Maximum 5mb / File Type .jpg, .jpeg, .png, .pdf)
                                                                                <span className="text-red">*</span>
                                                                            </label>
                                                                            <form action="/action_page.php" style={{ display: "inline-flex" }}>
                                                                                <input type="file" id="passportFile" name="filename"
                                                                                    accept=".jpg, .jpeg, .png, .pdf" maxLength={"50"}
                                                                                    disabled
                                                                                    onChange={e => this.onFilePick(e, e.target.files, 1)}

                                                                                />
                                                                                {<span className='text-danger text-start text-left d-block mt-1'>{passportFileErr}</span>}
                                                                                {
                                                                                    passportFile != "" &&
                                                                                    <i className="fa fa-times text-danger img-can"
                                                                                    // onClick={() => this.onCrossClick(1)}
                                                                                    ></i>
                                                                                }
                                                                            </form>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        isVisaApplied && passportFile != "" && passportFile != null && isPassportFilePresent &&
                                                                        <div className="col-xl-5">
                                                                            <label className="mt-3">
                                                                                Passport Front Page File
                                                                                <span className="text-red">*</span>
                                                                            </label>
                                                                            <div style={{
                                                                                border: '1px solid #ccc',
                                                                                padding: '10px',
                                                                                // display: 'inline-flex',
                                                                                alignItems: 'center',
                                                                                marginTop: '10px',
                                                                                // marginLeft: '12px',
                                                                                maxWidth: '300px'
                                                                            }}>
                                                                                <span style={{ marginRight: '10px' }}
                                                                                >{passPortFileName}</span>
                                                                                <i className="fa fa-times text-danger img-can"
                                                                                //  onClick={() => this.onCrossClick(1)}
                                                                                ></i>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {/* <div className="one-section">
                                        <div className="containerfluid">
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="p-1 title-bg">
                                                        <p className="mb-0">Car Parking Required (Paid Service)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 mb-4">
                                            <div className="row mt-4">
                                                <div className="col-sm-1 mb-2">
                                                    <label className="d-flex">
                                                        <input type="radio" name="carParking"
                                                            checked={carParking == "Yes"}
                                                            onChange={(e) => this.setState({ carParking: "Yes" })}
                                                        />
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="col-sm-1 mb-2">
                                                    <label className="d-flex">
                                                        <input type="radio" name="carParking"
                                                            checked={carParking == "No"}
                                                            onChange={(e) => this.setState({ carParking: "No" })}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        {isParkingAvailable &&
                                            <div className="one-section">
                                                <div className="containerfluid">
                                                    <div className="row mt-2">
                                                        <div className="col-md-12">
                                                            <div className="p-1 title-bg">
                                                                <p className="mb-0">Car Parking</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 mb-4">
                                                    <div className="row mt-2">
                                                        <div className="col-sm-1">
                                                            <label className="d-flex" htmlFor="carParkingYes">
                                                                {" "}
                                                                <input type="radio" name="billingRadio"
                                                                    id="carParkingYes"
                                                                    disabled={true}
                                                                    checked={isCarParking == 1}
                                                                    onChange={(e) => this.setState({ isCarParking: 1 })}
                                                                />
                                                                <strong>Yes</strong>
                                                            </label>
                                                        </div>
                                                        <div className="col-sm-1">
                                                            <label className="d-flex" htmlFor="carParkingNo">
                                                                {" "}
                                                                <input type="radio" name="billingRadio"
                                                                    id="carParkingNo"
                                                                    disabled={true}
                                                                    checked={isCarParking == 0}
                                                                    onChange={(e) => this.setState({ isCarParking: 0 })}
                                                                />
                                                                <strong>No</strong>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {isParkingChargable && isCarParking == 1 &&
                                                        <div className="row mt-3 left-align">
                                                            <strong>Car Parking Charges:- {parkingCharges}</strong>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Remarks</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-12 mb-4">
                                                <div className="row mt-2">
                                                    <div className="col-xl-12 mb-1">
                                                        <textarea
                                                            className="form-control"
                                                            rows={3}
                                                            maxLength={'250'}
                                                            defaultValue={""}
                                                            value={remarks}
                                                            disabled
                                                            onChange={(e) => this.setState({ remarks: e.target.value })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="one-section">
                                            <div className="col-xl-12 mb-4">
                                                <div className="mt-2">
                                                    <div className="col-sm-12 mb-2">
                                                        <input
                                                            type="checkbox"
                                                            className="mt-1 d-flex"
                                                            style={{ float: "left" }}
                                                            checked={isAgree}
                                                            disabled
                                                            onChange={(e) => this.setState({ isAgree: !isAgree })}
                                                        />
                                                        <label className="term">
                                                            I have read and accept the Visitor
                                                            <a>Terms &amp; Conditions &amp; Privacy Policy</a> on this
                                                            website and agree to register as visitor. By Submitting my registration, I give my consent to India
                                                            ITME Society to verify the provided information submitted by me online
                                                            by telephonic call or by Email and also to send me promotional
                                                            information via digital and offline channels not limited to email,
                                                            SMS, WhatsApp, print newsletter and invitation card.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.isPaymentEnable && <div className="one-section">
                                            <div className="containerfluid">
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="p-1 title-bg">
                                                            <p className="mb-0">Payment Details</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-3 mb-4 mt-2" style={{ float: "right" }}>
                                                <div className="row d-flex" >
                                                    <input type="text" className="form-control" />
                                                    <button class="btn btn-primary" >
                                                        <a className=""  >
                                                            Apply</a>
                                                    </button>
                                                </div>

                                            </div> */}
                                            {<div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-4 ml-auto mb-2 mt-2">
                                                        <div className="d-flex">
                                                            <input type="text" className="form-control rounded-0" style={{ marginRight: '5px' }}
                                                                disabled={isPromoApply} value={promoCode} onChange={(e) => this.setState({ promoCode: e.target.value })}
                                                            />
                                                            {isPromoApply ?
                                                                <button className="btn btn-primary rounded-0"
                                                                    onClick={() => this.onCancelPromo()}
                                                                >
                                                                    <a className="" >
                                                                        Cancel
                                                                    </a>
                                                                </button>
                                                                :
                                                                <button className="btn btn-primary rounded-0"
                                                                    onClick={() => this.onApplyPromo()}
                                                                >
                                                                    <a className="" >
                                                                        Apply
                                                                    </a>
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row text-left">
                                                    <div className="col-md-12 pl-0">
                                                        <div className="col">
                                                            <h5>Visitor Charges : {visitorCharges}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 pl-0">
                                                        <div className="col">
                                                            <h5>Parking Charges : {isCarParking ? parkingCharges : 0}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 pl-0">
                                                        <div className="col">
                                                            <h6>Promo Code Discount : -{promoCodeDiscount}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 pl-0">
                                                        <div className="col">
                                                            <h3>Total Charges : {totalCharges}</h3>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row" >
                                                    <div style={{ textAlign: 'left' }} className="col-md-4 mt-2">
                                                        <label>Remark</label>
                                                        <textarea value={this.state.remark} className="form-control" onChange={(e) => this.setState({ remark: e.target.value })}>

                                                        </textarea>
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>}
                                        <div className="main-container m-3">
                                            <div className="mt-1 mb-4 d-flex">
                                                <a
                                                    className="btn btn-previous" style={{ marginRight: '10px' }}
                                                    type="submit"
                                                    onClick={(e) => this.onPrevClick(e)}
                                                >
                                                    Previous
                                                </a>
                                                <form onSubmit={(e) => this.onPreviewSubmit(e)} ref={this.submitfrm} id="nonseamless" method="post" name="redirect"
                                                    action={paymentPortalLink}
                                                >

                                                    <input type="hidden" id="encRequest" name="encRequest"
                                                        value={encPayload} />
                                                    <input type="hidden" name="access_code" id="access_code"
                                                        value={paymentAccessCode} />
                                                    <button class="" >
                                                        <a className="btn btn-success rounded-0" style={{ padding: "4px 25px" }} >
                                                            Submit</a>
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onRequestClose={() => this.setState({ isOpen: false })}>
                    <div className="modal-dialog">
                        <div className="modal-content confirm-logout">
                            <div class="modal-header p-2">
                                <h5 class="modal-title modal_title_text text-danger">Confirm Delete</h5>
                                <button type="button " class="close pop-close" data-dismiss="modal" onClick={() => this.setState({ isOpen: false })} ><span aria-hidden="true" class="pop-cancel">&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="pop_label">Do you really want to delete this Document?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer p-1">
                                <div class="form-group text-right mod-fot">
                                    <button type="button" class="btn btn-del-pop" onClick={(e) => this.handleDelete(e)}>Yes</button>
                                    <button type="button" class="btn btn-can-pop" data-dismiss="modal" style={{ marginLeft: '5px' }} onClick={() => this.setState({ isOpen: false })}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}
export default withRouter(connect(null, {
    fill_action, combineVisitorRegistrationDetail_action, visitorRegistrationUpdate_action, payment_action, sendPaymentForm_action,
    deleteVisaDoc_action, getVisitorRegistrationCredentials_action, getDrawerDetails_action, getVisitorPreviewDetails_action,
    visitorPreviewSubmit_action
})(Visitor_Preview))

