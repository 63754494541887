import { axiosGet, axiosPost } from "../../../react_axios/Axios";
import { GET_VISITOR_BADGE_ACTION_TYPE, GET_VISITOR_DASHBOARD_ACTION_TYPE, GET_VISITOR_REGISTRATION_CREDENTIALS_ACTION_TYPE, badgeReportName, fetchAuthReport, getVisitorDashboardUrl, getVisitorRegistrationCredUrl, headers } from "../../../utils/constants";



export const getVisitorDashboard_action = () => async (dispatch) => {

    try {
        const params = {
            // ExhibitionID : sessionStorage.getItem('sessionExhibitionID') ,
            // ExhregistrationID : sessionStorage.getItem('ExhRegistrationIDSS') ,
            // AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            // UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getVisitorDashboard_action Action------->", params)

        const res = await axiosGet(getVisitorDashboardUrl, params, headers)

        dispatch({
            type: GET_VISITOR_DASHBOARD_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisitorRegistrationCredentials_action = () => async (dispatch) => {

    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            AccountID: JSON.parse(sessionStorage.getItem('loginDataSS')).AccountID,
            ExhibitionID: sessionStorage.getItem('sessionExhibitionID'),
        }
        console.log("getVisitorRegistrationCredentials_action Action------->", params)

        const res = await axiosGet(getVisitorRegistrationCredUrl, params, headers)

        dispatch({
            type: GET_VISITOR_REGISTRATION_CREDENTIALS_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getVisitorBadge_action = () => async (dispatch) => {
    try {
        var list = [];
        const mapdata1 = {
            "ParameterName": "@ExhibitionID",
            "ParameterValue": sessionStorage.getItem('sessionExhibitionID'),
            "ParameterDataType": "int"
        }
        list.push(mapdata1);
        const mapdata2 = {
            "ParameterName": "@VisitorID",
            "ParameterValue": JSON.parse(sessionStorage.getItem('VisitorDataSS')).ExhVisitorID,
            "ParameterDataType": "int"
        }
        list.push(mapdata2);

        const mapData = {
            "report_name": badgeReportName,
            "parameter_details": list
        }

        const res = await axiosPost(fetchAuthReport, mapData);

        dispatch({
            type: GET_VISITOR_BADGE_ACTION_TYPE,
            payload: res,
        });

        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};