import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../common_components/Header';
import Drawer from '../../common_components/Drawer';
import { lastSubmitAdd_action } from '../../actions/last_submit_action'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from '../../utils/withRouter';
import moment from 'moment';
import Modal from "react-modal";
import Loader from '../../utils/Loader'
import { clearSessionStorage, countryActionId, toastError, toastSuccess, countryIWithCodeActionId } from '../../utils/constants'
import Pagination from '../../utils/pagination'
import { useEffect, useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { fill_action } from '../../actions/fillCombo_action'
import StepHeader from './stepHeader'
import Footer from '../../common_components/Footer';



class ThankYou extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            geturl: "",
            is_partner: false,
            exhibitionAdd: '',
            exhibitionName: '',
            ExhRegistrationNo: '',
            fromDate: '',
            toDate: '',

            isClickValidation: false
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.onPreviousClick = this.onPreviousClick.bind(this)
    }

    onSubmit(e) {
        var exbid = sessionStorage.getItem("sessionExhibitionID");
        var regid = sessionStorage.getItem("sessionExhRegistrationID")

        this.setState({ isLoading: true })
        this.props
            .lastSubmitAdd_action(exbid, regid)
            .then((data) => {
                this.setState({ isLoading: false })
                if (data.error != 1) {
                    toastSuccess("Your space booking application has been submitted successfully.");
                }
                else {
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });

    }

    componentWillUnmount() {
        const { isClickValidation } = this.state
        if (!isClickValidation) {
            sessionStorage.clear()
            window.location.replace('http://localhost:3000/#/');
        }
    }


    componentDidMount() {
        sessionStorage.setItem('completed', true);
        console.log('route : ', this.props);
        var exhno = this.props.location.state != null && this.props.location.state != "" && this.props.location.state.ExhRegistrationNo != " " ? this.props.location.state.ExhRegistrationNo.replaceAll('/', '_') : ''
        // Exhibition From Date
        const dateString = sessionStorage.getItem('exhibitionDateFrom');
        const dateStringMoment = moment.utc(dateString).format('DD MMMM YYYY')
        const dateObject = new Date(dateStringMoment);
        const fromDay = dateObject.getDate();
        const fromMonth = dateObject.toLocaleString('default', { month: 'long' });
        const fromYear = dateObject.getFullYear();
        const ExfromDate = `${fromDay} ${fromMonth} ${fromYear}`;
        console.log('dateString', dateString)
        console.log('dateStringMoment', dateStringMoment)
        console.log('dateObject', dateObject)

        // Exhibition to Date
        const dateString1 = sessionStorage.getItem('exhibitionDateTo');
        const dateString1Moment = moment.utc(dateString1).format('DD MMMM YYYY')
        const dateObject1 = new Date(dateString1Moment);
        const toDay = dateObject1.getDate();
        const toMonth = dateObject1.toLocaleString('default', { month: 'long' });
        const toYear = dateObject1.getFullYear();
        const ExtoDate = `${toDay} ${toMonth} ${toYear}`;
        this.setState({
            geturl: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.geturl != " " ? this.props.location.state.geturl : '',
            is_partner: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.is_partner != " " ? this.props.location.state.is_partner : '',
            exhibitionAdd: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.exhibitionAdd != " " ? this.props.location.state.exhibitionAdd : '',
            exhibitionName: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.exhibitionName != " " ? this.props.location.state.exhibitionName : '',
            ExhRegistrationNo: exhno,
            fromDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.fromDate != " " ? this.props.location.state.fromDate : (sessionStorage.getItem('exhibitionDateFrom') != null && sessionStorage.getItem('exhibitionDateFrom') != '') ? ExfromDate : '',
            toDate: this.props.location.state != null && this.props.location.state != "" && this.props.location.state.toDate != " " ? this.props.location.state.toDate : (sessionStorage.getItem('exhibitionDateTo') != null && sessionStorage.getItem('exhibitionDateTo') != '') ? ExtoDate : '',
        })

    }

    onPreviousClick(e) {
        const { isClickValidation } = this.state
        e.preventDefault();
        this.setState({ isClickValidation: true }, () => {
            const win = window.location.replace(sessionStorage.getItem('sessionExhMailWebsite'));
            if (win != null) {
                win.focus();
            }
        })
    }



    render() {
        const { isLoading, geturl, is_partner, exhibitionAdd, fromDate, toDate, exhibitionName, ExhRegistrationNo } = this.state

        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (null)}
                <ToastContainer />
                {/* <!-- PAGE --> */}
                <Header />
                {/* <!-- CONTAINER --> */}
                <div >
                    {/* <StepHeader /> */}

                    {/* <!-- ROW-1 --> {geturl}*/}
                    <div
                        className="main-content app-content mt-0"
                        style={{ backgroundColor: "#ececec" }}
                    >
                        <div className="side-app">
                            <div className="main-container container-fluid top-form-name">
                                <div className="row ">
                                    <form className="needs-validation " noValidate="">
                                        <div className="form-row mt-4">
                                            <div className="form-group col-md-7 p-4 thank-box">
                                                {is_partner == true ? <div className="form-group mb-2">
                                                    <h4
                                                        className="mb-0 pt-2 pb-4 thank-top-mes"
                                                        style={{ color: "#318958" }}
                                                    >
                                                        <strong>Application Submitted Successfully</strong>
                                                    </h4>
                                                    <p className="mb-1 mt-1 text-center com-font">
                                                        Thank you for submitting your partner application for
                                                    </p>
                                                    <p className="mt-1 mb-0 text-center com-font">
                                                        { }
                                                        {exhibitionName} from {fromDate} to {toDate}
                                                    </p>
                                                    <p className="mb-3 text-center com-font">
                                                        {exhibitionAdd}
                                                    </p>
                                                    <h5 className="mb-4 pt-1" style={{ fontWeight: "bold" }}>
                                                        <strong>Your Application Number is
                                                            <span> {ExhRegistrationNo}</span></strong>
                                                    </h5>
                                                    <p className="mb-0 mt-2 text-center com-font">
                                                        <strong>Note:</strong> Kindly check your registered email
                                                        address for acknowledgement of application.
                                                        {/* and 1st <br></br> Proforma
                                                        for space booking amount. */}
                                                    </p>
                                                </div> :
                                                    <div className="form-group mb-2">
                                                        <h4
                                                            className="mb-0 pt-2 pb-4 thank-top-mes"
                                                            style={{ color: "#318958" }}
                                                        >
                                                            <strong>Application Submitted Successfully</strong>
                                                        </h4>
                                                        <p className="mb-1 mt-1 text-center com-font">
                                                            Thank you for submitting your space booking application for
                                                        </p>
                                                        <p className="mt-1 mb-0 text-center com-font">
                                                            {sessionStorage.getItem("sessionExhibitionName")} from {fromDate} to {toDate}
                                                        </p>
                                                        <p className="mb-3 text-center com-font">
                                                            at {sessionStorage.getItem("exhibitionAddress")}
                                                        </p>
                                                        <h5 className="mb-4 pt-1" style={{ fontWeight: "bold" }}>
                                                            <strong>Your Application Number is
                                                                <span> {sessionStorage.getItem("sessionExhRegistrationNo")}</span></strong>
                                                        </h5>
                                                        <p className="mb-0 mt-2 text-center com-font">
                                                            <strong>Note:</strong> Kindly check your registered email
                                                            address for acknowledgement of application and 1st <br></br> Proforma
                                                            for space booking amount.
                                                        </p>
                                                    </div>}
                                                <div classname="">
                                                    <div classname="mt-1 mb-4">

                                                        <button
                                                            classname="btn btn-next-bot m-1"
                                                            style={{
                                                                background: "#007bff",
                                                                padding: "6px 20px",
                                                                fontSize: 14,
                                                                border: "1px solid #007bff",
                                                                borderRadius: 2,
                                                                color: "#fff",
                                                                marginTop: 16
                                                            }}
                                                            onClick={e => this.onPreviousClick(e)}
                                                        >
                                                            {" "}
                                                            BACK TO EVENT WEBSITE
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <Footer />
                {/* <!-- CONTAINER END --> */}

            </>
        );
    }
}

export default withRouter(connect(null, {
    lastSubmitAdd_action
})(ThankYou));

