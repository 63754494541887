import { axiosGet, axiosPost } from "../../../../react_axios/Axios";
import { getExhibitionMasterDetailsUrl, getExhibitionMasterlistUrl, addExhibitionMasterUrl, updateExhibitionMasterUrl, deleteExhibitionMasterUrl, GET_EXHIBITION_MASTER_DETAILS, GET_EXHIBITION_MASTER_LIST, ADD_EXHIBITION_MASTER, UPDATE_EXHIBITION_MASTER, DELETE_EXHIBITION_MASTER, headers, deleteExhibitionDocumentUrl, DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE } from "../../../../utils/constants";
import moment from "moment";
export const getExhibitionMasterList_action = () => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getExhibitionMasterlistUrl, params, headers)

        dispatch({
            type: GET_EXHIBITION_MASTER_LIST,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const addExhibitionMaster_action = (Name, Code, countryId, fromDate, toDate, bankDetails, address, countryList, stallTypeList, exhibitionLogo, logoFileType, exhibitionBanner, bannerFileType, logoFilePath, bannerFilePath, PIseries, ExhMailWebsite, ExhMailEmail, ExhMailMobile) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: null,
            ExhibitionName: Name,
            Code: Code,
            CurrencyID: '',
            CountryID: countryId,
            Address: address,
            ExhibitionDateFrom: fromDate != '' ? moment(fromDate).format('YYYY-MM-DD') : null,
            ExhibitionDateTo: toDate != '' ? moment(toDate).format('YYYY-MM-DD') : null,
            BankDetails: bankDetails,
            PI_Series: PIseries,
            countryList: countryList,
            stallTypeList: stallTypeList,
            logoFile: exhibitionLogo,
            logoFileType: logoFileType,
            bannerFile: exhibitionBanner,
            bannerFileType: bannerFileType,
            logoPath: logoFilePath,
            bannerPath: bannerFilePath,
            ExhMailWebsite: ExhMailWebsite,
            ExhMailEmail: ExhMailEmail,
            ExhMailMobile: ExhMailMobile,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(addExhibitionMasterUrl, params, headers)

        dispatch({
            type: ADD_EXHIBITION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const updateExhibitionMaster_action = (ExhibitionID, Name, Code, countryId, fromDate, toDate, bankDetails, countryList, stallTypeList, address, exhibitionLogo, exhibitionBanner, logoFileType, bannerFileType, logoFilePath, bannerFilePath, PIseries, currencyId, ExhMailWebsite, ExhMailEmail, ExhMailMobile) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            ExhibitionName: Name,
            Code: Code,
            CurrencyID: currencyId,
            CountryID: countryId,
            Address: address,
            ExhibitionDateFrom: fromDate != '' ? moment(fromDate).format('YYYY-MM-DD') : null,
            ExhibitionDateTo: toDate != '' ? moment(toDate).format('YYYY-MM-DD') : null,
            BankDetails: bankDetails,
            PI_Series: PIseries,
            countryList: countryList,
            stallTypeList: stallTypeList,
            logoFile: exhibitionLogo,
            logoFileType: logoFileType,
            bannerFile: exhibitionBanner,
            bannerFileType: bannerFileType,
            logoPath: logoFilePath,
            bannerPath: bannerFilePath,
            ExhMailWebsite: ExhMailWebsite,
            ExhMailEmail: ExhMailEmail,
            ExhMailMobile: ExhMailMobile,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosPost(updateExhibitionMasterUrl, params, headers)

        dispatch({
            type: UPDATE_EXHIBITION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const getExhibitionMasterDetails_action = (ExhibitionID,) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(getExhibitionMasterDetailsUrl, params, headers)

        dispatch({
            type: GET_EXHIBITION_MASTER_DETAILS,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteExhibitionMaster_action = (ExhibitionID,) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
        }
        console.log("getVisaInvitation_action Action------->", params)

        const res = await axiosGet(deleteExhibitionMasterUrl, params, headers)

        dispatch({
            type: DELETE_EXHIBITION_MASTER,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}

export const deleteExhibitionDocument_action = (ExhibitionID, deleteKey) => async (dispatch) => {
    try {
        const params = {
            UserID: JSON.parse(sessionStorage.getItem('loginDataSS')).UserID,
            ExhibitionID: ExhibitionID,
            flag: deleteKey
        }
        console.log("deleteExhibitionDocument_action Action------->", params)

        const res = await axiosGet(deleteExhibitionDocumentUrl, params, headers)

        dispatch({
            type: DELETE_EXHIBITION_DOCUMENT_ACTION_TYPE,
            payload: res,
        });
        return Promise.resolve(res)
    } catch (error) {
        return Promise.reject(error)
    }
}