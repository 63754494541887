import { Component, forwardRef } from "react";
import Header from "../../../common_components/Header";
import Loader from "../../../utils/Loader";
import { ToastContainer } from "react-toastify";
import Pagination from "../../../utils/pagination";
import { withRouter } from "../../../utils/withRouter";
import { connect } from "react-redux";
import { fill_action } from '../../../actions/fillCombo_action';
import { downloadReport, exhibitorTypeActionId, toastError, toastSuccess } from "../../../utils/constants";
import AdminHeader from "../../../common_components/admin_module_header";
import AdminDrawer from "../../../common_components/admin_module_drawer";
import Workbook from 'react-excel-workbook'
import Modal from "react-modal";
import Footerwithdr from '../../../common_components/Footerwithdr';
import HeaderWithLogo from "../../../common_components/header_with_logo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExhibitorHeader from "../../../common_components/exhibitor_module_header";
import ExhibitorDrawer from "../../../common_components/exhibitor_module_drawer";
import ExhibitorTopMenu from "../../../common_components/exhibitor_module_topmenu";
import { getLedgerList_action, sendLedger_action, sendOutstanding_action } from "../../../actions/admin_module_action/ledger_action/ledger_action";
import { exhibitorNameActionId, accountTypeActionId, currencyActionId } from "../../../utils/constants";

const styles = {
    width: '100%',
};

class LedgerList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            ledgerList: [],
            currentPage: 1,
            isDisable: true,
            allSelected: false,
            fromAmount: '',
            toAmount: '',
            exhibitorId: '',
            exhibitorList: [],
            accountTypeId: '',
            accountTypeList: [],
            exhibitorTypeList: [],
            currencyId: '',
            currencyList: [],
            exhibitorTypeId: "",
            loginAccesslist: [],
        }
        this.updateItem = this.updateItem.bind(this)
        this.getDownloadAccess = this.getDownloadAccess.bind(this)
        this.getSendAccess = this.getSendAccess.bind(this)
    }

    componentDidMount() {
        this.setState({ loginAccesslist: JSON.parse(sessionStorage.getItem('loginDataSS'))['loginAccesslist'] })
        this.getFillList(exhibitorNameActionId, '', '', '', '', '');
        this.getFillList(accountTypeActionId, '', '', '', '', '');
        this.getFillList(currencyActionId, '', '', '', '', '');
        this.getFillList(exhibitorTypeActionId, '', '', '', '', '');


        this.getLedgerList()
    }

    // get fill List dropdown  
    getFillList(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID) {
        const { } = this.state
        this.setState({ isLoading: true })
        this.props
            .fill_action(actionID, exhibitionID, moduleID, keyValue, value, exhRegistrationID)
            .then((data) => {
                if (data.error != 1) {
                    // voucher list
                    if (actionID === exhibitorNameActionId) {
                        // console.log("exhibitorList", data.data)
                        this.setState({
                            exhibitorList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === accountTypeActionId) {
                        // console.log("accountTypeList", data.data)
                        this.setState({
                            accountTypeList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === currencyActionId) {
                        // console.log("voucher type data", data.data)
                        this.setState({
                            currencyList: data.data, isLoading: false,
                        })
                    }
                    if (actionID === exhibitorTypeActionId) {
                        console.log("Exhibitor Type list data", data.data)
                        this.setState({
                            exhibitorTypeList: data.data.length > 0 ? data.data : [],
                            isLoading: false
                        })
                    }
                }
                else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // Update Current Page
    updateItem(item) {
        const { ledgerList } = this.state
        this.setState({ currentPage: item });
        const recordsPerPage = 10;
        const indexOfLastRecord = item * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = ledgerList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(ledgerList.length / recordsPerPage)
    }

    getLedgerList() {
        const { fromAmount, toAmount, accountTypeId, currencyId, exhibitorId, exhibitorTypeId } = this.state
        this.setState({ isLoading: true })
        this.props.getLedgerList_action(exhibitorId, accountTypeId, currencyId, fromAmount, toAmount,
            exhibitorTypeId != "" && exhibitorTypeId != "Select Exhibitor Type" ? exhibitorTypeId : "",)
            .then((data) => {
                if (data.error != 1) {
                    data.data != '' && data.data != null ?

                        data.data.map((item) => {
                            item.Is_checked = 0
                        })

                        : <></>
                    console.log("data", data)
                    this.setState({ isLoading: false, ledgerList: data.data })

                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    sendLedger() {
        const { ledgerList } = this.state
        const list = []
        ledgerList.map((data) => {
            if (data.Is_checked == 1) {
                list.push(
                    {
                        ReceiptID: data.ReceiptID
                    }
                )
            }
        })
        console.log("ledgerList", list)
        this.setState({ isLoading: true })
        this.props.sendLedger_action(list)
            .then((data) => {
                if (data.error != 1) {
                    console.log("data", data)
                    this.setState({ isLoading: false })
                    this.getLedgerList()
                    toastSuccess("Ledger share successfully!")
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    sendOutstanding() {
        const { ledgerList } = this.state
        const list = []
        ledgerList.map((data) => {
            if (data.Is_checked == 1) {
                list.push(
                    {
                        ReceiptID: data.ReceiptID
                    }
                )
            }
        })
        console.log("ledgerList", list)
        this.setState({ isLoading: true })
        this.props.sendOutstanding_action(list)
            .then((data) => {
                if (data.error != 1) {
                    console.log("data", data)
                    this.setState({ isLoading: false })
                    this.getLedgerList()
                    toastSuccess("Outstanding share successfully!")
                } else {
                    this.setState({ isLoading: false })
                    toastError(data.msg)
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    checkedBoxList(pList, index, e) {
        const { ledgerList } = this.state
        const event = e.target.checked

        if (event == true) {
            pList.forEach((data, i) => {
                if (i === index) {
                    data.Is_checked = 1
                }
            });

        } else {
            pList.forEach((data, i) => {
                if (i === index) {
                    data.Is_checked = 0
                }
            });
        }

        console.log("pList", pList)

        const checkboxDisable = ledgerList.some(item => item.Is_checked == 1)

        // debugger
        if (checkboxDisable) {
            this.setState({ isDisable: false })
        }
        else {
            this.setState({ isDisable: true })
        }

        const allSelectedChecked = ledgerList.every(item => item.Is_checked == 1)

        // debugger
        if (allSelectedChecked) {
            this.setState({ allSelected: true })
        }
        else {
            this.setState({ allSelected: false })
        }

    }

    allSelectedCheckbox(e) {
        const { ledgerList } = this.state

        if (e.target.checked == true) {
            ledgerList.map((data) => {
                data.Is_checked = 1
            })
            this.setState({ allSelected: true, isDisable: false })
        } else {
            ledgerList.map((data) => {
                data.Is_checked = 0
            })
            this.setState({ allSelected: false, isDisable: true })
        }
    }

    handleClear() {
        const { ledgerList } = this.state
        ledgerList != '' ?
            ledgerList.map((data) => {
                data.Is_checked = 0
            }) : <></>
        this.setState({
            exhibitorId: '',
            currencyID: '',
            fromAmount: '',
            toAmount: '',
            accountTypeId: '',
            currencyId: '',
            isDisable: true,
            allSelected: false,
            exhibitorTypeId: ""
        })
        setTimeout(() => {
            this.getLedgerList()
        }, 100);
    }

    // to create excel file
    onCreateExcel() {
        const { ledgerList } = this.state
        var rowData = [];

        var columnArray = []

        const checkboxDisable = ledgerList.some(item => item.Is_checked == 1)

        var list = [...ledgerList]

        // debugger
        if (checkboxDisable) {
            list.map((data, index) => {
                if (data.Is_checked == 1) {
                    if (index == 0) {
                        delete data.ReceiptID;
                        delete data.ExhRegistrationID
                        Object.keys(data).map((key, index) => {
                            columnArray.push(key)
                        })

                    }
                }
            })
        }
        else {
            // for column data 
            list.map((data, index) => {
                if (index == 0) {
                    delete data.ReceiptID;
                    delete data.ExhRegistrationID
                    delete data.Is_checked
                    Object.keys(data).map((key, index) => {
                        columnArray.push(key)
                    })
                }
            })
        }

        if (checkboxDisable) {
            // for row data
            list.map((data, index) => {
                if (data.Is_checked == 1) {
                    rowData.push(data)
                }
            })
        } else {
            // for row data
            list.map((data, index) => {
                rowData.push(data)

            })
        }


        return (
            <div className="row text-center" style={{ marginLeft: "-1px" }} >
                <Workbook filename="RceiptList.xlsx" element={
                    <button type="button" className="btn rounded-0 btn-success"
                        disabled={!this.getDownloadAccess()} >
                        Download EXCEL
                    </button>
                }>
                    <Workbook.Sheet data={rowData} name="Sheet A">
                        {columnArray.map((value, index) =>
                            <Workbook.Column label={value} value={value} />
                        )
                        }
                    </Workbook.Sheet>
                </Workbook>
            </div>
        );
    }

    // to create excel file
    onCreateExcel() {
        const { ledgerList } = this.state
        console.log("xxxxxxxxxxxxxxxxx", ledgerList)

        const checkboxDisable = ledgerList.some(item => item.Is_checked == 1)

        var listData = []

        ledgerList.length > 0 && ledgerList.map((item, index) => {
            var mapData1 = {
                'SR No': index + 1,

            }
            var finalMapData = { ...mapData1, ...item }
            listData.push(finalMapData)
        })
        console.log('listData', listData);

        var excludeKeys = [
            'ReceiptID', 'ExhRegistrationID',
        ]

        // Function to remove keys from objects
        function removeKeysFromObjects(arr, keys) {
            return arr.map((obj) => {
                const newObj = { ...obj }; // Create a new object to avoid modifying the original
                keys.forEach((key) => delete newObj[key]); // Remove specified keys from the new object
                return newObj;
            });
        }

        // Remove keys from the array of objects
        const newArray = removeKeysFromObjects(listData, excludeKeys);
        console.log("new arrya-->", newArray);


        var columnArray = [],
            rowData = [];
        // for column data 

        newArray.map((data, index) => {
            if (index == 0) {
                Object.keys(data).map((key, index) => {
                    columnArray.push(key)
                })
            }
        })


        // for row data
        if (checkboxDisable) {
            newArray.map((data, index) => {
                if (data.Is_checked == 1) {
                    rowData.push(data)
                }
            })
        } else {
            newArray.map((data, index) => {
                rowData.push(data)
            })
        }
        // console.log("rowData", rowData)
        // this.setState({
        //     columnArrayData: columnArray,
        //     rowData: rowData
        // })

        // { this.renderList() }

        return (
            <div className="row text-center" style={{ marginLeft: "-1px" }}>
                {
                    ledgerList.length > 0 ? <Workbook filename="ledger_list.xlsx" style={{}} element={
                        <button type="button" className="btn rounded-0 btn-success"
                            disabled={!this.getDownloadAccess()}
                        >
                            Download EXCEL
                        </button>
                    }>
                        <Workbook.Sheet data={rowData} name="Sheet A">
                            {columnArray.map((value, index) =>
                                <Workbook.Column label={value} value={value} />
                            )
                            }
                        </Workbook.Sheet>
                    </Workbook> : <button type="button" className="btn rounded-0 btn-success" disabled={true}>
                        Download EXCEL
                    </button>}
            </div>
        );
    }

    getDownloadAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '5');
        if (moduleAccess) {
            return moduleAccess.DownloadAccess;
        } else {
            return false
        }
        return false;
    }

    getSendAccess() {
        const { loginAccesslist } = this.state
        const moduleAccess = loginAccesslist.find((item) => item.ModuleID == '5');
        if (moduleAccess) {
            return moduleAccess.SendAccess;
        } else {
            return false
        }
        return false;
    }

    render() {
        const { isLoading, ledgerList, exhibitorId, exhibitorList, currentPage, isDisable, allSelected, fromAmount, toAmount,
            accountTypeId, accountTypeList, currencyId, currencyList, exhibitorTypeList, exhibitorTypeId } = this.state

        const recordsPerPage = 10;
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
        const currentRecords = ledgerList.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(ledgerList.length / recordsPerPage)

        console.log("currentRecords", currentRecords)
        return (
            <>
                {isLoading ? (
                    <Loader></Loader>
                ) : (
                    null
                )}
                <ToastContainer />
                {
                    JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                        <ExhibitorHeader /> : <HeaderWithLogo />
                }
                <div className={JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ? "with-dr-container" : "with-dr-container com-height"}>
                    <div class="main-content">

                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorDrawer /> : <AdminDrawer />
                        }
                        {
                            JSON.parse(sessionStorage.getItem('loginDataSS')).RoleID == 1 ?
                                <ExhibitorTopMenu /> : null
                        }
                        <div class="row">
                            <div class="step-name">
                                <p>Ledger Details</p>
                            </div>
                        </div>

                        <div class="row mt-1 pt-3 pb-3 p-2">
                            <div className="col-md-3">
                                <label>Exhibitor</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={exhibitorId}
                                    onChange={(e) => this.setState({ exhibitorId: e.target.value })}
                                >
                                    <option>Select</option>
                                    {exhibitorList.length > 0 && exhibitorList.map((data) => (
                                        <option key={data.ExhRegistrationID
                                        } value={data.ExhRegistrationID
                                        }>{data.CompanyName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label>Account</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={accountTypeId}
                                    onChange={(e) => this.setState({ accountTypeId: e.target.value })}
                                >
                                    <option>Select</option>
                                    {accountTypeList.length > 0 && accountTypeList.map((data) => (
                                        <option key={data.ServiceID
                                        } value={data.ServiceID
                                        }>{data.ServiceName}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label>Currency</label>
                                <select
                                    className="form-control"
                                    id="roledb"
                                    // style={{ textAlign: "left" }}
                                    value={currencyId}
                                    onChange={(e) => this.setState({ currencyId: e.target.value })}
                                >
                                    <option>Select</option>
                                    {currencyList.length > 0 && currencyList.map((data) => (
                                        <option key={data.CurrencyID
                                        } value={data.CurrencyID
                                        }>{data.CurrencyName}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="col-md-3 pr-0">
                                <label for="validationCustom03">Pending Amount</label>
                                <div class="row">
                                    <div class="col-md-5">
                                        <input type="text"
                                            value={fromAmount}
                                            onChange={(e) => this.setState({ fromAmount: e.target.value })}
                                            class="form-control" name="" />
                                    </div>
                                    <div class="col-md-1 p-0 text-center">
                                        to
                                    </div>
                                    <div class="col-md-5">
                                        <input type="text"
                                            value={toAmount}
                                            onChange={(e) => this.setState({ toAmount: e.target.value })}
                                            class="form-control" name="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-1 pt-3 pb-3 p-2">
                            <div className="col-md-3 ">
                                <label htmlFor="validationCustom03">Exhibitor Type</label>
                                <select className="form-control"
                                    value={exhibitorTypeId}
                                    onChange={(e) => this.setState({ exhibitorTypeId: e.target.value })}
                                >
                                    <option>Select Exhibitor Type</option>
                                    {
                                        exhibitorTypeList.length > 0 && exhibitorTypeList.map((data) => (
                                            <option key={data.ID} value={data.ID}>{data.ExhibitorType}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div class="row p-2 mb-2">
                            <div class="btn-group w-auto">
                                <button class="btn rounded-0 btn-primary"
                                    onClick={() => this.getLedgerList()}
                                    style={{ padding: "4px 13px" }}>Search</button>
                                <button class="btn rounded-0 btn-primary"
                                    onClick={() => this.handleClear()}
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}>Clear</button>

                                {this.onCreateExcel()}

                                {/* <button class="btn rounded-0 btn-info"
                                        style={{ padding: "4px 13px", marginLeft: "10px" }}
                                        disabled={isDisable}
                                    >Export</button> */}

                                <button class="btn rounded-0 btn-dark"
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}
                                    disabled={isDisable == false ? (!this.getSendAccess()) : true}
                                    onClick={() => this.sendOutstanding()}
                                >Outstanding</button>
                                <button class="btn rounded-0 btn-warning"
                                    style={{ padding: "4px 13px", marginLeft: "10px" }}
                                    disabled={isDisable == false ? (!this.getSendAccess()) : true}
                                    onClick={() => this.sendLedger()}
                                >Send Ledger</button>
                            </div>

                        </div>

                        <div class="table-responsive mb-5 p-2">
                            <table class="table border text-nowrap text-md-nowrap table-bordered mb-0 ">
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            <input type="checkbox"
                                                onClick={(e) => this.allSelectedCheckbox(e)}
                                                checked={allSelected}
                                            /></th>
                                        <th>Exhibiter Name</th>
                                        <th>Type</th>
                                        <th>Account</th>
                                        <th>Total Payable</th>
                                        <th>Receipt Amount</th>
                                        <th>Credit Amount</th>
                                        <th>Refund Amount</th>
                                        <th>Pending</th>
                                        <th>GST</th>
                                        <th>Total Pending</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentRecords.length > 0 && currentRecords != null ?
                                            currentRecords.map((data, i) => (
                                                <tr>
                                                    <td class="text-center"> <input type="checkbox"
                                                        onClick={(e) => this.checkedBoxList(currentRecords, i, e)}
                                                        checked={data.Is_checked}
                                                        class="" name=""
                                                    /> </td>
                                                    <td>{data.CompanyName}</td>
                                                    <td>{data.ExhibitorType}</td>
                                                    <td>{data.Narration}</td>
                                                    <td style={{ textAlign: 'right' }}>{data.TotalPayable}</td>
                                                    <td style={{ textAlign: 'right' }}>{data.ReceiptAmount}</td>
                                                    <td style={{ textAlign: 'right' }}>{data.CreditAmount}</td>
                                                    <td style={{ textAlign: 'right' }}>{data.RefundAmount}</td>
                                                    <td style={{ textAlign: 'right' }}>{data.Pending}</td>
                                                    <td style={{ textAlign: 'right' }}>{data.GST}</td>
                                                    <td style={{ textAlign: 'right' }}>{data.TotalPending}</td>

                                                </tr>
                                            )) : (<tr><td colSpan={10} className='text-center'>Record not found</td></tr>)
                                    }

                                </tbody>
                            </table>
                            <div className="mt-2 float-left">
                                {
                                    ledgerList.length > 0 ?
                                        <Pagination
                                            nPages={nPages}
                                            currentPage={currentPage}
                                            setCurrentPage={this.updateItem}
                                        /> : <></>
                                }
                                <div className="tab-rec " style={{ float: "right" }}>
                                    <p>{`${currentPage} of ${nPages} pages`}</p>
                                </div>
                            </div>
                        </div>

                        <div class="footer-sec ">
                            <div class="sdsd">
                                <strong>Ownership:</strong> All content, formats and images used on this site are owned or licensed by India ITME Society. You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any other way exploit any part of copyrighted material without permission of the copyright owner. Failure to comply shall result in legal action.
                            </div>
                        </div>

                        <div class="footer-first">
                            <div class="container-fluid">
                                <div class="footer-first">  ITME All Rights Reserved <i class="fa fa-copyright" aria-hidden="true"></i> 2023  </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}
export default withRouter(connect(null, { getLedgerList_action, sendLedger_action, sendOutstanding_action, fill_action })(LedgerList));

