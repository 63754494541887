import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../utils/Loader";
import { exhRegUrl, userManualDownloadUrl } from '../utils/constants';
import Modal from "react-modal";
import StepHeader from '../screens/registration/stepHeader'
import ExhibitorTopMenu from "../common_components/exhibitor_module_topmenu";


Modal.setAppElement("#root");


// {head,subHeader}
const AgentHeader = () => {

    const history = useNavigate();
    const [isLoading, setLoader] = useState(false);
    const [agentCode, setAgentCode] = useState("")


    const loadScripts = () => {
        // This array contains all the files/CDNs 
        // const dynamicScripts = [
        //   'js/jquery.sticky.js',
        //   'js/metisMenu.min.js',
        //   'js/metisMenu-active.js',
        //   'js/main.js',
        //   'js/azia.js'
        // ];

        // for (let i = 0; i < dynamicScripts.length; i++) {
        //   const node = document.createElement('script');
        //   node.src = dynamicScripts[i];
        //   node.type = 'text/javascript';
        //   node.async = false;
        //   document.getElementsByTagName('head')[0].appendChild(node);
        // }
    }

    useEffect(() => {
        loadScripts();
        // console.log("sessionStorage.getItem(sessionExhibitorDrawerData)------->", sessionStorage.getItem(sessionExhibitorDrawerData))
        var agentCode = sessionStorage.getItem('IsAgentDirectLogin') != null && sessionStorage.getItem('IsAgentDirectLogin') == "false" ?
            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS'))['AgentCode'] != null &&
            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS'))['AgentCode'] :
            JSON.parse(sessionStorage.getItem('loginDataSS'))['AgentCode'] != null &&
            JSON.parse(sessionStorage.getItem('loginDataSS'))['AgentCode']

        setAgentCode(agentCode)

    }, []);



    const onLogoutClick = () => {
        localStorage.clear();
        // history.push('/login');
    }

    const openPdf = () => {
        var URL = userManualDownloadUrl /* http://0.0.0.0:8000/ or http://127.0.0.1:8000/; */
        window.open(URL, null);
    }

    return (
        <>
            {isLoading ? (
                <Loader></Loader>
            ) : (
                null
            )}
            <>
                {/* app-Header */}
                <div className="app-header header sticky app-heder-with-logo">
                    <div className="container-fluid main-container">
                        <div className="d-flex">
                            {/*  <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" href="javascript:void(0)"><i class="fa fa-bars"></i></a>  */}
                            {/* sidebar-toggle*/}
                            <h5 className="m-0 exbheader">
                                {" "}
                                {sessionStorage.getItem('ExhibitionFromDateSS')} - {sessionStorage.getItem('ExhibitionToDateSS')} <br />
                                {sessionStorage.getItem('ExhibitionAddressSS')}{" "}
                            </h5>
                            <div className="right-logo-logo">
                                <img src="images/itme-right-logo.png" style={{ width: 70 }} />
                            </div>
                            <p />
                            <a className="logo-horizontal" href="index.html">
                                <img
                                    src="images/itme-right-logo.png"
                                    className="header-brand-img light-logo1"
                                    alt="logo"
                                />
                            </a>
                            {/* LOGO */}
                            {/*  <div class="main-header-center ms-3 d-none d-lg-block">
                      <input type="text" class="form-control" id="typehead" placeholder="Search for results...">
                      <button class="btn px-0 pt-2"><i class="fe fe-search" aria-hidden="true"></i></button>
                  </div> */}
                            <div className="d-flex order-lg-2 ms-auto header-right-icons">
                                {/* SEARCH */}
                                <button
                                    className="navbar-toggler navresponsive-toggler d-lg-none ms-auto"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent-4"
                                    aria-controls="navbarSupportedContent-4"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon fe fe-more-vertical" />
                                </button>
                                <div className="navbar navbar-collapse responsive-navbar p-0">
                                    <div
                                        className="collapse navbar-collapse"
                                        id="navbarSupportedContent-4"
                                    >
                                        <div className="d-flex order-lg-2">
                                            <div className="dropdown d-lg-none d-flex">
                                                <a
                                                    href="javascript:void(0)"
                                                    className="nav-link icon"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    <i className="fe fe-search" />
                                                </a>
                                                <div className="dropdown-menu header-search dropdown-menu-start">
                                                    <div className="input-group w-100 p-2">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search...."
                                                        />
                                                        <div className="input-group-text btn btn-primary">
                                                            <i className="fa fa-search" aria-hidden="true" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex country">
                                                <a className="nav-link icon text-center">
                                                    {/* <span class="fs-16 ms-2 d-none d-xl-block">Admin</span> */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-sle">
                        {/* <div className="user-sle1">
                            <spa style={{ width: '180px', display: "inline-block", textTransform: "uppercase" }}>
                                <i className="fa fa-building-o mr-1" />   {sessionStorage.getItem('sessionHeaderData') != null &&
                                    JSON.parse(sessionStorage.getItem('sessionHeaderData'))[0]['CompanyName']}
                            </spa>
                        </div> */}
                        <div className="user-sle2 mt-1">
                            <i className="fa fa-user-o" aria-hidden="true" />{" "}
                            {
                                sessionStorage.getItem('IsAgentDirectLogin') != null && sessionStorage.getItem('IsAgentDirectLogin') == "false" ?
                                    <span style={{ textTransform: "uppercase" }}>
                                        {JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS'))['FirstName'] != null &&
                                            JSON.parse(sessionStorage.getItem('indirectAgentloginDataSS'))['FirstName']}</span> :
                                    <span style={{ textTransform: "uppercase" }}>
                                        {JSON.parse(sessionStorage.getItem('loginDataSS'))['FirstName'] != null &&
                                            JSON.parse(sessionStorage.getItem('loginDataSS'))['FirstName']}</span>
                            }

                        </div>
                        <div className="mt-1">
                            <a href={`/#/${sessionStorage.getItem("sessionExhibitionCode")}-${agentCode}`} className='change-pas' target="_blank"><i class="fa fa-share-alt" ></i> Share Link to Exhibitor</a>
                        </div>
                    </div>

                </div>

                {/* /app-Header */}
            </>

        </>
    );
}
export default AgentHeader;

